.close_trigger {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 80px;
  z-index: 33333;
  display: none;
}

// ----Banneri homepage-----//
.banner_cover_img {
  float: left;
  @include rem('height', 700px);
  width: 100%;
  position: relative;
  background-image: url('../images/banner_cover_img.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @extend %imgCropped;

  .banner_text {
    @extend %centered;
    color: white;
    text-align: center;

    h1 {
      @include rem('font-size', 72px);
      font-weight: 700;
      @include rem('margin-bottom', 40px);
    }

    h2 {
      @include rem('font-size', 60px);
      font-weight: 300;
    }

    @include mobile {
      top: 39%;

      h1 {
        font-size: 50px;
        margin-bottom: 8px;
      }

      h2 {
        font-size: 28px;
      }
    }
  }

  @include tablet {
    background-image: url('../images/ak_cover_tablet.png');

    .banner_text {
      width: 80%;
    }
  }

  @include tablet-big {
    background-image: url('../images/ak_cover_tablet.png');

    .banner_text {
      width: 80%;
    }
  }

  @include mobile {
    height: 470px;
    background-image: url('../images/ak_cover_mobile.png');

    .banner_text {
      width: 80%;
    }
  }
}

// Go top button
#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #231f20;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
  transition: $transition1;

  &:hover {
    i {
      color: #e2001a;
    }
  }
}

// ------Kontribo-Antaresohu tabs- Homepage-----//
.kontribo_anetaresohu {
  @include rem('width', 700px);
  @include rem('height', 241px);
  background: #fff;
  position: absolute;
  top: 100%;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  &:before {
    left: 0;
    bottom: -4%;
    content: '';
    width: 100%;
    height: 53%;
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
    transform: scale(1.03);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  ul {
    float: left;
    width: 100%;

    .kontribo {
      float: left;
      width: 50%;
      display: flex;
      align-items: center;
      cursor: pointer;
      @include rem('height', 98px);
      border-bottom-right-radius: 25px;
      @include rem('padding-left', 40px);

      &:not(.active) {
        -webkit-box-shadow: inset 0px -19px 148px -58px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: inset 0px -19px 148px -58px rgba(0, 0, 0, 0.25);
        box-shadow: inset 0px -19px 148px -58px rgba(0, 0, 0, 0.25);
      }

      .kontribo_tab_text {
        h4 {
          float: left;
          width: 100%;
          @include rem('font-size', 24px);
          color: #e2001a;
          font-weight: 700;
        }

        p {
          float: left;
          width: 100%;
          @include rem('font-size', 16px);
          font-weight: 300;
        }
      }

      @include mobile {
        height: 60px;
        padding-left: 0;

        .kontribo_tab_text {
          width: 100%;
          text-align: center;

          h4 {
            @include rem('font-size', 20px);
          }
        }
      }
    }

    .antaresohu {
      float: left;
      width: 50%;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-bottom-left-radius: 25px;
      @include rem('height', 98px);
      @include rem('padding-right', 40px);

      &:not(.active) {
        -webkit-box-shadow: inset 0px -19px 148px -58px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: inset 0px -19px 148px -58px rgba(0, 0, 0, 0.25);
        box-shadow: inset 0px -19px 148px -58px rgba(0, 0, 0, 0.25);
      }

      .antaresohu_tab_text {
        text-align: right;
        width: 100%;

        h4 {
          float: left;
          width: 100%;
          @include rem('font-size', 24px);
          color: #11ad8e;
          font-weight: 700;
        }

        p {
          float: left;
          width: 100%;
          @include rem('font-size', 16px);
          font-weight: 300;
        }
      }

      @include mobile {
        height: 60px;
        padding-left: 0;
        padding-right: 0 !important;

        .antaresohu_tab_text {
          width: 100%;
          text-align: center;
          padding-left: 0;

          h4 {
            @include rem('font-size', 20px);
          }
        }
      }
    }
  }

  .kontribo_antaresohu_tabs_content {
    flex: 1;
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: white;

    .kontribo_forma {
      float: left;
      width: 100%;
      @include rem('padding', 30px);

      form {
        float: left;
        width: 100%;

        &>:nth-child(2n+2) {
          @include rem('margin-right', 0);
        }

        .kontribo_form_wrap {
          @extend %fullBlock;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;

          @include mobile {
            margin-top: 15px;
          }
        }

        .input_holder {
          float: left;
          transition: $transition1;
          margin-right: 20px;

          input:checked {
            ~.kontribo_button {
              border-color: transparent;
              transition: $transition1;
              box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);

              i,
              span {
                color: #e2001a;
                transition: $transition1;
              }
            }
          }

          @include mobile {
            margin-right: 15px;

            &:nth-child(2n+2) {
              margin-right: 0;
            }

            i {
              font-size: 15px;
            }

            span {
              font-size: 14px;
            }
          }
        }

        .kontribo_button {
          float: left;
          @include rem('width', 100px);
          @include rem('height', 50px);
          text-align: center;
          border: 1px solid #e6e6e6;
          transition: $transition1;

          input[type=text],
          input[type=number] {
            float: left;
            width: 60px;
            border: none;
            font-size: 16px;
            font-weight: 700;
            text-align: right;
            padding-right: 15px;
            line-height: 44px;
            background: transparent;
            border: 0;
          }

          input[type=text] {
            text-align: center;
            padding-right: 0;
          }

          i {
            float: left;
            width: 43px;
            font-size: 18px;
            text-align: center;
            line-height: 44px;
          }

          span {
            line-height: 46px;
            font-weight: 700;
            font-size: 16px;
          }

          &:hover {
            border-color: transparent;
            box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);

            label {
              color: #e2001a;
              transition: $transition1;
            }
          }

          @include mobile {
            width: 120px;
            height: 40px;
            margin-bottom: 15px;

            i, span {
              line-height: 39px;
            }
          }
        }

        button {
          float: left;
          @include rem('height', 50px);
          width: 140px;
          background: #e2001a;
          @include rem('font-size', 18px);
          font-weight: 700;
          color: white;
          cursor: pointer;
          text-transform: uppercase;
          text-decoration: none;
          border-radius: 3px;
          @include flexCenter(true, true);
          box-shadow: none !important;
          border-color: rgba(0, 0, 0, 0) !important;
          outline: none !important;

          @include mobile {
            width: 255px;
            height: 40px;
            font-size: 14px;
          }
        }
      }

      @include mobile {
        padding: 0;
        padding-bottom: 40px;

        p {
          display: block;
          text-align: center;
          margin-bottom: 20px;
          margin-top: 20px;
        }
      }
    }

    .bashkohu_me_vv {
      @include mobile {
        display: block;
        text-align: center;
        margin: 20px 0;
        color: #231f20;
      }
    }

    #antaresohu_tab {
      float: left;
      width: 100%;
      display: flex;
      justify-content: center;

      .dhuro_vlerat {
        float: left;
        @include rem('width', 140px);
        height: auto;
        @include rem('margin-right', 20px);

        &:last-child {
          @include rem('margin-right', 0);
        }

        label {
          float: left;
          width: 100%;
          font-size: 13px;
          color: #231f20;
          @include rem('margin-bottom', 10px);
        }

        input {
          float: left;
          width: 100%;
          @include rem('width', 140px);
          @include rem('height', 50px);
          margin-left: auto;
          text-align: left;
          border: 1px solid #e6e6e6;
          border-radius: 2px;
          @include rem('padding-left', 10px);
          font-size: 16px;
          color: #231f20;
          font-weight: 700;
          transition: $transition1;

          &:hover,
          &:focus {
            outline: none !important;
            border-color: transparent;
            box-shadow: 0 0 125px 3px rgba(0, 0, 0, .18);
          }
        }
      }

      button {
        float: left;
        @include rem('width', 140px);
        @include rem('height', 50px);
        background: #11ad8e;
        color: #fff;
        font-weight: 700;
        box-shadow: none !important;
        border-radius: 2px;
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;
        border-color: rgba(0, 0, 0, 0) !important;
        outline: none !important;
      }

      @include mobile {
        padding: 15px 30px 40px 30px;
        flex-direction: column;

        .dhuro_vlerat {
          margin-right: 0;
          float: left;
          width: 100%;
          margin-bottom: 15px;

          label {
            margin-bottom: 5px;
          }

          input {
            width: 100%;
            height: 40px;
          }

          #transparent_label {
            display: none;
          }

          button {
            width: 100%;
            height: 40px;
            font-size: 14px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @include tablet {
    width: 100%;
  }

  @include tablet-big {
    width: 100%;
  }

  @include mobile {
    width: 90%;
    height: auto;
    border-radius: 3px;
    -webkit-box-shadow: 0px 0px 97px -11px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 97px -11px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 97px -11px rgba(0, 0, 0, 0.2);

    &:before {
      display: none;
    }
  }
}

// -----MEDIA GRID - homepage------//
.media_homepage {
  float: left;
  width: 100%;
}

.media_readmore {
  float: left;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  @include rem('padding-top', 190px);
  @include rem('margin-bottom', 52px);

  span, h2, p {
    display: inline-block;
  }

  span {
    content: " ";
    background-image: url('../images/dash.svg');
    @include rem('height', 5px);
    @include rem('width', 20px);
  }

  h2 {
    @include rem('font-size', 27px);
    font-weight: 700;
    color: #231f20;
    @include rem('margin-left', 29px);
  }

  a {
    @include rem('font-size', 15px);
    font-weight: 300;
    color: #666;
    margin-left: auto;
    text-decoration: none;

    &:hover {
      color: #e2001a;

      .read_more_arrow {
        content: " ";
        background-image: url('../images/lexomeshume-red.svg');
        @include rem('height', 8px);
        @include rem('width', 14px);
        @include rem('margin-left', 22px);
      }
    }
  }

  .read_more_arrow {
    content: " ";
    background-image: url('../images/lexomeshume.svg');
    @include rem('height', 8px);
    @include rem('width', 14px);
    @include rem('margin-left', 22px);
  }

  @include tablet {
    a {
      color: #e2001a;

      .read_more_arrow {
        content: " ";
        background-image: url('../images/lexomeshume-red.svg');
        @include rem('height', 8px);
        @include rem('width', 14px);
        @include rem('margin-left', 22px);
      }
    }
  }

  @include tablet-big {
    a {
      color: #e2001a;

      .read_more_arrow {
        content: " ";
        background-image: url('../images/lexomeshume-red.svg');
        @include rem('height', 8px);
        @include rem('width', 14px);
        @include rem('margin-left', 22px);
      }
    }
  }

  @include mobile {
    padding-top: 225px;
    margin-bottom: 25px;

    .see_more_lajme {
      display: block;
      color: #e2001a !important;
      font-weight: 700;
    }
  }
}

.media_grid_view {
  @include fullBlock;
  margin-bottom: 90px;

  @include tablet {
    display: flex;
    flex-wrap: wrap;

    .lajmet_block {
      order: 1;
    }

    .lajmet_e_fundit_block {
      order: 3;
    }

    .media_grid_bottom_block {
      order: 2;
    }
  }

  @include mobile {
    height: auto;

    .lajmet_block, .lajmet_e_fundit_block, .media_grid_bottom_block {
      width: 100%;
      margin-right: 0;
      height: auto;
    }

    .lajmet_e_fundit_block, .media_grid_bottom_block {
      display: none;
    }
  }
}

.lajmet_block {
  float: left;
  width: 950px;
  margin-right: 40px;
  height: 414px;

  @include tablet {
    height: auto;
    width: 100%;
    margin-right: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .mini_lajmet {
    float: left;
    width: 290px;
    height: auto;
    margin-right: 40px;

    @include tablet {
      width: 100%;
      float: left;
      margin-right: 0;
      display: flex;
      margin-top: 40px;
    }

    @include mobile {
      width: 100%;
      margin-right: 0;
      margin-bottom: 40px;
    }

    .lajmi_item {
      float: left;
      width: 100%;
      height: 187px;
      margin-bottom: 40px;
      position: relative;

      @include tablet {
        width: calc(50% - 20px);

        &:last-child {
          margin-left: auto;
        }
      }

      &:before {
        content: " ";
        position: absolute;
        height: 50%;
        width: 100%;
        left: 0;
        bottom: 0;
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        z-index: 1;
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
        /* IE6-9 */
      }

      img {
        @extend %imgCropped;
      }

      .column_item_text {
        position: absolute;
        top: 106px;
        left: 30px;
        right: 30px;
        bottom: 28px;
        z-index: 3;

        p {
          @include rem('font-size', 12px);
          @include rem('margin-bottom', 8px);
          font-weight: 300;
          color: white;
          cursor: default;

          span {
            @include rem('margin-right', 10px);
            @include rem('margin-left', 10px);
          }
        }

        a {
          @include rem('font-size', 16px);
          font-weight: 700;
          line-height: 21px;
          text-decoration: none;
          color: white;
          transition: $transition1;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          /* number of lines to show */
          max-height: 42px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        cursor: pointer;

        a {
          text-decoration: underline;
        }
      }
    }
  }

  .lajmi_item_big {
    float: left;
    width: 620px;
    height: 100%;
    position: relative;

    &:before {
      content: " ";
      position: absolute;
      height: 50%;
      width: 100%;
      left: 0;
      bottom: 0;
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
      z-index: 1;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
      /* IE6-9 */
    }

    img {
      @extend %imgCropped;
    }

    .column_item_text {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 61px;
      width: 100%;
      //left: 61px;
      //right: 61px;
      //bottom: 61px;
      z-index: 3;

      @include mobile {
        padding: 0;
      }

      p {
        @include rem('font-size', 12px);
        @include rem('margin-bottom', 8px);
        font-weight: 300;
        color: white;
        cursor: default;

        span {
          @include rem('margin-right', 10px);
          @include rem('margin-left', 10px);
        }
      }

      a {
        @include rem('font-size', 24px);
        font-weight: 700;
        line-height: 27px;
        text-decoration: none;
        color: white;
        transition: $transition1;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        max-height: 54px;
      }
    }

    &:hover {
      cursor: pointer;

      a {
        text-decoration: underline;
      }
    }

    @include tablet {
      float: left;
      margin-right: 0;
      width: 100%;
      height: auto;
    }

    @include mobile {
      width: 100%;
      margin-right: 0;
    }
  }
}

//DEBUG
/* * {
  &:before,&:after {
    outline: 1px solid red;
    visibility: visibility;
    opacity: 1;
  }
} */
//END DEBUG
.lajmet_e_fundit_block {
  float: right;
  width: 290px;
  height: 415px;
  box-sizing: border-box;
  @include rem('padding', 41px);
  @include rem('margin-bottom', 40px);
  background: #fff;
  -webkit-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);

  .te_fundit_heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include rem('margin-bottom', 35px);

    h3 {
      @include rem('font-size', 18px);
      font-weight: 700;
      @include rem('margin-left', 17px);
      text-transform: uppercase;
    }

    span {
      content: " ";
      background-image: url('../images/dash.svg');
      @include rem('height', 5px);
      @include rem('width', 20px);
    }
  }

  .te_fundit_item {
    @include rem('margin-bottom', 27px);
    @extend %fullBlock;

    p {
      @include rem('font-size', 12px);
      @include rem('margin-bottom', 8px);
      font-weight: 300;
      color: #666666;
      line-height: 8px;
      cursor: default;
      @extend %fullBlock;

      span {
        @include rem('margin-right', 10px);
        @include rem('margin-left', 10px);
      }
    }

    a {
      @include rem('font-size', 14px);
      @extend %fullBlock;
      font-weight: 700;
      line-height: 18px;
      text-decoration: none;
      color: #231f20;
      transition: $transition1;
      overflow: hidden;
      max-height: 36px;

      &:hover {
        color: #e2001a;
      }
    }
  }

  @include tablet {
    width: 100%;
    margin-right: 0;
  }

  @include tablet-big {
    margin-top: 40px;
  }
}

.media_grid_bottom_block {
  @include fullBlock;

  .bottom_block_item {
    float: left;
    width: 290px;
    height: 100px;
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  .first_column_lajme {
    float: left;
    width: 100%;
    height: 100%;
    background: #11ad8e;
    position: relative;

    .lajme_icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 38px;
      @include rem('width', 41px);
      @include rem('height', 28px);
    }

    .lajme_text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 100px;

      .lajme_text_heading {
        @include rem('font-size', 18px);
        color: white;
        font-weight: 700;
        text-decoration: none;
      }

      p {
        @include rem('font-size', 14px);
        color: white;
        opacity: .5;
        @include rem('margin-top', 6px);
      }
    }
  }

  .second_column_intervistat {
    float: left;
    width: 100%;
    height: 100%;
    background: #e2001a;
    position: relative;

    .lajme_icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 38px;
      @include rem('width', 38px);
      @include rem('height', 34px);
    }

    .lajme_text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 100px;

      .lajme_text_heading {
        @include rem('font-size', 18px);
        color: white;
        font-weight: 700;
        text-decoration: none;
      }

      p {
        @include rem('font-size', 14px);
        color: white;
        opacity: .5;
        @include rem('margin-top', 6px);
      }
    }
  }

  .second_column_qendrat {
    float: left;
    width: 100%;
    height: 100%;
    background: #231f20;
    position: relative;

    .lajme_icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 38px;
      @include rem('width', 38px);
      @include rem('height', 34px);
    }

    .lajme_text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 100px;

      .lajme_text_heading {
        @include rem('font-size', 18px);
        color: white;
        font-weight: 700;
        text-decoration: none;
      }

      p {
        @include rem('font-size', 14px);
        color: white;
        opacity: .5;
        @include rem('margin-top', 6px);
      }
    }
  }

  .third_column_youtube {
    float: left;
    width: 100%;
    height: 100%;
    background: #666666;
    position: relative;

    .lajme_icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 38px;
      @include rem('width', 33px);
      @include rem('height', 37px);
    }

    .lajme_text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 100px;

      .lajme_text_heading {
        @include rem('font-size', 18px);
        color: white;
        font-weight: 700;
        text-decoration: none;
      }

      p {
        @include rem('font-size', 14px);
        color: white;
        opacity: .5;
        @include rem('margin-top', 6px);
      }
    }
  }

  @include tablet {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .bottom_block_item {
      margin-right: 0;
      width: calc(50% - 20px);
      margin-bottom: 40px;
    }
  }

  @include tablet-big {
    float: left;
    width: calc(100% - 290px);
    clear: none;
    margin-top: 40px;

    .bottom_block_item {
      margin-bottom: 40px;
    }
  }
}

// -------ABONOHU - homepage------//
.gray_banner {
  float: left;
  width: 100%;
  @include rem('height', 308px);
  position: relative;
  background-image: url('../images/gray_banner.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @extend %imgCropped;
  @include rem('padding-top', 87px);

  .abonohu_text {
    display: flex;
    flex-direction: row;
    align-items: center;

    span, h2, p {
      display: inline-block;
    }

    span {
      content: " ";
      background-image: url('../images/dash.svg');
      @include rem('height', 5px);
      @include rem('width', 20px);
    }

    h2 {
      @include rem('font-size', 27px);
      font-weight: 700;
      color: white;
      @include rem('margin-left', 29px);
    }

    p {
      @include rem('font-size', 15px);
      font-weight: 300;
      color: white;
      @include rem('margin-left', 50px);
    }

    @include tablet {
      width: 100%;
      display: inline-block;

      p {
        float: left;
        width: 90%;
        margin-top: 30px;
      }

      h2 {
        margin-left: 48px;
      }

      span {
        position: absolute;
        top: 10px;
        margin-right: 20px;
      }
    }

    @include tablet-big {
      width: 100%;
      display: inline-block;

      p {
        float: left;
        width: 90%;
        margin-top: 30px;
      }

      h2 {
        margin-left: 48px;
      }

      span {
        position: absolute;
        top: 10px;
        margin-right: 20px;
      }
    }
  }

  #register_form, .register_form {
    float: left;
    width: 100%;
    @include rem('margin-top', 59px);

    form {
      width: 100%;

      .mc4wp-form-fields {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }

    input, .selectdiv, button {
      float: left;
      @include rem('height', 52px);
      @include rem('width', 289px);
      font-size: 15px;
      border: none;

      @include desktop-small {
        width: calc(25% - 20px);
        margin-right: 0;
      }

      @include tablet {
        width: 48%;
        margin: 0;
      }

      @include tablet-big {
        width: 48%;
        margin: 0;
      }

      @include mobile {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0;
      }
    }

    select {
      padding-right: 25px;
    }

    input {
      @include desktop-small {
        padding-right: 25px;
      }

      @include tablet {
        margin-bottom: 30px;
      }

      @include tablet-big {
        margin-bottom: 30px;
      }
    }

    input:focus,
    button:focus {
      outline: none !important;
    }

    input, select {
      @include rem('margin-right', 37px);
      @include rem('padding-left', 25px);
      background: #ddd;
      color: #666666;
      border-radius: 2px;
    }

    input::placeholder {
      color: #666666;
    }

    input:focus::-webkit-input-placeholder {
      color: transparent;
    }

    .selectdiv {
      position: relative;
      @include rem('margin-right', 37px);
    }

    .selectdiv:after {
      content: ' ';
      background-image: url('../images/select_down_arrow.svg');
      right: 20px;
      top: 20px;
      @include rem('width', 10px);
      @include rem('height', 6px);
      position: absolute;
      pointer-events: none;
    }

    select::-ms-expand {
      display: none;
    }

    .selectdiv select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* Add some styling */
      display: block;
      width: 100%;
      @include rem('height', 52px);
      @include rem('margin', 0);
      @include rem('padding', 0px 24px);
      @include rem('font-size', 15px);
      color: #666666;
      border: none;
      -ms-word-break: normal;
      word-break: normal;
    }

    input[type="submit"] {
      float: left;
      background: #e2001a;
      border: none;
      color: #fff;
      opacity: .8;
      font-weight: 700;
      cursor: pointer;
      margin-right: 0;
      margin-left: auto;
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-left: 0 !important;
    }

    @include tablet {
      margin-top: 40px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @include tablet-big {
      margin-top: 40px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @include mobile {
      margin-top: 30px;
    }
  }

  @include tablet {
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include tablet-big {
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media screen and (min-width: 1025px) and (max-width:1309px) {
    #register_form, .regiser_form {
      width: 100%;

      input {
        width: 23%;
        margin-right: 2.5%;
      }

      input[type="submit"] {
        width: 23%;
      }

      .selectdiv {
        width: 23%;
        margin-right: 2.5%;
      }
    }
  }

  @include mobile {
    float: left;
    width: 100%;
    height: auto;
    position: relative;
    background-image: url('../images/gray_banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @include rem('padding-top', 87px);
    display: block !important;
    padding: 30px 0;
    height: auto;

    .abonohu_text {
      float: left;
      width: 100%;
      color: #fff;
      flex-wrap: wrap;

      span {
        display: none;
      }

      h2 {
        width: 100%;
        text-align: center;
        margin: 0;
        font-size: 24px;
        margin-bottom: 30px;

        &:before {
          content: " ";
          background-image: url('../images/dash.svg');
          @include rem('height', 5px);
          @include rem('width', 20px);
          display: block;
          position: absolute;
          top: 9px;
          left: 75px;
        }
      }

      p {
        margin: 0;
        text-align: center;
        padding: 0 15px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

// home-page-tabs
.prioritetet_rep_headline {
  @include fullBlock;
  @include rem('margin-top', 20px);
  @include rem('margin-bottom', 76px);
  text-align: center;
  color: #000;

  p {
    float: left;
    width: 100%;
    @include rem('font-size', 18px);
    @include rem('margin-bottom', 7px);
  }

  span {
    float: left;
    width: 100%;
    @include rem('font-size', 18px);
    font-weight: 700;
  }

  @include mobile {
    margin-bottom: 40px;

    p {
      padding: 0 17px;
      font-size: 14px;
      line-height: 23px;
      margin-bottom: 0;
    }

    span {
      font-size: 14px;
    }
  }
}

.prioritetet_rep_tab {
  @include fullBlock;

  .prioritetet_item {
    float: left;
    width: 100%;
    @include rem('height', 290px);
    @include rem('margin-bottom', 40px);
    border: 1px solid #e6e6e6;
    text-align: center;
    transition: $transition1;
    overflow: hidden;

    a {
      text-decoration: none;
      text-align: center;

      .prioritetet_item_logo {
        float: left;
        width: 100%;
        @include rem('padding-top', 57px);
        text-align: center;
        @include rem('height', 165px);
      }

      .prioritetet_item_title {
        float: left;
        width: 100%;
        @include rem('padding-left', 50px);
        @include rem('padding-right', 50px);
        text-align: center;
        @include rem('padding-bottom', 57px);

        p, span {
          @include rem('font-size', 18px);
          color: #231f20;
          text-align: center;
        }

        p {
          @include rem('margin-bottom', 5px);
          max-height: 96px;
          text-align: center;
        }

        @include tablet {
          padding-bottom: 47px !important;
        }

        @include tablet-big {
          padding-bottom: 47px !important;
        }
      }
    }

    &:hover {
      -webkit-box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.1);

      p, span {
        transition: $transition1;
        color: #e2001a !important;
        text-decoration: underline;
      }
    }

    @include mobile {
      height: auto;

      .prioritetet_item_title {
        padding: 30px !important;
      }
    }
  }

  @include tablet {
    .column-3 {
      width: 50%;
    }
  }

  @include tablet-big {
    .column-3 {
      width: 50%;
    }
  }

  @include mobile {
    .column-3 {
      width: 100%;

      &:nth-of-type(1n+4) {
        display: none;
      }
    }
  }
}

.prioritetet_lexo_me_shume {
  float: left;
  width: 100%;
  text-align: center;
  @include rem('margin-top', 30px);
  @include rem('margin-bottom', 108px);

  a {
    font-size: 16px;
    font-weight: 300;
    color: #666;
    text-decoration: none;

    .read_more_arrow {
      content: " ";
      background-image: url('../images/lexomeshume.svg');
      height: 8px;
      height: .5rem;
      width: 14px;
      width: .875rem;
      margin-left: 22px;
      margin-left: 1.375rem;
      display: inline-block;
    }

    &:hover {
      color: #e2001a;

      .read_more_arrow {
        content: " ";
        background-image: url('../images/lexomeshume-red.svg');
        height: 8px;
        height: .5rem;
        width: 14px;
        width: .875rem;
        margin-left: 22px;
        margin-left: 1.375rem;
        display: inline-block;
      }
    }
  }

  @include tablet {
    margin-bottom: 70px;

    a {
      color: #e2001a;

      .read_more_arrow {
        content: " ";
        background-image: url('../images/lexomeshume-red.svg');
        height: 8px;
        height: .5rem;
        width: 14px;
        width: .875rem;
        margin-left: 22px;
        margin-left: 1.375rem;
        display: inline-block;
      }
    }
  }

  @include tablet-big {
    margin-bottom: 70px;

    a {
      color: #e2001a;

      .read_more_arrow {
        content: " ";
        background-image: url('../images/lexomeshume-red.svg');
        height: 8px;
        height: .5rem;
        width: 14px;
        width: .875rem;
        margin-left: 22px;
        margin-left: 1.375rem;
        display: inline-block;
      }
    }
  }

  @include mobile {
    margin-top: 0;
    margin-bottom: 45px;

    a {
      color: #e2001a;
      font-size: 15px;

      .read_more_arrow {
        content: " ";
        background-image: url('../images/lexomeshume-red.svg');
        height: 8px;
        height: .5rem;
        width: 14px;
        width: .875rem;
        margin-left: 22px;
        margin-left: 1.375rem;
        display: inline-block;
      }
    }
  }
}

.kand_komunes_item {
  float: left;
  width: 100%;
  @include rem('height', 400px);
  border: 1px solid #e6e6e6;
  @include rem('margin-bottom', 40px);
  position: relative;

  .deputeti_img {
    width: 100%;
    @include rem('height', 290px);

    img {
      @extend %imgCropped;
    }
  }

  .gr_parl_kryetari {
    width: 200px;
    position: absolute;
    bottom: 94px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    text-align: center;
    color: #fff;
    background: #27ba9e;
    padding: 10px 20px;
    text-decoration: none;

    @include tablet {
      bottom: 23%;
      left: 50%;
    }

    @include tablet-big {
      bottom: 23%;
      left: 50%;
    }

    @include mobile {
      top: 68%;
      left: 50%;
    }
  }

  .deputeti_white {
    float: left;
    width: 100%;
    @include rem('height', 111px);
    position: relative;
    @include rem('padding', 15px);

    .deputeti_info {
      float: left;
      @include rem('padding', 0);
      margin-top: 10px;

      a {
        color: #231f20;
        text-decoration: none;

        h2 {
          @include rem('font-size', 18px);
          @include rem('margin-bottom', 5px);
        }

        h1 {
          @include rem('font-size', 18px);
          @include rem('margin-bottom', 10px);
        }

        span {
          @include rem('font-size', 21px);
          font-weight: 700;
        }
      }
    }

    .deputeti_social_icons {
      position: absolute;
      right: 27px;
      bottom: 40px;

      a {
        color: #666666;

        .fa-twitter {
          @include rem('margin-left', 22px);
          @include rem('font-size', 21px);

          &:hover {
            color: #e2001a;
            cursor: pointer;
          }
        }

        .fa-facebook-f {
          @include rem('font-size', 21px);

          &:hover {
            color: #e2001a;
            cursor: pointer;
          }
        }
      }
    }
  }

  &:hover {
    box-shadow: 0 0 56px 0 rgba(0, 0, 0, .1);

    .deputeti_info {
      h1, h2, span {
        color: #e2001a;
      }
    }
  }
}

.zotimet_margine_top {
  margin-top: 40px;

  @include tablet {
    .column-3 {
      width: 50%;
    }
  }

  @include tablet-big {
    .column-3 {
      width: 50%;
    }
  }

  @include mobile {
    .column-3 {
      width: 100%;
    }
  }
}

.zotimet_item {
  @include fullBlock;
  @include rem('margin-bottom', 40px);
  height: 327px;

  a {
    text-decoration: none;

    .zotimi_img {
      float: left;
      width: 100%;
      @include rem('height', 228px);

      img {
        @extend %imgCropped;
        opacity: 0.8;
        transition: $transition1;
      }
    }

    .zotimi_data {
      float: left;
      width: 100%;
      @include rem('margin-top', 28px);
      @include rem('margin-bottom', 17px);

      p {
        @include rem('font-size', 13px);
        color: #666666;
        transition: $transition1;

        span {
          @include rem('margin', 0 10px);
        }
      }
    }

    .media_description {
      float: left;
      width: 100%;

      h2 {
        width: 100%;
        color: #231f20;
        @include rem('font-size', 16px);
        font-weight: 700;
        transition: $transition1;
        line-height: 21px;
        white-space: wrap;
        max-height: 42px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    &:hover {
      p, span, h2 {
        color: #e2001a;
      }

      .zotimi_img {
        img {
          opacity: 1;
        }
      }
    }
  }
}

// ------FACEBOOK - LEVIZJA - TWITTER -homepage------//
.facebook_levizja_twitter {
  float: left;
  width: 100%;
  background: #f2f2f2;
  @include rem('padding-top', 135px);
  @include rem('padding-bottom', 139px);

  .flt_heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include rem('margin-bottom', 35px);

    h3 {
      @include rem('font-size', 27px);
      font-weight: 700;
      @include rem('margin-left', 17px);
      text-transform: uppercase;
    }

    span {
      content: " ";
      background-image: url('../images/dash.svg');
      @include rem('height', 5px);
      @include rem('width', 20px);
    }
  }

  .facebook_column_tablet {
    @include tablet {
      display: block !important;
      width: 50%;
      margin-right: 0;
      padding-right: 15px;
      display: block;
      float: left;

      iframe {
        -webkit-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
        -moz-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
        box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
      }
    }

    @include tablet-big {
      width: 100%;
      display: block !important;
      width: 50%;
      margin-right: 0;
      padding-right: 15px;
      display: block;
      float: left;

      iframe {
        -webkit-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
        -moz-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
        box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
      }
    }
  }

  .facebook_column {
    float: left;
    @include rem('width', 290px);
    @include rem('margin-right', 40px);

    iframe {
      -webkit-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
      -moz-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
      box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
    }

    @include tablet {
      display: none !important;
    }

    @include tablet-big {
      display: none !important;
    }

    @include mobile {}

    @media screen and (min-width: 1025px) and (max-width:1309px) {
      margin-right: 2%;
    }
  }

  .levizja_column {
    float: left;
    @include rem('width', 620px);

    .kos_map_dropdown {
      width: 100%;
      float: left;
      @include rem('height', 370px);
      background: white;
      position: relative;
      -webkit-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
      -moz-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
      box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
    }

    .kos_map_img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @include rem('width', 253px);
      @include rem('height', 280px);
      @include rem('margin-left', 49px);
      @include rem('margin-right', 32px);
    }

    .kos_dropdown {
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%);
      width: 40%;

      li {
        @include rem('padding-bottom', 19px);
        border-bottom: 1px solid #f2f2f2;
        @include rem('margin-bottom', 18px);

        .ks_links {
          text-decoration: none;
          color: #231f20;
          @include rem('font-size', 16px);
        }

        &:last-child {
          @include rem('margin-bottom', 0);
          @include rem('padding-bottom', 0);
          border-bottom: none;
        }
      }

      select {
        background: #fff;
        border: none;
        border-radius: 4px;
        width: 90%;
        padding: 20px;
        font-size: 16px;
        color: #3F3F3F;
        margin-bottom: 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;

        &:active {
          outline: none;
          border: none;
        }

        &:focus {
          outline: 0;
        }
      }

      .select-wrapper {
        position: relative;
      }

      .select-wrapper:before {
        content: " ";
        background-image: url('../images/dash.svg');
        @include rem('height', 5px);
        @include rem('width', 19px);
        display: inline-block;
        margin-bottom: 2px;

        @media screen and (min-width: 1025px) and (max-width:1309px) {
          content: " ";
          background-image: url(../images/dash.svg);
          height: 5px;
          width: 19px;
          display: block;
          position: absolute;
          z-index: 33;
          top: 26px;
        }
      }

      .select-wrapper:after {
        content: '\f107';
        font-family: 'Font Awesome 5 Pro';
        font-size: 20px;
        position: absolute;
        top: 19px;
        right: 20px;
        color: #e2001a;
        pointer-events: none;
      }

      select::-ms-expand {
        display: none;
      }
    }

    @include tablet {
      width: 100%;
      margin-bottom: 40px;
    }

    @include tablet-big {
      width: 100%;
      margin-bottom: 60px;
    }

    @include mobile {
      width: 100%;
      height: auto;
      margin-top: 50px;

      .kos_map_dropdown {
        height: auto;
        padding: 30px;

        .kos_map_img {
          all: unset;
        }

        .kos_dropdown {
          all: unset;

          .select_city {
            margin-bottom: 15px;
            margin-top: 15px;

            &::after {
              right: 0px;
            }
          }

          ul {
            margin-bottom: 25px;
          }
        }
      }
    }

    @media screen and (min-width: 1025px) and (max-width:1309px) {
      width: 554px;

      .kos_map_dropdown {
        .kos_map_img {
          width: 44%;
          top: 55%;
          margin-left: 15px;
          margin-left: 20px;
        }

        .kos_dropdown {
          .select-wrapper {
            position: relative;

            select {
              padding-left: 30px !important;

              &:before {
                width: 15px !important;
                position: absolute !important;
                top: 26px !important;
              }

              &:after {
                top: 34px;
              }
            }
          }

          ul {
            li {
              a {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  .twitter_column {
    float: right;
    @include rem('width', 290px);
    @include rem('margin-left', 40px);

    .twitter-timeline {
      -webkit-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
      -moz-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
      box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
    }

    @include tablet {
      display: block;
      width: 50%;
      margin-left: 0;
      padding-left: 13px;

      iframe {
        width: 100% !important;
      }
    }

    @include tablet-big {
      display: block;
      width: 50%;
      margin-left: 0;
      padding-left: 13px;

      iframe {
        width: 100% !important;
      }
    }

    @include mobile {
      margin-left: 0;
      width: 100%;
      margin-top: 50px;

      iframe {
        width: 100% !important;
      }
    }

    @media screen and (min-width: 1025px) and (max-width:1309px) {
      margin-left: 2%;
    }
  }

  @include tablet {
    padding-top: 35px;
    padding-bottom: 45px;
  }

  @include tablet-big {
    padding-top: 35px;
    padding-bottom: 45px;
  }

  @include mobile {
    padding-top: 40px;
    padding-bottom: 60px;
  }
}

// -----Grupi Parlamentar-template--------------//
.grupi_parlamentar_banner {
  @extend %fullBlock;
  background-image: url('../images/gr_parl_banner.png');
  @include rem('height', 250px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @extend %imgCropped;

  .height_container {
    @include rem('height', 250px);
  }

  .banner_text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .gr_parl_banner_title {
      @include rem('font-size', 36px);
      color: #fff;
      font-weight: 700;
    }

    .gr_parl_banner_subtitle {
      @include rem('font-size', 13px);
      @include rem('margin-top', 11px);
      color: #fff;

      p, span {
        float: left;
        opacity: 0.5;
      }

      span:before {
        content: "  ";
      }
    }
  }

  @include tablet {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include tablet-big {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include mobile {
    height: 120px;

    .height_container {
      height: 120px;

      .banner_text {
        h1 {
          font-size: 23px;
        }
      }
    }
  }
}

.background_deputetet {
  float: left;
  width: 100%;
  background: #fff;

  @include mobile {
    .deputetet_heading {
      margin-bottom: 10px;
      padding-top: 14px;

      h2 {
        font-size: 23px;
        margin-left: 25px;
      }
    }
  }
}

.deputetet_heading {
  @extend %fullBlock;
  @include rem('padding-top', 59px);
  @include rem('margin-bottom', 48px);
  @include rem('height', 86px);
  display: flex;
  align-items: center;

  span {
    content: " ";
    background-image: url('../images/dash.svg');
    @include rem('height', 5px);
    @include rem('width', 20px);
  }

  h2 {
    @include rem('font-size', 27px);
    font-weight: 700;
    color: #231f20;
    text-transform: uppercase;
    @include rem('margin-left', 29px);
  }

  ul {
    margin-left: auto;

    li {
      display: inline-block;
      @include rem('margin-right', 15px);

      a {
        text-decoration: none;
        @include rem('font-size', 13px);
        color: #666666;

        &:hover {
          color: #e2001a;
          cursor: pointer;
        }
      }

      .pdf_icon:after {
        content: "\f1c1";
        font-family: "Font Awesome 5 Pro";
        @include rem('font-size', 16px);
        @include rem('padding-left', 10px);

        &:hover {
          color: #e2001a;
          cursor: pointer;
        }
      }

      .print_icon:after {
        content: "\f02f";
        font-family: "Font Awesome 5 Pro";
        @include rem('font-size', 16px);
        @include rem('padding-left', 10px);

        &:hover {
          color: #e2001a;
          cursor: pointer;
        }
      }

      .social_icons {
        @include rem('font-size', 14px);

        &:hover {
          color: #e2001a;
          cursor: pointer;
        }
      }

      &:last-child {
        @include rem('margin-right', 0);
      }

      &:nth-child(3) {
        a {
          cursor: default;

          &:hover {
            color: #666666;
          }
        }
      }
    }
  }

  #see_all {
    color: #666;
    text-decoration: none;
    margin-left: auto;

    span {
      content: " ";
      background-image: url('../images/lexomeshume.svg');
      @include rem('height', 8px);
      @include rem('width', 14px);
      display: block;
      float: right;
      @include rem('margin-left', 20px);
      @include rem('margin-top', 3px);

      @media (max-width: 320px) {
        margin-left: 10px;
      }
    }

    &:hover {
      color: #e2001a;

      span {
        content: " ";
        background-image: url('../images/lexomeshume-red.svg');
        @include rem('height', 8px);
        @include rem('width', 14px);
        @include rem('margin-left', 22px);
        display: block;
        float: right;
        @include rem('margin-left', 20px);
        @include rem('margin-top', 3px);
      }
    }

    @media (max-width: 320px) {
      font-size: 12px;
    }
  }
}

.deputetet_grid {
  @extend %fullBlock;
  @include rem('margin-bottom', 90px);

  ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &> :nth-child(4n+4) {
      @include rem('margin-right', 0);
    }
  }
}

.group_gutter {
  .row {
    margin-left: -20px;
    margin-right: -20px;
  }

  [class*=column-] {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@include desktop {
  .column-fifth {
    width: 25%;
    padding-left: 20px;
    padding-right: 20px;

    &:nth-child(4n+1) {
      clear: left;
    }
  }
}

.deputeti_block {
  @extend %fullBlock;
  @include rem('margin-bottom', 40px);
  flex-grow: 0;
  flex-shrink: 0;
  border: 1px solid #e6e6e6;
  position: relative;

  @include mobile {
    @include rem('margin-right', 0);
    width: 100%;
  }

  .deputeti_img {
    width: 100%;
    @include rem('height', 290px);
    position: relative;
    z-index: 3;

    &:after {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 6px;
      background: #e2001a;
      z-index: 1;
      opacity: 0;
      transition: $transition1;
    }

    img {
      @extend %imgCropped;
    }
  }

  .gr_parl_kryetari {
    @include rem('width', 200px);
    position: absolute;
    bottom: -15px;
    left: 50%;
    @include rem('font-size', 13px);
    text-align: center;
    color: white;
    background: #27ba9e;
    @include rem('padding', 9px 20px 8px 20px);
    text-decoration: none;
    z-index: 2;
    transform: translateX(-50%);
    /* opacity: 0;
      transform:translateX(-50%) translateY(10px); */
    transition: $transition1;
  }

  // --Zgjedhjet 2019-nr-temmplate--//
  .kandidati_nr {
    @include rem('width', 50px);
    @include rem('height', 50px);
    position: absolute;
    top: 0;
    left: 0;
    @include rem('font-size', 24px);
    text-align: center;
    color: white;
    background: #e2001a;
    text-decoration: none;
    @include rem('padding', 13px 0);
    z-index: 3;
  }

  // ----------------------------------------------------
  .deputeti_white {
    float: left;
    width: 100%;
    height: auto;
    @include rem('padding-left', 30px);
    @include rem('padding-right', 80px);
    @include rem('padding-top', 26px);
    @include rem('padding-bottom', 25px);
    position: relative;

    .deputeti_info {
      float: left;

      a {
        text-decoration: none;
      }

      h2 {
        @include rem('font-size', 21px);
        line-height: 1.15em;
        word-wrap: break-word;
        color: #231f20;
      }
    }

    .deputeti_social_icons {
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);

      a {
        color: #666666;
        font-size: 16px;

        .fa-twitter {
          @include rem('margin-left', 22px);

          &:hover {
            color: #e2001a;
            cursor: pointer;
          }
        }

        .fa-facebook-f {
          &:hover {
            color: #e2001a;
            cursor: pointer;
          }
        }
      }
    }
  }

  &:hover {
    transition: $transition1;
    -webkit-box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);
    -moz-box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);
    box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);

    .gr_parl_kryetari {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }

    .deputeti_img {
      &:after {
        opacity: 1;
      }
    }

    .deputeti_white {
      background: #fff;

      .deputeti_info h1,
      .deputeti_info h2 {
        color: #e2001a;
        cursor: pointer;
      }
    }
  }
}

// ---- Dekonstruktime-template------//
.dekonstruktime_banner {
  @extend %fullBlock;
  background-image: url('../images/dekonstruktime_banner.png');
  @include rem('height', 250px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @extend %imgCropped;

  .height_container {
    @include rem('height', 250px);
  }

  .banner_text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .gr_parl_banner_title {
      @include rem('font-size', 36px);
      color: #fff;
      font-weight: 700;
    }

    .gr_parl_banner_subtitle {
      @include rem('font-size', 13px);
      @include rem('margin-top', 11px);
      color: #fff;

      p, span {
        float: left;
        opacity: 0.5;
      }

      span:before {
        content: "  ";
      }
    }
  }

  @include tablet {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include tablet-big {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include mobile {
    height: 120px;

    .height_container {
      height: 120px;

      .banner_text {
        h1 {
          font-size: 23px;
        }
      }
    }
  }
}

.dekonstruktime_accordion {
  @extend %fullBlock;
  @include rem('margin-bottom', 100px);

  a {
    text-decoration: none;
  }

  .accordion {
    -webkit-box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);
    -moz-box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);
    box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);
    list-style: none;
    @include rem('padding', 0);

    li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &.is--active {
        .toggle {
          color: #e2001a;

          span {
            background: #e2001a;
          }
        }
      }

      &.no-tag li {
        padding-left: 18px !important;
      }

      .toggle {
        width: 100%;
        display: flex;
        align-items: center;
        background: #fff;
        color: #231f20;
        @include rem('font-size', 15px);
        font-weight: 700;
        @include rem('padding', 18px 25px 18px 18px);
        transition: background .3s ease;

        i {
          margin-left: auto;
          color: #cccccc;
        }

        span {
          float: left;
          @include rem('width', 100px);
          text-align: center;
          color: #fff;
          background: #000;
          @include rem('padding', 10px 0);
          border-radius: 15px;
          @include rem('margin-right', 19px);
          @include rem('font-size', 12px);
        }
      }

      .inner {
        overflow: hidden;
        display: none;

        li {
          background: #fff;
          color: #231f20;
          @include rem('font-size', 15px);
          font-weight: 300;
          @include rem('padding', 0px 78px 38px 138px);
          line-height: 1.7;
        }

        &.show {
          //   display: block;
        }
      }

      &:hover {
        .fa-plus {
          color: #e2001a;
        }
      }
    }

    @include tablet {
      margin-top: 30px;

      li {
        .toggle {
          p {
            width: 70%;
          }

          i {
            margin-left: 25px;
          }

          span {
            width: 20%;
          }
        }

        .inner {
          li {
            padding: 0 30px 38px 30px;
          }
        }
      }
    }

    @include tablet-big {
      margin-top: 30px;

      li {
        .toggle {
          p {
            width: 70%;
          }

          i {
            margin-left: 25px;
          }

          span {
            width: 20%;
          }
        }

        .inner {
          li {
            padding: 0 30px 38px 30px;
          }
        }
      }
    }

    @include mobile {
      margin-top: 15px;

      li {
        .toggle {
          padding: 15px 10px;
          flex-wrap: wrap;

          p {
            width: calc(100% - 30px);
            font-size: 13px;
          }

          i {
            margin-left: 15px;
          }

          span {
            float: left;
            clear: both;
            width: auto;
            margin-right: 0;
            font-size: 12px;
            padding: 5px 7px;
            margin-bottom: 9px;
          }
        }

        .inner {
          li {
            padding: 0 15px 28px 15px;
          }
        }
      }
    }
  }

  @include mobile {
    margin-bottom: 70px;
  }
}

// -----Media-template----//
.media_banner {
  @extend %fullBlock;
  background-image: url('../images/media_banner.png');
  @include rem('height', 250px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @extend %imgCropped;

  // margin-top:-1px;
  .height_container {
    @include rem('height', 250px);
  }

  .banner_text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .gr_parl_banner_title {
      @include rem('font-size', 36px);
      color: #fff;
      font-weight: 700;
    }

    .gr_parl_banner_subtitle {
      @include rem('font-size', 13px);
      @include rem('margin-top', 11px);
      color: #fff;

      p, span {
        float: left;
        opacity: 0.5;
      }

      span:before {
        content: "  ";
      }
    }
  }

  @include tablet {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include tablet-big {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include mobile {
    height: 120px;

    .height_container {
      height: 120px;

      .banner_text {
        h1 {
          font-size: 23px;
        }
      }
    }
  }
}

#big_img {
  margin-bottom: 100px;

  @include tablet {
    margin-bottom: 50px;
  }

  @include tablet_big {
    margin-bottom: 50px;
  }

  @include mobile {
    margin-bottom: 50px;
  }
}

.media_grid {
  @extend %fullBlock;
  @include rem('margin-bottom', -55px);

  ul {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &> :nth-child(4n+4) {
      @include rem('margin-right', 0);
    }

    @include desktop-small {
      justify-content: space-between;
    }
  }

  .media_block {
    @include rem('width', 290px);
    @include rem('margin-right', 40px);
    @include rem('margin-bottom', 55px);

    @include mobile {
      @include rem('margin-right', 0);
      width: 100%;
    }

    position:relative;

    a {
      color: #231f20;
    }

    .media_img {
      float: left;
      width: 100%;
      @include rem('height', 186px);
      position: relative;

      img {
        @extend %imgCropped;
      }

      .hover_enlarge {
        visibility: hidden;
      }

      &:hover {
        .hover_enlarge {
          visibility: visible;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          background: rgba(0, 0, 0, 0.7);
          display: flex;
          align-items: center;
          justify-content: center;
          transition: $transition1;
          cursor: pointer;

          img {
            @include rem('width', 24px);
            @include rem('height', 24px);
            opacity: 1;
          }
        }
      }
    }

    .media_data {
      float: left;
      width: 100%;
      color: #666666;
      @include rem('margin-top', 25px);
      text-align: left;
      @include rem('margin-bottom', 14px);

      p {
        @include rem('font-size', 13px);

        span {
          @include rem('margin-left', 13px);
          @include rem('margin-right', 13px);
        }
      }
    }

    .media_description {
      float: left;
      width: 100%;
      text-align: left;

      a {
        text-decoration: none;
        @include rem('font-size', 16px);
        font-weight: 700;
        line-height: 1.3;
      }
    }

    &:hover {
      a {
        color: #e2001a;
        cursor: pointer;
      }
    }
  }

  .lajme_te_ngjashme {
    @include tablet {
      width: 50%;
      margin-right: 0;
      margin-bottom: 40px !important;
      padding-right: 20px;

      &:nth-child(2n+2) {
        padding-left: 20px;
        padding-right: 0;
      }

      .media_img {
        height: 220px;
      }
    }

    @include tablet-big {
      width: 50%;
      margin: 0;
      margin-bottom: 40px !important;
      padding-right: 20px;

      &:nth-child(2n+2) {
        padding-left: 20px;
        padding-right: 0;
      }

      .media_img {
        height: 270px;
      }
    }

    @include mobile {
      margin-bottom: 40px !important;

      .media_img {
        height: 220px;
      }
    }

    @media screen and (min-width: 1025px) and (max-width:1309px) {
      width: calc(25% - 20px);
      margin-right: 0;

      &:nth-child(4n+4) {
        margin-right: 0
      }
    }
  }
}

// -----Pagination -----//
.pagination_wrapper {
  float: left;
  width: 100%;
  margin: 80px auto 78px auto;
  text-align: center;

  ul {
    li {
      display: inline-block;
      @include rem('margin-right', 10px);
      position: relative;
      border-radius: 2px;
      transition: $transition1;

      &:last-child {
        @include rem('margin-right', 0);
      }

      a {
        @include rem('width', 50px);
        @include rem('height', 50px);
        @include rem('line-height', 50px);
        border: 1px solid #e6e6e6;
        @include rem('font-size', 16px);
        font-weight: 700;
        text-decoration: none;
        float: left;
        cursor: pointer;
        color: #231f20;
        transition: $transition1;
      }

      span {
        @include rem('width', 50px);
        @include rem('height', 50px);
        @include rem('line-height', 50px);
        border: 1px solid #e6e6e6;
        @include rem('font-size', 16px);
        font-weight: 700;
        text-decoration: none;
        float: left;
        cursor: pointer;
        color: #231f20;
        transition: $transition1;
      }

      &:hover {
        border-color: transparent;
        background: white;
        -webkit-box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);
        -moz-box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);
        box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);

        a {
          color: #e2001a;
        }
      }
    }

    .active {
      border-color: transparent;
      background: white;
      -webkit-box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);
      -moz-box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);
      box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);

      a {
        color: #e2001a;
      }
    }

    .current {
      border-color: transparent;
      background: white;
      -webkit-box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);
      -moz-box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);
      box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);
      color: #e2001a;
    }
  }

  @media (max-width: 320px) {
    ul {
      li {
        width: 35px;
        height: 35px;

        a {
          font-size: 11px;
        }
      }
    }
  }
}

// ------Zgjedhjet 2019------//
.zgjedhjet19_banner {
  @extend %fullBlock;
  background-image: url('../images/zgj19_banner.png');
  @include rem('height', 250px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @extend %imgCropped;

  .height_container {
    @include rem('height', 250px);
  }

  .banner_text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .gr_parl_banner_title {
      @include rem('font-size', 36px);
      color: #fff;
      font-weight: 700;
    }

    .gr_parl_banner_subtitle {
      @include rem('font-size', 13px);
      @include rem('margin-top', 11px);
      color: #fff;

      p, span {
        float: left;
        opacity: 0.5;
      }

      span:before {
        content: "  ";
      }
    }
  }

  @include tablet {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include tablet-big {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include mobile {
    height: 120px;

    .height_container {
      height: 120px;

      .banner_text {
        h1 {
          font-size: 23px;
        }
      }
    }
  }
}

// -----Qendrat e Levizjes-template-----//
.qendrat_banner {
  @extend %fullBlock;
  background-image: url('../images/qendrat_banner.png');
  @include rem('height', 250px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @extend %imgCropped;

  .height_container {
    @include rem('height', 250px);
  }

  .banner_text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .gr_parl_banner_title {
      @include rem('font-size', 36px);
      color: #fff;
      font-weight: 700;
    }

    .gr_parl_banner_subtitle {
      @include rem('font-size', 13px);
      @include rem('margin-top', 11px);
      color: #fff;

      p, span {
        float: left;
        opacity: 0.5;
      }

      span:before {
        content: "  ";
      }
    }
  }

  @include tablet {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include tablet-big {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include mobile {
    height: 120px;

    .height_container {
      height: 120px;

      .banner_text {
        h1 {
          font-size: 23px;
        }
      }
    }
  }
}

.qendrat_wrapper {
  float: left;
  width: 100%;
  @include rem('margin-bottom', 80px);

  ul {
    float: left;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    li {
      float: left;
      @include rem('width', 620px);
      @include rem('height', 290px);
      @include rem('margin-right', 40px);
      @include rem('margin-bottom', 40px);

      &:hover {
        transition: $transition1;
        -webkit-box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);
        -moz-box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);
        box-shadow: 0px 0px 41px 10px rgba(214, 211, 214, 1);
      }
    }

    &> :nth-child(2n+2) {
      @include rem('margin-right', 0);
    }

    .qendra_img {
      float: left;
      @include rem('width', 290px);
      position: relative;
      height: 100%;

      &:hover {
        &:after {
          background-color: #e2001a;
        }
      }

      &:after {
        content: "";
        @include rem('width', 5px);
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background-color: transparent;
        transition: $transition1;
      }

      img {
        @extend %imgCropped;
      }
    }

    .qendra_description {
      float: left;
      width: calc(100% - 290px);
      height: 100%;
      border-left: none;
      background: #fff;
      position: relative;
      @include rem('padding', 40px 40px 45px 35px);

      a {
        text-decoration: none;
        color: #231f20;

        h1 {
          float: left;
          @include rem('font-size', 21px);
        }

        .qendra_vendi {
          float: left;
          width: 100%;
          font-weight: 700;
          @include rem('margin-top', 2px);
        }

        i {
          @include rem('font-size', 15px);
          color: #666666;
          @include rem('margin-left', 15px);

          &:hover {
            color: #e2001a;
          }
        }

        &:hover {
          color: #e2001a;
        }
      }

      .qendra_social_icons {
        float: right;
        @include rem('margin-top', 3px);
        position: absolute;
        right: 40px;
      }

      h2, h3, p {
        float: left;
        width: 100%;
      }

      h2 {
        @include rem('font-size', 18px);
        font-weight: 700;
        @include rem('margin-top', 25px);
        @include rem('margin-bottom', 3px);
      }

      h3 {
        @include rem('font-size', 14px);
        font-weight: 700;
        text-transform: uppercase;
        @include rem('margin-top', 24px);
        @include rem('margin-bottom', 3px);
      }

      p {
        line-height: 1.3;
        @include rem('font-size', 15px);
        color: #666666;
      }
    }
  }

  @include tablet {
    ul {
      li {
        width: 100%;
        margin-right: 0;

        .qendra_img {
          width: 50%;
        }

        .qendra_description {
          width: 50%;
        }
      }
    }
  }

  @include tablet-big {
    ul {
      li {
        width: 100%;
        margin-right: 0;

        .qendra_img {
          width: 50%;
        }

        .qendra_description {
          width: 50%;
        }
      }
    }
  }

  @include mobile {
    ul {
      li {
        width: 100%;
        margin-right: 0;
        height: auto;

        .qendra_img {
          width: 100%;
          height: 170px;
        }

        .qendra_description {
          width: 100%;
          padding: 20px;
          height: 235px;
        }
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width:1309px) {
    ul {
      li {
        width: 48%;
        height: 255px;
        margin-right: 4%;

        &:nth-child(2n+2) {
          margin-right: 0;
        }

        .qendra_img {
          width: 50%;
        }

        .qendra_description {
          width: 50%;
          padding: 15px;

          a {
            h1 {
              font-size: 20px;
            }
          }

          .qendra_social_icons {
            margin-left: 3px;
          }
        }
      }
    }
  }
}

// -----KOMUNIKATA-template-----//
.komunikata_list {
  @extend %fullBlock;
  @include rem('margin-top', 7px);

  ul li {
    float: left;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    position: relative;
    overflow: hidden;

    &:after {
      content: " ";
      height: 5px;
      background: #e2001a;
      width: 100%;
      left: 0;
      bottom: 0;
      transform: translateY(5px);
      position: absolute;
      transition: $transition1;
    }

    &:first-child {
      border-top: 1px solid #e6e6e6;
    }

    &:hover {
      &:after {
        transform: translateY(0);
      }

      h3 {
        color: #e2001a;
      }

      .komunikata_date {
        display: none;
      }

      .lexoje_te_plote {
        display: block;
        color: #e2001a;
      }
    }

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      @include rem('padding-top', 40px);
      @include rem('padding-bottom', 36px);

      h3 {
        float: left;
        width: 70%;
        padding-right: 30px;
        @include rem('font-size', 21px);
        transition: $transition1;
        color: #231f20;
        font-weight: 700;
      }

      p {
        width: 30%;
        padding-left: 30px;
        word-wrap: normal;
        text-align: right;
        margin-left: auto;
        color: #666666;
        @include rem('font-size', 13px);

        span {
          margin-left: 5px;
          margin-right: 5px;
        }

        br {
          display: none;
        }
      }

      .lexoje_te_plote {
        display: none;

        &::after {
          content: " ";
          background-image: url('../images/read_more_arrow.svg');
          background-repeat: no-repeat;
          @include rem('width', 14px);
          @include rem('height', 9px);
          display: block;
          float: right;
          @include rem('margin-left', 20px);
          @include rem('margin-top', 3px);
        }
      }

      @include tablet {
        h3 {
          font-size: 18px;
          padding-right: 25px;
        }

        p {
          font-size: 12px;
          padding-left: 25px;
          word-wrap: normal;
        }
      }

      @include tablet-big {
        h3 {
          font-size: 18px;
          padding-right: 25px;
        }

        p {
          font-size: 12px;
          padding-left: 25px;
          word-wrap: normal;
        }
      }

      @include mobile {
        h3 {
          width: 65%;
          font-size: 15px;
          padding-right: 10px;
          line-height: 19px;
        }

        p {
          width: 35%;
          font-size: 10px;
          padding-left: 10px;
          line-height: 15px;
          word-wrap: normal;

          span {
            display: none;
          }

          br {
            display: block;
          }
        }

        .lexoje_te_plote {
          font-size: 10px;

          &::after {
            width: 10px;
            height: 5px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

// ---Media-Lajme-Politika-levizja-Aksionet-template...//
.bcg_lajme_levizja_aksione {
  background: white;

  .deputetet_heading {
    height: auto;
    @include rem('padding-top', 0);
    @include rem('margin-bottom', 58px);

    @include tablet {
      margin-bottom: 30px;
    }

    @include tablet-big {
      margin-bottom: 30px;
    }

    @include mobile {
      margin-bottom: 30px;
    }
  }

  #first_heading_item {
    @include rem('margin-top', 60px);

    @include mobile {
      margin: 35px auto;
    }
  }

  .media_grid {
    @include rem('margin-bottom', 78px);

    li {
      @include rem('margin-bottom', 0);
    }

    @include tablet {
      margin-bottom: 20px;
    }

    @include tablet-big {
      margin-bottom: 20px;
    }

    @include mobile {
      margin-bottom: 10px;
    }
  }

  .lajmi {
    @extend %fullBlock;
    @include rem('height', 427px);
    @include rem('margin-bottom', 60px);

    .lajmi_image {
      float: left;
      width: 50%;
      height: 100%;

      img {
        @extend %imgCropped;
      }
    }

    .lajmi_description {
      float: left;
      width: 50%;
      height: 100%;
      border: 1px solid #e6e6e6;
      border-left: none;
      @include rem('padding', 60px 40px 55px 60px);

      p {
        color: #666666;
        @include rem('font-size', 13px);

        span {
          @include rem('margin', 0 15px);
        }
      }

      h2 {
        @include rem('font-size', 27px);
        font-weight: 700;
        color: #231f20;
        line-height: 29px;
        @include rem('margin-top', 15px);
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 2;
        max-height: 58px;
      }

      h4 {
        line-height: 32px;
        @include rem('font-size', 18px);
        color: #666666;
        max-height: 128px;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 4;
        @include rem('margin-top', 29px);
        @include rem('margin-bottom', 46px);
      }

      #see_all {
        color: #666;
        text-decoration: none;
        float: left;
        @include rem('font-size', 16px);

        span {
          content: " ";
          background-image: url('../images/lexomeshume.svg');
          @include rem('height', 8px);
          @include rem('width', 14px);
          @include rem('margin-left', 22px);
          display: block;
          float: right;
          @include rem('margin-left', 20px);
          @include rem('margin-top', 3px);
        }

        &:hover {
          color: #e2001a;

          span {
            content: " ";
            background-image: url('../images/lexomeshume-red.svg');
            @include rem('height', 8px);
            @include rem('width', 14px);
            @include rem('margin-left', 22px);
            display: block;
            float: right;
            @include rem('margin-left', 20px);
            @include rem('margin-top', 3px);
          }
        }
      }
    }

    @include tablet {
      width: 100%;
      height: 270px;

      .lajmi_description {
        height: 100%;
        padding: 25px;

        p {
          font-size: 12px;
        }

        h2 {
          font-size: 15px;
          margin-top: 10px;
          text-overflow: ellipsis;
          line-height: 17px;
          overflow: hidden;
          -webkit-line-clamp: 2;
          max-height: 34px;
        }

        h4 {
          font-size: 13px;
          line-height: 16px;
          margin-top: 15px;
          margin-bottom: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 5;
          height: 80px;
        }

        #see_all {
          font-size: 12px;
        }
      }
    }

    @include tablet-big {
      width: 100%;
      height: 270px;

      .lajmi_description {
        height: 100%;
        padding: 25px;

        p {
          font-size: 12px;
        }

        h2 {
          font-size: 15px;
          margin-top: 10px;
          text-overflow: ellipsis;
          line-height: 17px;
          overflow: hidden;
          -webkit-line-clamp: 2;
          max-height: 34px;
        }

        h4 {
          font-size: 13px;
          line-height: 16px;
          margin-top: 15px;
          margin-bottom: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 5;
          height: 80px;
        }

        #see_all {
          font-size: 12px;
        }
      }
    }

    @include mobile {
      height: auto;

      .lajmi_image {
        width: 100%;
      }

      .lajmi_description {
        width: 100%;
        padding: 15px;
        border: 1px solid #e6e6e6;
        margin-top: -2px;

        h2 {
          font-size: 20px;
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          max-height: 44px;
        }

        h4 {
          font-size: 16px;
          line-height: 20px;
          margin-top: 15px;
          margin-bottom: 20px;
          height: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
        }

        #see_all {
          font-size: 13px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.arkiva {
  @extend %fullBlock;
  background: #f2f2f2;
  @include rem('padding-bottom', 104px);
  @include rem('padding-top', 104px);

  .deputetet_heading {
    @include rem('padding-top', 0);
    height: auto;
  }

  .media_grid {
    @include rem('margin-bottom', 0);

    li {
      @include rem('margin-bottom', 0);
    }
  }

  @include tablet {
    padding-top: 60px;
  }

  @include tablet-big {
    padding-top: 60px;
  }

  @include mobile {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}

//  ----Single-Kandidati-template----//
.bcg_lajme_levizja_aksione {
  float: left;
  width: 100%;

  .single_kandidati_heading_date {
    float: left;
    @include rem('font-size', 13px);
    color: #666666;
  }

  .single_kandidati_heading {
    float: left;
    width: 100%;
    @include rem('margin-bottom', 50px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .kandidati_name {
      float: left;
      @include rem('font-size', 42px);
      font-weight: 700;
      hyphens: auto;
    }

    .kandidati_tag {
      text-transform: uppercase;
      text-decoration: none;
      background: #27ba9e;
      @include rem('font-size', 14px);
      color: #fff;
      @include rem('padding', 10px 10px 10px 12px);
      border-radius: 2px;
    }

    @include tablet {
      width: 100%;
      margin-bottom: 25px;

      .kandidati_name {
        font-size: 27px;
      }
    }

    @include tablet-big {
      width: 100%;
      margin-bottom: 25px;

      .kandidati_name {
        font-size: 27px;
      }
    }

    @include mobile {
      width: 100%;
      margin-bottom: 25px;

      .kandidati_name {
        font-size: 27px;
      }
    }
  }

  .single_kandidati_sm_section {
    float: right;
    @include rem('width', 330px);
    height: auto;
    @include rem('margin-bottom', 45px);
    display: flex;
    align-items: center;

    span {
      content: " ";
      background-image: url('../images/dash.svg');
      @include rem('height', 5px);
      @include rem('width', 20px);
      @include rem('margin-right', 16px);
      display: block;
    }

    h3 {
      @include rem('font-size', 21px);
      font-weight: 700;
      color: #231f20;
    }
  }

  .single_kandidati_sm_section_resp {
    @include tablet {
      float: left;
      width: 100%;
      display: block !important;
      margin-bottom: 30px;

      span {
        float: left;
        content: " ";
        background-image: url('../images/dash.svg');
        @include rem('height', 5px);
        @include rem('width', 20px);
        @include rem('margin-right', 16px);
        display: block;
        margin-top: 8px;
      }

      h3 {
        float: left;
        @include rem('font-size', 21px);
        font-weight: 700;
        color: #231f20;
      }
    }

    @include tablet-big {
      float: left;
      width: 100%;
      display: block !important;
      margin-bottom: 30px;

      span {
        float: left;
        content: " ";
        background-image: url('../images/dash.svg');
        @include rem('height', 5px);
        @include rem('width', 20px);
        @include rem('margin-right', 16px);
        display: block;
        margin-top: 8px;
      }

      h3 {
        float: left;
        @include rem('font-size', 21px);
        font-weight: 700;
        color: #231f20;
      }
    }

    @include mobile {
      float: left;
      width: 100%;
      display: block !important;
      margin-bottom: 30px;

      span {
        float: left;
        content: " ";
        background-image: url('../images/dash.svg');
        @include rem('height', 5px);
        @include rem('width', 20px);
        @include rem('margin-right', 16px);
        display: block;
        margin-top: 8px;
      }

      h3 {
        float: left;
        @include rem('font-size', 21px);
        font-weight: 700;
        color: #231f20;
      }
    }
  }
}

.kandidati_info {
  float: left;
  @include rem('width', 840px);
  @include rem('margin-right', 109px);

  .kandidati_img_basic_info {
    float: left;
    width: 100%;
    @include rem('height', 542px);
    @include rem('margin-bottom', 53px);

    .kandidati_img {
      float: left;
      @include rem('width', 538px);
      height: 100%;
      position: relative;

      .kandidati_number {
        position: absolute;
        top: 0;
        left: 0;
        background: #e2001a;
        color: #fff;
        @include rem('font-size', 24px);
        font-weight: 700;
        text-decoration: none;
        @include rem('width', 50px);
        @include rem('height', 50px);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        @extend %imgCropped;
      }
    }

    .lajmi_img {
      float: left;
      width: 100%;
      height: 100%;

      img {
        @extend %imgCropped;
      }
    }

    .kandidati_basic_info {
      float: left;
      @include rem('width', 300px);
      height: 100%;
      @include rem('padding', 35px 31px 74px 36px);
      box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);

      .basic_info_heading {
        float: left;
        width: 100%;
        @include rem('margin-bottom', 37px);
        display: flex;
        align-items: center;

        span {
          content: " ";
          background-image: url('../images/dash.svg');
          @include rem('height', 5px);
          @include rem('width', 20px);
          @include rem('margin-right', 16px);
          display: block;
        }

        h3 {
          @include rem('font-size', 18px);
          font-weight: 700;
          color: #231f20;
        }

        .kandidati_info_social_icons {
          margin-left: auto;

          i {
            color: #666666;
            @include rem('font-size', 15px);
            @include rem('margin-left', 23px);

            &:hover {
              color: #e2001a;
            }
          }
        }
      }

      .basic_info_content {
        float: left;
        width: 100%;

        p {
          @include rem('font-size', 14px);
          color: #666666;
          text-transform: uppercase;
          @include rem('margin-bottom', 4px);
        }

        h3 {
          @include rem('font-size', 16px);
          color: #231f20;
          font-weight: 700;
          @include rem('margin-bottom', 28px);
        }
      }
    }
  }

  .kandidati_biography {
    @extend %fullBlock;
    @include rem('margin-bottom', 79px);
    @include rem('font-size', 15px);
    color: #666;

    h4 {
      @include rem('font-size', 18px);
      font-weight: 700;
      @include rem('margin-bottom', 37px);
      line-height: 1.8em;
      color: #231f20;
    }

    p {
      @include rem('margin-bottom', 26px);
      line-height: 1.93em;
    }
  }

  @include tablet {
    width: 100%;
    margin-right: 0;

    .kandidati_img_basic_info {
      height: 400px;
      margin-bottom: 30px;
      width: 100%;

      .kandidati_img {
        width: 50%;
      }

      .kandidati_basic_info {
        width: 50%;

        .basic_info_heading {
          margin-bottom: 15px;
        }
      }
    }

    .kandidati_biography {
      margin-bottom: 50px;
    }
  }

  @include tablet-big {
    width: 100%;
    margin-right: 0;

    .kandidati_img_basic_info {
      height: 400px;
      margin-bottom: 30px;
      width: 100%;

      .kandidati_img {
        width: 50%;
      }

      .kandidati_basic_info {
        width: 50%;

        .basic_info_heading {
          margin-bottom: 15px;
        }
      }
    }

    .kandidati_biography {
      margin-bottom: 50px;
    }
  }

  @include mobile {
    width: 100%;
    margin-right: 0;

    .kandidati_img_basic_info {
      height: auto;
      margin-bottom: 20px;

      .kandidati_img {
        width: 100%;
      }

      .kandidati_basic_info {
        width: 100%;
        padding: 30px;
      }
    }

    .kandidati_biography {
      margin-bottom: 50px;
    }
  }

  @media screen and (min-width: 1025px) and (max-width:1309px) {
    width: 67%;
    margin-right: 5%;

    .kandidati_img_basic_info {
      .kandidati_img {
        width: 60%;
      }

      .kandidati_basic_info {
        width: 40%;
      }
    }
  }
}

.single_kandidati_shkrime {
  float: right;
  clear: right;
  @include rem('width', 330px);
  @include rem('height', 414px);
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);
  @include rem('padding', 40px);
  @include rem('margin-bottom', 40px);

  .shkrime_heading {
    float: left;
    width: 100%;
    @include rem('margin-bottom', 10px);
    display: flex;
    align-items: center;

    span {
      content: " ";
      background-image: url('../images/dash.svg');
      @include rem('height', 5px);
      @include rem('width', 20px);
      @include rem('margin-right', 16px);
      display: block;
    }

    h3 {
      @include rem('font-size', 18px);
      font-weight: 700;
    }
  }

  .shkrime_item {
    float: left;
    width: 100%;

    p {
      @include rem('font-size', 12px);
      color: #666;
      @include rem('margin-bottom', 9px);
      @include rem('margin-top', 19px);
    }

    a {
      @include rem('font-size', 14px);
      color: #231f20;
      font-weight: 700;
      text-decoration: none;
      @include rem('line-height', 18px);

      &:hover {
        color: #e2001a;
      }
    }
  }

  @include tablet {
    float: left;
  }

  @include tablet-big {
    width: 57%;
    float: left;
  }

  @include mobile {
    width: 100%;
  }

  @media screen and (min-width: 1025px) and (max-width:1309px) {
    width: 28%;
  }
}

.right_sidebar_shkrime {
  @include tablet {
    height: auto;
    width: 100%;
  }

  @include tablet-big {
    height: auto;
    width: 100%;
  }
}

.single_kandidati_fb_plugin {
  float: right;
  clear: right;
  @include rem('width', 330px);
  @include rem('height', 414px);
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);

  @include tablet {
    width: 100%;
    margin-bottom: 50px;

    iframe {
      width: 100%;
    }
  }

  @include tablet-big {
    width: 100%;
    margin-bottom: 50px;

    iframe {
      width: 100%;
    }
  }

  @include mobile {
    width: 100%;
    margin-bottom: 50px;

    iframe {
      width: 100%;
    }
  }

  @media screen and (min-width: 1025px) and (max-width:1309px) {
    width: 309px;
  }
}

//----Single Lajmi-template-----//
.kontribo_buttons {
  float: right;
  clear: right;
  @include rem('width', 330px);
  @include rem('height', 325px);
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);
  @include rem('padding', 40px);
  @include rem('margin-bottom', 40px);

  .shkrime_heading {
    float: left;
    width: 100%;
    @include rem('margin-bottom', 30px);
    display: flex;
    align-items: center;

    span {
      content: " ";
      background-image: url('../images/dash.svg');
      @include rem('height', 5px);
      @include rem('width', 20px);
      @include rem('margin-right', 16px);
      display: block;
    }

    h3 {
      @include rem('font-size', 18px);
      font-weight: 700;
    }
  }

  form {
    float: left;
    width: 100%;

    &>:nth-child(2n+2) {
      @include rem('margin-right', 0);
    }

    .kontribo_form_wrap {
      @extend %fullBlock;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .input_holder {
      float: left;
      transition: $transition1;

      input:checked {
        ~.kontribo_button {
          border-color: transparent;
          transition: $transition1;
          box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);

          i,
          span {
            color: #e2001a;
            transition: $transition1;
          }
        }
      }
    }

    .kontribo_button {
      float: left;
      @include rem('width', 117px);
      @include rem('height', 50px);
      @include rem('margin-bottom', 20px);
      text-align: center;
      border: 1px solid #e6e6e6;
      transition: $transition1;

      input[type=text],
      input[type=number] {
        float: left;
        width: 60px;
        border: none;
        font-size: 16px;
        font-weight: 700;
        text-align: right;
        padding-right: 15px;
        line-height: 44px;
        background: transparent;
        border: 0;
      }

      input[type=text] {
        text-align: center;
        padding-right: 0;
      }

      i {
        float: left;
        width: 43px;
        font-size: 18px;
        text-align: center;
        line-height: 44px;
      }

      span {
        line-height: 46px;
        font-weight: 700;
        font-size: 16px;
      }

      &:hover {
        border-color: transparent;
        box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);

        label {
          color: #e2001a;
          transition: $transition1;
        }
      }
    }

    button, input[type="submit"] {
      float: left;
      @include rem('height', 47px);
      width: 100%;
      background: #e2001a;
      @include rem('font-size', 18px);
      font-weight: 700;
      color: white;
      cursor: pointer;
      text-transform: uppercase;
      text-decoration: none;
      border-radius: 3px;
      @include flexCenter(true, true);
      box-shadow: none !important;
      border-color: rgba(0, 0, 0, 0) !important;
      outline: none !important;
      cursor: pointer;
      transition: background .25s ease-in-out;

      &:hover {
        background: lighten(#e2001a, 5%);
      }

      &:active {
        transform: scale(0.95);
      }
    }
  }

  .dhuro {
    float: left;
    @include rem('height', 50px);
    width: 100%;
    background: #e2001a;
    @include rem('font-size', 18px);
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 3px;
    @include flexCenter(true, true);
  }

  @include tablet {
    float: right;
  }

  @include tablet-big {
    float: right;
  }

  @include mobile {
    width: 100%;
    height: auto;
  }

  @media (max-width: 320px) {
    form {
      .kontribo_form_wrap {
        .input_holder {
          .kontribo_button {
            width: 107px !important;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width:1309px) {
    width: 28%;

    form {
      .kontribo_form_wrap {
        .input_holder {
          .kontribo_button {
            width: 107px;
          }
        }
      }
    }
  }
}

.te_tjere {
  @extend %fullBlock;
  background: #f2f2f2;
  @include rem('padding-bottom', 104px);
  @include rem('padding-top', 104px);

  .te_tjere_heading {
    @include rem('padding-top', 0);
    @include rem('margin-bottom', 41px);
    height: auto;
    display: flex;
    -ms-flex-align: center;
    align-items: center;

    span {
      content: " ";
      background-image: url(../images/dash.svg);
      @include rem('height', 5px);
      @include rem('width', 20px);
    }

    h2 {
      @include rem('font-size', 27px);
      font-weight: 700;
      color: #231f20;
      text-transform: uppercase;
      @include rem('margin-left', 29px);
    }
  }

  .media_grid {
    @include rem('margin-bottom', 0);

    li {
      @include rem('margin-bottom', 0);

      .media_img {
        @include rem('width', 290px);
        @include rem('height', 290px);
        position: relative;

        span {
          position: absolute;
          top: 0;
          left: 0;
          background: #e2001a;
          color: white;
          @include rem('font-size', 24px);
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          @include rem('width', 50px);
          @include rem('height', 50px);
        }
      }

      .te_tjere_kandidati_name {
        float: left;
        width: 100%;
        @include rem('margin-top', 30px);
        text-align: left;

        h2 {
          @include rem('font-size', 18px);
          text-align: left;
          color: #231f20;
          font-weight: 700;
        }
      }

      &:hover {
        .te_tjere_kandidati_name h2 {
          color: #e2001a;
        }
      }
    }
  }

  @include tablet {
    padding-top: 45px;
    padding-bottom: 45px;

    .media_block {
      width: calc(50% - 30px);
      margin-right: 0 !important;
      height: auto;
      margin-right: 5%;
      margin-bottom: 30px !important;

      &:nth-child(3n+3) {
        margin-right: 0;
      }

      .media_img {
        width: 100% !important;
        height: 220px !important;
      }

      .te_tjere_kandidati_name {
        margin-top: 20px !important;
      }
    }
  }

  @include tablet-big {
    padding-top: 45px;
    padding-bottom: 45px;

    .media_block {
      width: calc(33.3333333333% - 30px);
      height: auto;
      margin-right: 0 !important;
      margin-bottom: 30px !important;

      &:nth-child(3n+3) {
        margin-right: 0;
      }

      .media_img {
        width: 100% !important;
        height: 220px !important;
      }

      .te_tjere_kandidati_name {
        margin-top: 20px !important;
      }
    }
  }

  @include mobile {
    padding-top: 45px;
    padding-bottom: 45px;

    .media_block {
      width: 100%;
      height: auto;
      margin-right: 0;
      margin-bottom: 40px !important;

      &:nth-child(3n+3) {
        margin-right: 0;
      }

      .media_img {
        width: 100% !important;
        height: 250px !important;
      }

      .te_tjere_kandidati_name {
        margin-top: 20px !important;
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width:1309px) {
    .media_block {
      width: 260px;
      height: auto;
      margin-right: 2%;
      margin-bottom: 30px !important;

      &:nth-child(4n+4) {
        margin-right: 0;
      }

      .media_img {
        width: 100% !important;
        height: 220px !important;
      }

      .te_tjere_kandidati_name {
        margin-top: 20px !important;
      }
    }
  }
}

// Levizja-Statusi //
.statusi_banner {
  @extend %fullBlock;
  background-image: url('../images/levizja_statusi.png');
  @include rem('height', 250px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @extend %imgCropped;

  .height_container {
    @include rem('height', 250px);
  }

  .banner_text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .gr_parl_banner_title {
      @include rem('font-size', 36px);
      color: #fff;
      font-weight: 700;
    }

    .gr_parl_banner_subtitle {
      @include rem('font-size', 13px);
      @include rem('margin-top', 11px);
      color: #fff;

      p, span {
        float: left;
        opacity: 0.5;
      }

      span:before {
        content: "  ";
      }
    }
  }

  @include tablet {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include tablet-big {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include mobile {
    height: 120px;

    .height_container {
      height: 120px;

      .banner_text {
        h1 {
          font-size: 23px;
        }
      }
    }
  }
}

.levizja_statusi {
  float: left;
  @include rem('width', 840px);
  @include rem('margin-right', 148px);
  @include rem('margin-bottom', 122px);

  .deputetet_heading {
    @extend %fullBlock;
    @include rem('padding-top', 59px);
    @include rem('margin-bottom', 48px);
    @include rem('height', 86px);
    display: flex;
    align-items: center;

    span {
      content: " ";
      background-image: url('../images/dash.svg');
      @include rem('height', 5px);
      @include rem('width', 20px);
    }

    h2 {
      @include rem('font-size', 27px);
      font-weight: 700;
      color: #231f20;
      text-transform: uppercase;
      @include rem('margin-left', 29px);
    }
  }

  ul {
    margin-left: auto;

    li {
      display: inline-block;
      @include rem('margin-right', 15px);

      a {
        text-decoration: none;
        @include rem('font-size', 13px);
        color: #666666;

        &:hover {
          color: #e2001a;
          cursor: pointer;
        }
      }

      .pdf_icon:after {
        content: "\f1c1";
        font-family: "Font Awesome 5 Pro";
        @include rem('font-size', 16px);
        @include rem('padding-left', 10px);

        &:hover {
          color: #e2001a;
          cursor: pointer;
        }
      }

      .print_icon:after {
        content: "\f02f";
        font-family: "Font Awesome 5 Pro";
        @include rem('font-size', 16px);
        @include rem('padding-left', 10px);

        &:hover {
          color: #e2001a;
          cursor: pointer;
        }
      }

      .social_icons {
        @include rem('font-size', 14px);

        &:hover {
          color: #e2001a;
          cursor: pointer;
        }
      }

      &:last-child {
        @include rem('margin-right', 0);
      }

      &:nth-child(3) {
        a {
          cursor: default;

          &:hover {
            color: #666666;
          }
        }
      }
    }
  }

  #see_all {
    color: #666;
    text-decoration: none;
    margin-left: auto;

    span {
      content: " ";
      background-image: url('../images/lexomeshume.svg');
      @include rem('height', 8px);
      @include rem('width', 14px);
      @include rem('margin-left', 22px);
      display: block;
      float: right;
      @include rem('margin-left', 20px);
      @include rem('margin-top', 3px);
    }

    &:hover {
      color: #e2001a;

      span {
        content: " ";
        background-image: url('../images/lexomeshume-red.svg');
        @include rem('height', 8px);
        @include rem('width', 14px);
        @include rem('margin-left', 22px);
        display: block;
        float: right;
        @include rem('margin-left', 20px);
        @include rem('margin-top', 3px);
      }
    }
  }

  @include tablet {
    width: 100%;
    margin-right: 0;
    margin-top: 40px;
  }

  @include tablet-big {
    width: 100%;
    margin-right: 0;
    margin-top: 40px;
  }

  @include mobile {
    width: 100%;
    margin-right: 0;
    margin-top: 40px;
  }

  @media screen and (min-width: 1025px) and (max-width:1309px) {
    width: 70%;
    margin-right: 5%;

    .deputetet_heading {
      h2 {
        margin-left: 15px;
        font-size: 25px;
      }

      ul {
        li {
          margin-right: 12px;
        }
      }
    }
  }
}

.statuti_right {
  float: left;
  @include rem('width', 290px);
  @include rem('margin-top', 59px);
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);

  ul {
    li {
      width: 100%;
      float: left;

      a {
        width: 100%;
        display: flex;
        align-items: center;
        @include rem('height', 70px);
        text-decoration: none;
        @include rem('font-size', 14px);
        @include rem('padding-right', 30px);
        color: #231f20;
        border-bottom: 1px solid rgba(0, 0, 0, .1);

        span {
          float: left;
          @include rem('width', 57px);
          color: #e2001a;
          @include rem('font-size', 14px);
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
        }

        p {
          float: left;
          width: 100%;
        }

        #statuti_heading {
          @include rem('font-size', 15px);
          font-weight: 700;
        }

        &.active,
        &:hover {
          color: #e2001a;
          border-right: 5px solid #e2001a;
          box-shadow: -84px 0px 0px 0px rgba(255, 255, 255, 1);
          transition: $transition1;

          @include desktop-small {
            box-shadow: -50px 0px 0px 0px rgba(255, 255, 255, 1);
          }
        }
      }

      #statuti_dash {
        float: left;
        content: " ";
        background-image: url('../images/dash.svg');
        @include rem('height', 5px);
        @include rem('width', 10px);
        @include rem('margin', 0 16px 0 27px);
      }

      &:hover {
        &:first-child {
          a {
            color: #231f20;
            box-shadow: none;
            border-right: none;
          }
        }
      }
    }
  }

  @include tablet {
    width: 100%;

    ul {
      li {
        a.active {
          color: #e2001a;
          box-shadow: none;
          border-right: 5px solid #e2001a;
          transition: $transition1;
        }

        &.hide_prioritetet {
          display: none;
        }

        &.hide_statuti {
          display: none;
        }
      }
    }
  }

  @include tablet-big {
    width: 100%;

    ul {
      li {
        a.active {
          color: #e2001a;
          box-shadow: none;
          border-right: 5px solid #e2001a;
          transition: $transition1;
        }

        &.hide_prioritetet {
          display: none;
        }

        &.hide_statuti {
          display: none;
        }
      }
    }
  }

  @include mobile {
    width: 100%;

    ul {
      li {
        a.active {
          color: #e2001a;
          box-shadow: none;
          border-right: 5px solid #e2001a;
          transition: $transition1;
        }

        &.hide_prioritetet {
          display: none;
        }

        &.hide_statuti {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width:1309px) {
    width: 25%;
  }
}

#levizja_statuti_container {
  @include tablet {
    display: flex;
    flex-direction: column;

    #levizja_statusi {
      order: 2;
    }

    #statuti_right {
      order: 1;
    }
  }

  @include tablet-big {
    display: flex;
    flex-direction: column;

    #levizja_statusi {
      order: 2;
    }

    #statuti_right {
      order: 1;
    }
  }

  @include mobile {
    display: flex;
    flex-direction: column;

    #levizja_statusi {
      order: 2;
    }

    #statuti_right {
      order: 1;
    }
  }
}

.neni {
  float: left;
  width: 100%;
  @include rem('margin-bottom', 29px);

  .neni_heading {
    @include rem('font-size', 21px);
    display: flex;
    font-weight: 700;
    color: #231f20;
    text-decoration: none;
    @include rem('margin-bottom', 29px);

    &:after {
      content: "";
      @include rem('height', 1px);
      @include rem('margin-left', 19px);
      background-color: rgba(0, 0, 0, 0.1);
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      -ms-flex-positive: 1;
      flex-grow: 1;
    }
  }

  .neni_content {
    color: #666666;
    @include rem('font-size', 15px);
    line-height: 27px;
  }

  &:last-child {
    @include rem('margin-bottom', 0);
  }
}

//---Prioritetet-----//
.prioritetet {
  float: left;
  width: 100%;
  @include rem('margin-bottom', 270px);

  .prioriteti_heading {
    @include rem('font-size', 21px);
    display: flex;
    font-weight: 700;
    color: #231f20;
    text-decoration: none;
    @include rem('margin-bottom', 29px);

    span {
      color: #e2001a;
    }
  }

  .active_pr {
    color: #e2001a !important;
  }

  .prioriteti_content {
    color: #666666;
    @include rem('font-size', 15px);
    line-height: 27px;
    border-bottom: 1px solid RGBA(0, 0, 0, 0.1);
    @include rem('padding-bottom', 44px);
    @include rem('margin-bottom', 50px);
  }

  & p:last-child {
    @include rem('margin-bottom', 0);
    border-bottom: 0;
  }
}

// -----Publikimet - fletushkat-template------//
.fletushkat_banner {
  @extend %fullBlock;
  background-image: url('../images/publikimet.png');
  @include rem('height', 250px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @extend %imgCropped;

  .height_container {
    @include rem('height', 250px);
  }

  .banner_text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .gr_parl_banner_title {
      @include rem('font-size', 36px);
      color: #fff;
      font-weight: 700;
    }

    .gr_parl_banner_subtitle {
      @include rem('font-size', 13px);
      @include rem('margin-top', 11px);
      color: #fff;

      p, span {
        float: left;
        opacity: 0.5;
      }

      span:before {
        content: "  ";
      }
    }
  }

  @include tablet {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include tablet-big {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include mobile {
    height: 120px;

    .height_container {
      height: 120px;

      .banner_text {
        h1 {
          font-size: 23px;
        }
      }
    }
  }
}

.fletushkat_background {
  background: #fff;

  .publikimet_heading {
    @extend %fullBlock;
    @include rem('padding-top', 59px);
    @include rem('margin-bottom', 48px);
    @include rem('height', 86px);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    span {
      content: " ";
      background-image: url('../images/dash.svg');
      @include rem('height', 5px);
      @include rem('width', 20px);
    }

    h2 {
      @include rem('font-size', 27px);
      font-weight: 700;
      color: #231f20;
      text-transform: uppercase;
      @include rem('margin-left', 29px);
    }

    #kerko_button {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      color: #231f20;
      border: 1px solid #e6e6e6;
      @include rem('font-size', 15px);
      border-radius: 50px;

      input {
        outline: none;
      }

      input[type=search] {
        -webkit-appearance: textfield;
        font-family: inherit;
        font-size: 100%;
      }

      input::-webkit-search-decoration,
      input::-webkit-search-cancel-button {
        display: none;
      }

      input[type=search] {
        background: #fff url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 18px center;
        border: solid 0px;
        padding: 0px 10px 0px 32px;
        width: 135px;
        -webkit-border-radius: 10em;
        -moz-border-radius: 10em;
        border-radius: 10em;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        transition: all .5s;
      }

      input[type=search]:focus {
        width: 100%;
        background-color: #fff;
        outline: none;
      }

      input:-moz-placeholder {
        color: #231f20;
        font-size: 15px;
        font-weight: 300;
      }

      input::-webkit-input-placeholder {
        color: #231f20;
        font-size: 15px;
        font-weight: 300;
      }

      & input[type=search] {
        height: 50px;
        padding-left: 55px;
        cursor: pointer;
      }

      & input[type=search]:hover {
        background-color: #fff;
      }

      & input[type=search]:focus {
        width: 230px;
        padding-left: 55px;
        color: #000;
        outline: none;
        background-color: #fff;
        cursor: auto;
      }
    }

    ul {
      position: absolute;
      right: 0;

      li {
        display: inline-block;
        @include rem('margin-right', 20px);

        a {
          text-decoration: none;
          @include rem('font-size', 13px);
          color: #666666;

          &:hover {
            color: #e2001a;
            cursor: pointer;
          }
        }

        .pdf_icon:after {
          content: "\f1c1";
          font-family: "Font Awesome 5 Pro";
          @include rem('font-size', 16px);
          @include rem('padding-left', 10px);

          &:hover {
            color: #e2001a;
            cursor: pointer;
          }
        }

        .print_icon:after {
          content: "\f02f";
          font-family: "Font Awesome 5 Pro";
          @include rem('font-size', 16px);
          @include rem('padding-left', 10px);

          &:hover {
            color: #e2001a;
            cursor: pointer;
          }
        }

        .social_icons {
          @include rem('font-size', 14px);

          &:hover {
            color: #e2001a;
            cursor: pointer;
          }
        }

        &:last-child {
          @include rem('margin-right', 0);
        }

        &:nth-child(3) {
          a {
            cursor: default;

            &:hover {
              color: #666666;
            }
          }
        }
      }
    }

    @include tablet {
      margin-bottom: 100px;

      #kerko_button {
        top: 120%;
      }
    }

    @include tablet-big {
      margin-bottom: 100px;

      #kerko_button {
        top: 120%;
      }
    }

    @include mobile {
      margin-bottom: 50px;
      padding-top: 20px;

      #kerko_button {
        top: 88%;
        position: static;
        transform: none;
        width: 100%;
        margin-top: 18px;

        input[type=search] {
          @include fullBlock;
          height: 40px;
          transform: none;
        }
      }

      h2 {
        margin-left: 25px;
      }
    }
  }

  .publikimet_default_template_text {
    float: left;
    @include rem('margin-bottom', 50px);

    h4 {
      @include rem('font-size', 21px);
      font-weight: 700;
      color: #231f20;
      @include rem('margin-bottom', 20px);
    }

    p {
      @include rem('font-size', 15px);
      color: #666666;
      line-height: 27px;
    }
  }

  .media_grid {
    @include fullBlock;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    ul {
      &>:nth-child(4n+4) {
        @include rem('margin-right', 0);
      }

      li {
        float: left;
        @include rem('width', 290px);
        @include rem('height', 290px);
        @include rem('margin-right', 40px);
        @include rem('margin-bottom', 40px);
        @include rem('padding-top', 80px);
        border: 1px solid #e6e6e6;
        text-align: center;

        a {
          text-decoration: none;
          color: #231f20;
          @include rem('font-size', 18px);

          img {
            @include rem('width', 45px);
            @include rem('height', 62px);
            margin: 0 auto;
            @extend %imgCropped;
            @include rem('margin-bottom', 55px);
          }

          h4 {
            font-weight: 700;
            @include rem('margin-bottom', 7px);
          }
        }

        &:hover {
          transition: $transition1;
          box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);

          h4, p {
            color: #e2001a;
            text-decoration: underline;
          }
        }
      }

      @include tablet {
        .fletushka_block {
          width: 30%;
          height: 200px;
          padding-top: 37px;
          margin-right: 5%;

          img {
            margin-bottom: 23px;
          }

          &:nth-child(3n+3) {
            margin-right: 0 !important;
          }
        }
      }

      @include tablet-big {
        .fletushka_block {
          width: 30%;
          height: 200px;
          padding-top: 37px;
          margin-right: 5%;

          img {
            margin-bottom: 23px;
          }

          &:nth-child(3n+3) {
            margin-right: 0 !important;
          }
        }
      }

      @include mobile {
        .fletushka_block {
          width: 100%;
          margin-right: 0;
        }
      }

      @media screen and (min-width: 1025px) and (max-width:1309px) {
        .fletushka_block {
          width: 22%;
          height: 246px;
          padding-top: 37px;
          margin-right: 4%;

          img {
            margin-bottom: 23px;
          }

          &:nth-child(4n+4) {
            margin-right: 0 !important;
          }
        }
      }
    }
  }

  .publikimet_grid_template {
    @include rem('margin-bottom', 20px);
  }
}

//---Alternativa-shteti-se-drejtes-template---- //
.alternativa_banner {
  @extend %fullBlock;
  background-image: url('../images/alternativa_banner.png');
  @include rem('height', 250px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @extend %imgCropped;

  .height_container {
    @include rem('height', 250px);
  }

  .banner_text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .gr_parl_banner_title {
      @include rem('font-size', 36px);
      color: #fff;
      font-weight: 700;
    }

    .gr_parl_banner_subtitle {
      @include rem('font-size', 13px);
      @include rem('margin-top', 11px);
      color: #fff;

      p, span {
        float: left;
        opacity: 0.5;
      }

      span:before {
        content: "  ";
      }
    }
  }

  @include tablet {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include tablet-big {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include mobile {
    height: 120px;

    .height_container {
      height: 120px;

      .banner_text {
        h1 {
          font-size: 23px;
        }
      }
    }
  }
}

.alternativa_shteti_se_drejtes {
  @extend %fullBlock;
  @include rem('width', 840px);
  @include rem('margin-right', 148px);

  .shteti_se_drejtes_content {
    @extend %fullBlock;

    h4 {
      @include rem('font-size', 15px);
      font-weight: 700;
      font-weight: 700;
      color: #666666;
      line-height: 27px;
      @include rem('margin-bottom', 33px);
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }

    img {
      float: left;
      @include rem('width', 840px);
      @include rem('height', 560px);
    }

    h3 {
      float: left;
      @include rem('font-size', 21px);
      font-weight: 700;
      color: #231f20;
      @include rem('margin-top', 55px);
    }

    p {
      float: left;
      width: 100%;
      @include rem('font-size', 15px);
      color: #666666;
      line-height: 27px;
      @include rem('margin-top', 28px);
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }

    .sidebar_template_text_content {
      @include rem('margin-bottom', 77px);

      @include mobile {
        margin-bottom: 0;
      }
    }

    .shkarko_alternativen {
      float: left;
      width: 100%;
      text-align: center;
      text-decoration: none;
      color: white;
      @include rem('font-size', 15px);
      font-weight: 700;
      background: #e2001a;
      border-radius: 2px;
      @include rem('padding', 16px 0 20px 0);
      @include rem('margin-top', 33px);
    }

    .shkarko_alternativen_plote {
      float: left;
      width: 100%;
      text-align: center;
      text-decoration: none;
      color: #e2001a;
      @include rem('font-size', 15px);
      font-weight: 700;
      background: #f2f2f2;
      border-radius: 2px;
      @include rem('padding', 16px 0 20px 0);
      @include rem('margin-top', 33px);
      @include rem('margin-bottom', 202px);
    }
  }

  @include tablet {
    width: 100%;
    margin-right: 0;

    .shteti_se_drejtes_content {
      img {
        width: 100%;
      }

      .shkarko_alternativen_plote {
        margin-bottom: 80px !important;
      }
    }
  }

  @include tablet-big {
    width: 100%;
    margin-right: 0;

    .shteti_se_drejtes_content {
      img {
        width: 100%;
      }

      .shkarko_alternativen_plote {
        margin-bottom: 80px !important;
      }
    }
  }

  @include mobile {
    width: 100%;
    margin-right: 0;

    .deputetet_heading {
      padding-top: 14px;
      margin-bottom: 0;

      h2 {
        font-size: 23px;
        margin-left: 25px;
      }
    }

    .shteti_se_drejtes_content {
      img {
        width: 100%;
        height: auto !important;
      }

      h3 {
        margin-top: 45px;
      }

      h4 {
        font-size: 13px;
        line-height: 20px;
      }

      iframe {
        height: auto;
      }

      p {
        font-size: 13px;
        line-height: 20px;
      }

      .shkarko_alternativen {
        font-size: 13px;
        padding: 15px;
      }

      .shkarko_alternativen_plote {
        font-size: 13px;
        padding: 15px;
        margin-bottom: 0;
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width:1309px) {
    width: 70%;
    margin-right: 5%;

    .deputetet_heading {
      h2 {
        margin-left: 15px;
        font-size: 25px;
      }
    }
  }
}

.alternativa_kontribo_section {
  float: right;
  clear: right;
  @include rem('width', 290px);
  @include rem('margin-top', 59px);
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);
  @include rem('margin-bottom', 38px);
  background: #fff;

  .kontribo_section_heading {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    @include rem('padding', 26px 32px 20px 31px);

    span {
      float: left;
      content: " ";
      background-image: url('../images/dash.svg');
      @include rem('width', 10px);
      @include rem('height', 5px);
      @include rem('margin-right', 17px);
    }

    p {
      float: left;
      @include rem('font-size', 15px);
      font-weight: 700;
      color: #231f20;
      text-transform: uppercase;
    }
  }

  .kontribo_img {
    float: left;
    width: 100%;
    height: auto;

    img {
      @extend %imgCropped;
    }
  }

  .kontribo_forma {
    float: left;
    width: 100%;
    @include rem('padding', 30px);

    form {
      float: left;
      width: 100%;

      &>:nth-child(2n+2) {
        @include rem('margin-right', 0);
      }

      .kontribo_form_wrap {
        @extend %fullBlock;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .input_holder {
        float: left;
        transition: $transition1;

        input:checked {
          ~.kontribo_button {
            border-color: transparent;
            transition: $transition1;
            box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);

            i,
            span {
              color: #e2001a;
              transition: $transition1;
            }
          }
        }
      }

      .kontribo_button {
        float: left;
        @include rem('width', 105px);
        @include rem('height', 47px);
        @include rem('margin-bottom', 20px);
        text-align: center;
        border: 1px solid #e6e6e6;
        transition: $transition1;

        input[type=text],
        input[type=number] {
          float: left;
          width: 60px;
          border: none;
          font-size: 16px;
          font-weight: 700;
          text-align: right;
          padding-right: 15px;
          line-height: 44px;
          background: transparent;
          border: 0;
        }

        input[type=text] {
          text-align: center;
          padding-right: 0;
        }

        i {
          float: left;
          width: 43px;
          font-size: 18px;
          text-align: center;
          line-height: 44px;
        }

        span {
          line-height: 46px;
          font-weight: 700;
          font-size: 16px;
        }

        &:hover {
          border: none;
          box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);

          label {
            color: #e2001a;
            transition: $transition1;
          }
        }
      }

      button {
        float: left;
        @include rem('height', 47px);
        width: 100%;
        background: #e2001a;
        @include rem('font-size', 18px);
        font-weight: 700;
        color: white;
        cursor: pointer;
        text-transform: uppercase;
        text-decoration: none;
        border-radius: 3px;
        @include flexCenter(true, true);
        box-shadow: none !important;
        border-color: rgba(0, 0, 0, 0) !important;
        outline: none !important;
      }
    }

    @include mobile {
      p {
        display: block;
      }
    }
  }

  @include tablet {
    margin-top: 0;
    width: 45%;
    float: left;
  }

  @include tablet-big {
    margin-top: 0;
    width: 45%;
    float: left;

    .kontribo_forma {
      form {
        .kontribo_button {
          width: 150px !important;
          height: 55px !important;

          i, span {
            line-height: 55px;
          }
        }

        button {
          height: 55px;
        }
      }
    }
  }

  @include mobile {
    width: 100%;

    .kontribo_forma {
      form {
        .kontribo_button {
          width: 120px !important;
          height: 50px !important;

          i, span {
            line-height: 50px;
          }
        }

        button {
          height: 50px;
        }
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width:1309px) {
    width: 25%;

    .kontribo_forma {
      form {
        .kontribo_button {
          width: 100px;
        }
      }
    }
  }

  @media (max-width: 320px) {
    .kontribo_forma {
      form {
        .kontribo_form_wrap {
          .input_holder {
            .kontribo_button {
              width: 107px !important;
            }
          }
        }
      }
    }
  }
}

.alternativa_lajmet_e_fundit {
  float: right;
  clear: right;
  @include rem('width', 290px);
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);
  background: #fff;
  @include rem('padding', 27px 30px 27px 30px);
  margin-bottom: 38px;

  .lajmet_section_heading {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;

    span {
      float: left;
      content: " ";
      background-image: url('../images/dash.svg');
      @include rem('width', 10px);
      @include rem('height', 5px);
      @include rem('margin-right', 17px);
    }

    p {
      float: left;
      @include rem('font-size', 15px);
      font-weight: 700;
      color: #231f20;
      text-transform: uppercase;
    }
  }

  .lajmifundit_lajmi {
    float: left;
    width: 100%;
    @include rem('margin-top', 27px);

    .lajmi_img {
      float: left;
      width: 100%;
      height: auto;

      img {
        @extend %imgCropped;
      }
    }

    p {
      float: left;
      width: 100%;
      @include rem('margin-top', 22px);
      @include rem('margin-bottom', 13px);
      @include rem('font-size', 12px);
      color: #666666;

      span {
        @include rem('margin-left', 10px);
        @include rem('margin-right', 10px);
      }
    }

    h4 {
      float: left;
      @include rem('font-size', 14px);
      font-weight: 700;
      color: #231f20;
    }

    &:hover {
      h4 {
        color: #e2001a;
      }
    }
  }

  @include tablet {
    float: right;
    width: 45%;
    margin-bottom: 70px;
  }

  @include tablet-big {
    float: right;
    width: 45%;
    margin-bottom: 70px;
  }

  @include mobile {
    width: 100%;
    margin-bottom: 40px;
  }

  @media screen and (min-width: 1025px) and (max-width:1309px) {
    width: 25%;
  }
}

// -----Gazetat-template----//
.gazetat_banner {
  @extend %fullBlock;
  background-image: url('../images/publikimet.png');
  @include rem('height', 250px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: -1px;
  @extend %imgCropped;

  .height_container {
    @include rem('height', 250px);
  }

  .banner_text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .gr_parl_banner_title {
      @include rem('font-size', 36px);
      color: #fff;
      font-weight: 700;
    }

    .gr_parl_banner_subtitle {
      @include rem('font-size', 13px);
      @include rem('margin-top', 11px);
      color: #fff;

      p, span {
        float: left;
        opacity: 0.5;
      }

      span:before {
        content: "  ";
      }
    }
  }

  @include tablet {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include tablet-big {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include mobile {
    height: 120px;

    .height_container {
      height: 120px;

      .banner_text {
        h1 {
          font-size: 23px;
        }
      }
    }
  }
}

.gazetat_background {
  float: left;
  width: 100%;
  background: #fff;

  .publikimet_heading {
    @extend %fullBlock;
    @include rem('padding-top', 59px);
    @include rem('margin-bottom', 48px);
    @include rem('height', 86px);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    span {
      content: " ";
      background-image: url('../images/dash.svg');
      @include rem('height', 5px);
      @include rem('width', 20px);
    }

    h2 {
      @include rem('font-size', 27px);
      font-weight: 700;
      color: #231f20;
      text-transform: uppercase;
      @include rem('margin-left', 29px);
    }

    #kerko_button {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      color: #231f20;
      border: 1px solid #e6e6e6;
      @include rem('font-size', 15px);
      border-radius: 50px;

      input {
        outline: none;
      }

      input[type=search] {
        -webkit-appearance: textfield;
        font-family: inherit;
        font-size: 100%;
      }

      input::-webkit-search-decoration,
      input::-webkit-search-cancel-button {
        display: none;
      }

      input[type=search] {
        background: #fff url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 18px center;
        border: solid 0px;
        padding: 0px 10px 0px 32px;
        width: 135px;
        -webkit-border-radius: 10em;
        -moz-border-radius: 10em;
        border-radius: 10em;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        transition: all .5s;
      }

      input[type=search]:focus {
        width: 100%;
        background-color: #fff;
        outline: none;
      }

      input:-moz-placeholder {
        color: #231f20;
        font-size: 15px;
        font-weight: 300;
      }

      input::-webkit-input-placeholder {
        color: #231f20;
        font-size: 15px;
        font-weight: 300;
      }

      & input[type=search] {
        height: 50px;
        padding-left: 55px;
        cursor: pointer;
      }

      & input[type=search]:hover {
        background-color: #fff;
      }

      & input[type=search]:focus {
        width: 230px;
        padding-left: 55px;
        color: #000;
        outline: none;
        background-color: #fff;
        cursor: auto;
      }
    }

    ul {
      position: absolute;
      right: 0;

      li {
        display: inline-block;
        @include rem('margin-right', 20px);

        a {
          text-decoration: none;
          @include rem('font-size', 13px);
          color: #666666;

          &:hover {
            color: #e2001a;
            cursor: pointer;
          }
        }

        .pdf_icon:after {
          content: "\f1c1";
          font-family: "Font Awesome 5 Pro";
          @include rem('font-size', 16px);
          @include rem('padding-left', 10px);

          &:hover {
            color: #e2001a;
            cursor: pointer;
          }
        }

        .print_icon:after {
          content: "\f02f";
          font-family: "Font Awesome 5 Pro";
          @include rem('font-size', 16px);
          @include rem('padding-left', 10px);

          &:hover {
            color: #e2001a;
            cursor: pointer;
          }
        }

        .social_icons {
          @include rem('font-size', 14px);

          &:hover {
            color: #e2001a;
            cursor: pointer;
          }
        }

        &:last-child {
          @include rem('margin-right', 0);
        }

        &:nth-child(3) {
          a {
            cursor: default;

            &:hover {
              color: #666666;
            }
          }
        }
      }
    }

    @include tablet {
      margin-bottom: 100px;

      #kerko_button {
        top: 120%;
      }
    }

    @include tablet-big {
      margin-bottom: 100px;

      #kerko_button {
        top: 120%;
      }
    }

    @include mobile {
      margin-bottom: 50px;
      padding-top: 20px;

      #kerko_button {
        top: 88%;
        position: static;
        transform: none;
        width: 100%;
        margin-top: 17px;

        input[type=search] {
          @include fullBlock;
          height: 40px;
          transform: none;
        }
      }

      h2 {
        margin-left: 25px;
      }
    }
  }

  .gazetat_grid {
    @include fullBlock;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    ul {
      @extend %fullBlock;

      &>:nth-child(4n+4) {
        @include rem('margin-right', 0);
      }

      li {
        float: left;
        @include rem('width', 290px);
        @include rem('height', 450px);
        @include rem('margin-right', 40px);
        @include rem('margin-bottom', 40px);
        @include rem('padding-top', 20px);
        border: 1px solid #e6e6e6;
        text-align: center;

        a {
          text-decoration: none;
          color: #231f20;
          @include rem('font-size', 18px);

          img {
            @include rem('width', 245px);
            @include rem('height', 323px);
            margin: 0 auto;
            @extend %imgCropped;
            @include rem('margin-bottom', 28px);
          }

          h4 {
            font-weight: 700;
            @include rem('margin-bottom', 7px);
          }
        }

        &:hover {
          transition: $transition1;
          box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);

          h4, p {
            color: #e2001a;
            text-decoration: underline;
          }
        }
      }
    }

    @include tablet {
      ul {
        .fletushka_block {
          width: 48%;
          margin-right: 2%;

          &:nth-child(2n+2) {
            margin-right: 0;
            margin-left: 2%;
          }
        }
      }
    }

    @include tablet-big {
      ul {
        .fletushka_block {
          width: 30%;
          margin-right: 5%;

          &:nth-child(3n+3) {
            margin-right: 0;
          }
        }
      }
    }

    @include mobile {
      ul {
        .fletushka_block {
          width: 100%;
          margin-right: 0;
        }
      }
    }

    @media screen and (min-width: 1025px) and (max-width:1309px) {
      ul {
        .fletushka_block {
          width: 23%;
          margin-right: 2.5%;

          &:nth-child(4n+4) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .pagination_wrapper {
    @include mobile {
      margin-top: 34px;
    }
  }
}

// ---Sidebar-template----//
.sidebar_template_banner {
  @extend %fullBlock;
  background-image: url('../images/publikimet.png');
  @include rem('height', 250px);
  margin-top: -1px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @extend %imgCropped;

  .height_container {
    @include rem('height', 250px);
  }

  .banner_text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .gr_parl_banner_title {
      @include rem('font-size', 36px);
      color: #fff;
      font-weight: 700;
    }

    .gr_parl_banner_subtitle {
      @include rem('font-size', 13px);
      @include rem('margin-top', 11px);
      color: #fff;

      p, span {
        float: left;
        opacity: 0.5;
      }

      span:before {
        content: "  ";
      }
    }
  }

  @include tablet {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include tablet-big {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include mobile {
    height: 120px;

    .height_container {
      height: 120px;

      .banner_text {
        h1 {
          font-size: 23px;
        }
      }
    }
  }
}

// ---Organogram-Template---//
.organogram_banner {
  @extend %fullBlock;
  background-image: url('../images/organogram-banner.png');
  @include rem('height', 250px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @extend %imgCropped;

  .height_container {
    @include rem('height', 250px);
  }

  .banner_text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .gr_parl_banner_title {
      @include rem('font-size', 36px);
      color: #fff;
      font-weight: 700;
    }

    .gr_parl_banner_subtitle {
      @include rem('font-size', 13px);
      @include rem('margin-top', 11px);
      color: #fff;

      p, span {
        float: left;
        opacity: 0.5;
      }

      span:before {
        content: "  ";
      }
    }
  }

  @include tablet {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include tablet-big {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include mobile {
    height: 120px;

    .height_container {
      height: 120px;

      .banner_text {
        h1 {
          font-size: 23px;
        }
      }
    }
  }
}

.organogrami_large_image {
  float: left;
  width: 100%;
  @include rem('height', 704px);
  @include rem('margin-bottom', 45px);

  img {
    @extend %imgCropped;
  }

  @include tablet {
    width: 100%;
    height: auto;
  }

  @include tablet-big {
    width: 100%;
    height: auto;
  }

  @include mobile {
    width: 100%;
    height: auto;
  }

  @media (max-width: 320px) {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 1025px) and (max-width:1309px) {
    width: 100%;
    height: auto;
  }
}

.organogrami_bottom_half {
  float: left;
  width: 100%;
  z-index: 5;
  box-shadow: 0 -9px 55px -19px #d9d6d9;
}

.kryesia_lista {
  float: left;
  width: 100%;

  ul {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    @include rem('margin-bottom', 40px);

    &> :nth-child(6n+6) {
      @include rem('margin-right', 0);
    }

    li {
      float: left;
      @include rem('width', 180px);
      @include rem('margin-right', 40px);
      @include rem('margin-bottom', 40px);
      border: 1px solid #e6e6e6;

      a {
        text-decoration: none;

        .kryesia_kandidati_img {
          float: left;
          width: 100%;
          @include rem('height', 180px);
          position: relative;

          &:after {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 5px;
            background: #e2001a;
            opacity: 0;
            transition: $transition1;
          }

          img {
            @extend %imgCropped;
          }
        }

        .kryesia_kandidati_name {
          float: left;
          width: 100%;
          @include rem('padding-top', 26px);
          @include rem('padding-bottom', 26px);
          @include rem('padding-left', 24px);
          @include rem('padding-right', 24px);
          position: relative;

          h3 {
            color: #231f20;
            text-align: left;
            @include rem('font-size', 15px);
          }

          span {
            font-weight: 700;
          }
        }
      }

      &:hover {
        transition: $transition1;

        .kryesia_kandidati_img {
          &:after {
            opacity: 1;
          }
        }

        .kryesia_kandidati_name {
          transition: $transition1;
          box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);

          h3, span {
            color: #e2001a;
          }
        }
      }
    }
  }

  @include tablet {
    ul {
      li {
        width: 30%;
        margin-right: 5%;
        margin-bottom: 30px !important;

        &:nth-child(3n+3) {
          margin-right: 0;
        }
      }
    }
  }

  @include tablet-big {
    ul {
      li {
        width: 30%;
        margin-right: 5%;
        margin-bottom: 30px !important;

        &:nth-child(3n+3) {
          margin-right: 0;
        }
      }
    }
  }

  @include mobile {
    ul {
      li {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width:1309px) {
    ul {
      li {
        width: 185px;
        margin-right: 4% !important;
        margin-bottom: 30px !important;

        &:nth-child(5n+5) {
          margin-right: 0 !important;
        }
      }
    }
  }
}

// ---Antaresohu-miresevjen---//
.antaresohu_banner {
  @extend %fullBlock;
  background-image: url('../images/antaresohu-miresevjen-banner.png');
  @include rem('height', 250px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @extend %imgCropped;

  .height_container {
    @include rem('height', 250px);
  }

  .banner_text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .gr_parl_banner_title {
      @include rem('font-size', 36px);
      color: #fff;
      font-weight: 700;
    }

    .gr_parl_banner_subtitle {
      @include rem('font-size', 13px);
      @include rem('margin-top', 11px);
      color: #fff;

      p, span {
        float: left;
        opacity: 0.5;
      }

      span:before {
        content: "  ";
      }
    }
  }

  @include tablet {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include tablet-big {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include mobile {
    height: 120px;

    .height_container {
      height: 120px;

      .banner_text {
        h1 {
          font-size: 23px;
        }
      }
    }
  }
}

.error {
  position: absolute;
  color: #e2001a;
  bottom: -20px;
  left: 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.error_input {
  border: 1px solid #e2001a !important;
}

#language_checkboxes {
  span.error {
    position: absolute;
    color: #e2001a;
    bottom: -20px;
    left: 0;
  }
}

.antaresohu_form1 {
  float: left;
  width: 100%;

  form {
    float: left;
    width: 100%;

    .antaresohu_1, .antaresohu_2 {
      float: left;
      width: 100%;

      .form_columns {
        float: left;
        @include rem('margin-right', 40px);
        @include rem('margin-bottom', 37px);

        label {
          color: #666666;
          @include rem('font-size', 15px);
        }

        input, select {
          @include rem('width', 290px);
          @include rem('height', 50px);
          @include rem('margin-top', 17px);
          display: flex;
          align-items: center;
          border: 1px solid #e6e6e6;
          border-radius: 2px;
          color: #231f20;
          @include rem('font-size', 15px);
          font-weight: 700;
          @include rem('padding-left', 40px);
          outline: none;
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        @media screen and (min-width: 1025px) and (max-width:1309px) {
          input {
            width: 246px;
          }

          select {
            width: 246px;
          }
        }
      }

      .inputWithIcon {
        position: relative;

        i {
          position: absolute;
          left: 0;
          top: 40px;
          @include rem('padding', 9px 16px);
          color: #aaa;
          transition: 0.3s;
          @include rem('font-size', 13px);
          color: #e2001a;
          font-family: 'Font Awesome 5 Pro';
          font-weight: 700;
        }
      }

      .form_columns2 {
        float: left;
        width: 620px;
        @include rem('margin-right', 40px);

        label {
          color: #666666;
          @include rem('font-size', 15px);
        }

        input {
          @include rem('width', 620px);
          @include rem('height', 50px);
          @include rem('margin-top', 17px);
          @include rem('padding-left', 40px);
          border: 1px solid #e6e6e6;
          border-radius: 2px;
          outline: none;
        }

        @media screen and (min-width: 1025px) and (max-width:1309px) {
          input {
            width: 534px;
          }
        }
      }

      .form_columns3 {
        float: left;
        @include rem('width', 950px);

        label {
          color: #666666;
          @include rem('font-size', 15px);
          font-weight: 300;
        }

        .language_checkboxes {
          float: left;
          width: 100%;
          @include rem('margin-top', 17px);
          @include rem('height', 50px);
          display: flex;
          align-items: center;
          border: 1px solid #e6e6e6;
          border-radius: 2px;
          position: relative;
          padding-left: 16px;
          padding-right: 16px;

          @include mobile {
            flex-wrap: wrap;
            height: auto;
            padding: 16px;
            padding-bottom: 0;
            justify-content: flex-start;

            .cbox_holder {
              margin-right: 0;
              margin-bottom: 16px;
              justify-content: flex-start;
              width: 50%;
            }
          }

          .cbox_holder {
            @include flexCenter(true, true);
            @include rem('margin-right', 16px);

            &:last-child {
              margin-right: 0;
            }

            @include tablet {
              margin-right: 22px;

              label {
                margin-left: 10px;
                margin-right: 0;
                font-size: 14px;
              }
            }
          }

          label {
            color: #231f20;
            @include rem('font-size', 14px);
            font-weight: 700;
            float: left;
            margin-left: 16px;
          }

          input[type=checkbox] {
            @include rem('width', 20px);
            @include rem('height', 20px);
            -webkit-appearance: none;
            background-color: #fff;
            border: 1px solid #b3b3b3;
            border-radius: 10%;
            float: left;
            position: relative;
            cursor: pointer;
            margin: 0;

            &:focus {
              outline: none;
            }

            &:checked:after {
              content: '\f00c';
              font-family: 'Font Awesome 5 Pro';
              font-weight: 700;
              position: absolute;
              top: .1em;
              left: .25em;
              @include rem('font-size', 13px);
              color: #e2001a;
              transition: all .2s;
            }

            &:checked+label {
              color: #e2001a;
            }
          }

          input[type="text"] {
            position: absolute;
            right: 0;
            @include rem('width', 280px);
            height: 100%;
            border-left: 1px solid #e6e6e6 !important;
            border: none;
            outline: 0;
            @include rem('margin', 0);
            color: #231f20;
            @include rem('font-size', 14px);
            font-weight: 700;
            @include rem('padding-left', 16px);
          }
        }

        @media screen and (min-width: 1025px) and (max-width:1309px) {
          width: 820px;

          .language_checkboxes {
            input[type="text"] {
              width: 163px;
            }
          }
        }
      }

      #antaresohu_form_email {
        @include rem('margin-right', 0);
      }

      &> :nth-child(4n+4) {
        @include rem('margin-right', 0);
      }

      @include tablet {
        .form_columns {
          width: 48%;
          margin-right: 0;
          padding-right: 10px;

          &:nth-child(2n+2) {
            margin-right: 0;
            padding-right: 0;
            float: right;
          }

          input {
            width: 100%;
          }

          select {
            width: 100%;
          }
        }

        .form_columns2 {
          width: 100%;
          margin-right: 0;

          input {
            width: 100%;
          }
        }

        .antaresohu_address_responsive {
          margin-bottom: 27px;
        }

        .form_columns3 {
          width: 100%;

          .language_checkboxes {
            label {
              margin-right: 10px;
            }
          }
        }
      }

      @include tablet-big {
        .form_columns {
          width: 48%;
          margin-right: 0;
          padding-right: 10px;

          &:nth-child(2n+2) {
            margin-right: 0;
            padding-right: 0;
            float: right;
          }

          input {
            width: 100%;
          }

          select {
            width: 100%;
          }
        }

        .form_columns2 {
          width: 100%;
          margin-right: 0;

          input {
            width: 100%;
          }
        }

        .antaresohu_address_responsive {
          margin-bottom: 27px;
        }

        .form_columns3 {
          width: 100%;

          .language_checkboxes {
            label {
              margin-right: 20px;
            }
          }
        }
      }

      @include mobile {
        .form_columns {
          width: 100%;
          margin-right: 0;

          input {
            width: 100%;
          }

          select {
            width: 100%;
          }
        }

        .form_columns2 {
          width: 100%;
          margin-right: 0;

          input {
            width: 100%;
          }
        }

        .antaresohu_address_responsive {
          margin-bottom: 27px;
        }

        .form_columns3 {
          width: 100%;

          .language_checkboxes_mobile {
            display: block !important;
            float: left;
            width: 100%;
            @include rem('margin-top', 17px);
            border: 1px solid #e6e6e6;
            border-radius: 2px;
            position: relative;

            .language {
              float: left;
              width: 50%;
              margin: 10px 0;
              display: flex;
              align-items: center;

              label {
                color: #231f20;
                @include rem('font-size', 14px);
                font-weight: 700;
              }

              input[type=checkbox] {
                @include rem('width', 20px);
                @include rem('height', 20px);
                -webkit-appearance: none;
                background-color: #fff;
                border: 1px solid #b3b3b3;
                border-radius: 10%;
                @include rem('margin', 0 16px);
                position: relative;
                cursor: pointer;

                &:focus {
                  outline: none;
                }

                &:checked:after {
                  content: '\f00c';
                  font-family: 'Font Awesome 5 Pro';
                  font-weight: 700;
                  position: absolute;
                  top: .1em;
                  left: .25em;
                  @include rem('font-size', 13px);
                  color: #e2001a;
                  transition: all .2s;
                }

                &:checked+label {
                  color: #e2001a;
                }
              }
            }
          }
        }
      }
    }
  }
}

.antaresohu_vazhdo_button {
  float: left;
  width: 100%;
  height: auto;
  @include rem('margin-bottom', 45px);
  display: flex;
  align-items: center;
  @include rem('margin-top', 22px);
  position: relative;

  .vazhdo_buttoni {
    margin: 0 auto;
    @include rem('width', 180px);
    @include rem('height', 50px);
    text-decoration: none;
    text-align: center;
    color: #fff;
    @include rem('font-size', 15px);
    font-weight: 700;
    background: #e2001a;
    text-transform: uppercase;
    border-radius: 2px;
    @include rem('padding', 0 22px);
    @include rem('line-height', 50px);
    position: relative;
    z-index: 2;
    transition: background .25s ease-in-out;

    &:hover {
      background: lighten(#e2001a, 5%);
    }

    &:active {
      transform: scale(0.98);
    }
  }

  &::before {
    content: '';
    @include rem('height', 1px);
    background: #CCC;
    width: 100%;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &::before {
    left: 0;
  }
}

.antaresohu_hr {
  float: left;
  width: 100%;
  @include rem('margin-bottom', 29px);
  @include rem('margin-top', 29px);

  hr {
    width: 100%;
    display: block;
    @include rem('height', 1px);
    border: 0;
    background: #e6e6e6;
  }
}

.konfirmo_antaresimin {
  @extend %fullBlock;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include rem('margin-bottom', 106px);

  input[type=checkbox] {
    @include rem('width', 12px);
    @include rem('height', 12px);
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #b3b3b3;
    @include rem('margin-right', 22px);
    position: relative;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:checked:after {
      content: '\f00c';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 700;
      position: absolute;
      top: -1px;
      background: #e2001a;
      @include rem('font-size', 12px);
      color: #fff;
      transition: all .2s;
    }
  }

  label {
    a {
      color: #666666;
      @include rem('font-size', 15px);
      text-decoration: none;
    }
  }

  button[type=submit] {
    align-self: flex-end;
    margin-left: auto;
    @include rem('width', 290px);
    @include rem('height', 50px);
    background: #e2001a;
    color: #fff;
    font-weight: 700;
    border-radius: 2px;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: none !important;
    border-color: rgba(0, 0, 0, 0) !important;
    outline: none !important;

    @include mobile {
      width: 100%;
      margin-top: 40px;
    }
  }
}

.konfirmo_antaresimin_mobile {
  @include mobile {
    display: block !important;
    float: left;
    width: 100%;
    margin-bottom: 30px;

    .confirm_check {
      display: flex;
      align-items: center;
      margin: 30px 0;

      input[type=checkbox] {
        @include rem('width', 12px);
        @include rem('height', 12px);
        -webkit-appearance: none;
        background-color: #fff;
        border: 1px solid #b3b3b3;
        ;
        @include rem('margin-right', 22px);
        position: relative;
        cursor: pointer;

        &:focus {
          outline: none;
        }

        &:checked:after {
          content: '\f00c';
          font-family: 'Font Awesome 5 Pro';
          font-weight: 700;
          position: absolute;
          top: -1px;
          background: #e2001a;
          @include rem('font-size', 12px);
          color: #fff;
          transition: all .2s;
        }
      }

      label {
        width: 90%;
        color: #666666;
        @include rem('font-size', 13px);

        span {
          color: #e2001a;
          font-weight: 700;
        }
      }
    }

    button[type=submit] {
      align-self: flex-end;
      margin-left: auto;
      width: 100%;
      @include rem('height', 50px);
      background: #e2001a;
      color: #fff;
      font-weight: 700;
      border-radius: 2px;
      cursor: pointer;
      text-transform: uppercase;
      box-shadow: none !important;
      border-color: rgba(0, 0, 0, 0) !important;
      outline: none !important;
    }
  }
}

.antaresohu_2,
.antaresohu_1 {
  position: relative;
  float: left;
  width: 100%;
}

.is--hidden {
  user-select: none;

  &:before {
    content: " ";
    background: rgba(255, 255, 255, .750);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
}

// ----Kontribo-Donacione----//
.kontribo_banner {
  @extend %fullBlock;
  background-image: url('../images/kontribo-banner.png');
  @include rem('height', 250px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .height_container {
    @include rem('height', 250px);
  }

  .banner_text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .gr_parl_banner_title {
      @include rem('font-size', 36px);
      color: #fff;
      font-weight: 700;
    }

    .gr_parl_banner_subtitle {
      @include rem('font-size', 13px);
      @include rem('margin-top', 11px);
      color: #fff;

      p, span {
        float: left;
        opacity: 0.5;
      }

      span:before {
        content: "  ";
      }
    }
  }

  @include tablet {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include tablet-big {
    height: 200px;

    .height_container {
      height: 200px;
    }
  }

  @include mobile {
    height: 200px;

    .height_container {
      height: 200px;

      .banner_text {
        h1 {
          font-size: 23px;
        }
      }
    }
  }
}

.background_donacione {
  float: left;
  width: 100%;
  background: #fff;
}

.donacione_tabs {
  @extend %fullBlock;
  z-index: 99;
  box-shadow: inset 0 84px 97px -81px rgba(0, 0, 0, 0.1);

  ul {
    float: left;
    width: 100%;
    position: relative;

    li {
      float: left;
      @include rem('width', 320px);
      height: auto;
      background: transparent;
      border-right: 1px solid #fff;

      a {
        text-decoration: none;
        color: #231f20;

        p {
          @include rem('font-size', 21px);
          @include rem('padding-left', 47px);
          @include rem('padding-right', 33px);
          @include rem('padding-top', 40px);
          @include rem('margin-bottom', 5px);
          display: flex;
          align-items: center;

          &::before {
            content: " ";
            background-image: url('../images/dash.svg');
            @include rem('height', 5px);
            @include rem('width', 20px);
            display: inline-block;
            @include rem('margin-right', 30px);
          }
        }

        h4 {
          @include rem('font-size', 21px);
          font-weight: 700;
          @include rem('padding-left', 47px);
          @include rem('padding-right', 20px);
          @include rem('padding-bottom', 86px);
        }

        #kartela::before {
          content: "\f09d";
          font-family: 'Font Awesome 5 Pro';
          @include rem('height', 15px);
          @include rem('width', 20px);
          display: inline-block;
          @include rem('margin-right', 30px);
        }

        #kartela_2::before {
          content: "\f09d ";
          font-family: 'Font Awesome 5 Pro';
          @include rem('height', 15px);
          @include rem('width', 20px);
          display: inline-block;
          @include rem('margin-right', 30px);
        }

        #mallra_sherbime::before {
          content: "\f49d";
          font-family: 'Font Awesome 5 Pro';
          @include rem('height', 15px);
          @include rem('width', 20px);
          display: inline-block;
          @include rem('margin-right', 30px);
        }

        #informata_shtese::before {
          content: "\f095";
          font-family: 'Font Awesome 5 Pro';
          @include rem('height', 15px);
          @include rem('width', 20px);
          display: block;
          display: inline-block;
          @include rem('margin-right', 30px);
        }

        #prioritetet_tab_icon::before {
          content: "\f024 ";
          font-family: 'Font Awesome 5 Pro';
          @include rem('height', 15px);
          @include rem('width', 20px);
          display: inline-block;
          @include rem('margin-right', 30px);
        }

        #zotimet_tab_icon::before {
          content: "\f19c ";
          font-family: 'Font Awesome 5 Pro';
          @include rem('height', 15px);
          @include rem('width', 20px);
          display: inline-block;
          @include rem('margin-right', 30px);
        }

        #gr_parl_tab_icon::before {
          content: "\f508";
          font-family: 'Font Awesome 5 Pro';
          @include rem('height', 15px);
          @include rem('width', 20px);
          display: inline-block;
          @include rem('margin-right', 30px);
        }

        #kandid_komuna_tab_icon::before {
          content: "\f500";
          font-family: 'Font Awesome 5 Pro';
          @include rem('height', 15px);
          @include rem('width', 20px);
          display: inline-block;
          @include rem('margin-right', 30px);
        }
      }

      &:last-child {
        border-right: none;
      }

      &:hover,
      &.active {
        transition: $transition1;
        background: #fff;
        transform: translateY(-10px);
        box-shadow: 0px -18px 35px -16px rgba(0, 0, 0, .1);

        p, h4 {
          color: #e2001a;
        }

        @include mobile {
          background: transparent;
          transform: none;
          box-shadow: none;
        }
      }

      &.active {
        @include tablet {
          transition: $transition1;
          background: #e6e6e6;
          transform: none;
          box-shadow: none;

          p, h4 {
            color: #e2001a;
          }
        }

        @include tablet-big {
          transition: $transition1;
          background: #e6e6e6;
          transform: none;
          ;
          box-shadow: none;

          p, h4 {
            color: #e2001a;
          }
        }
      }

      @include tablet {
        width: 50%;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        background: #e6e6e6;

        a {
          h4 {
            padding-bottom: 50px;
          }
        }
      }

      @include tablet-big {
        width: 50%;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        background: #e6e6e6;

        a {
          h4 {
            padding-bottom: 50px;
          }
        }
      }

      @include mobile {
        width: 100vw;
        margin-left: calc(-50vw + 50%);

        a {
          p {
            padding-left: 15px;
            padding-right: 15px;
            font: size 20px;

            i {
              display: block;
              font-size: 15px;
              margin-right: 20px;
            }

            b {
              display: block;
            }

            &:before {
              margin-right: 20px;
            }
          }
        }
      }
    }

    @include tablet {
      width: 100vw;
      margin-left: calc(-50vw + 50%);
    }

    @include tablet-big {
      width: 100vw;
      margin-left: calc(-50vw + 50%);
    }

    @include mobile {
      margin-bottom: 40px;

      li {
        border-bottom: 1px solid #e6e6e6;

        a {
          p {
            padding-top: 40px;
            padding-left: 48px;
          }

          h4 {
            padding-bottom: 20px;
          }
        }
      }

      #mobile_hide_border {
        border-bottom: none;

        a {
          p {
            padding-left: 34px !important;
            color: #e2001a;

            b {
              display: block !important;
            }

            .fa-flag {
              display: block !important;
              color: #e2001a;
            }

            @media (max-width: 320px) {
              font-size: 17px;
            }
          }
        }
      }
    }

    @media screen and (min-width: 1025px) and (max-width:1309px) {
      li {
        width: 25%;

        a {
          p {
            font-size: 19px;
          }

          h4 {
            font-size: 17px;
          }
        }
      }
    }
  }

  .homepage_tabs_tablets {
    display: block;
  }

  .kontribo_tabs_wrapper {
    @include fullBlock;

    .transferet_brenda_ks {
      @include fullBlock;
      height: auto;
      @include rem('padding-bottom', 32px);
      @include rem('margin-bottom', 57px);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .transferet_brenda_ks_left {
        float: left;
        width: 50%;

        h2 {
          float: left;
          width: 100%;
          @include rem('font-size', 21px);
          @include rem('margin-bottom', 25px);
        }

        h4 {
          float: left;
          width: 100%;
          @include rem('font-size', 15px);
          color: #e2001a;
          @include rem('margin-bottom', 18px);
        }

        p {
          float: left;
          width: 100%;
          @include rem('font-size', 15px);
          color: #666666;
          @include rem('margin-bottom', 8px);
        }

        h3 {
          float: left;
          width: 100%;
          color: #231f20;
          font-weight: 700;
          @include rem('font-size', 18px);
          @include rem('margin-bottom', 22px);
        }
      }

      .xhirollogaria_graybox {
        float: left;
        width: 50%;

        .gray_box {
          @include rem('width', 510px);
          @include rem('height', 193px);
          @include rem('padding', 39px);
          @include rem('margin-left', 18px);
          @include rem('margin-top', 55px);
          background: #f2f2f2;

          p {
            color: #808080;
            @include rem('font-size', 14px);
            line-height: 16px;
            @include rem('margin-bottom', 17px);
          }
        }
      }

      @include tablet {
        .transferet_brenda_ks_left {
          width: 50%;
          padding-right: 20px;
        }

        .xhirollogaria_graybox {
          width: 50%;

          .gray_box {
            width: 100%;
            margin-left: 0;
            height: auto;
          }
        }
      }

      @include tablet-big {
        .transferet_brenda_ks_left {
          width: 50%;
          padding-right: 20px;
        }

        .xhirollogaria_graybox {
          width: 50%;

          .gray_box {
            width: 100%;
            margin-left: 0;
            height: auto;
          }
        }
      }

      @include mobile {
        .transferet_brenda_ks_left {
          width: 100%;
          padding-right: 20px;
        }

        .xhirollogaria_graybox {
          float: left;
          width: 100%;

          .gray_box {
            float: left;
            width: 100%;
            margin-left: 0;
            height: auto;
          }
        }
      }
    }

    .pcb_vv_contact_contactinfo {
      @extend %fullBlock;
      @include rem('margin-bottom', 59px);

      .pcb_contact_info,
      .vv_contact_info {
        float: left;
        width: 50%;
      }

      .pcb_contact_info {
        .pcb_logo {
          float: left;
          width: 100%;
          @include rem('width', 167px);
          @include rem('height', 30px);
        }

        .contact_pcb {
          float: left;
          width: 100%;
          text-align: left;
          @include rem('margin-top', 20px);

          p {
            color: #666;
            @include rem('font-size', 15px);
          }

          a {
            text-decoration: none;
            color: #e2001a;
            @include rem('font-size', 15px);
          }

          .tel_bottom_dist {
            @include rem('margin-bottom', 4px);
          }
        }
      }

      .vv_contact_info {
        .vv_logo {
          float: right;
          // width:100%;
          @include rem('width', 176px);
          @include rem('height', 23px);
        }

        .contact_vv {
          float: right;
          width: 100%;
          text-align: right;
          @include rem('margin-top', 20px);

          p {
            color: #666;
            @include rem('font-size', 15px);
          }

          a {
            text-decoration: none;
            color: #e2001a;
            @include rem('font-size', 15px);
          }
        }
      }

      .contact_email {
        @include rem('margin-top', 20px);
        @include rem('margin-bottom', 20px);
      }

      @include mobile {
        .pcb_contact_info {
          width: 100%;
          margin-bottom: 60px;
        }

        .vv_contact_info {
          width: 100%;

          .vv_logo {
            float: left;
          }

          .contact_vv {
            text-align: left;
          }
        }
      }
    }

    .dhuro_mallra_sherbime_text {
      float: left;
      width: 100%;

      .dhuro_mallra_left_text,
      .dhuro_mallra_right_text {
        float: left;
        width: 50%;
        @include rem('padding-right', 25px);
        @include rem('padding-left', 15px);

        p {
          color: #666;
          @include rem('font-size', 13px);
          line-height: 24px;
          text-align: justify;
          hyphens: auto;
        }
      }

      @include mobile {
        .dhuro_mallra_left_text {
          width: 100%;
          float: left;
          margin-bottom: 20px;
        }

        .dhuro_mallra_right_text {
          float: left;
          width: 100%;
        }
      }
    }

    .dhuro_form_wrapper {
      float: left;
      width: 100%;
      @include rem('margin-top', 54px);

      form {
        float: left;
        width: 100%;

        .dhuro_form_columns {
          @include rem('height', 50px);
          border: 1px solid #e6e6e6;
          @include rem('margin-bottom', 29px);
          display: flex;
          align-items: center;
          border: 1px solid #e6e6e6;
          border-radius: 2px;
          position: relative;
          transition: $transition1;

          label {
            color: #231f20;
            @include rem('font-size', 14px);
            font-weight: 700;

            @media (max-width: 320px) {
              font-size: 12px;
            }
          }

          input[type=checkbox] {
            @include rem('width', 20px);
            @include rem('height', 20px);
            -webkit-appearance: none;
            background-color: #fff;
            border: 1px solid #b3b3b3;
            border-radius: 10%;
            @include rem('margin', 0 16px);
            position: relative;
            cursor: pointer;

            &:focus {
              outline: none;
            }

            &:checked:after {
              content: '\f00c';
              font-family: 'Font Awesome 5 Pro';
              font-weight: 700;
              position: absolute;
              top: .1em;
              left: .25em;
              @include rem('font-size', 13px);
              color: #e2001a;
              transition: all .2s;
              border: none;
            }

            &:checked+label {
              color: #e2001a;
            }

            @media (max-width: 320px) {
              width: 15px;
              height: 15px;
              margin: 0 8px;
            }
          }

          i {
            @include rem('font-size', 13px);
            font-weight: 700;
            @include rem('margin', 0 23px);
          }

          input[type="text"] {
            position: absolute;
            right: 0;
            width: 91%;
            height: 100%;
            border: none;
            outline: 0;
            @include rem('margin', 0);
            color: #231f20;
            @include rem('font-size', 14px);
            font-weight: 700;

            &:focus::-webkit-input-placeholder {
              opacity: 0;
            }

            @include tablet {
              width: 85%;
            }

            @include tablet-big {
              width: 88%;
            }

            @include mobile {
              width: 65%;
            }

            @media (max-width: 320px) {
              width: 63%;
            }
          }

          &:hover {
            border: none;
            -webkit-box-shadow: 0px 0px 154px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 0px 154px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 0px 154px 0px rgba(0, 0, 0, 0.2);
          }
        }

        .form_columns {
          float: left;
          @include rem('margin-bottom', 27px);
          @include rem('margin-top', 15px);

          label {
            color: #666666;
            @include rem('font-size', 15px);
          }

          input, select {
            @include rem('width', 224px);
            @include rem('height', 50px);
            @include rem('margin-top', 17px);
            display: flex;
            align-items: center;
            border: 1px solid #e6e6e6;
            border-radius: 2px;
            color: #231f20;
            @include rem('font-size', 15px);
            font-weight: 700;
            @include rem('padding-left', 40px);
            outline: none;
          }

          input[type=number]::-webkit-inner-spin-button,
          input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .inputWithIcon {
          position: relative;

          i {
            position: absolute;
            left: 14;
            top: 40px;
            @include rem('padding', 9px 16px);
            color: #aaa;
            transition: 0.3s;
            @include rem('font-size', 13px);
            color: #e2001a;
            font-family: 'Font Awesome 5 Pro';
            font-weight: 700;
          }
        }

        .antaresohu_hr {
          float: left;
          width: 100%;
          @include rem('margin-bottom', 29px);
          @include rem('margin-top', 29px);

          hr {
            width: 100%;
            display: block;
            @include rem('height', 1px);
            border: 0;
            background: #e6e6e6;
          }
        }

        .konfirmo_dergimin {
          @extend %fullBlock;
          display: flex;
          align-items: center;
          @include rem('margin-bottom', 106px);

          input[type=checkbox] {
            @include rem('width', 12px);
            @include rem('height', 12px);
            -webkit-appearance: none;
            background-color: #fff;
            border: 1px solid #b3b3b3;
            ;
            @include rem('margin-right', 22px);
            position: relative;
            cursor: pointer;

            &:focus {
              outline: none;
            }

            &:checked:after {
              content: '\f00c';
              font-family: 'Font Awesome 5 Pro';
              font-weight: 700;
              position: absolute;
              top: -1px;
              background: #e2001a;
              @include rem('font-size', 12px);
              color: #fff;
              transition: all .2s;
            }
          }

          label {
            color: #666666;
            @include rem('font-size', 15px);

            span {
              color: #e2001a;
              font-weight: 700;
            }
          }

          button[type=submit] {
            align-self: flex-end;
            margin-left: auto;
            @include rem('width', 290px);
            @include rem('height', 50px);
            background: #e2001a;
            color: #fff;
            font-weight: 700;
            border-radius: 2px;
            cursor: pointer;
            text-transform: uppercase;
            box-shadow: none !important;
            border-color: rgba(0, 0, 0, 0) !important;
            outline: none !important;
          }
        }

        @include tablet {
          .form_columns {
            width: 50%;

            input {
              width: 100%;
            }

            select {
              width: 100%;
            }
          }

          .dergo_mallra {
            width: 100% !important;
          }
        }

        @include tablet-big {
          .form_columns {
            width: 50%;

            input {
              width: 100%;
            }

            select {
              width: 100%;
            }
          }

          .dergo_mallra {
            width: 100% !important;
          }
        }

        @include mobile {
          .form_columns {
            width: 100%;

            input {
              width: 100%;
            }

            select {
              width: 100%;
            }
          }

          .column-3 {
            width: 50%;
          }
        }

        @media screen and (min-width: 1025px) and (max-width:1309px) {
          .form_columns {
            width: auto;

            input {
              width: 189px;
            }

            select {
              width: 189px;
            }
          }
        }
      }
    }

    .dhuro_me_kartele {
      @include fullBlock;

      .individ_biznes {
        @include fullBlock;

        .container {
          float: left;
          width: auto;
          position: relative;
          padding-left: 45px;
          cursor: pointer;
          font-size: 21px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;

            &:checked~.checkmark {
              background-color: #e2001a;
            }

            &:checked~.checkmark:after {
              display: block;
            }
          }

          input[type=radiobutton]:checked+label {
            color: #e2001a;
          }

          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #e6e6e6;
            border-radius: 50%;

            &:after {
              content: "";
              @extend %centered;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: white;
            }
          }

          &:first-child {
            margin-right: 16px;
          }
        }
      }

      .dhuro_shuma {
        @include fullBlock;
        margin-top: 62px;

        .kontribo_form_wrap {
          @extend %fullBlock;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          @include tablet {
            .column-2 {
              width: 33.333%;

              .input_holder {
                width: 100%;

                label {
                  width: 100%;
                }
              }
            }
          }

          @include tablet-big {
            .column-2 {
              width: 33.333%;

              .input_holder {
                width: 100%;

                label {
                  width: 100%;
                }
              }
            }
          }

          @include mobile {
            .column-2 {
              width: 50%;

              .input_holder {
                width: 100%;

                label {
                  width: 100%;
                }
              }
            }
          }
        }

        .input_holder {
          float: left;
          transition: $transition1;

          input:checked {
            ~.kontribo_button {
              border-color: transparent;
              box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);

              i,
              span {
                color: #e2001a;
                transition: $transition1;
              }
            }
          }
        }

        .kontribo_button {
          float: left;
          @include rem('width', 180px);
          @include rem('height', 50px);
          @include rem('margin-bottom', 20px);
          text-align: center;
          border: 1px solid #e6e6e6;
          transition: $transition1;

          input[type=text],
          input[type=number] {
            float: left;
            width: 60px;
            border: none;
            font-size: 16px;
            font-weight: 700;
            text-align: right;
            padding-right: 15px;
            line-height: 44px;
            background: transparent;
            border: 0;
          }

          input[type=text] {
            text-align: center;
            padding-right: 0;
          }

          i {
            float: left;
            width: 43px;
            font-size: 18px;
            text-align: center;
            line-height: 44px;
          }

          span {
            float: right;
            margin-right: 15px;
            line-height: 46px;
            font-weight: 700;
            font-size: 16px;
          }

          &:hover {
            border: none;
            box-shadow: 0 0 66px -6px rgba(0, 0, 0, .27);

            label {
              color: #e2001a;
              transition: $transition1;
            }
          }

          @media screen and (min-width: 1025px) and (max-width:1309px) {
            width: 150px;
          }
        }
      }

      .shuma_limit {
        @include fullBlock;
        @include rem('margin-top', 47px);
        @include rem('margin-bottom', 21px);

        p {
          float: left;
          color: #231f20;
          @include rem('font-size', 15px);
        }

        span {
          float: right;
          color: #231f20;
          @include rem('font-size', 15px);
        }

        @media (max-width: 320px) {
          p, span {
            font-size: 13px;
          }
        }
      }

      .slidecontainer {
        @include fullBlock;
        height: 90px;
        position: relative;
        margin-bottom: 36px;

        .slider {
          -webkit-appearance: none;
          width: 100%;
          height: 5px;
          background: #e6e6e6;
          outline: none;
          -webkit-transition: .2s;
          transition: opacity .2s;
          margin-bottom: 27px;

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 5px solid white;
            background: #e2001a;
            cursor: pointer;
          }

          &::-moz-range-thumb {
            width: 25px;
            height: 25px;
            background: #4CAF50;
            cursor: pointer;
          }
        }

        .range_start {
          float: left;
          font-size: 18px;
          font-weight: 300;

          span {
            font-size: 16px;
            font-weight: 700;
          }
        }

        .range_value {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 180px;
          height: 50px;
          display: inline-block;
          border: 1px solid #e6e6e6;
          border-radius: 2px;
          padding: 16px;
          font-size: 18px;
          font-weight: 300;

          span {
            float: right;
            font-size: 16px;
            font-weight: 700;
          }
        }

        .range_end {
          float: right;
          margin-left: auto;
          font-size: 18px;
          font-weight: 300;

          span {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }

      .donatoret_posedojne {
        @include fullBlock;
        text-align: center;
        padding: 23px 5px 25px 5px;
        background: #f2f2f2;
        border-radius: 2px;
        margin-bottom: 40px;

        p {
          color: #666666;
          font-size: 15px;
        }
      }

      .donatori_form_data {
        @include fullBlock;

        .form_columns {
          @include rem('margin-bottom', 27px);

          label {
            color: #666666;
            @include rem('font-size', 15px);
          }

          input, select {
            width: 100%;
            @include rem('height', 50px);
            @include rem('margin-top', 17px);
            display: flex;
            align-items: center;
            border: 1px solid #e6e6e6;
            border-radius: 2px;
            color: #231f20;
            @include rem('font-size', 15px);
            font-weight: 700;
            @include rem('padding-left', 40px);
            outline: none;
          }

          input[type=number]::-webkit-inner-spin-button,
          input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .inputWithIcon {
          position: relative;

          i {
            position: absolute;
            left: 0;
            top: 40px;
            @include rem('padding', 9px 16px);
            color: #aaa;
            transition: 0.3s;
            @include rem('font-size', 13px);
            color: #e2001a;
            font-family: 'Font Awesome 5 Pro';
            font-weight: 700;
          }
        }

        @include tablet {
          .column-3 {
            width: 50%;
          }

          .column-6 {
            width: 100%;
          }
        }

        @include tablet-big {
          .column-3 {
            width: 50%;
          }

          .column-6 {
            width: 100%;
          }
        }

        @include mobile {
          .column-3 {
            width: 100%
          }

          .column-6 {
            width: 100%;
          }
        }
      }

      .antaresohu_hr {
        float: left;
        width: 100%;
        @include rem('margin-bottom', 29px);
        @include rem('margin-top', 29px);

        hr {
          width: 100%;
          display: block;
          @include rem('height', 1px);
          border: 0;
          background: #e6e6e6;
        }
      }

      .vazhdo_dhuro {
        @extend %fullBlock;
        display: flex;
        align-items: center;
        @include rem('margin-bottom', 106px);

        input[type=checkbox] {
          @include rem('width', 12px);
          @include rem('height', 12px);
          -webkit-appearance: none;
          background-color: #fff;
          border: 1px solid #b3b3b3;
          ;
          @include rem('margin-right', 22px);
          position: relative;
          cursor: pointer;

          &:focus {
            outline: none;
          }

          &:checked:after {
            content: '\f00c';
            font-family: 'Font Awesome 5 Pro';
            font-weight: 700;
            position: absolute;
            top: -1px;
            background: #e2001a;
            @include rem('font-size', 12px);
            color: #fff;
            transition: all .2s;
          }
        }

        label {
          color: #666666;
          @include rem('font-size', 15px);

          a {
            color: #e2001a;
            font-weight: 700;
            text-decoration: none;
          }
        }

        button[type=submit] {
          align-self: flex-end;
          margin-left: auto;
          @include rem('width', 290px);
          @include rem('height', 50px);
          background: #e2001a;
          color: #fff;
          font-weight: 700;
          border-radius: 2px;
          cursor: pointer;
          text-transform: uppercase;
          box-shadow: none !important;
          border-color: rgba(0, 0, 0, 0) !important;
          outline: none !important;
        }
      }
    }

    .kontakto_info_shtese {
      .form_columns {
        @include rem('margin-bottom', 27px);

        label {
          color: #666666;
          @include rem('font-size', 15px);
        }

        input, select {
          width: 100%;
          @include rem('height', 50px);
          @include rem('margin-top', 17px);
          display: flex;
          align-items: center;
          border: 1px solid #e6e6e6;
          border-radius: 2px;
          color: #231f20;
          @include rem('font-size', 15px);
          font-weight: 700;
          @include rem('padding-left', 40px);
          outline: none;
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        textarea {
          float: left;
          width: 100%;
          @include rem('height', 200px);
          @include rem('margin-top', 17px);
          display: flex;
          align-items: center;
          border: 1px solid #e6e6e6;
          border-radius: 2px;
          color: #231f20;
          font-weight: 700;
          font-weight: 700;
          @include rem('font-size', 15px);
          @include rem('padding', 20px);
          outline: none;
          resize: none;

          &::placeholder {
            color: #d0c8c8;
            font-weight: 300;
          }
        }

        button[type=submit] {
          float: right;
          margin: 30px auto;
          // margin-left:auto;
          @include rem('width', 290px);
          @include rem('height', 50px);
          background: #e2001a;
          color: #fff;
          font-weight: 700;
          border-radius: 2px;
          cursor: pointer;
          text-transform: uppercase;
          box-shadow: none !important;
          border-color: rgba(0, 0, 0, 0) !important;
          outline: none !important;
        }

        .vv_contact_info {
          float: right;
          width: 210px;

          .vv_logo {
            @include rem('width', 176px);
            @include rem('height', 23px);
          }

          .contact_vv {
            width: 210px;
            text-align: left;
            @include rem('margin-top', 20px);

            p {
              color: #666;
              @include rem('font-size', 15px);
            }

            a {
              text-decoration: none;
              color: #e2001a;
              @include rem('font-size', 15px);
            }
          }
        }
      }

      .inputWithIcon {
        position: relative;

        i {
          position: absolute;
          left: 0;
          top: 40px;
          @include rem('padding', 9px 16px);
          color: #aaa;
          transition: 0.3s;
          @include rem('font-size', 13px);
          color: #e2001a;
          font-family: 'Font Awesome 5 Pro';
          font-weight: 700;
        }
      }

      @include tablet {
        .column-3 {
          width: 50%;
        }
      }

      @include tablet-big {
        .column-3 {
          width: 50%;
        }
      }

      @include mobile {
        .form_columns {
          button[type=submit] {
            width: 100%;
          }
        }
      }
    }

    @include tablet {
      padding-top: 30px;
    }

    @include tablet-big {
      padding-top: 30px;
    }
  }
}

.block_section {
  float: left;
  width: 100%;
}

.tab_container {
  .tabs_content {
    width: 100%;
    display: none;

    &.active {
      width: 100%;
      display: block;
    }
  }
}

.kontribo_tabs_wrapper {
  .tabs_content_status {
    display: none;

    &.active {
      display: block;
    }
  }
}

// media gallery
.media_gallery_popup {
  position: fixed;
  background: rgba(255, 255, 255, .92);
  width: 100%;
  z-index: 6;
  height: 100%;
  top: 0;
  left: 0;
}

// *,*::before,*::after{
//     outline:1px solid red;
// }
.konfirmo_antaresimin {
  @include rem('padding-top', 40px);
  @include rem('margin-top', 28px);
  border-top: 1px solid #e6e6e6;
}

.confirm_cbox {
  position: relative;

  @include tablet {
    width: 50%;
  }

  input {
    display: none;
  }

  label {
    color: #666;
    font-size: 15px;
  }

  input:checked+label {
    &:before {
      @include fa-solid;
      color: #e2001a;
      content: "\f14a"
    }
  }

  .label_text {
    float: left;
    padding-left: 20px;

    @include tablet {
      width: calc(100% - 40px);
    }

    @include mobile {
      width: calc(100% - 40px);
    }
  }

  label:before {
    content: "\f0c8";
    float: left;
    @include fa-reg;
    font-size: 14px;
    transition: $transition1;
  }
}

.single_right {
  float: right;
  margin-top: -50px;
  width: 330px;

  @include tablet {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }

  @include tablet-big {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }

  @include mobile {
    width: 100%;
  }
}

.single_kandidati_sm_section {
  flex-wrap: wrap;

  iframe, .fb-page {
    width: 100%;
  }

  @include mobile {
    width: 100% !important;

    iframe, .fb-page {
      width: 100% !important;
    }
  }
}

.small_title {
  @extend %fullBlock;
  margin-bottom: 30px;
  position: relative;
  clear: both;
  padding-left: 40px;

  span {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.publikimet_heading {
  position: relative;

  >span {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    display: none !important;
  }

  h2 {
    margin-left: 0 !important;
    padding-left: 52px;
    position: relative;

    &:before {
      content: " ";
      height: 5px;
      width: 20px;
      position: absolute;
      background: #e2001a;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      left: 0;
    }
  }

  select {
    @include rem('width', 290px);
    @include rem('height', 50px);
    @include rem('padding', 0 20px);
    display: flex;
    align-items: center;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    color: #231f20;
    @include rem('font-size', 15px);
    font-weight: 700;
    outline: none;
    background: url(../images/select_down_arrow.svg) no-repeat;
    background-position: calc(100% - 20px) center;
    appearance: none;
    background-size: 9px 6px;
  }

  @include mobile {
    height: auto !important;

    select {
      margin-right: 0;
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

.kryetaret_header {
  position: relative;

  >span {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    display: none !important;
  }

  h2 {
    margin-left: 0 !important;
    padding-left: 52px;
    position: relative;

    &:before {
      content: " ";
      height: 5px;
      width: 20px;
      position: absolute;
      background: #e2001a;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      left: 0;
    }
  }

  form {
    border: 0 !important;
    border-radius: 0 !important;
  }

  @include mobile {
    height: auto !important;

    select {
      margin-right: 0;
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

.form_columns {
  select {
    background: url(../images/select_down_arrow.svg) no-repeat;
    background-position: calc(100% - 20px) center;
    appearance: none;
    background-size: 9px 6px;
    padding-right: 46px;
  }
}

.single_post {
  section {
    text-align: left;
  }
  .single_kandidati_heading {
    max-width: 840px;
    @include desktop-small {
      max-width: 67%;
    }
  }
  &__excerpt {
    @include rem('font-size', 18px);
    font-weight: 700;
    @include rem('margin-bottom', 37px);
    color: #231f20;

    p {
      line-height: 1.8em !important;
      margin-bottom: 0 !important;
    }
  }
}

.single_post__content {
  font-size: 15px;
  color: #666;

  ul {
    list-style-type: disc;
    padding-left: 22px;
    @include rem('margin-bottom', 26px);
    line-height: 1.93em;
  }

  ol {
    list-style-type: decimal;
    padding-left: 22px;
    @include rem('margin-bottom', 26px);
    line-height: 1.93em;
  }

  h2, h3, h4, h5 {
    @include rem('margin-top', 15px);
    @include rem('margin-bottom', 26px);
    line-height: 1.93em;
    color: #231f20;
    font-weight: 700;
    @include mobile {
      line-height: 1.2em; 
    }
  }

  h2 {
    font-size: 35px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 18px;
  }

  a {
    text-decoration: none;
    color: #666;
    position: relative;
    transition: $transition1;

    &:after {
      content: " ";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 2px;
      background: #e2001a;
    }

    &:hover {
      color: #e2001a;
    }
  }

  b, strong {
    font-weight: 700;
  }

  i, em {
    font-style: italic;
  }

  blockquote, q {
    @include rem('padding-left', 30px) //border-left: 5px solid  #e2001a; 
    font-style: italic;
    font-size: 18px;
    quotes: "“""”";
    position: relative;

    &:before {
      content: open-quote;
      float: left;
      color: #e2001a;
      font-weight: bold;
      font-size: 30px;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      content: close-quote;
      float: right;
      color: #e2001a;
      font-weight: bold;
      font-size: 30px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  iframe, .fb_iframe_widget, .instagram-media, .twitter-tweet {
    @include rem('margin-bottom', 26px);
    max-width: 100%;
    line-height: 1.93em;

    span {
      max-width: 100% !important;
    }
  }

  figure {
    @include rem('margin-bottom', 26px);
    line-height: 1.93em;

    figcaption {
      line-height: 1.1em;
      font-size: 13px;
      font-weight: bold;
    }

    a {
      text-decoration: none;

      &:after {
        display: none;
      }
    }
  }

  table {
    line-height: 1.93em;
    @include rem('margin-bottom', 26px);
    border: 1px solid #b9b9b9;
    border-collapse: collapse;
    border-spacing: 0;
    display: table;

    @include mobile {
      table-layout: fixed;
      word-break: break-all;
    }

    thead {
      background-color: #e0e0e0;
      color: #000;
      text-align: left;
      vertical-align: bottom;
    }
    tr { 
      border-top: 1px solid #b9b9b9;
    }
    th, td {
      padding: 0.5em 1em;
    }
    td,th {
      line-height: 1.2em; 
    }
    th {
       background-color: #e0e0e0;
      color: #000;
      text-align: left;
      vertical-align: bottom;
      font-weight: bold;
    }
    td, th {
      border-width: 0 0 0 1px;
      font-size: inherit;
      margin: 0;
      overflow: visible;
      padding: .5em 1em;
      border-right: 1px solid #b9b9b9;
    }

    tr:nth-child(2n-1) td {
      background-color: #efefef;
    }
  }
}
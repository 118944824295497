.footer_banner {
  float: left;
  width: 100%;
  background: #e2001a;
  color: white;
  display: inline-block;
  @include rem('height', 200px);
  @include rem('padding-top', 37px);
  @include rem('padding-bottom', 37px);
  font-weight: 300;

  .rasti_10_shkurtit {
    float: left;
    display: inline-block;

    .dhjete_shkurti_img {
      float: left;
      @include rem('margin-right', 32px);
    }

    .dhjete_shkurti_text {
      float: left;
      @include rem('margin-top', 16px);
    }

    @include tablet {
      width: 50%;

      .dhjete_shkurti_img {
        width: 100%;
        text-align: center;
        margin-right: 0;
      }

      .dhjete_shkurti_text {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        font-weight: 300;

        h4 {
          padding: 0 15%;
        }
      }
    }

    @include tablet-big {
      width: 50%;

      .dhjete_shkurti_img {
        width: 100%;
        text-align: center;
        margin-right: 0;
      }

      .dhjete_shkurti_text {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        font-weight: 300;

        h4 {
          padding: 0 15%;
        }
      }
    }

    @include mobile {
      width: 100%;
      margin-bottom: 40px;

      .dhjete_shkurti_img {
        width: 100%;
        text-align: center;
        margin-right: 0;
      }

      .dhjete_shkurti_text {
        width: 100%;
        text-align: center;
        line-height: 23px;
        margin-top: 30px;
        font-weight: 300;

        h4 {
          padding: 0;
        }
      }
    }
  }

  .astrit_dehari {
    float: right;
    display: inline-block;
    text-align: right;

    .ah_img {
      float: right;
    }

    .ah_text {
      float: left;
      @include rem('margin-right', 32px);
      @include rem('margin-top', 16px);
    }

    @include tablet {
      float: left;
      width: 50%;

      .ah_img {
        width: 100%;
        text-align: center;
        margin-right: 0;
      }

      .ah_text {
        float: left;
        display: block !important;
        width: 100%;
        text-align: center;
        margin-top: 30px;
        font-weight: 300;

        h4 {
          padding: 0 15%;
        }
      }
    }

    @include tablet-big {
      float: left;
      width: 50%;

      .ah_img {
        width: 100%;
        text-align: center;
        margin-right: 0;
      }

      .ah_text {
        float: left;
        display: block !important;
        width: 100%;
        text-align: center;
        margin-top: 30px;
        font-weight: 300;

        h4 {
          padding: 0 15%;
        }
      }
    }

    @include mobile {
      float: left;
      width: 100%;

      .ah_img {
        width: 100%;
        text-align: center;
        margin-right: 0;
      }

      .ah_text {
        float: left;
        display: block !important;
        width: 100%;
        text-align: center;
        line-height: 23px;
        margin-top: 30px;
        font-weight: 300;

        h4 {
          padding: 0;
        }
      }
    }
  }

  h3 {
    @include rem('font-size', 27px);
    font-weight: 700;
    @include rem('margin-bottom', 15px);

    @include mobile {
      margin-bottom: 10px;
    }
  }

  h4 {
    @include rem('font-size', 18px);
    font-weight: 300;
    @include rem('margin-bottom', 15px);

    @include mobile {
      margin-bottom: 10px;
    }
  }

  .read_more {
    text-decoration: none;
    @include rem('font-size', 14px);
    color: white;
  }

  @include tablet {
    width: 100%;
    height: auto;
  }

  @include tablet-big {
    height: auto;
    width: 100%;
  }

  @include mobile {
    height: auto;
  }
}

.footer_main {
  float: left;
  background: #f2f2f2;
  width: 100%;
  @include rem('padding-top', 135px);

  .footer_lists_content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include rem('margin-bottom', 97px);
    justify-content: space-between;

    ul {
      display: inline-block;

      .footer_list_heading {
        color: #151515;
        @include rem('font-size', 16px);
        font-weight: 700;
      }

      .footer_list_item a {
        color: #666666;
        @include rem('font-size', 15px);
        text-decoration: none;
      }

      .footer_list_heading {
        @include rem('margin-bottom', 32px);
      }

      .footer_list_item {
        @include rem('margin-bottom', 33px);

        &:last-child {
          @include rem('margin-bottom', 0);
        }
      }

      @include tablet {
        padding: 0 30px 0 0;
        margin-bottom: 50px;
      }

      @include tablet-big {
        padding: 0 30px 0 0;
        margin-bottom: 50px;
      }

      @include mobile {
        width: 50%;
        margin-bottom: 30px;

        // padding-right:15px;
        &:nth-child(2n+2) {
          padding-left: 30px;
          padding-right: 0;
        }

        .footer_list_heading {
          @include rem('margin-bottom', 15px);
        }

        .footer_list_item {
          @include rem('margin-bottom', 15px);

          &:last-child {
            @include rem('margin-bottom', 0);
          }

          a {
            line-height: 20px;
            font-size: 13px;
          }
        }
      }
    }

    #invisible_element {
      color: transparent;
    }

    @include tablet {
      margin-bottom: 10px;
    }

    @include tablet-big {
      margin-bottom: 10px;
    }

    @include mobile {
      margin-bottom: 10px;
      padding: 0 20px;
    }
  }

  .footer_social_media {
    li {
      @extend %fullBlock;
      @include rem('margin-bottom', 10px);
      margin-bottom: 10px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        @include rem('font-size', 18px);
        @include rem('width', 50px);
        @include rem('height', 50px);
        @include rem('line-height', 50px);
        float: left;
        text-align: center;
        background: white;
        border-radius: 50%;
        text-align: center;
        box-shadow: 0 0 129px -5px #9e909e;
        color: #cccccc;

        &:hover {
          background: #27ba9e;
          box-shadow: none;
          transition: $transition1;
          color: white;
        }
      }

      &:last-child {
        @include rem('margin-bottom', 0);
      }
    }

    @include tablet-big {
      text-align: center;
      padding-right: 0 !important;
      margin-bottom: 0 !important;
    }

    @include tablet {
      width: 100% !important;
      text-align: center;
      padding-right: 0 !important;
      margin-bottom: 0 !important;

      ul {
        padding-right: 0 !important;
      }

      li {
        float: none;
        width: auto;
        clear: none;
        display: inline-block;
        margin-bottom: 0 !important;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .footer_copyright_logo {
    @extend %fullBlock;
    @include rem('margin-bottom', 185px);

    .footer_logo_img {
      float: none;
      @include rem('width', 176px);
      @include rem('height', 23px);
      margin: 0 auto 1.3125rem auto;
    }

    .copy_right {
      text-align: center;
      color: #666666;
      font-size: 15px;
    }

    @include tablet {
      float: left;
      width: 100%;
      margin-top: 50px;
      margin-bottom: 70px;
    }

    @include tablet-big {
      float: left;
      width: 100%;
      margin-top: 50px;
      margin-bottom: 70px;
    }

    @include mobile {
      margin-bottom: 50px;

      .copy_right {
        font-size: 13px;
      }
    }
  }

  @include tablet {
    padding-top: 50px;
  }

  @include tablet-big {
    padding-top: 50px;
  }

  @include mobile {
    padding-top: 50px;
  }
}

.cta_col {
  a {
    text-decoration: none;
    color: white;
  }
}
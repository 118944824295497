@media screen and (min-width: 1025px) and (max-width:1309px) {
  .container {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@include mobile {
  .lajmi_item, .lajmi_item_big {
    height: auto !important;

    a {
      @include fullBlock;
    }

    &:before {
      display: none !important;
    }
  }

  .lajmi_item_big {
    margin-bottom: 40px;
  }

  .column_item_text {
    @include fullBlock;
    position: static !important;
    margin-top: 25px;
    height: auto !important;

    p {
      color: #666 !important;
      font-size: 13px !important;
      font-weight: 300;
    }

    >a {
      font-size: 16px !important;
      max-height: 100% !important;
      font-weight: 700 !important;
      color: #231f20 !important;
      line-height: 1.32em !important;
    }
  }

  .lajmet_block {
    display: flex;
    flex-wrap: wrap;

    .mini_lajmet {
      order: 2;
      margin-bottom: 0;
    }
  }
}

@include mobile {
  .facebook_column {
    width: 100% !important;
    margin-right: 0;
    text-align: center;

    iframe {
      display: inline-block;
    }
  }

  .astrit_dehari {
    text-align: center !important;

    a {
      display: flex !important;
      flex-wrap: wrap;
    }

    .ah_text {
      width: 100%;
      margin-right: 0 !important;
      margin-top: 20px !important;
      order: 2;
    }
  }

  .cta_col {
    h4 {
      line-height: 1.4em;
    }
  }

  .footer_social_media {
    width: 100% !important;
    text-align: center;
    padding-right: 0 !important;
    margin-bottom: 20px !important;

    ul {
      padding-right: 0 !important;
    }

    li {
      float: none !important;
      width: auto !important;
      clear: none !important;
      display: inline-block;
      margin-bottom: 0 !important;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@include tablet {
  .facebook_column {
    text-align: center;

    iframe {
      display: inline-block;
    }
  }

  .astrit_dehari {
    text-align: center !important;

    a {
      display: flex !important;
      flex-wrap: wrap;
    }

    .ah_text {
      width: 100%;
      margin-right: 0 !important;
      margin-top: 20px !important;
      order: 2;
    }
  }

  .cta_col {
    h4 {
      line-height: 1.4em;
    }
  }

  .kontribo_anetaresohu {
    overflow: hidden;
    border-bottom: 10px solid #e5e5e5;
  }
}

@include tablet-big {
  .facebook_column {
    text-align: center;

    iframe {
      display: inline-block;
    }
  }

  .astrit_dehari {
    text-align: center !important;

    a {
      display: flex !important;
      flex-wrap: wrap;
    }

    .ah_text {
      width: 100%;
      margin-right: 0 !important;
      margin-top: 20px !important;
      order: 2;
    }
  }

  .kontribo_anetaresohu {
    overflow: hidden;
    border-bottom: 10px solid #e5e5e5;
  }
}

@include mobile {
  .donacione_tabs {
    position: relative;
    overflow: hidden;
  }

  .kontribo_anetaresohu {
    overflow: hidden;
  }
}

@media (min-width:1100px) and (max-width: 1330px) {
  .media_grid_view {
    .lajmet_block {
      width: 905px;
    }

    .lajmet_block .mini_lajmet {
      width: 275px;
    }

    .lajmi_item_big {
      width: 590px;
    }

    .lajmet_e_fundit_block {
      width: 238px;
    }
  }
  .media_grid_bottom_block {
    display: flex;
    justify-content:space-between;
  }
  .bottom_block_item {
    width: calc(25% - 20px) !important;
    margin-right: 0 !important;

    .lajme_icon {
      left: 20px !important;
    }

    .lajme_text {
      left: 79px !important;
    }
  }
}
@charset "UTF-8";
html.lb-disable-scrolling {
  overflow: hidden;
  /* Position fixed required for iOS. Just putting overflow: hidden; on the body is not enough. */
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.92);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: fixed;
  left: 0;
  top: 0 !important;
  width: 100%;
  height: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

.lightbox .lb-image {
  display: block;
  width: 70%;
  height: auto;
  border-radius: 3px;
  margin: auto;
  /* Image border */
  border: 4px solid white;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 100% !important;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
  width: 34%;
}

.lb-nav a.lb-prev {
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
  margin-left: 50px;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  margin-right: 50px;
}

.lb-nav a.lb-next:hover {
  -webkit-filter: none;
  /* Safari 6.0 - 9.0 */
  filter: none;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
  color: black;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  margin-top: 10px;
  padding-bottom: 1em;
  font-size: 12px;
  color: black;
}

.lb-data .lb-close {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  margin-right: 20px;
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.error-404-wrap {
  float: left;
  display: block;
  width: 100%;
  min-height: auto;
  margin: 0 auto;
  background: url(../images/background_404.svg) no-repeat 0 0;
  background-size: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 0;
}

.error-404 {
  float: none;
  margin: 0 auto;
  width: 405px;
  text-align: center;
}

.content_right {
  font-size: 17px;
  font-size: 1.0625rem;
}

.single_wrap {
  padding-top: 20px;
  padding-top: 1.25rem;
  margin-bottom: 51px;
  margin-bottom: 3.1875rem;
}

section {
  text-align: center;
}

.homePageLink {
  color: #e2001a;
}

.page-content {
  text-align: center;
}

.page-content p {
  font-family: "Museo Sans 300";
  font-size: 16px;
}

.page-content .img_404 {
  width: 200px;
  margin: 0 auto;
}

.page-content .img_404 img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media (max-width: 767px) {
  .error-404 {
    float: none;
    margin: 0 auto;
    width: 95%;
    text-align: center;
  }
  .page-content .img_404 {
    width: 130px;
    margin: 0 auto;
  }
}

@media (max-width: 320px) {
  .error-404 {
    float: none;
    margin: 0 auto;
    width: 95%;
    text-align: center;
  }
  .page-content .img_404 {
    width: 130px;
    margin: 0 auto;
  }
}

.confirmation-wrap {
  float: left;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  height: auto;
}

.confirmation_heading {
  font-family: "Museo Sans 300";
  font-size: 21px;
  color: #666;
  font-weight: 700;
  width: 40%;
  margin: 0 auto;
  margin-bottom: 15px;
  text-align: center;
}

.confirmation_text {
  font-family: "Museo Sans 300";
  font-size: 16px;
  color: #666;
  width: 40%;
  text-align: center;
  margin: 0 auto;
}

@media (min-width: 991px) and (max-width: 1199px) {
  .confirmation_heading {
    font-family: "Museo Sans 300";
    font-size: 18px;
    color: #666;
    font-weight: 700;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 15px;
    text-align: center;
    font-size: 18px;
  }
  .confirmation_text {
    font-family: "Museo Sans 300";
    font-size: 14px;
    color: #666;
    width: 77%;
    text-align: center;
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .confirmation-wrap {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .confirmation_heading {
    font-family: "Museo Sans 300";
    font-size: 18px;
    color: #666;
    font-weight: 700;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 15px;
    text-align: center;
    font-size: 18px;
  }
  .confirmation_text {
    font-family: "Museo Sans 300";
    font-size: 14px;
    color: #666;
    width: 77%;
    text-align: center;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .confirmation-wrap {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .confirmation_heading {
    font-family: "Museo Sans 300";
    font-size: 18px;
    color: #666;
    font-weight: 700;
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
  }
  .confirmation_text {
    font-family: "Museo Sans 300";
    font-size: 12px;
    color: #666;
    width: 85%;
    text-align: center;
    margin: 0 auto;
  }
}

@media (max-width: 320px) {
  .confirmation-wrap {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .confirmation_heading {
    font-family: "Museo Sans 300";
    font-size: 18px;
    color: #666;
    font-weight: 700;
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
  }
  .confirmation_text {
    font-family: "Museo Sans 300";
    font-size: 12px;
    color: #666;
    width: 85%;
    text-align: center;
    margin: 0 auto;
  }
}

#citiesMap path.active {
  fill: #e2001a;
}

/*====================================
=            GRID GLOBALS            =
====================================*/
/*=====  End of GRID GLOBALS  ======*/
/*===================================
=            TRANSITIONS            =
===================================*/
/*=====  End of TRANSITIONS  ======*/
/*=================================
=            STRUCTURE            =
=================================*/
/*=====  End of STRUCTURE  ======*/
/*==============================
=            COLORS            =
==============================*/
/*=====  End of COLORS  ======*/
@font-face {
  font-family: 'Museo Sans';
  src: url("../fonts/MuseoSans-700.woff2") format("woff2"), url("../fonts/MuseoSans-700.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url("../fonts/MuseoSans-300.woff2") format("woff2"), url("../fonts/MuseoSans-300.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Font Awesome 5 Pro Light';
  src: url("../fonts/FontAwesome5ProLight.woff2") format("woff2"), url("../fonts/FontAwesome5ProLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Font Awesome 5 Pro Solid';
  src: url("../fonts/FontAwesome5ProSolid.woff2") format("woff2"), url("../fonts/FontAwesome5ProSolid.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  src: url("../fonts/FontAwesome5BrandsRegular.woff2") format("woff2"), url("../fonts/FontAwesome5BrandsRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/fa-light-300.eot");
  src: url("../fonts/fa-light-300.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.woff") format("woff"), url("../fonts/fa-light-300.ttf") format("truetype"), url("../fonts/fa-light-300.svg#fontawesome") format("svg");
}

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-Italic.woff2") format("woff2"), url("Raleway-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-ExtraBoldItalic.woff2") format("woff2"), url("Raleway-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-Thin.woff2") format("woff2"), url("Raleway-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-BoldItalic.woff2") format("woff2"), url("Raleway-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-Light.woff2") format("woff2"), url("Raleway-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-ThinItalic.woff2") format("woff2"), url("Raleway-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-Bold.woff2") format("woff2"), url("Raleway-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-BlackItalic.woff2") format("woff2"), url("Raleway-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-MediumItalic.woff2") format("woff2"), url("Raleway-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-ExtraLight.woff2") format("woff2"), url("Raleway-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-ExtraLightItalic.woff2") format("woff2"), url("Raleway-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-Medium.woff2") format("woff2"), url("Raleway-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-SemiBold.woff2") format("woff2"), url("Raleway-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-Regular.woff2") format("woff2"), url("Raleway-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-LightItalic.woff2") format("woff2"), url("Raleway-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-SemiBoldItalic.woff2") format("woff2"), url("Raleway-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-Black.woff2") format("woff2"), url("Raleway-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url("Raleway-ExtraBold.woff2") format("woff2"), url("Raleway-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-LightItalic.woff2") format("woff2"), url("Montserrat-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-ExtraBold.woff2") format("woff2"), url("Montserrat-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-BoldItalic.woff2") format("woff2"), url("Montserrat-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-SemiBoldItalic.woff2") format("woff2"), url("Montserrat-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-Black.woff2") format("woff2"), url("Montserrat-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-Light.woff2") format("woff2"), url("Montserrat-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-Italic.woff2") format("woff2"), url("Montserrat-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-BlackItalic.woff2") format("woff2"), url("Montserrat-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-Regular.woff2") format("woff2"), url("Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-SemiBold.woff2") format("woff2"), url("Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-ExtraLight.woff2") format("woff2"), url("Montserrat-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-Thin.woff2") format("woff2"), url("Montserrat-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-MediumItalic.woff2") format("woff2"), url("Montserrat-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-Medium.woff2") format("woff2"), url("Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-ExtraBoldItalic.woff2") format("woff2"), url("Montserrat-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-Bold.woff2") format("woff2"), url("Montserrat-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-ThinItalic.woff2") format("woff2"), url("Montserrat-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-ExtraLightItalic.woff2") format("woff2"), url("Montserrat-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("SourceSansPro-BlackItalic.woff2") format("woff2"), url("SourceSansPro-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("SourceSansPro-SemiBold.woff2") format("woff2"), url("SourceSansPro-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("SourceSansPro-BoldItalic.woff2") format("woff2"), url("SourceSansPro-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("SourceSansPro-Italic.woff2") format("woff2"), url("SourceSansPro-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("SourceSansPro-SemiBoldItalic.woff2") format("woff2"), url("SourceSansPro-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("SourceSansPro-Regular.woff2") format("woff2"), url("SourceSansPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("SourceSansPro-Light.woff2") format("woff2"), url("SourceSansPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("SourceSansPro-Bold.woff2") format("woff2"), url("SourceSansPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("SourceSansPro-ExtraLightItalic.woff2") format("woff2"), url("SourceSansPro-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("SourceSansPro-ExtraLight.woff2") format("woff2"), url("SourceSansPro-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("SourceSansPro-LightItalic.woff2") format("woff2"), url("SourceSansPro-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("SourceSansPro-Black.woff2") format("woff2"), url("SourceSansPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

header,
main,
footer {
  float: left;
  width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

*:after, *:before {
  box-sizing: border-box;
}

.headerWrap,
.mainWrap,
.footerWrap {
  position: relative;
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
div,
ul,
input,
blockquote,
span,
button,
i,
b,
em,
strong {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

i,
em {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}

body {
  overflow-x: hidden;
  position: relative;
}

/*=====================================
=            MEDIA QUERIES            =
=====================================*/
/*=====  End of MEDIA QUERIES  ======*/
/*==============================
=            MIXINS            =
==============================*/
/*=====  End of MIXINS  ======*/
/*========================================
=            EXTEND SELECTORS            =
========================================*/
.container,
.row {
  *zoom: 1;
}

.container:before,
.row:before, .container:after,
.row:after {
  content: " ";
  display: table;
}

.container:after,
.row:after {
  clear: both;
}

.banner_cover_img .banner_text, .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .individ_biznes .container .checkmark:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.headerWrap,
.mainWrap,
.footerWrap, .kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .kontribo_form_wrap, .lajmet_e_fundit_block .te_fundit_item, .lajmet_e_fundit_block .te_fundit_item p, .lajmet_e_fundit_block .te_fundit_item a, .grupi_parlamentar_banner, .deputetet_heading, .deputetet_grid, .deputeti_block, .dekonstruktime_banner, .dekonstruktime_accordion, .media_banner, .media_grid, .zgjedhjet19_banner, .qendrat_banner, .komunikata_list, .bcg_lajme_levizja_aksione .lajmi, .arkiva, .kandidati_info .kandidati_biography, .kontribo_buttons form .kontribo_form_wrap, .te_tjere, .statusi_banner, .levizja_statusi .deputetet_heading, .fletushkat_banner, .fletushkat_background .publikimet_heading, .alternativa_banner, .alternativa_shteti_se_drejtes, .alternativa_shteti_se_drejtes .shteti_se_drejtes_content, .alternativa_kontribo_section .kontribo_forma form .kontribo_form_wrap, .gazetat_banner, .gazetat_background .publikimet_heading, .gazetat_background .gazetat_grid ul, .sidebar_template_banner, .organogram_banner, .antaresohu_banner, .konfirmo_antaresimin, .kontribo_banner, .donacione_tabs, .donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo, .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .konfirmo_dergimin, .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_form_wrap, .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .vazhdo_dhuro, .small_title, .footer_main .footer_social_media li, .footer_main .footer_copyright_logo {
  float: left;
  width: 100%;
  clear: both;
}

.logo img, .banner_cover_img, .lajmet_block .mini_lajmet .lajmi_item img, .lajmet_block .lajmi_item_big img, .gray_banner, .kand_komunes_item .deputeti_img img, .zotimet_item a .zotimi_img img, .grupi_parlamentar_banner, .deputeti_block .deputeti_img img, .dekonstruktime_banner, .media_banner, .media_grid .media_block .media_img img, .zgjedhjet19_banner, .qendrat_banner, .qendrat_wrapper ul .qendra_img img, .bcg_lajme_levizja_aksione .lajmi .lajmi_image img, .kandidati_info .kandidati_img_basic_info .kandidati_img img, .kandidati_info .kandidati_img_basic_info .lajmi_img img, .statusi_banner, .fletushkat_banner, .fletushkat_background .media_grid ul li a img, .alternativa_banner, .alternativa_kontribo_section .kontribo_img img, .alternativa_lajmet_e_fundit .lajmifundit_lajmi .lajmi_img img, .gazetat_banner, .gazetat_background .gazetat_grid ul li a img, .sidebar_template_banner, .organogram_banner, .organogrami_large_image img, .kryesia_lista ul li a .kryesia_kandidati_img img, .antaresohu_banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*=====  End of EXTEND SELECTORS  ======*/
.column-1 {
  width: 8.33333%;
}

.column-2 {
  width: 16.66667%;
}

.column-3 {
  width: 25%;
}

.column-4 {
  width: 33.33333%;
}

.column-5 {
  width: 41.66667%;
}

.column-6 {
  width: 50%;
}

.column-7 {
  width: 58.33333%;
}

.column-8 {
  width: 66.66667%;
}

.column-9 {
  width: 75%;
}

.column-10 {
  width: 83.33333%;
}

.column-11 {
  width: 91.66667%;
}

.column-12 {
  width: 100%;
}

.wow {
  visibility: hidden;
}

[class*='column-'] {
  float: left;
  min-height: 1px;
}

.has_gutter [class*='column-'] {
  padding: 0 15px;
}

.has_gutter_columns [class*='column-'] {
  padding: 0 20px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.row_dhuro_column {
  margin-left: -20px;
  margin-right: -20px;
}

.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 15px;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .container {
    max-width: 747px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .container {
    max-width: 985px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1310px;
  }
}

@media only screen and (min-width: 1200px) {
  .show-mob {
    display: none !important;
  }
  .show-tab {
    display: none !important;
  }
  .show-tab-big {
    display: none !important;
  }
}

.hidden-mob {
  display: block;
}

.hidden {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .column-mob-1 {
    width: 8.33333%;
  }
  .column-mob-2 {
    width: 16.66667%;
  }
  .column-mob-3 {
    width: 25%;
  }
  .column-mob-4 {
    width: 33.33333%;
  }
  .column-mob-5 {
    width: 41.66667%;
  }
  .column-mob-6 {
    width: 50%;
  }
  .column-mob-7 {
    width: 58.33333%;
  }
  .column-mob-8 {
    width: 66.66667%;
  }
  .column-mob-9 {
    width: 75%;
  }
  .column-mob-10 {
    width: 83.33333%;
  }
  .column-mob-11 {
    width: 91.66667%;
  }
  .column-mob-12 {
    width: 100%;
  }
  .show-mob {
    display: block !important;
  }
  .hidden-mob {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .column-tab-1 {
    width: 8.33333%;
  }
  .column-tab-2 {
    width: 16.66667%;
  }
  .column-tab-3 {
    width: 25%;
  }
  .column-tab-4 {
    width: 33.33333%;
  }
  .column-tab-5 {
    width: 41.66667%;
  }
  .column-tab-6 {
    width: 50%;
  }
  .column-tab-7 {
    width: 58.33333%;
  }
  .column-tab-8 {
    width: 66.66667%;
  }
  .column-tab-9 {
    width: 75%;
  }
  .column-tab-10 {
    width: 83.33333%;
  }
  .column-tab-11 {
    width: 91.66667%;
  }
  .column-tab-12 {
    width: 100%;
  }
  .show-tab {
    display: block !important;
  }
  .hidden-tab {
    display: none !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .column-tab-big-1 {
    width: 8.33333%;
  }
  .column-tab-big-2 {
    width: 16.66667%;
  }
  .column-tab-big-3 {
    width: 25%;
  }
  .column-tab-big-4 {
    width: 33.33333%;
  }
  .column-tab-big-5 {
    width: 41.66667%;
  }
  .column-tab-big-6 {
    width: 50%;
  }
  .column-tab-big-7 {
    width: 58.33333%;
  }
  .column-tab-big-8 {
    width: 66.66667%;
  }
  .column-tab-big-9 {
    width: 75%;
  }
  .column-tab-big-10 {
    width: 83.33333%;
  }
  .column-tab-big-11 {
    width: 91.66667%;
  }
  .column-tab-big-12 {
    width: 100%;
  }
  .show-tablet-big {
    display: block !important;
  }
  .hidden-tablet-big {
    display: none !important;
  }
}

.is-full-width {
  width: 100vw !important;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  max-width: none;
}

.no_padding {
  padding: 0 !important;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1;
}

.no-flexboxlegacy body {
  display: block !important;
}

body {
  font-family: 'Museo Sans',sans-serif;
  font-size: 14px;
  color: black;
  position: relative;
  overflow-x: hidden;
}

input,
textarea,
select,
button,
option {
  font-family: 'Museo Sans',sans-serif;
  opacity: 1;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder,
button::-webkit-input-placeholder,
option::-webkit-input-placeholder {
  font-family: 'Museo Sans',sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder,
button:-moz-placeholder,
option:-moz-placeholder {
  font-family: 'Museo Sans',sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder,
button::-moz-placeholder,
option::-moz-placeholder {
  font-family: 'Museo Sans',sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder,
button:-ms-input-placeholder,
option:-ms-input-placeholder {
  font-family: 'Museo Sans',sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

.iphone input,
.iphone textarea,
.iphone select,
.iphone option {
  appearance: none;
  border-radius: 0;
}

.safari input,
.safari textarea,
.safari select,
.safari option {
  appearance: none;
  border-radius: 0;
}

select::-ms-expand {
  display: none;
}

/*=================================
=            Normalize            =
=================================*/
mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 15px;
  font-size: 0.9375rem;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

/* Do not show the outline on the skip link target. */
#main[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 26px;
  margin-right: 1.625rem;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 26px;
  margin-left: 1.625rem;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*=====  End of Normalize  ======*/
.main_header {
  position: relative;
}

.main_header .headerWrap {
  height: 80px;
  height: 5rem;
  position: relative;
  box-shadow: -3px -2px 102px 0px rgba(0, 0, 0, 0.18);
  z-index: 99;
}

@media only screen and (max-width: 767px) {
  .main_header .headerWrap {
    height: 60px;
  }
}

.logo {
  float: left;
  width: 245px;
  width: 15.3125rem;
  height: auto;
  margin-left: 24px;
  margin-left: 1.5rem;
  margin-top: 23px;
  margin-top: 1.4375rem;
}

@media only screen and (max-width: 767px) {
  .logo {
    float: left;
    width: 180px;
    height: 100%;
    margin-left: 15px;
    margin-top: 0;
    display: flex;
    align-items: center;
  }
  .logo img {
    width: 100%;
    height: 19px;
  }
}

@media (max-width: 320px) {
  .logo {
    width: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .logo {
    width: 205px;
  }
}

.header_nav_bar {
  float: left;
  height: 100%;
  margin-left: 45px;
  margin-left: 2.8125rem;
}

.header_nav_bar li {
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.header_nav_bar li a {
  text-decoration: none;
  color: #231f20;
  margin-right: 29px;
  margin-right: 1.8125rem;
  font-size: 20px;
  font-size: 1.25rem;
}

.header_nav_bar li:last-child {
  margin-right: 0;
  margin-right: 0;
}

.header_nav_bar li:hover {
  cursor: pointer;
}

.header_nav_bar li:hover a {
  color: #e2001a;
}

.header_nav_bar li:hover .nav_extension {
  visibility: visible;
  opacity: 1;
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .header_nav_bar {
    display: none;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .header_nav_bar {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header_nav_bar {
    display: none;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .header_nav_bar {
    margin-left: 40px;
  }
  .header_nav_bar li a {
    font-size: 16px;
    margin-right: 25px;
  }
}

.active_link {
  color: #e2001a !important;
}

.hide_desktop {
  display: none !important;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .hide_tablet {
    display: none !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .hide_tablet {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .hide_mobile {
    display: none !important;
  }
}

.search_icon {
  float: left;
  text-decoration: none;
  font-family: 'Font Awesome 5 Pro Light';
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  margin-left: 25px;
  margin-left: 1.5625rem;
  margin-top: 33px;
  margin-top: 2.0625rem;
}

.search_icon .fa-search {
  color: #231f20;
}

.search_icon:hover .fa-search {
  color: #e2001a;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .search_icon {
    margin-left: 40%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .search_icon {
    margin-left: 55%;
  }
}

@media only screen and (max-width: 767px) {
  .search_icon {
    margin-top: 25px;
    margin-left: 25px;
    font-size: 12px;
    font-size: 0.75rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .search_icon {
    margin-left: 15px;
  }
}

.languages {
  float: left;
  margin-top: 30px;
  margin-top: 1.875rem;
}

.languages li {
  margin-left: 25px;
  margin-left: 1.5625rem;
}

.languages li a {
  text-decoration: none;
  color: #b3b3b3;
  font-size: 20px;
  font-size: 1.25rem;
}

@media only screen and (max-width: 767px) {
  .languages li a {
    font-size: 16px;
    font-size: 1rem;
  }
}

.languages li:hover a {
  color: #e2001a;
}

@media only screen and (max-width: 767px) {
  .languages {
    margin-top: 23px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .languages {
    margin-top: 32px;
  }
  .languages li a {
    font-size: 16px;
  }
}

.right_header {
  float: right;
  height: 100%;
  display: flex;
  align-items: center;
}

.header_social_media {
  float: left;
}

.header_social_media li {
  display: inline-block;
}

.header_social_media li a {
  font-family: 'Font Awesome 5 Pro Light';
  font-size: 18px;
  font-size: 1.125rem;
  color: #b3b3b3;
  margin-right: 26px;
  margin-right: 1.625rem;
  text-decoration: none;
}

.header_social_media li a:hover {
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  color: #27ba9e;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .header_social_media {
    display: none;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .header_social_media {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header_social_media {
    display: none;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .header_social_media li a {
    margin-right: 20px;
    font-size: 16px;
  }
}

.donation_activists {
  float: left;
  border: 2px solid #e6e6e6;
  border-radius: 2px;
  text-decoration: none;
  margin-right: 18px;
  margin-right: 1.125rem;
  padding: 18px 21px 15px 18px;
  padding: 1.125rem 1.3125rem 0.9375rem 1.125rem;
}

.donation_activists i {
  font-size: 14px;
  font-size: 0.875rem;
  color: #27ba9e;
  font-weight: bold;
  padding-right: 17px;
  padding-right: 1.0625rem;
  padding-right: 17px;
  font-family: 'Font Awesome 5 Pro Light';
}

.donation_activists span {
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  color: #231f20;
}

.donation_activists:hover {
  border: 2px solid #27ba9e;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .donation_activists {
    display: none;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .donation_activists {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .donation_activists {
    display: none;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .donation_activists {
    padding: 15px;
    margin-right: 15px;
  }
  .donation_activists span {
    font-size: 13px;
  }
}

#nav_btn {
  display: block;
  float: right;
  height: 80px;
  height: 5rem;
  width: 80px;
  width: 5rem;
  background: #27ba9e;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
}

#nav_btn span, #nav_btn span::before, #nav_btn span::after {
  width: 21px;
  width: 1.3125rem;
  height: 2px;
  height: 0.125rem;
  float: left;
  display: block;
  background: #fff;
  position: relative;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

@media only screen and (max-width: 767px) {
  #nav_btn span, #nav_btn span::before, #nav_btn span::after {
    width: 17px;
  }
}

#nav_btn span {
  margin: 8px 0;
}

#nav_btn span::before, #nav_btn span::after {
  content: '';
  position: absolute;
}

#nav_btn span::before {
  top: -8px;
}

#nav_btn span::after {
  bottom: -8px;
}

#nav_btn.active span {
  background-color: transparent;
}

#nav_btn.active span::before, #nav_btn .active span::after {
  top: 0;
}

#nav_btn.active span:before {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

#nav_btn.active span::after {
  transform: translateY(-10px) rotate(-45deg);
  -webkit-transform: translateY(-10px) rotate(-45deg);
  top: 10px;
}

@media only screen and (max-width: 767px) {
  #nav_btn {
    height: 60px;
    height: 3.75rem;
    width: 60px;
    width: 3.75rem;
  }
}

.nav_extension {
  float: left;
  width: 100%;
  height: 250px;
  height: 15.625rem;
  position: absolute;
  top: 80px;
  left: 0;
  background: rgba(102, 102, 102, 0.9);
  z-index: 1;
  display: none;
  opacity: 0;
  visibility: hidden;
  animation: fadeIn 0.5s;
  padding-left: 0;
  padding-left: 0;
  padding-right: 0;
  padding-right: 0;
  padding-bottom: 75px;
  padding-bottom: 4.6875rem;
}

.nav_extension .levizja_suboptions {
  float: left;
  width: 100%;
  font-size: 15px;
  font-size: 0.9375rem;
  margin-right: 53px;
  margin-right: 3.3125rem;
  padding-top: 75px;
  padding-top: 4.6875rem;
}

.nav_extension .levizja_suboptions:last-child {
  margin-right: 0;
  margin-right: 0;
}

.nav_extension .levizja_suboptions li {
  display: block;
  margin-bottom: 13px;
  margin-bottom: 0.8125rem;
  float: left;
  width: 25%;
}

.nav_extension .levizja_suboptions li a {
  padding-bottom: 10px;
  padding-bottom: 0.625rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  text-decoration: none;
  color: white;
  position: relative;
  float: left;
}

.nav_extension .levizja_suboptions li a:after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: transparent;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.nav_extension .levizja_suboptions li a:hover {
  cursor: pointer;
}

.nav_extension .levizja_suboptions li a:hover:after {
  background: white;
}

.nav_extension:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .nav_extension {
    height: 200px;
    padding-left: 268px;
  }
  .nav_extension .levizja_suboptions {
    margin-top: 40px !important;
  }
}

.hamburger_menu_toggle {
  width: 290px;
  width: 18.125rem;
  height: auto;
  background: #fff;
  z-index: 999;
  position: absolute;
  right: 0;
  top: 80px;
  display: none;
  -webkit-box-shadow: 0px 0px 129px -23px #9e909e;
  -moz-box-shadow: 0px 0px 129px -23px #9e909e;
  box-shadow: 0px 0px 129px -23px #9e909e;
  padding-top: 50px;
  padding-top: 3.125rem;
  padding-left: 50px;
  padding-left: 3.125rem;
}

.hamburger_menu_toggle ul {
  display: block;
}

.hamburger_menu_toggle ul a {
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  text-decoration: none;
  color: #231f20;
}

.hamburger_menu_toggle ul .list_heading {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 36px;
  margin-bottom: 2.25rem;
}

.hamburger_menu_toggle ul .menu_list_item {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 23px;
  margin-bottom: 1.4375rem;
}

.hamburger_menu_toggle ul .menu_list_item:last-child {
  margin-bottom: 44px;
}

.hamburger_menu_toggle ul .menu_list_item:hover a {
  color: #e2001a;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .hamburger_menu_toggle ul {
    display: none;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .hamburger_menu_toggle ul {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .hamburger_menu_toggle ul {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .hamburger_menu_toggle {
    width: 100%;
    padding: 0;
  }
  .hamburger_menu_toggle .hamburger_menu_content {
    display: block !important;
    float: left;
    width: 100%;
    clear: both;
    height: 100%;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading {
    float: left;
    width: 100%;
    clear: both;
    border-bottom: 1px solid #e6e6e6;
    padding: 30px;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_donation_activists {
    float: left;
    text-decoration: none;
    margin-right: 9px;
    margin-right: 0.5625rem;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_donation_activists i {
    font-size: 14px;
    font-size: 0.875rem;
    color: #27ba9e;
    font-weight: bold;
    padding-right: 17px;
    padding-right: 1.0625rem;
    font-family: 'Font Awesome 5 Pro Light';
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_donation_activists span {
    font-size: 15px;
    font-size: 0.9375rem;
    font-weight: 700;
    color: #231f20;
  }
}

@media only screen and (max-width: 767px) and (max-width: 767px) {
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_donation_activists i {
    font-size: 13px;
    font-size: 0.8125rem;
    padding-right: 10px;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_donation_activists span {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 767px) {
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social {
    float: right;
    display: inline-block !important;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social li {
    display: inline-block;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social li a {
    font-family: 'Font Awesome 5 Pro Light';
    font-size: 15px;
    font-size: 0.9375rem;
    color: #b3b3b3;
    margin-left: 15px;
    margin-left: 0.9375rem;
    text-decoration: none;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social li a:last-child {
    margin-right: 0;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social li a:focus {
    color: #27ba9e;
  }
}

@media only screen and (max-width: 767px) and (max-width: 767px) {
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social li a {
    margin-left: 8px !important;
  }
}

@media only screen and (max-width: 767px) and (max-width: 320px) {
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social {
    width: 100%;
    float: left;
    margin-top: 5px;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social li a {
    margin-left: 0 !important;
    margin-right: 10px !important;
  }
}

@media only screen and (max-width: 767px) and (max-width: 767px) {
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading {
    padding: 25px 15px;
  }
}

@media only screen and (max-width: 767px) and (max-width: 320px) {
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_donation_activists {
    margin-right: 10px;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_donation_activists span {
    font-size: 12px;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social i {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion {
    float: left;
    width: 100%;
    clear: both;
    padding: 50px 40px;
    display: inline-block !important;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger {
    display: inline-block !important;
    float: left;
    width: 100%;
    clear: both;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li {
    float: left;
    width: 100%;
    margin-bottom: 30px;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li:last-child {
    margin-bottom: 0;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li.is--active .toggle_hamburger_menu {
    color: #e2001a !important;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li a.toggle_hamburger_menu {
    width: 100% !important;
    font-size: 16px;
    color: #231f20;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li a.toggle_hamburger_menu i {
    color: #231f20;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li .inner_list {
    float: left;
    width: 100%;
    clear: both;
    padding: 30px 0 0 0;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li .inner_list li {
    float: left;
    width: 100%;
    clear: both;
    margin-bottom: 25px;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li .inner_list li:last-child {
    margin-bottom: 0;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li .inner_list li a {
    color: #b3b3b3;
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .hamburger_menu_toggle {
    width: 60%;
    padding: 0;
  }
  .hamburger_menu_toggle .hamburger_menu_content {
    display: block !important;
    float: left;
    width: 100%;
    clear: both;
    height: 100vh;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading {
    float: left;
    width: 100%;
    clear: both;
    border-bottom: 1px solid #e6e6e6;
    padding: 30px;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_donation_activists {
    float: left;
    text-decoration: none;
    margin-right: 18px;
    margin-right: 1.125rem;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_donation_activists i {
    font-size: 14px;
    font-size: 0.875rem;
    color: #27ba9e;
    font-weight: bold;
    padding-right: 15px;
    padding-right: 0.9375rem;
    font-family: 'Font Awesome 5 Pro Light';
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_donation_activists span {
    font-size: 15px;
    font-size: 0.9375rem;
    font-weight: 700;
    color: #231f20;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social {
    float: right;
    display: inline-block !important;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social li {
    display: inline-block;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social li a {
    font-family: 'Font Awesome 5 Pro Light';
    font-size: 15px;
    font-size: 0.9375rem;
    color: #b3b3b3;
    margin-left: 15px;
    margin-left: 0.9375rem;
    text-decoration: none;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social li a:last-child {
    margin-right: 0;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social li a:focus {
    color: #27ba9e;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion {
    float: left;
    width: 100%;
    clear: both;
    padding: 50px 40px;
    display: inline-block !important;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger {
    display: inline-block !important;
    float: left;
    width: 100%;
    clear: both;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li {
    float: left;
    width: 100%;
    margin-bottom: 30px;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li:last-child {
    margin-bottom: 0;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li.is--active .toggle_hamburger_menu {
    color: #e2001a !important;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li a.toggle_hamburger_menu {
    width: 100% !important;
    font-size: 16px;
    color: #231f20;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li a.toggle_hamburger_menu i {
    color: #231f20;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li .inner_list {
    float: left;
    width: 100%;
    clear: both;
    padding: 30px 0 0 0;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li .inner_list li {
    float: left;
    width: 100%;
    clear: both;
    margin-bottom: 25px;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li .inner_list li:last-child {
    margin-bottom: 0;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li .inner_list li a {
    color: #b3b3b3;
    font-size: 14px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .hamburger_menu_toggle {
    width: 60%;
    padding: 0;
  }
  .hamburger_menu_toggle .hamburger_menu_content {
    display: block !important;
    float: left;
    width: 100%;
    clear: both;
    height: 100%;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading {
    float: left;
    width: 100%;
    clear: both;
    border-bottom: 1px solid #e6e6e6;
    padding: 30px;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_donation_activists {
    float: left;
    text-decoration: none;
    margin-right: 18px;
    margin-right: 1.125rem;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_donation_activists i {
    font-size: 14px;
    font-size: 0.875rem;
    color: #27ba9e;
    font-weight: bold;
    padding-right: 17px;
    padding-right: 1.0625rem;
    font-family: 'Font Awesome 5 Pro Light';
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_donation_activists span {
    font-size: 15px;
    font-size: 0.9375rem;
    font-weight: 700;
    color: #231f20;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social {
    float: right;
    display: inline-block !important;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social li {
    display: inline-block;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social li a {
    font-family: 'Font Awesome 5 Pro Light';
    font-size: 15px;
    font-size: 0.9375rem;
    color: #b3b3b3;
    margin-left: 15px;
    margin-left: 0.9375rem;
    text-decoration: none;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social li a:last-child {
    margin-right: 0;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_heading .hamburger_social li a:focus {
    color: #27ba9e;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion {
    float: left;
    width: 100%;
    clear: both;
    padding: 50px 40px;
    display: inline-block !important;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger {
    display: inline-block !important;
    float: left;
    width: 100%;
    clear: both;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li {
    float: left;
    width: 100%;
    margin-bottom: 30px;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li:last-child {
    margin-bottom: 0;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li.is--active .toggle_hamburger_menu {
    color: #e2001a !important;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li a.toggle_hamburger_menu {
    width: 100% !important;
    font-size: 16px;
    color: #231f20;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li a.toggle_hamburger_menu i {
    color: #231f20;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li .inner_list {
    float: left;
    width: 100%;
    clear: both;
    padding: 30px 0 0 0;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li .inner_list li {
    float: left;
    width: 100%;
    clear: both;
    margin-bottom: 25px;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li .inner_list li:last-child {
    margin-bottom: 0;
  }
  .hamburger_menu_toggle .hamburger_menu_content .hamburger_menu_accordion .accordion_hamburger li .inner_list li a {
    color: #b3b3b3;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .hamburger_menu_toggle {
    top: 60px;
  }
}

#search_form {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 80px;
  height: 5rem;
  background: white;
  display: none;
  animation: popupIn 0.4s;
}

#search_form input[type=search] {
  background: #fff url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
  width: 100%;
  height: 100%;
  text-align: center;
  border: solid 0px;
  padding: 9px 10px 9px 32px;
}

@keyframes popupIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 767px) {
  #search_form {
    height: 60px;
  }
}

.close_trigger {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 80px;
  z-index: 33333;
  display: none;
}

.banner_cover_img {
  float: left;
  height: 700px;
  height: 43.75rem;
  width: 100%;
  position: relative;
  background-image: url("../images/banner_cover_img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.banner_cover_img .banner_text {
  color: white;
  text-align: center;
}

.banner_cover_img .banner_text h1 {
  font-size: 72px;
  font-size: 4.5rem;
  font-weight: 700;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
}

.banner_cover_img .banner_text h2 {
  font-size: 60px;
  font-size: 3.75rem;
  font-weight: 300;
}

@media only screen and (max-width: 767px) {
  .banner_cover_img .banner_text {
    top: 39%;
  }
  .banner_cover_img .banner_text h1 {
    font-size: 50px;
    margin-bottom: 8px;
  }
  .banner_cover_img .banner_text h2 {
    font-size: 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .banner_cover_img {
    background-image: url("../images/ak_cover_tablet.png");
  }
  .banner_cover_img .banner_text {
    width: 80%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .banner_cover_img {
    background-image: url("../images/ak_cover_tablet.png");
  }
  .banner_cover_img .banner_text {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .banner_cover_img {
    height: 470px;
    background-image: url("../images/ak_cover_mobile.png");
  }
  .banner_cover_img .banner_text {
    width: 80%;
  }
}

#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #231f20;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

#myBtn:hover i {
  color: #e2001a;
}

.kontribo_anetaresohu {
  width: 700px;
  width: 43.75rem;
  height: 241px;
  height: 15.0625rem;
  background: #fff;
  position: absolute;
  top: 100%;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

.kontribo_anetaresohu:before {
  left: 0;
  bottom: -4%;
  content: '';
  width: 100%;
  height: 53%;
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  transform: scale(1.03);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.kontribo_anetaresohu ul {
  float: left;
  width: 100%;
}

.kontribo_anetaresohu ul .kontribo {
  float: left;
  width: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 98px;
  height: 6.125rem;
  border-bottom-right-radius: 25px;
  padding-left: 40px;
  padding-left: 2.5rem;
}

.kontribo_anetaresohu ul .kontribo:not(.active) {
  -webkit-box-shadow: inset 0px -19px 148px -58px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px -19px 148px -58px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px -19px 148px -58px rgba(0, 0, 0, 0.25);
}

.kontribo_anetaresohu ul .kontribo .kontribo_tab_text h4 {
  float: left;
  width: 100%;
  font-size: 24px;
  font-size: 1.5rem;
  color: #e2001a;
  font-weight: 700;
}

.kontribo_anetaresohu ul .kontribo .kontribo_tab_text p {
  float: left;
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 300;
}

@media only screen and (max-width: 767px) {
  .kontribo_anetaresohu ul .kontribo {
    height: 60px;
    padding-left: 0;
  }
  .kontribo_anetaresohu ul .kontribo .kontribo_tab_text {
    width: 100%;
    text-align: center;
  }
  .kontribo_anetaresohu ul .kontribo .kontribo_tab_text h4 {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.kontribo_anetaresohu ul .antaresohu {
  float: left;
  width: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom-left-radius: 25px;
  height: 98px;
  height: 6.125rem;
  padding-right: 40px;
  padding-right: 2.5rem;
}

.kontribo_anetaresohu ul .antaresohu:not(.active) {
  -webkit-box-shadow: inset 0px -19px 148px -58px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px -19px 148px -58px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px -19px 148px -58px rgba(0, 0, 0, 0.25);
}

.kontribo_anetaresohu ul .antaresohu .antaresohu_tab_text {
  text-align: right;
  width: 100%;
}

.kontribo_anetaresohu ul .antaresohu .antaresohu_tab_text h4 {
  float: left;
  width: 100%;
  font-size: 24px;
  font-size: 1.5rem;
  color: #11ad8e;
  font-weight: 700;
}

.kontribo_anetaresohu ul .antaresohu .antaresohu_tab_text p {
  float: left;
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 300;
}

@media only screen and (max-width: 767px) {
  .kontribo_anetaresohu ul .antaresohu {
    height: 60px;
    padding-left: 0;
    padding-right: 0 !important;
  }
  .kontribo_anetaresohu ul .antaresohu .antaresohu_tab_text {
    width: 100%;
    text-align: center;
    padding-left: 0;
  }
  .kontribo_anetaresohu ul .antaresohu .antaresohu_tab_text h4 {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content {
  flex: 1;
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: white;
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma {
  float: left;
  width: 100%;
  padding: 30px;
  padding: 1.875rem;
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form {
  float: left;
  width: 100%;
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form > :nth-child(2n+2) {
  margin-right: 0;
  margin-right: 0;
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .kontribo_form_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .kontribo_form_wrap {
    margin-top: 15px;
  }
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .input_holder {
  float: left;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  margin-right: 20px;
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .input_holder input:checked ~ .kontribo_button {
  border-color: transparent;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .input_holder input:checked ~ .kontribo_button i,
.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .input_holder input:checked ~ .kontribo_button span {
  color: #e2001a;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

@media only screen and (max-width: 767px) {
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .input_holder {
    margin-right: 15px;
  }
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .input_holder:nth-child(2n+2) {
    margin-right: 0;
  }
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .input_holder i {
    font-size: 15px;
  }
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .input_holder span {
    font-size: 14px;
  }
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .kontribo_button {
  float: left;
  width: 100px;
  width: 6.25rem;
  height: 50px;
  height: 3.125rem;
  text-align: center;
  border: 1px solid #e6e6e6;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .kontribo_button input[type=text],
.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .kontribo_button input[type=number] {
  float: left;
  width: 60px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
  padding-right: 15px;
  line-height: 44px;
  background: transparent;
  border: 0;
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .kontribo_button input[type=text] {
  text-align: center;
  padding-right: 0;
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .kontribo_button i {
  float: left;
  width: 43px;
  font-size: 18px;
  text-align: center;
  line-height: 44px;
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .kontribo_button span {
  line-height: 46px;
  font-weight: 700;
  font-size: 16px;
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .kontribo_button:hover {
  border-color: transparent;
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .kontribo_button:hover label {
  color: #e2001a;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

@media only screen and (max-width: 767px) {
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .kontribo_button {
    width: 120px;
    height: 40px;
    margin-bottom: 15px;
  }
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .kontribo_button i, .kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form .kontribo_button span {
    line-height: 39px;
  }
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form button {
  float: left;
  height: 50px;
  height: 3.125rem;
  width: 140px;
  background: #e2001a;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, 0) !important;
  outline: none !important;
}

@media only screen and (max-width: 767px) {
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma form button {
    width: 255px;
    height: 40px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma {
    padding: 0;
    padding-bottom: 40px;
  }
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content .kontribo_forma p {
    display: block;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content .bashkohu_me_vv {
    display: block;
    text-align: center;
    margin: 20px 0;
    color: #231f20;
  }
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content #antaresohu_tab {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content #antaresohu_tab .dhuro_vlerat {
  float: left;
  width: 140px;
  width: 8.75rem;
  height: auto;
  margin-right: 20px;
  margin-right: 1.25rem;
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content #antaresohu_tab .dhuro_vlerat:last-child {
  margin-right: 0;
  margin-right: 0;
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content #antaresohu_tab .dhuro_vlerat label {
  float: left;
  width: 100%;
  font-size: 13px;
  color: #231f20;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content #antaresohu_tab .dhuro_vlerat input {
  float: left;
  width: 100%;
  width: 140px;
  width: 8.75rem;
  height: 50px;
  height: 3.125rem;
  margin-left: auto;
  text-align: left;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  padding-left: 10px;
  padding-left: 0.625rem;
  font-size: 16px;
  color: #231f20;
  font-weight: 700;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content #antaresohu_tab .dhuro_vlerat input:hover, .kontribo_anetaresohu .kontribo_antaresohu_tabs_content #antaresohu_tab .dhuro_vlerat input:focus {
  outline: none !important;
  border-color: transparent;
  box-shadow: 0 0 125px 3px rgba(0, 0, 0, 0.18);
}

.kontribo_anetaresohu .kontribo_antaresohu_tabs_content #antaresohu_tab button {
  float: left;
  width: 140px;
  width: 8.75rem;
  height: 50px;
  height: 3.125rem;
  background: #11ad8e;
  color: #fff;
  font-weight: 700;
  box-shadow: none !important;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0) !important;
  outline: none !important;
}

@media only screen and (max-width: 767px) {
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content #antaresohu_tab {
    padding: 15px 30px 40px 30px;
    flex-direction: column;
  }
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content #antaresohu_tab .dhuro_vlerat {
    margin-right: 0;
    float: left;
    width: 100%;
    margin-bottom: 15px;
  }
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content #antaresohu_tab .dhuro_vlerat label {
    margin-bottom: 5px;
  }
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content #antaresohu_tab .dhuro_vlerat input {
    width: 100%;
    height: 40px;
  }
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content #antaresohu_tab .dhuro_vlerat #transparent_label {
    display: none;
  }
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content #antaresohu_tab .dhuro_vlerat button {
    width: 100%;
    height: 40px;
    font-size: 14px;
  }
  .kontribo_anetaresohu .kontribo_antaresohu_tabs_content #antaresohu_tab .dhuro_vlerat:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .kontribo_anetaresohu {
    width: 100%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .kontribo_anetaresohu {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .kontribo_anetaresohu {
    width: 90%;
    height: auto;
    border-radius: 3px;
    -webkit-box-shadow: 0px 0px 97px -11px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 97px -11px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 97px -11px rgba(0, 0, 0, 0.2);
  }
  .kontribo_anetaresohu:before {
    display: none;
  }
}

.media_homepage {
  float: left;
  width: 100%;
}

.media_readmore {
  float: left;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 190px;
  padding-top: 11.875rem;
  margin-bottom: 52px;
  margin-bottom: 3.25rem;
}

.media_readmore span, .media_readmore h2, .media_readmore p {
  display: inline-block;
}

.media_readmore span {
  content: " ";
  background-image: url("../images/dash.svg");
  height: 5px;
  height: 0.3125rem;
  width: 20px;
  width: 1.25rem;
}

.media_readmore h2 {
  font-size: 27px;
  font-size: 1.6875rem;
  font-weight: 700;
  color: #231f20;
  margin-left: 29px;
  margin-left: 1.8125rem;
}

.media_readmore a {
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 300;
  color: #666;
  margin-left: auto;
  text-decoration: none;
}

.media_readmore a:hover {
  color: #e2001a;
}

.media_readmore a:hover .read_more_arrow {
  content: " ";
  background-image: url("../images/lexomeshume-red.svg");
  height: 8px;
  height: 0.5rem;
  width: 14px;
  width: 0.875rem;
  margin-left: 22px;
  margin-left: 1.375rem;
}

.media_readmore .read_more_arrow {
  content: " ";
  background-image: url("../images/lexomeshume.svg");
  height: 8px;
  height: 0.5rem;
  width: 14px;
  width: 0.875rem;
  margin-left: 22px;
  margin-left: 1.375rem;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .media_readmore a {
    color: #e2001a;
  }
  .media_readmore a .read_more_arrow {
    content: " ";
    background-image: url("../images/lexomeshume-red.svg");
    height: 8px;
    height: 0.5rem;
    width: 14px;
    width: 0.875rem;
    margin-left: 22px;
    margin-left: 1.375rem;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .media_readmore a {
    color: #e2001a;
  }
  .media_readmore a .read_more_arrow {
    content: " ";
    background-image: url("../images/lexomeshume-red.svg");
    height: 8px;
    height: 0.5rem;
    width: 14px;
    width: 0.875rem;
    margin-left: 22px;
    margin-left: 1.375rem;
  }
}

@media only screen and (max-width: 767px) {
  .media_readmore {
    padding-top: 225px;
    margin-bottom: 25px;
  }
  .media_readmore .see_more_lajme {
    display: block;
    color: #e2001a !important;
    font-weight: 700;
  }
}

.media_grid_view {
  float: left;
  width: 100%;
  clear: both;
  margin-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .media_grid_view {
    display: flex;
    flex-wrap: wrap;
  }
  .media_grid_view .lajmet_block {
    order: 1;
  }
  .media_grid_view .lajmet_e_fundit_block {
    order: 3;
  }
  .media_grid_view .media_grid_bottom_block {
    order: 2;
  }
}

@media only screen and (max-width: 767px) {
  .media_grid_view {
    height: auto;
  }
  .media_grid_view .lajmet_block, .media_grid_view .lajmet_e_fundit_block, .media_grid_view .media_grid_bottom_block {
    width: 100%;
    margin-right: 0;
    height: auto;
  }
  .media_grid_view .lajmet_e_fundit_block, .media_grid_view .media_grid_bottom_block {
    display: none;
  }
}

.lajmet_block {
  float: left;
  width: 950px;
  margin-right: 40px;
  height: 414px;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .lajmet_block {
    height: auto;
    width: 100%;
    margin-right: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

.lajmet_block .mini_lajmet {
  float: left;
  width: 290px;
  height: auto;
  margin-right: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .lajmet_block .mini_lajmet {
    width: 100%;
    float: left;
    margin-right: 0;
    display: flex;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .lajmet_block .mini_lajmet {
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
}

.lajmet_block .mini_lajmet .lajmi_item {
  float: left;
  width: 100%;
  height: 187px;
  margin-bottom: 40px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .lajmet_block .mini_lajmet .lajmi_item {
    width: calc(50% - 20px);
  }
  .lajmet_block .mini_lajmet .lajmi_item:last-child {
    margin-left: auto;
  }
}

.lajmet_block .mini_lajmet .lajmi_item:before {
  content: " ";
  position: absolute;
  height: 50%;
  width: 100%;
  left: 0;
  bottom: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
  z-index: 1;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
}

.lajmet_block .mini_lajmet .lajmi_item .column_item_text {
  position: absolute;
  top: 106px;
  left: 30px;
  right: 30px;
  bottom: 28px;
  z-index: 3;
}

.lajmet_block .mini_lajmet .lajmi_item .column_item_text p {
  font-size: 12px;
  font-size: 0.75rem;
  margin-bottom: 8px;
  margin-bottom: 0.5rem;
  font-weight: 300;
  color: white;
  cursor: default;
}

.lajmet_block .mini_lajmet .lajmi_item .column_item_text p span {
  margin-right: 10px;
  margin-right: 0.625rem;
  margin-left: 10px;
  margin-left: 0.625rem;
}

.lajmet_block .mini_lajmet .lajmi_item .column_item_text a {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 21px;
  text-decoration: none;
  color: white;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  max-height: 42px;
}

.lajmet_block .mini_lajmet .lajmi_item:last-child {
  margin-bottom: 0;
}

.lajmet_block .mini_lajmet .lajmi_item:hover {
  cursor: pointer;
}

.lajmet_block .mini_lajmet .lajmi_item:hover a {
  text-decoration: underline;
}

.lajmet_block .lajmi_item_big {
  float: left;
  width: 620px;
  height: 100%;
  position: relative;
}

.lajmet_block .lajmi_item_big:before {
  content: " ";
  position: absolute;
  height: 50%;
  width: 100%;
  left: 0;
  bottom: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
  z-index: 1;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
}

.lajmet_block .lajmi_item_big .column_item_text {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 61px;
  width: 100%;
  z-index: 3;
}

@media only screen and (max-width: 767px) {
  .lajmet_block .lajmi_item_big .column_item_text {
    padding: 0;
  }
}

.lajmet_block .lajmi_item_big .column_item_text p {
  font-size: 12px;
  font-size: 0.75rem;
  margin-bottom: 8px;
  margin-bottom: 0.5rem;
  font-weight: 300;
  color: white;
  cursor: default;
}

.lajmet_block .lajmi_item_big .column_item_text p span {
  margin-right: 10px;
  margin-right: 0.625rem;
  margin-left: 10px;
  margin-left: 0.625rem;
}

.lajmet_block .lajmi_item_big .column_item_text a {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 27px;
  text-decoration: none;
  color: white;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  max-height: 54px;
}

.lajmet_block .lajmi_item_big:hover {
  cursor: pointer;
}

.lajmet_block .lajmi_item_big:hover a {
  text-decoration: underline;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .lajmet_block .lajmi_item_big {
    float: left;
    margin-right: 0;
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .lajmet_block .lajmi_item_big {
    width: 100%;
    margin-right: 0;
  }
}

/* * {
  &:before,&:after {
    outline: 1px solid red;
    visibility: visibility;
    opacity: 1;
  }
} */
.lajmet_e_fundit_block {
  float: right;
  width: 290px;
  height: 415px;
  box-sizing: border-box;
  padding: 41px;
  padding: 2.5625rem;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
  background: #fff;
  -webkit-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
}

.lajmet_e_fundit_block .te_fundit_heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
  margin-bottom: 2.1875rem;
}

.lajmet_e_fundit_block .te_fundit_heading h3 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  margin-left: 17px;
  margin-left: 1.0625rem;
  text-transform: uppercase;
}

.lajmet_e_fundit_block .te_fundit_heading span {
  content: " ";
  background-image: url("../images/dash.svg");
  height: 5px;
  height: 0.3125rem;
  width: 20px;
  width: 1.25rem;
}

.lajmet_e_fundit_block .te_fundit_item {
  margin-bottom: 27px;
  margin-bottom: 1.6875rem;
}

.lajmet_e_fundit_block .te_fundit_item p {
  font-size: 12px;
  font-size: 0.75rem;
  margin-bottom: 8px;
  margin-bottom: 0.5rem;
  font-weight: 300;
  color: #666666;
  line-height: 8px;
  cursor: default;
}

.lajmet_e_fundit_block .te_fundit_item p span {
  margin-right: 10px;
  margin-right: 0.625rem;
  margin-left: 10px;
  margin-left: 0.625rem;
}

.lajmet_e_fundit_block .te_fundit_item a {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
  color: #231f20;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  overflow: hidden;
  max-height: 36px;
}

.lajmet_e_fundit_block .te_fundit_item a:hover {
  color: #e2001a;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .lajmet_e_fundit_block {
    width: 100%;
    margin-right: 0;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .lajmet_e_fundit_block {
    margin-top: 40px;
  }
}

.media_grid_bottom_block {
  float: left;
  width: 100%;
  clear: both;
}

.media_grid_bottom_block .bottom_block_item {
  float: left;
  width: 290px;
  height: 100px;
  margin-right: 40px;
}

.media_grid_bottom_block .bottom_block_item:last-child {
  margin-right: 0;
}

.media_grid_bottom_block .first_column_lajme {
  float: left;
  width: 100%;
  height: 100%;
  background: #11ad8e;
  position: relative;
}

.media_grid_bottom_block .first_column_lajme .lajme_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 38px;
  width: 41px;
  width: 2.5625rem;
  height: 28px;
  height: 1.75rem;
}

.media_grid_bottom_block .first_column_lajme .lajme_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100px;
}

.media_grid_bottom_block .first_column_lajme .lajme_text .lajme_text_heading {
  font-size: 18px;
  font-size: 1.125rem;
  color: white;
  font-weight: 700;
  text-decoration: none;
}

.media_grid_bottom_block .first_column_lajme .lajme_text p {
  font-size: 14px;
  font-size: 0.875rem;
  color: white;
  opacity: .5;
  margin-top: 6px;
  margin-top: 0.375rem;
}

.media_grid_bottom_block .second_column_intervistat {
  float: left;
  width: 100%;
  height: 100%;
  background: #e2001a;
  position: relative;
}

.media_grid_bottom_block .second_column_intervistat .lajme_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 38px;
  width: 38px;
  width: 2.375rem;
  height: 34px;
  height: 2.125rem;
}

.media_grid_bottom_block .second_column_intervistat .lajme_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100px;
}

.media_grid_bottom_block .second_column_intervistat .lajme_text .lajme_text_heading {
  font-size: 18px;
  font-size: 1.125rem;
  color: white;
  font-weight: 700;
  text-decoration: none;
}

.media_grid_bottom_block .second_column_intervistat .lajme_text p {
  font-size: 14px;
  font-size: 0.875rem;
  color: white;
  opacity: .5;
  margin-top: 6px;
  margin-top: 0.375rem;
}

.media_grid_bottom_block .second_column_qendrat {
  float: left;
  width: 100%;
  height: 100%;
  background: #231f20;
  position: relative;
}

.media_grid_bottom_block .second_column_qendrat .lajme_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 38px;
  width: 38px;
  width: 2.375rem;
  height: 34px;
  height: 2.125rem;
}

.media_grid_bottom_block .second_column_qendrat .lajme_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100px;
}

.media_grid_bottom_block .second_column_qendrat .lajme_text .lajme_text_heading {
  font-size: 18px;
  font-size: 1.125rem;
  color: white;
  font-weight: 700;
  text-decoration: none;
}

.media_grid_bottom_block .second_column_qendrat .lajme_text p {
  font-size: 14px;
  font-size: 0.875rem;
  color: white;
  opacity: .5;
  margin-top: 6px;
  margin-top: 0.375rem;
}

.media_grid_bottom_block .third_column_youtube {
  float: left;
  width: 100%;
  height: 100%;
  background: #666666;
  position: relative;
}

.media_grid_bottom_block .third_column_youtube .lajme_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 38px;
  width: 33px;
  width: 2.0625rem;
  height: 37px;
  height: 2.3125rem;
}

.media_grid_bottom_block .third_column_youtube .lajme_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100px;
}

.media_grid_bottom_block .third_column_youtube .lajme_text .lajme_text_heading {
  font-size: 18px;
  font-size: 1.125rem;
  color: white;
  font-weight: 700;
  text-decoration: none;
}

.media_grid_bottom_block .third_column_youtube .lajme_text p {
  font-size: 14px;
  font-size: 0.875rem;
  color: white;
  opacity: .5;
  margin-top: 6px;
  margin-top: 0.375rem;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .media_grid_bottom_block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .media_grid_bottom_block .bottom_block_item {
    margin-right: 0;
    width: calc(50% - 20px);
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .media_grid_bottom_block {
    float: left;
    width: calc(100% - 290px);
    clear: none;
    margin-top: 40px;
  }
  .media_grid_bottom_block .bottom_block_item {
    margin-bottom: 40px;
  }
}

.gray_banner {
  float: left;
  width: 100%;
  height: 308px;
  height: 19.25rem;
  position: relative;
  background-image: url("../images/gray_banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 87px;
  padding-top: 5.4375rem;
}

.gray_banner .abonohu_text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gray_banner .abonohu_text span, .gray_banner .abonohu_text h2, .gray_banner .abonohu_text p {
  display: inline-block;
}

.gray_banner .abonohu_text span {
  content: " ";
  background-image: url("../images/dash.svg");
  height: 5px;
  height: 0.3125rem;
  width: 20px;
  width: 1.25rem;
}

.gray_banner .abonohu_text h2 {
  font-size: 27px;
  font-size: 1.6875rem;
  font-weight: 700;
  color: white;
  margin-left: 29px;
  margin-left: 1.8125rem;
}

.gray_banner .abonohu_text p {
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 300;
  color: white;
  margin-left: 50px;
  margin-left: 3.125rem;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .gray_banner .abonohu_text {
    width: 100%;
    display: inline-block;
  }
  .gray_banner .abonohu_text p {
    float: left;
    width: 90%;
    margin-top: 30px;
  }
  .gray_banner .abonohu_text h2 {
    margin-left: 48px;
  }
  .gray_banner .abonohu_text span {
    position: absolute;
    top: 10px;
    margin-right: 20px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .gray_banner .abonohu_text {
    width: 100%;
    display: inline-block;
  }
  .gray_banner .abonohu_text p {
    float: left;
    width: 90%;
    margin-top: 30px;
  }
  .gray_banner .abonohu_text h2 {
    margin-left: 48px;
  }
  .gray_banner .abonohu_text span {
    position: absolute;
    top: 10px;
    margin-right: 20px;
  }
}

.gray_banner #register_form, .gray_banner .register_form {
  float: left;
  width: 100%;
  margin-top: 59px;
  margin-top: 3.6875rem;
}

.gray_banner #register_form form, .gray_banner .register_form form {
  width: 100%;
}

.gray_banner #register_form form .mc4wp-form-fields, .gray_banner .register_form form .mc4wp-form-fields {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.gray_banner #register_form input, .gray_banner #register_form .selectdiv, .gray_banner #register_form button, .gray_banner .register_form input, .gray_banner .register_form .selectdiv, .gray_banner .register_form button {
  float: left;
  height: 52px;
  height: 3.25rem;
  width: 289px;
  width: 18.0625rem;
  font-size: 15px;
  border: none;
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .gray_banner #register_form input, .gray_banner #register_form .selectdiv, .gray_banner #register_form button, .gray_banner .register_form input, .gray_banner .register_form .selectdiv, .gray_banner .register_form button {
    width: calc(25% - 20px);
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .gray_banner #register_form input, .gray_banner #register_form .selectdiv, .gray_banner #register_form button, .gray_banner .register_form input, .gray_banner .register_form .selectdiv, .gray_banner .register_form button {
    width: 48%;
    margin: 0;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .gray_banner #register_form input, .gray_banner #register_form .selectdiv, .gray_banner #register_form button, .gray_banner .register_form input, .gray_banner .register_form .selectdiv, .gray_banner .register_form button {
    width: 48%;
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .gray_banner #register_form input, .gray_banner #register_form .selectdiv, .gray_banner #register_form button, .gray_banner .register_form input, .gray_banner .register_form .selectdiv, .gray_banner .register_form button {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.gray_banner #register_form select, .gray_banner .register_form select {
  padding-right: 25px;
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .gray_banner #register_form input, .gray_banner .register_form input {
    padding-right: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .gray_banner #register_form input, .gray_banner .register_form input {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .gray_banner #register_form input, .gray_banner .register_form input {
    margin-bottom: 30px;
  }
}

.gray_banner #register_form input:focus,
.gray_banner #register_form button:focus, .gray_banner .register_form input:focus,
.gray_banner .register_form button:focus {
  outline: none !important;
}

.gray_banner #register_form input, .gray_banner #register_form select, .gray_banner .register_form input, .gray_banner .register_form select {
  margin-right: 37px;
  margin-right: 2.3125rem;
  padding-left: 25px;
  padding-left: 1.5625rem;
  background: #ddd;
  color: #666666;
  border-radius: 2px;
}

.gray_banner #register_form input::placeholder, .gray_banner .register_form input::placeholder {
  color: #666666;
}

.gray_banner #register_form input:focus::-webkit-input-placeholder, .gray_banner .register_form input:focus::-webkit-input-placeholder {
  color: transparent;
}

.gray_banner #register_form .selectdiv, .gray_banner .register_form .selectdiv {
  position: relative;
  margin-right: 37px;
  margin-right: 2.3125rem;
}

.gray_banner #register_form .selectdiv:after, .gray_banner .register_form .selectdiv:after {
  content: ' ';
  background-image: url("../images/select_down_arrow.svg");
  right: 20px;
  top: 20px;
  width: 10px;
  width: 0.625rem;
  height: 6px;
  height: 0.375rem;
  position: absolute;
  pointer-events: none;
}

.gray_banner #register_form select::-ms-expand, .gray_banner .register_form select::-ms-expand {
  display: none;
}

.gray_banner #register_form .selectdiv select, .gray_banner .register_form .selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */
  display: block;
  width: 100%;
  height: 52px;
  height: 3.25rem;
  margin: 0;
  margin: 0;
  padding: 0 24px;
  padding: 0 1.5rem;
  font-size: 15px;
  font-size: 0.9375rem;
  color: #666666;
  border: none;
  -ms-word-break: normal;
  word-break: normal;
}

.gray_banner #register_form input[type="submit"], .gray_banner .register_form input[type="submit"] {
  float: left;
  background: #e2001a;
  border: none;
  color: #fff;
  opacity: .8;
  font-weight: 700;
  cursor: pointer;
  margin-right: 0;
  margin-left: auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .gray_banner #register_form, .gray_banner .register_form {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .gray_banner #register_form, .gray_banner .register_form {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 767px) {
  .gray_banner #register_form, .gray_banner .register_form {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .gray_banner {
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .gray_banner {
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .gray_banner #register_form, .gray_banner .regiser_form {
    width: 100%;
  }
  .gray_banner #register_form input, .gray_banner .regiser_form input {
    width: 23%;
    margin-right: 2.5%;
  }
  .gray_banner #register_form input[type="submit"], .gray_banner .regiser_form input[type="submit"] {
    width: 23%;
  }
  .gray_banner #register_form .selectdiv, .gray_banner .regiser_form .selectdiv {
    width: 23%;
    margin-right: 2.5%;
  }
}

@media only screen and (max-width: 767px) {
  .gray_banner {
    float: left;
    width: 100%;
    height: auto;
    position: relative;
    background-image: url("../images/gray_banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 87px;
    padding-top: 5.4375rem;
    display: block !important;
    padding: 30px 0;
    height: auto;
  }
  .gray_banner .abonohu_text {
    float: left;
    width: 100%;
    color: #fff;
    flex-wrap: wrap;
  }
  .gray_banner .abonohu_text span {
    display: none;
  }
  .gray_banner .abonohu_text h2 {
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 24px;
    margin-bottom: 30px;
  }
  .gray_banner .abonohu_text h2:before {
    content: " ";
    background-image: url("../images/dash.svg");
    height: 5px;
    height: 0.3125rem;
    width: 20px;
    width: 1.25rem;
    display: block;
    position: absolute;
    top: 9px;
    left: 75px;
  }
  .gray_banner .abonohu_text p {
    margin: 0;
    text-align: center;
    padding: 0 15px;
    font-size: 14px;
    line-height: 20px;
  }
}

.prioritetet_rep_headline {
  float: left;
  width: 100%;
  clear: both;
  margin-top: 20px;
  margin-top: 1.25rem;
  margin-bottom: 76px;
  margin-bottom: 4.75rem;
  text-align: center;
  color: #000;
}

.prioritetet_rep_headline p {
  float: left;
  width: 100%;
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 7px;
  margin-bottom: 0.4375rem;
}

.prioritetet_rep_headline span {
  float: left;
  width: 100%;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .prioritetet_rep_headline {
    margin-bottom: 40px;
  }
  .prioritetet_rep_headline p {
    padding: 0 17px;
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 0;
  }
  .prioritetet_rep_headline span {
    font-size: 14px;
  }
}

.prioritetet_rep_tab {
  float: left;
  width: 100%;
  clear: both;
}

.prioritetet_rep_tab .prioritetet_item {
  float: left;
  width: 100%;
  height: 290px;
  height: 18.125rem;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
  border: 1px solid #e6e6e6;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  overflow: hidden;
}

.prioritetet_rep_tab .prioritetet_item a {
  text-decoration: none;
  text-align: center;
}

.prioritetet_rep_tab .prioritetet_item a .prioritetet_item_logo {
  float: left;
  width: 100%;
  padding-top: 57px;
  padding-top: 3.5625rem;
  text-align: center;
  height: 165px;
  height: 10.3125rem;
}

.prioritetet_rep_tab .prioritetet_item a .prioritetet_item_title {
  float: left;
  width: 100%;
  padding-left: 50px;
  padding-left: 3.125rem;
  padding-right: 50px;
  padding-right: 3.125rem;
  text-align: center;
  padding-bottom: 57px;
  padding-bottom: 3.5625rem;
}

.prioritetet_rep_tab .prioritetet_item a .prioritetet_item_title p, .prioritetet_rep_tab .prioritetet_item a .prioritetet_item_title span {
  font-size: 18px;
  font-size: 1.125rem;
  color: #231f20;
  text-align: center;
}

.prioritetet_rep_tab .prioritetet_item a .prioritetet_item_title p {
  margin-bottom: 5px;
  margin-bottom: 0.3125rem;
  max-height: 96px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .prioritetet_rep_tab .prioritetet_item a .prioritetet_item_title {
    padding-bottom: 47px !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .prioritetet_rep_tab .prioritetet_item a .prioritetet_item_title {
    padding-bottom: 47px !important;
  }
}

.prioritetet_rep_tab .prioritetet_item:hover {
  -webkit-box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.1);
}

.prioritetet_rep_tab .prioritetet_item:hover p, .prioritetet_rep_tab .prioritetet_item:hover span {
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  color: #e2001a !important;
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .prioritetet_rep_tab .prioritetet_item {
    height: auto;
  }
  .prioritetet_rep_tab .prioritetet_item .prioritetet_item_title {
    padding: 30px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .prioritetet_rep_tab .column-3 {
    width: 50%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .prioritetet_rep_tab .column-3 {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .prioritetet_rep_tab .column-3 {
    width: 100%;
  }
  .prioritetet_rep_tab .column-3:nth-of-type(1n+4) {
    display: none;
  }
}

.prioritetet_lexo_me_shume {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-top: 1.875rem;
  margin-bottom: 108px;
  margin-bottom: 6.75rem;
}

.prioritetet_lexo_me_shume a {
  font-size: 16px;
  font-weight: 300;
  color: #666;
  text-decoration: none;
}

.prioritetet_lexo_me_shume a .read_more_arrow {
  content: " ";
  background-image: url("../images/lexomeshume.svg");
  height: 8px;
  height: .5rem;
  width: 14px;
  width: .875rem;
  margin-left: 22px;
  margin-left: 1.375rem;
  display: inline-block;
}

.prioritetet_lexo_me_shume a:hover {
  color: #e2001a;
}

.prioritetet_lexo_me_shume a:hover .read_more_arrow {
  content: " ";
  background-image: url("../images/lexomeshume-red.svg");
  height: 8px;
  height: .5rem;
  width: 14px;
  width: .875rem;
  margin-left: 22px;
  margin-left: 1.375rem;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .prioritetet_lexo_me_shume {
    margin-bottom: 70px;
  }
  .prioritetet_lexo_me_shume a {
    color: #e2001a;
  }
  .prioritetet_lexo_me_shume a .read_more_arrow {
    content: " ";
    background-image: url("../images/lexomeshume-red.svg");
    height: 8px;
    height: .5rem;
    width: 14px;
    width: .875rem;
    margin-left: 22px;
    margin-left: 1.375rem;
    display: inline-block;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .prioritetet_lexo_me_shume {
    margin-bottom: 70px;
  }
  .prioritetet_lexo_me_shume a {
    color: #e2001a;
  }
  .prioritetet_lexo_me_shume a .read_more_arrow {
    content: " ";
    background-image: url("../images/lexomeshume-red.svg");
    height: 8px;
    height: .5rem;
    width: 14px;
    width: .875rem;
    margin-left: 22px;
    margin-left: 1.375rem;
    display: inline-block;
  }
}

@media only screen and (max-width: 767px) {
  .prioritetet_lexo_me_shume {
    margin-top: 0;
    margin-bottom: 45px;
  }
  .prioritetet_lexo_me_shume a {
    color: #e2001a;
    font-size: 15px;
  }
  .prioritetet_lexo_me_shume a .read_more_arrow {
    content: " ";
    background-image: url("../images/lexomeshume-red.svg");
    height: 8px;
    height: .5rem;
    width: 14px;
    width: .875rem;
    margin-left: 22px;
    margin-left: 1.375rem;
    display: inline-block;
  }
}

.kand_komunes_item {
  float: left;
  width: 100%;
  height: 400px;
  height: 25rem;
  border: 1px solid #e6e6e6;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
  position: relative;
}

.kand_komunes_item .deputeti_img {
  width: 100%;
  height: 290px;
  height: 18.125rem;
}

.kand_komunes_item .gr_parl_kryetari {
  width: 200px;
  position: absolute;
  bottom: 94px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  text-align: center;
  color: #fff;
  background: #27ba9e;
  padding: 10px 20px;
  text-decoration: none;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .kand_komunes_item .gr_parl_kryetari {
    bottom: 23%;
    left: 50%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .kand_komunes_item .gr_parl_kryetari {
    bottom: 23%;
    left: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .kand_komunes_item .gr_parl_kryetari {
    top: 68%;
    left: 50%;
  }
}

.kand_komunes_item .deputeti_white {
  float: left;
  width: 100%;
  height: 111px;
  height: 6.9375rem;
  position: relative;
  padding: 15px;
  padding: 0.9375rem;
}

.kand_komunes_item .deputeti_white .deputeti_info {
  float: left;
  padding: 0;
  padding: 0;
  margin-top: 10px;
}

.kand_komunes_item .deputeti_white .deputeti_info a {
  color: #231f20;
  text-decoration: none;
}

.kand_komunes_item .deputeti_white .deputeti_info a h2 {
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 5px;
  margin-bottom: 0.3125rem;
}

.kand_komunes_item .deputeti_white .deputeti_info a h1 {
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
}

.kand_komunes_item .deputeti_white .deputeti_info a span {
  font-size: 21px;
  font-size: 1.3125rem;
  font-weight: 700;
}

.kand_komunes_item .deputeti_white .deputeti_social_icons {
  position: absolute;
  right: 27px;
  bottom: 40px;
}

.kand_komunes_item .deputeti_white .deputeti_social_icons a {
  color: #666666;
}

.kand_komunes_item .deputeti_white .deputeti_social_icons a .fa-twitter {
  margin-left: 22px;
  margin-left: 1.375rem;
  font-size: 21px;
  font-size: 1.3125rem;
}

.kand_komunes_item .deputeti_white .deputeti_social_icons a .fa-twitter:hover {
  color: #e2001a;
  cursor: pointer;
}

.kand_komunes_item .deputeti_white .deputeti_social_icons a .fa-facebook-f {
  font-size: 21px;
  font-size: 1.3125rem;
}

.kand_komunes_item .deputeti_white .deputeti_social_icons a .fa-facebook-f:hover {
  color: #e2001a;
  cursor: pointer;
}

.kand_komunes_item:hover {
  box-shadow: 0 0 56px 0 rgba(0, 0, 0, 0.1);
}

.kand_komunes_item:hover .deputeti_info h1, .kand_komunes_item:hover .deputeti_info h2, .kand_komunes_item:hover .deputeti_info span {
  color: #e2001a;
}

.zotimet_margine_top {
  margin-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .zotimet_margine_top .column-3 {
    width: 50%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .zotimet_margine_top .column-3 {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .zotimet_margine_top .column-3 {
    width: 100%;
  }
}

.zotimet_item {
  float: left;
  width: 100%;
  clear: both;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
  height: 327px;
}

.zotimet_item a {
  text-decoration: none;
}

.zotimet_item a .zotimi_img {
  float: left;
  width: 100%;
  height: 228px;
  height: 14.25rem;
}

.zotimet_item a .zotimi_img img {
  opacity: 0.8;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.zotimet_item a .zotimi_data {
  float: left;
  width: 100%;
  margin-top: 28px;
  margin-top: 1.75rem;
  margin-bottom: 17px;
  margin-bottom: 1.0625rem;
}

.zotimet_item a .zotimi_data p {
  font-size: 13px;
  font-size: 0.8125rem;
  color: #666666;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.zotimet_item a .zotimi_data p span {
  margin: 0 10px;
  margin: 0 0.625rem;
}

.zotimet_item a .media_description {
  float: left;
  width: 100%;
}

.zotimet_item a .media_description h2 {
  width: 100%;
  color: #231f20;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  line-height: 21px;
  white-space: wrap;
  max-height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.zotimet_item a:hover p, .zotimet_item a:hover span, .zotimet_item a:hover h2 {
  color: #e2001a;
}

.zotimet_item a:hover .zotimi_img img {
  opacity: 1;
}

.facebook_levizja_twitter {
  float: left;
  width: 100%;
  background: #f2f2f2;
  padding-top: 135px;
  padding-top: 8.4375rem;
  padding-bottom: 139px;
  padding-bottom: 8.6875rem;
}

.facebook_levizja_twitter .flt_heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
  margin-bottom: 2.1875rem;
}

.facebook_levizja_twitter .flt_heading h3 {
  font-size: 27px;
  font-size: 1.6875rem;
  font-weight: 700;
  margin-left: 17px;
  margin-left: 1.0625rem;
  text-transform: uppercase;
}

.facebook_levizja_twitter .flt_heading span {
  content: " ";
  background-image: url("../images/dash.svg");
  height: 5px;
  height: 0.3125rem;
  width: 20px;
  width: 1.25rem;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .facebook_levizja_twitter .facebook_column_tablet {
    display: block !important;
    width: 50%;
    margin-right: 0;
    padding-right: 15px;
    display: block;
    float: left;
  }
  .facebook_levizja_twitter .facebook_column_tablet iframe {
    -webkit-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
    box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .facebook_levizja_twitter .facebook_column_tablet {
    width: 100%;
    display: block !important;
    width: 50%;
    margin-right: 0;
    padding-right: 15px;
    display: block;
    float: left;
  }
  .facebook_levizja_twitter .facebook_column_tablet iframe {
    -webkit-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
    box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
  }
}

.facebook_levizja_twitter .facebook_column {
  float: left;
  width: 290px;
  width: 18.125rem;
  margin-right: 40px;
  margin-right: 2.5rem;
}

.facebook_levizja_twitter .facebook_column iframe {
  -webkit-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .facebook_levizja_twitter .facebook_column {
    display: none !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .facebook_levizja_twitter .facebook_column {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .facebook_levizja_twitter .facebook_column {
    margin-right: 2%;
  }
}

.facebook_levizja_twitter .levizja_column {
  float: left;
  width: 620px;
  width: 38.75rem;
}

.facebook_levizja_twitter .levizja_column .kos_map_dropdown {
  width: 100%;
  float: left;
  height: 370px;
  height: 23.125rem;
  background: white;
  position: relative;
  -webkit-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
}

.facebook_levizja_twitter .levizja_column .kos_map_img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 253px;
  width: 15.8125rem;
  height: 280px;
  height: 17.5rem;
  margin-left: 49px;
  margin-left: 3.0625rem;
  margin-right: 32px;
  margin-right: 2rem;
}

.facebook_levizja_twitter .levizja_column .kos_dropdown {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  width: 40%;
}

.facebook_levizja_twitter .levizja_column .kos_dropdown li {
  padding-bottom: 19px;
  padding-bottom: 1.1875rem;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 18px;
  margin-bottom: 1.125rem;
}

.facebook_levizja_twitter .levizja_column .kos_dropdown li .ks_links {
  text-decoration: none;
  color: #231f20;
  font-size: 16px;
  font-size: 1rem;
}

.facebook_levizja_twitter .levizja_column .kos_dropdown li:last-child {
  margin-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.facebook_levizja_twitter .levizja_column .kos_dropdown select {
  background: #fff;
  border: none;
  border-radius: 4px;
  width: 90%;
  padding: 20px;
  font-size: 16px;
  color: #3F3F3F;
  margin-bottom: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.facebook_levizja_twitter .levizja_column .kos_dropdown select:active {
  outline: none;
  border: none;
}

.facebook_levizja_twitter .levizja_column .kos_dropdown select:focus {
  outline: 0;
}

.facebook_levizja_twitter .levizja_column .kos_dropdown .select-wrapper {
  position: relative;
}

.facebook_levizja_twitter .levizja_column .kos_dropdown .select-wrapper:before {
  content: " ";
  background-image: url("../images/dash.svg");
  height: 5px;
  height: 0.3125rem;
  width: 19px;
  width: 1.1875rem;
  display: inline-block;
  margin-bottom: 2px;
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .facebook_levizja_twitter .levizja_column .kos_dropdown .select-wrapper:before {
    content: " ";
    background-image: url(../images/dash.svg);
    height: 5px;
    width: 19px;
    display: block;
    position: absolute;
    z-index: 33;
    top: 26px;
  }
}

.facebook_levizja_twitter .levizja_column .kos_dropdown .select-wrapper:after {
  content: '\f107';
  font-family: 'Font Awesome 5 Pro';
  font-size: 20px;
  position: absolute;
  top: 19px;
  right: 20px;
  color: #e2001a;
  pointer-events: none;
}

.facebook_levizja_twitter .levizja_column .kos_dropdown select::-ms-expand {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .facebook_levizja_twitter .levizja_column {
    width: 100%;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .facebook_levizja_twitter .levizja_column {
    width: 100%;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .facebook_levizja_twitter .levizja_column {
    width: 100%;
    height: auto;
    margin-top: 50px;
  }
  .facebook_levizja_twitter .levizja_column .kos_map_dropdown {
    height: auto;
    padding: 30px;
  }
  .facebook_levizja_twitter .levizja_column .kos_map_dropdown .kos_map_img {
    all: unset;
  }
  .facebook_levizja_twitter .levizja_column .kos_map_dropdown .kos_dropdown {
    all: unset;
  }
  .facebook_levizja_twitter .levizja_column .kos_map_dropdown .kos_dropdown .select_city {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .facebook_levizja_twitter .levizja_column .kos_map_dropdown .kos_dropdown .select_city::after {
    right: 0px;
  }
  .facebook_levizja_twitter .levizja_column .kos_map_dropdown .kos_dropdown ul {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .facebook_levizja_twitter .levizja_column {
    width: 554px;
  }
  .facebook_levizja_twitter .levizja_column .kos_map_dropdown .kos_map_img {
    width: 44%;
    top: 55%;
    margin-left: 15px;
    margin-left: 20px;
  }
  .facebook_levizja_twitter .levizja_column .kos_map_dropdown .kos_dropdown .select-wrapper {
    position: relative;
  }
  .facebook_levizja_twitter .levizja_column .kos_map_dropdown .kos_dropdown .select-wrapper select {
    padding-left: 30px !important;
  }
  .facebook_levizja_twitter .levizja_column .kos_map_dropdown .kos_dropdown .select-wrapper select:before {
    width: 15px !important;
    position: absolute !important;
    top: 26px !important;
  }
  .facebook_levizja_twitter .levizja_column .kos_map_dropdown .kos_dropdown .select-wrapper select:after {
    top: 34px;
  }
  .facebook_levizja_twitter .levizja_column .kos_map_dropdown .kos_dropdown ul li a {
    font-size: 14px;
  }
}

.facebook_levizja_twitter .twitter_column {
  float: right;
  width: 290px;
  width: 18.125rem;
  margin-left: 40px;
  margin-left: 2.5rem;
}

.facebook_levizja_twitter .twitter_column .twitter-timeline {
  -webkit-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 0px 66px -6px rgba(0, 0, 0, 0.27);
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .facebook_levizja_twitter .twitter_column {
    display: block;
    width: 50%;
    margin-left: 0;
    padding-left: 13px;
  }
  .facebook_levizja_twitter .twitter_column iframe {
    width: 100% !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .facebook_levizja_twitter .twitter_column {
    display: block;
    width: 50%;
    margin-left: 0;
    padding-left: 13px;
  }
  .facebook_levizja_twitter .twitter_column iframe {
    width: 100% !important;
  }
}

@media only screen and (max-width: 767px) {
  .facebook_levizja_twitter .twitter_column {
    margin-left: 0;
    width: 100%;
    margin-top: 50px;
  }
  .facebook_levizja_twitter .twitter_column iframe {
    width: 100% !important;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .facebook_levizja_twitter .twitter_column {
    margin-left: 2%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .facebook_levizja_twitter {
    padding-top: 35px;
    padding-bottom: 45px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .facebook_levizja_twitter {
    padding-top: 35px;
    padding-bottom: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .facebook_levizja_twitter {
    padding-top: 40px;
    padding-bottom: 60px;
  }
}

.grupi_parlamentar_banner {
  background-image: url("../images/gr_parl_banner.png");
  height: 250px;
  height: 15.625rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.grupi_parlamentar_banner .height_container {
  height: 250px;
  height: 15.625rem;
}

.grupi_parlamentar_banner .banner_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.grupi_parlamentar_banner .banner_text .gr_parl_banner_title {
  font-size: 36px;
  font-size: 2.25rem;
  color: #fff;
  font-weight: 700;
}

.grupi_parlamentar_banner .banner_text .gr_parl_banner_subtitle {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 11px;
  margin-top: 0.6875rem;
  color: #fff;
}

.grupi_parlamentar_banner .banner_text .gr_parl_banner_subtitle p, .grupi_parlamentar_banner .banner_text .gr_parl_banner_subtitle span {
  float: left;
  opacity: 0.5;
}

.grupi_parlamentar_banner .banner_text .gr_parl_banner_subtitle span:before {
  content: "  ";
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .grupi_parlamentar_banner {
    height: 200px;
  }
  .grupi_parlamentar_banner .height_container {
    height: 200px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .grupi_parlamentar_banner {
    height: 200px;
  }
  .grupi_parlamentar_banner .height_container {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .grupi_parlamentar_banner {
    height: 120px;
  }
  .grupi_parlamentar_banner .height_container {
    height: 120px;
  }
  .grupi_parlamentar_banner .height_container .banner_text h1 {
    font-size: 23px;
  }
}

.background_deputetet {
  float: left;
  width: 100%;
  background: #fff;
}

@media only screen and (max-width: 767px) {
  .background_deputetet .deputetet_heading {
    margin-bottom: 10px;
    padding-top: 14px;
  }
  .background_deputetet .deputetet_heading h2 {
    font-size: 23px;
    margin-left: 25px;
  }
}

.deputetet_heading {
  padding-top: 59px;
  padding-top: 3.6875rem;
  margin-bottom: 48px;
  margin-bottom: 3rem;
  height: 86px;
  height: 5.375rem;
  display: flex;
  align-items: center;
}

.deputetet_heading span {
  content: " ";
  background-image: url("../images/dash.svg");
  height: 5px;
  height: 0.3125rem;
  width: 20px;
  width: 1.25rem;
}

.deputetet_heading h2 {
  font-size: 27px;
  font-size: 1.6875rem;
  font-weight: 700;
  color: #231f20;
  text-transform: uppercase;
  margin-left: 29px;
  margin-left: 1.8125rem;
}

.deputetet_heading ul {
  margin-left: auto;
}

.deputetet_heading ul li {
  display: inline-block;
  margin-right: 15px;
  margin-right: 0.9375rem;
}

.deputetet_heading ul li a {
  text-decoration: none;
  font-size: 13px;
  font-size: 0.8125rem;
  color: #666666;
}

.deputetet_heading ul li a:hover {
  color: #e2001a;
  cursor: pointer;
}

.deputetet_heading ul li .pdf_icon:after {
  content: "\f1c1";
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-size: 1rem;
  padding-left: 10px;
  padding-left: 0.625rem;
}

.deputetet_heading ul li .pdf_icon:after:hover {
  color: #e2001a;
  cursor: pointer;
}

.deputetet_heading ul li .print_icon:after {
  content: "\f02f";
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-size: 1rem;
  padding-left: 10px;
  padding-left: 0.625rem;
}

.deputetet_heading ul li .print_icon:after:hover {
  color: #e2001a;
  cursor: pointer;
}

.deputetet_heading ul li .social_icons {
  font-size: 14px;
  font-size: 0.875rem;
}

.deputetet_heading ul li .social_icons:hover {
  color: #e2001a;
  cursor: pointer;
}

.deputetet_heading ul li:last-child {
  margin-right: 0;
  margin-right: 0;
}

.deputetet_heading ul li:nth-child(3) a {
  cursor: default;
}

.deputetet_heading ul li:nth-child(3) a:hover {
  color: #666666;
}

.deputetet_heading #see_all {
  color: #666;
  text-decoration: none;
  margin-left: auto;
}

.deputetet_heading #see_all span {
  content: " ";
  background-image: url("../images/lexomeshume.svg");
  height: 8px;
  height: 0.5rem;
  width: 14px;
  width: 0.875rem;
  display: block;
  float: right;
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-top: 3px;
  margin-top: 0.1875rem;
}

@media (max-width: 320px) {
  .deputetet_heading #see_all span {
    margin-left: 10px;
  }
}

.deputetet_heading #see_all:hover {
  color: #e2001a;
}

.deputetet_heading #see_all:hover span {
  content: " ";
  background-image: url("../images/lexomeshume-red.svg");
  height: 8px;
  height: 0.5rem;
  width: 14px;
  width: 0.875rem;
  margin-left: 22px;
  margin-left: 1.375rem;
  display: block;
  float: right;
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-top: 3px;
  margin-top: 0.1875rem;
}

@media (max-width: 320px) {
  .deputetet_heading #see_all {
    font-size: 12px;
  }
}

.deputetet_grid {
  margin-bottom: 90px;
  margin-bottom: 5.625rem;
}

.deputetet_grid ul {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.deputetet_grid ul > :nth-child(4n+4) {
  margin-right: 0;
  margin-right: 0;
}

.group_gutter .row {
  margin-left: -20px;
  margin-right: -20px;
}

.group_gutter [class*=column-] {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 1200px) {
  .column-fifth {
    width: 25%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .column-fifth:nth-child(4n+1) {
    clear: left;
  }
}

.deputeti_block {
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
  flex-grow: 0;
  flex-shrink: 0;
  border: 1px solid #e6e6e6;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .deputeti_block {
    margin-right: 0;
    margin-right: 0;
    width: 100%;
  }
}

.deputeti_block .deputeti_img {
  width: 100%;
  height: 290px;
  height: 18.125rem;
  position: relative;
  z-index: 3;
}

.deputeti_block .deputeti_img:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: #e2001a;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.deputeti_block .gr_parl_kryetari {
  width: 200px;
  width: 12.5rem;
  position: absolute;
  bottom: -15px;
  left: 50%;
  font-size: 13px;
  font-size: 0.8125rem;
  text-align: center;
  color: white;
  background: #27ba9e;
  padding: 9px 20px 8px 20px;
  padding: 0.5625rem 1.25rem 0.5rem 1.25rem;
  text-decoration: none;
  z-index: 2;
  transform: translateX(-50%);
  /* opacity: 0;
      transform:translateX(-50%) translateY(10px); */
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.deputeti_block .kandidati_nr {
  width: 50px;
  width: 3.125rem;
  height: 50px;
  height: 3.125rem;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 24px;
  font-size: 1.5rem;
  text-align: center;
  color: white;
  background: #e2001a;
  text-decoration: none;
  padding: 13px 0;
  padding: 0.8125rem 0;
  z-index: 3;
}

.deputeti_block .deputeti_white {
  float: left;
  width: 100%;
  height: auto;
  padding-left: 30px;
  padding-left: 1.875rem;
  padding-right: 80px;
  padding-right: 5rem;
  padding-top: 26px;
  padding-top: 1.625rem;
  padding-bottom: 25px;
  padding-bottom: 1.5625rem;
  position: relative;
}

.deputeti_block .deputeti_white .deputeti_info {
  float: left;
}

.deputeti_block .deputeti_white .deputeti_info a {
  text-decoration: none;
}

.deputeti_block .deputeti_white .deputeti_info h2 {
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.15em;
  word-wrap: break-word;
  color: #231f20;
}

.deputeti_block .deputeti_white .deputeti_social_icons {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
}

.deputeti_block .deputeti_white .deputeti_social_icons a {
  color: #666666;
  font-size: 16px;
}

.deputeti_block .deputeti_white .deputeti_social_icons a .fa-twitter {
  margin-left: 22px;
  margin-left: 1.375rem;
}

.deputeti_block .deputeti_white .deputeti_social_icons a .fa-twitter:hover {
  color: #e2001a;
  cursor: pointer;
}

.deputeti_block .deputeti_white .deputeti_social_icons a .fa-facebook-f:hover {
  color: #e2001a;
  cursor: pointer;
}

.deputeti_block:hover {
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  -webkit-box-shadow: 0px 0px 41px 10px #d6d3d6;
  -moz-box-shadow: 0px 0px 41px 10px #d6d3d6;
  box-shadow: 0px 0px 41px 10px #d6d3d6;
}

.deputeti_block:hover .gr_parl_kryetari {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

.deputeti_block:hover .deputeti_img:after {
  opacity: 1;
}

.deputeti_block:hover .deputeti_white {
  background: #fff;
}

.deputeti_block:hover .deputeti_white .deputeti_info h1,
.deputeti_block:hover .deputeti_white .deputeti_info h2 {
  color: #e2001a;
  cursor: pointer;
}

.dekonstruktime_banner {
  background-image: url("../images/dekonstruktime_banner.png");
  height: 250px;
  height: 15.625rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.dekonstruktime_banner .height_container {
  height: 250px;
  height: 15.625rem;
}

.dekonstruktime_banner .banner_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.dekonstruktime_banner .banner_text .gr_parl_banner_title {
  font-size: 36px;
  font-size: 2.25rem;
  color: #fff;
  font-weight: 700;
}

.dekonstruktime_banner .banner_text .gr_parl_banner_subtitle {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 11px;
  margin-top: 0.6875rem;
  color: #fff;
}

.dekonstruktime_banner .banner_text .gr_parl_banner_subtitle p, .dekonstruktime_banner .banner_text .gr_parl_banner_subtitle span {
  float: left;
  opacity: 0.5;
}

.dekonstruktime_banner .banner_text .gr_parl_banner_subtitle span:before {
  content: "  ";
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .dekonstruktime_banner {
    height: 200px;
  }
  .dekonstruktime_banner .height_container {
    height: 200px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .dekonstruktime_banner {
    height: 200px;
  }
  .dekonstruktime_banner .height_container {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .dekonstruktime_banner {
    height: 120px;
  }
  .dekonstruktime_banner .height_container {
    height: 120px;
  }
  .dekonstruktime_banner .height_container .banner_text h1 {
    font-size: 23px;
  }
}

.dekonstruktime_accordion {
  margin-bottom: 100px;
  margin-bottom: 6.25rem;
}

.dekonstruktime_accordion a {
  text-decoration: none;
}

.dekonstruktime_accordion .accordion {
  -webkit-box-shadow: 0px 0px 41px 10px #d6d3d6;
  -moz-box-shadow: 0px 0px 41px 10px #d6d3d6;
  box-shadow: 0px 0px 41px 10px #d6d3d6;
  list-style: none;
  padding: 0;
  padding: 0;
}

.dekonstruktime_accordion .accordion li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.dekonstruktime_accordion .accordion li.is--active .toggle {
  color: #e2001a;
}

.dekonstruktime_accordion .accordion li.is--active .toggle span {
  background: #e2001a;
}

.dekonstruktime_accordion .accordion li.no-tag li {
  padding-left: 18px !important;
}

.dekonstruktime_accordion .accordion li .toggle {
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  color: #231f20;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  padding: 18px 25px 18px 18px;
  padding: 1.125rem 1.5625rem 1.125rem 1.125rem;
  transition: background .3s ease;
}

.dekonstruktime_accordion .accordion li .toggle i {
  margin-left: auto;
  color: #cccccc;
}

.dekonstruktime_accordion .accordion li .toggle span {
  float: left;
  width: 100px;
  width: 6.25rem;
  text-align: center;
  color: #fff;
  background: #000;
  padding: 10px 0;
  padding: 0.625rem 0;
  border-radius: 15px;
  margin-right: 19px;
  margin-right: 1.1875rem;
  font-size: 12px;
  font-size: 0.75rem;
}

.dekonstruktime_accordion .accordion li .inner {
  overflow: hidden;
  display: none;
}

.dekonstruktime_accordion .accordion li .inner li {
  background: #fff;
  color: #231f20;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 300;
  padding: 0 78px 38px 138px;
  padding: 0 4.875rem 2.375rem 8.625rem;
  line-height: 1.7;
}

.dekonstruktime_accordion .accordion li:hover .fa-plus {
  color: #e2001a;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .dekonstruktime_accordion .accordion {
    margin-top: 30px;
  }
  .dekonstruktime_accordion .accordion li .toggle p {
    width: 70%;
  }
  .dekonstruktime_accordion .accordion li .toggle i {
    margin-left: 25px;
  }
  .dekonstruktime_accordion .accordion li .toggle span {
    width: 20%;
  }
  .dekonstruktime_accordion .accordion li .inner li {
    padding: 0 30px 38px 30px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .dekonstruktime_accordion .accordion {
    margin-top: 30px;
  }
  .dekonstruktime_accordion .accordion li .toggle p {
    width: 70%;
  }
  .dekonstruktime_accordion .accordion li .toggle i {
    margin-left: 25px;
  }
  .dekonstruktime_accordion .accordion li .toggle span {
    width: 20%;
  }
  .dekonstruktime_accordion .accordion li .inner li {
    padding: 0 30px 38px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .dekonstruktime_accordion .accordion {
    margin-top: 15px;
  }
  .dekonstruktime_accordion .accordion li .toggle {
    padding: 15px 10px;
    flex-wrap: wrap;
  }
  .dekonstruktime_accordion .accordion li .toggle p {
    width: calc(100% - 30px);
    font-size: 13px;
  }
  .dekonstruktime_accordion .accordion li .toggle i {
    margin-left: 15px;
  }
  .dekonstruktime_accordion .accordion li .toggle span {
    float: left;
    clear: both;
    width: auto;
    margin-right: 0;
    font-size: 12px;
    padding: 5px 7px;
    margin-bottom: 9px;
  }
  .dekonstruktime_accordion .accordion li .inner li {
    padding: 0 15px 28px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .dekonstruktime_accordion {
    margin-bottom: 70px;
  }
}

.media_banner {
  background-image: url("../images/media_banner.png");
  height: 250px;
  height: 15.625rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.media_banner .height_container {
  height: 250px;
  height: 15.625rem;
}

.media_banner .banner_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.media_banner .banner_text .gr_parl_banner_title {
  font-size: 36px;
  font-size: 2.25rem;
  color: #fff;
  font-weight: 700;
}

.media_banner .banner_text .gr_parl_banner_subtitle {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 11px;
  margin-top: 0.6875rem;
  color: #fff;
}

.media_banner .banner_text .gr_parl_banner_subtitle p, .media_banner .banner_text .gr_parl_banner_subtitle span {
  float: left;
  opacity: 0.5;
}

.media_banner .banner_text .gr_parl_banner_subtitle span:before {
  content: "  ";
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .media_banner {
    height: 200px;
  }
  .media_banner .height_container {
    height: 200px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .media_banner {
    height: 200px;
  }
  .media_banner .height_container {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .media_banner {
    height: 120px;
  }
  .media_banner .height_container {
    height: 120px;
  }
  .media_banner .height_container .banner_text h1 {
    font-size: 23px;
  }
}

#big_img {
  margin-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  #big_img {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  #big_img {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  #big_img {
    margin-bottom: 50px;
  }
}

.media_grid {
  margin-bottom: -55px;
  margin-bottom: -3.4375rem;
}

.media_grid ul {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.media_grid ul > :nth-child(4n+4) {
  margin-right: 0;
  margin-right: 0;
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .media_grid ul {
    justify-content: space-between;
  }
}

.media_grid .media_block {
  width: 290px;
  width: 18.125rem;
  margin-right: 40px;
  margin-right: 2.5rem;
  margin-bottom: 55px;
  margin-bottom: 3.4375rem;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .media_grid .media_block {
    margin-right: 0;
    margin-right: 0;
    width: 100%;
  }
}

.media_grid .media_block a {
  color: #231f20;
}

.media_grid .media_block .media_img {
  float: left;
  width: 100%;
  height: 186px;
  height: 11.625rem;
  position: relative;
}

.media_grid .media_block .media_img .hover_enlarge {
  visibility: hidden;
}

.media_grid .media_block .media_img:hover .hover_enlarge {
  visibility: visible;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  cursor: pointer;
}

.media_grid .media_block .media_img:hover .hover_enlarge img {
  width: 24px;
  width: 1.5rem;
  height: 24px;
  height: 1.5rem;
  opacity: 1;
}

.media_grid .media_block .media_data {
  float: left;
  width: 100%;
  color: #666666;
  margin-top: 25px;
  margin-top: 1.5625rem;
  text-align: left;
  margin-bottom: 14px;
  margin-bottom: 0.875rem;
}

.media_grid .media_block .media_data p {
  font-size: 13px;
  font-size: 0.8125rem;
}

.media_grid .media_block .media_data p span {
  margin-left: 13px;
  margin-left: 0.8125rem;
  margin-right: 13px;
  margin-right: 0.8125rem;
}

.media_grid .media_block .media_description {
  float: left;
  width: 100%;
  text-align: left;
}

.media_grid .media_block .media_description a {
  text-decoration: none;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.3;
}

.media_grid .media_block:hover a {
  color: #e2001a;
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .media_grid .lajme_te_ngjashme {
    width: 50%;
    margin-right: 0;
    margin-bottom: 40px !important;
    padding-right: 20px;
  }
  .media_grid .lajme_te_ngjashme:nth-child(2n+2) {
    padding-left: 20px;
    padding-right: 0;
  }
  .media_grid .lajme_te_ngjashme .media_img {
    height: 220px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .media_grid .lajme_te_ngjashme {
    width: 50%;
    margin: 0;
    margin-bottom: 40px !important;
    padding-right: 20px;
  }
  .media_grid .lajme_te_ngjashme:nth-child(2n+2) {
    padding-left: 20px;
    padding-right: 0;
  }
  .media_grid .lajme_te_ngjashme .media_img {
    height: 270px;
  }
}

@media only screen and (max-width: 767px) {
  .media_grid .lajme_te_ngjashme {
    margin-bottom: 40px !important;
  }
  .media_grid .lajme_te_ngjashme .media_img {
    height: 220px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .media_grid .lajme_te_ngjashme {
    width: calc(25% - 20px);
    margin-right: 0;
  }
  .media_grid .lajme_te_ngjashme:nth-child(4n+4) {
    margin-right: 0;
  }
}

.pagination_wrapper {
  float: left;
  width: 100%;
  margin: 80px auto 78px auto;
  text-align: center;
}

.pagination_wrapper ul li {
  display: inline-block;
  margin-right: 10px;
  margin-right: 0.625rem;
  position: relative;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.pagination_wrapper ul li:last-child {
  margin-right: 0;
  margin-right: 0;
}

.pagination_wrapper ul li a {
  width: 50px;
  width: 3.125rem;
  height: 50px;
  height: 3.125rem;
  line-height: 50px;
  line-height: 3.125rem;
  border: 1px solid #e6e6e6;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  float: left;
  cursor: pointer;
  color: #231f20;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.pagination_wrapper ul li span {
  width: 50px;
  width: 3.125rem;
  height: 50px;
  height: 3.125rem;
  line-height: 50px;
  line-height: 3.125rem;
  border: 1px solid #e6e6e6;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  float: left;
  cursor: pointer;
  color: #231f20;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.pagination_wrapper ul li:hover {
  border-color: transparent;
  background: white;
  -webkit-box-shadow: 0px 0px 41px 10px #d6d3d6;
  -moz-box-shadow: 0px 0px 41px 10px #d6d3d6;
  box-shadow: 0px 0px 41px 10px #d6d3d6;
}

.pagination_wrapper ul li:hover a {
  color: #e2001a;
}

.pagination_wrapper ul .active {
  border-color: transparent;
  background: white;
  -webkit-box-shadow: 0px 0px 41px 10px #d6d3d6;
  -moz-box-shadow: 0px 0px 41px 10px #d6d3d6;
  box-shadow: 0px 0px 41px 10px #d6d3d6;
}

.pagination_wrapper ul .active a {
  color: #e2001a;
}

.pagination_wrapper ul .current {
  border-color: transparent;
  background: white;
  -webkit-box-shadow: 0px 0px 41px 10px #d6d3d6;
  -moz-box-shadow: 0px 0px 41px 10px #d6d3d6;
  box-shadow: 0px 0px 41px 10px #d6d3d6;
  color: #e2001a;
}

@media (max-width: 320px) {
  .pagination_wrapper ul li {
    width: 35px;
    height: 35px;
  }
  .pagination_wrapper ul li a {
    font-size: 11px;
  }
}

.zgjedhjet19_banner {
  background-image: url("../images/zgj19_banner.png");
  height: 250px;
  height: 15.625rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.zgjedhjet19_banner .height_container {
  height: 250px;
  height: 15.625rem;
}

.zgjedhjet19_banner .banner_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.zgjedhjet19_banner .banner_text .gr_parl_banner_title {
  font-size: 36px;
  font-size: 2.25rem;
  color: #fff;
  font-weight: 700;
}

.zgjedhjet19_banner .banner_text .gr_parl_banner_subtitle {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 11px;
  margin-top: 0.6875rem;
  color: #fff;
}

.zgjedhjet19_banner .banner_text .gr_parl_banner_subtitle p, .zgjedhjet19_banner .banner_text .gr_parl_banner_subtitle span {
  float: left;
  opacity: 0.5;
}

.zgjedhjet19_banner .banner_text .gr_parl_banner_subtitle span:before {
  content: "  ";
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .zgjedhjet19_banner {
    height: 200px;
  }
  .zgjedhjet19_banner .height_container {
    height: 200px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .zgjedhjet19_banner {
    height: 200px;
  }
  .zgjedhjet19_banner .height_container {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .zgjedhjet19_banner {
    height: 120px;
  }
  .zgjedhjet19_banner .height_container {
    height: 120px;
  }
  .zgjedhjet19_banner .height_container .banner_text h1 {
    font-size: 23px;
  }
}

.qendrat_banner {
  background-image: url("../images/qendrat_banner.png");
  height: 250px;
  height: 15.625rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.qendrat_banner .height_container {
  height: 250px;
  height: 15.625rem;
}

.qendrat_banner .banner_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.qendrat_banner .banner_text .gr_parl_banner_title {
  font-size: 36px;
  font-size: 2.25rem;
  color: #fff;
  font-weight: 700;
}

.qendrat_banner .banner_text .gr_parl_banner_subtitle {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 11px;
  margin-top: 0.6875rem;
  color: #fff;
}

.qendrat_banner .banner_text .gr_parl_banner_subtitle p, .qendrat_banner .banner_text .gr_parl_banner_subtitle span {
  float: left;
  opacity: 0.5;
}

.qendrat_banner .banner_text .gr_parl_banner_subtitle span:before {
  content: "  ";
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .qendrat_banner {
    height: 200px;
  }
  .qendrat_banner .height_container {
    height: 200px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .qendrat_banner {
    height: 200px;
  }
  .qendrat_banner .height_container {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .qendrat_banner {
    height: 120px;
  }
  .qendrat_banner .height_container {
    height: 120px;
  }
  .qendrat_banner .height_container .banner_text h1 {
    font-size: 23px;
  }
}

.qendrat_wrapper {
  float: left;
  width: 100%;
  margin-bottom: 80px;
  margin-bottom: 5rem;
}

.qendrat_wrapper ul {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.qendrat_wrapper ul li {
  float: left;
  width: 620px;
  width: 38.75rem;
  height: 290px;
  height: 18.125rem;
  margin-right: 40px;
  margin-right: 2.5rem;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
}

.qendrat_wrapper ul li:hover {
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  -webkit-box-shadow: 0px 0px 41px 10px #d6d3d6;
  -moz-box-shadow: 0px 0px 41px 10px #d6d3d6;
  box-shadow: 0px 0px 41px 10px #d6d3d6;
}

.qendrat_wrapper ul > :nth-child(2n+2) {
  margin-right: 0;
  margin-right: 0;
}

.qendrat_wrapper ul .qendra_img {
  float: left;
  width: 290px;
  width: 18.125rem;
  position: relative;
  height: 100%;
}

.qendrat_wrapper ul .qendra_img:hover:after {
  background-color: #e2001a;
}

.qendrat_wrapper ul .qendra_img:after {
  content: "";
  width: 5px;
  width: 0.3125rem;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.qendrat_wrapper ul .qendra_description {
  float: left;
  width: calc(100% - 290px);
  height: 100%;
  border-left: none;
  background: #fff;
  position: relative;
  padding: 40px 40px 45px 35px;
  padding: 2.5rem 2.5rem 2.8125rem 2.1875rem;
}

.qendrat_wrapper ul .qendra_description a {
  text-decoration: none;
  color: #231f20;
}

.qendrat_wrapper ul .qendra_description a h1 {
  float: left;
  font-size: 21px;
  font-size: 1.3125rem;
}

.qendrat_wrapper ul .qendra_description a .qendra_vendi {
  float: left;
  width: 100%;
  font-weight: 700;
  margin-top: 2px;
  margin-top: 0.125rem;
}

.qendrat_wrapper ul .qendra_description a i {
  font-size: 15px;
  font-size: 0.9375rem;
  color: #666666;
  margin-left: 15px;
  margin-left: 0.9375rem;
}

.qendrat_wrapper ul .qendra_description a i:hover {
  color: #e2001a;
}

.qendrat_wrapper ul .qendra_description a:hover {
  color: #e2001a;
}

.qendrat_wrapper ul .qendra_description .qendra_social_icons {
  float: right;
  margin-top: 3px;
  margin-top: 0.1875rem;
  position: absolute;
  right: 40px;
}

.qendrat_wrapper ul .qendra_description h2, .qendrat_wrapper ul .qendra_description h3, .qendrat_wrapper ul .qendra_description p {
  float: left;
  width: 100%;
}

.qendrat_wrapper ul .qendra_description h2 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  margin-top: 25px;
  margin-top: 1.5625rem;
  margin-bottom: 3px;
  margin-bottom: 0.1875rem;
}

.qendrat_wrapper ul .qendra_description h3 {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 24px;
  margin-top: 1.5rem;
  margin-bottom: 3px;
  margin-bottom: 0.1875rem;
}

.qendrat_wrapper ul .qendra_description p {
  line-height: 1.3;
  font-size: 15px;
  font-size: 0.9375rem;
  color: #666666;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .qendrat_wrapper ul li {
    width: 100%;
    margin-right: 0;
  }
  .qendrat_wrapper ul li .qendra_img {
    width: 50%;
  }
  .qendrat_wrapper ul li .qendra_description {
    width: 50%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .qendrat_wrapper ul li {
    width: 100%;
    margin-right: 0;
  }
  .qendrat_wrapper ul li .qendra_img {
    width: 50%;
  }
  .qendrat_wrapper ul li .qendra_description {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .qendrat_wrapper ul li {
    width: 100%;
    margin-right: 0;
    height: auto;
  }
  .qendrat_wrapper ul li .qendra_img {
    width: 100%;
    height: 170px;
  }
  .qendrat_wrapper ul li .qendra_description {
    width: 100%;
    padding: 20px;
    height: 235px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .qendrat_wrapper ul li {
    width: 48%;
    height: 255px;
    margin-right: 4%;
  }
  .qendrat_wrapper ul li:nth-child(2n+2) {
    margin-right: 0;
  }
  .qendrat_wrapper ul li .qendra_img {
    width: 50%;
  }
  .qendrat_wrapper ul li .qendra_description {
    width: 50%;
    padding: 15px;
  }
  .qendrat_wrapper ul li .qendra_description a h1 {
    font-size: 20px;
  }
  .qendrat_wrapper ul li .qendra_description .qendra_social_icons {
    margin-left: 3px;
  }
}

.komunikata_list {
  margin-top: 7px;
  margin-top: 0.4375rem;
}

.komunikata_list ul li {
  float: left;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  overflow: hidden;
}

.komunikata_list ul li:after {
  content: " ";
  height: 5px;
  background: #e2001a;
  width: 100%;
  left: 0;
  bottom: 0;
  transform: translateY(5px);
  position: absolute;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.komunikata_list ul li:first-child {
  border-top: 1px solid #e6e6e6;
}

.komunikata_list ul li:hover:after {
  transform: translateY(0);
}

.komunikata_list ul li:hover h3 {
  color: #e2001a;
}

.komunikata_list ul li:hover .komunikata_date {
  display: none;
}

.komunikata_list ul li:hover .lexoje_te_plote {
  display: block;
  color: #e2001a;
}

.komunikata_list ul li a {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-top: 2.5rem;
  padding-bottom: 36px;
  padding-bottom: 2.25rem;
}

.komunikata_list ul li a h3 {
  float: left;
  width: 70%;
  padding-right: 30px;
  font-size: 21px;
  font-size: 1.3125rem;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  color: #231f20;
  font-weight: 700;
}

.komunikata_list ul li a p {
  width: 30%;
  padding-left: 30px;
  word-wrap: normal;
  text-align: right;
  margin-left: auto;
  color: #666666;
  font-size: 13px;
  font-size: 0.8125rem;
}

.komunikata_list ul li a p span {
  margin-left: 5px;
  margin-right: 5px;
}

.komunikata_list ul li a p br {
  display: none;
}

.komunikata_list ul li a .lexoje_te_plote {
  display: none;
}

.komunikata_list ul li a .lexoje_te_plote::after {
  content: " ";
  background-image: url("../images/read_more_arrow.svg");
  background-repeat: no-repeat;
  width: 14px;
  width: 0.875rem;
  height: 9px;
  height: 0.5625rem;
  display: block;
  float: right;
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-top: 3px;
  margin-top: 0.1875rem;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .komunikata_list ul li a h3 {
    font-size: 18px;
    padding-right: 25px;
  }
  .komunikata_list ul li a p {
    font-size: 12px;
    padding-left: 25px;
    word-wrap: normal;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .komunikata_list ul li a h3 {
    font-size: 18px;
    padding-right: 25px;
  }
  .komunikata_list ul li a p {
    font-size: 12px;
    padding-left: 25px;
    word-wrap: normal;
  }
}

@media only screen and (max-width: 767px) {
  .komunikata_list ul li a h3 {
    width: 65%;
    font-size: 15px;
    padding-right: 10px;
    line-height: 19px;
  }
  .komunikata_list ul li a p {
    width: 35%;
    font-size: 10px;
    padding-left: 10px;
    line-height: 15px;
    word-wrap: normal;
  }
  .komunikata_list ul li a p span {
    display: none;
  }
  .komunikata_list ul li a p br {
    display: block;
  }
  .komunikata_list ul li a .lexoje_te_plote {
    font-size: 10px;
  }
  .komunikata_list ul li a .lexoje_te_plote::after {
    width: 10px;
    height: 5px;
    margin-left: 10px;
  }
}

.bcg_lajme_levizja_aksione {
  background: white;
}

.bcg_lajme_levizja_aksione .deputetet_heading {
  height: auto;
  padding-top: 0;
  padding-top: 0;
  margin-bottom: 58px;
  margin-bottom: 3.625rem;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .bcg_lajme_levizja_aksione .deputetet_heading {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .bcg_lajme_levizja_aksione .deputetet_heading {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .bcg_lajme_levizja_aksione .deputetet_heading {
    margin-bottom: 30px;
  }
}

.bcg_lajme_levizja_aksione #first_heading_item {
  margin-top: 60px;
  margin-top: 3.75rem;
}

@media only screen and (max-width: 767px) {
  .bcg_lajme_levizja_aksione #first_heading_item {
    margin: 35px auto;
  }
}

.bcg_lajme_levizja_aksione .media_grid {
  margin-bottom: 78px;
  margin-bottom: 4.875rem;
}

.bcg_lajme_levizja_aksione .media_grid li {
  margin-bottom: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .bcg_lajme_levizja_aksione .media_grid {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .bcg_lajme_levizja_aksione .media_grid {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .bcg_lajme_levizja_aksione .media_grid {
    margin-bottom: 10px;
  }
}

.bcg_lajme_levizja_aksione .lajmi {
  height: 427px;
  height: 26.6875rem;
  margin-bottom: 60px;
  margin-bottom: 3.75rem;
}

.bcg_lajme_levizja_aksione .lajmi .lajmi_image {
  float: left;
  width: 50%;
  height: 100%;
}

.bcg_lajme_levizja_aksione .lajmi .lajmi_description {
  float: left;
  width: 50%;
  height: 100%;
  border: 1px solid #e6e6e6;
  border-left: none;
  padding: 60px 40px 55px 60px;
  padding: 3.75rem 2.5rem 3.4375rem 3.75rem;
}

.bcg_lajme_levizja_aksione .lajmi .lajmi_description p {
  color: #666666;
  font-size: 13px;
  font-size: 0.8125rem;
}

.bcg_lajme_levizja_aksione .lajmi .lajmi_description p span {
  margin: 0 15px;
  margin: 0 0.9375rem;
}

.bcg_lajme_levizja_aksione .lajmi .lajmi_description h2 {
  font-size: 27px;
  font-size: 1.6875rem;
  font-weight: 700;
  color: #231f20;
  line-height: 29px;
  margin-top: 15px;
  margin-top: 0.9375rem;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  max-height: 58px;
}

.bcg_lajme_levizja_aksione .lajmi .lajmi_description h4 {
  line-height: 32px;
  font-size: 18px;
  font-size: 1.125rem;
  color: #666666;
  max-height: 128px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 4;
  margin-top: 29px;
  margin-top: 1.8125rem;
  margin-bottom: 46px;
  margin-bottom: 2.875rem;
}

.bcg_lajme_levizja_aksione .lajmi .lajmi_description #see_all {
  color: #666;
  text-decoration: none;
  float: left;
  font-size: 16px;
  font-size: 1rem;
}

.bcg_lajme_levizja_aksione .lajmi .lajmi_description #see_all span {
  content: " ";
  background-image: url("../images/lexomeshume.svg");
  height: 8px;
  height: 0.5rem;
  width: 14px;
  width: 0.875rem;
  margin-left: 22px;
  margin-left: 1.375rem;
  display: block;
  float: right;
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-top: 3px;
  margin-top: 0.1875rem;
}

.bcg_lajme_levizja_aksione .lajmi .lajmi_description #see_all:hover {
  color: #e2001a;
}

.bcg_lajme_levizja_aksione .lajmi .lajmi_description #see_all:hover span {
  content: " ";
  background-image: url("../images/lexomeshume-red.svg");
  height: 8px;
  height: 0.5rem;
  width: 14px;
  width: 0.875rem;
  margin-left: 22px;
  margin-left: 1.375rem;
  display: block;
  float: right;
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-top: 3px;
  margin-top: 0.1875rem;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .bcg_lajme_levizja_aksione .lajmi {
    width: 100%;
    height: 270px;
  }
  .bcg_lajme_levizja_aksione .lajmi .lajmi_description {
    height: 100%;
    padding: 25px;
  }
  .bcg_lajme_levizja_aksione .lajmi .lajmi_description p {
    font-size: 12px;
  }
  .bcg_lajme_levizja_aksione .lajmi .lajmi_description h2 {
    font-size: 15px;
    margin-top: 10px;
    text-overflow: ellipsis;
    line-height: 17px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    max-height: 34px;
  }
  .bcg_lajme_levizja_aksione .lajmi .lajmi_description h4 {
    font-size: 13px;
    line-height: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    height: 80px;
  }
  .bcg_lajme_levizja_aksione .lajmi .lajmi_description #see_all {
    font-size: 12px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .bcg_lajme_levizja_aksione .lajmi {
    width: 100%;
    height: 270px;
  }
  .bcg_lajme_levizja_aksione .lajmi .lajmi_description {
    height: 100%;
    padding: 25px;
  }
  .bcg_lajme_levizja_aksione .lajmi .lajmi_description p {
    font-size: 12px;
  }
  .bcg_lajme_levizja_aksione .lajmi .lajmi_description h2 {
    font-size: 15px;
    margin-top: 10px;
    text-overflow: ellipsis;
    line-height: 17px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    max-height: 34px;
  }
  .bcg_lajme_levizja_aksione .lajmi .lajmi_description h4 {
    font-size: 13px;
    line-height: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    height: 80px;
  }
  .bcg_lajme_levizja_aksione .lajmi .lajmi_description #see_all {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .bcg_lajme_levizja_aksione .lajmi {
    height: auto;
  }
  .bcg_lajme_levizja_aksione .lajmi .lajmi_image {
    width: 100%;
  }
  .bcg_lajme_levizja_aksione .lajmi .lajmi_description {
    width: 100%;
    padding: 15px;
    border: 1px solid #e6e6e6;
    margin-top: -2px;
  }
  .bcg_lajme_levizja_aksione .lajmi .lajmi_description h2 {
    font-size: 20px;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    max-height: 44px;
  }
  .bcg_lajme_levizja_aksione .lajmi .lajmi_description h4 {
    font-size: 16px;
    line-height: 20px;
    margin-top: 15px;
    margin-bottom: 20px;
    height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
  }
  .bcg_lajme_levizja_aksione .lajmi .lajmi_description #see_all {
    font-size: 13px;
    margin-bottom: 10px;
  }
}

.arkiva {
  background: #f2f2f2;
  padding-bottom: 104px;
  padding-bottom: 6.5rem;
  padding-top: 104px;
  padding-top: 6.5rem;
}

.arkiva .deputetet_heading {
  padding-top: 0;
  padding-top: 0;
  height: auto;
}

.arkiva .media_grid {
  margin-bottom: 0;
  margin-bottom: 0;
}

.arkiva .media_grid li {
  margin-bottom: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .arkiva {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .arkiva {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .arkiva {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}

.bcg_lajme_levizja_aksione {
  float: left;
  width: 100%;
}

.bcg_lajme_levizja_aksione .single_kandidati_heading_date {
  float: left;
  font-size: 13px;
  font-size: 0.8125rem;
  color: #666666;
}

.bcg_lajme_levizja_aksione .single_kandidati_heading {
  float: left;
  width: 100%;
  margin-bottom: 50px;
  margin-bottom: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bcg_lajme_levizja_aksione .single_kandidati_heading .kandidati_name {
  float: left;
  font-size: 42px;
  font-size: 2.625rem;
  font-weight: 700;
  hyphens: auto;
}

.bcg_lajme_levizja_aksione .single_kandidati_heading .kandidati_tag {
  text-transform: uppercase;
  text-decoration: none;
  background: #27ba9e;
  font-size: 14px;
  font-size: 0.875rem;
  color: #fff;
  padding: 10px 10px 10px 12px;
  padding: 0.625rem 0.625rem 0.625rem 0.75rem;
  border-radius: 2px;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .bcg_lajme_levizja_aksione .single_kandidati_heading {
    width: 100%;
    margin-bottom: 25px;
  }
  .bcg_lajme_levizja_aksione .single_kandidati_heading .kandidati_name {
    font-size: 27px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .bcg_lajme_levizja_aksione .single_kandidati_heading {
    width: 100%;
    margin-bottom: 25px;
  }
  .bcg_lajme_levizja_aksione .single_kandidati_heading .kandidati_name {
    font-size: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .bcg_lajme_levizja_aksione .single_kandidati_heading {
    width: 100%;
    margin-bottom: 25px;
  }
  .bcg_lajme_levizja_aksione .single_kandidati_heading .kandidati_name {
    font-size: 27px;
  }
}

.bcg_lajme_levizja_aksione .single_kandidati_sm_section {
  float: right;
  width: 330px;
  width: 20.625rem;
  height: auto;
  margin-bottom: 45px;
  margin-bottom: 2.8125rem;
  display: flex;
  align-items: center;
}

.bcg_lajme_levizja_aksione .single_kandidati_sm_section span {
  content: " ";
  background-image: url("../images/dash.svg");
  height: 5px;
  height: 0.3125rem;
  width: 20px;
  width: 1.25rem;
  margin-right: 16px;
  margin-right: 1rem;
  display: block;
}

.bcg_lajme_levizja_aksione .single_kandidati_sm_section h3 {
  font-size: 21px;
  font-size: 1.3125rem;
  font-weight: 700;
  color: #231f20;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .bcg_lajme_levizja_aksione .single_kandidati_sm_section_resp {
    float: left;
    width: 100%;
    display: block !important;
    margin-bottom: 30px;
  }
  .bcg_lajme_levizja_aksione .single_kandidati_sm_section_resp span {
    float: left;
    content: " ";
    background-image: url("../images/dash.svg");
    height: 5px;
    height: 0.3125rem;
    width: 20px;
    width: 1.25rem;
    margin-right: 16px;
    margin-right: 1rem;
    display: block;
    margin-top: 8px;
  }
  .bcg_lajme_levizja_aksione .single_kandidati_sm_section_resp h3 {
    float: left;
    font-size: 21px;
    font-size: 1.3125rem;
    font-weight: 700;
    color: #231f20;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .bcg_lajme_levizja_aksione .single_kandidati_sm_section_resp {
    float: left;
    width: 100%;
    display: block !important;
    margin-bottom: 30px;
  }
  .bcg_lajme_levizja_aksione .single_kandidati_sm_section_resp span {
    float: left;
    content: " ";
    background-image: url("../images/dash.svg");
    height: 5px;
    height: 0.3125rem;
    width: 20px;
    width: 1.25rem;
    margin-right: 16px;
    margin-right: 1rem;
    display: block;
    margin-top: 8px;
  }
  .bcg_lajme_levizja_aksione .single_kandidati_sm_section_resp h3 {
    float: left;
    font-size: 21px;
    font-size: 1.3125rem;
    font-weight: 700;
    color: #231f20;
  }
}

@media only screen and (max-width: 767px) {
  .bcg_lajme_levizja_aksione .single_kandidati_sm_section_resp {
    float: left;
    width: 100%;
    display: block !important;
    margin-bottom: 30px;
  }
  .bcg_lajme_levizja_aksione .single_kandidati_sm_section_resp span {
    float: left;
    content: " ";
    background-image: url("../images/dash.svg");
    height: 5px;
    height: 0.3125rem;
    width: 20px;
    width: 1.25rem;
    margin-right: 16px;
    margin-right: 1rem;
    display: block;
    margin-top: 8px;
  }
  .bcg_lajme_levizja_aksione .single_kandidati_sm_section_resp h3 {
    float: left;
    font-size: 21px;
    font-size: 1.3125rem;
    font-weight: 700;
    color: #231f20;
  }
}

.kandidati_info {
  float: left;
  width: 840px;
  width: 52.5rem;
  margin-right: 109px;
  margin-right: 6.8125rem;
}

.kandidati_info .kandidati_img_basic_info {
  float: left;
  width: 100%;
  height: 542px;
  height: 33.875rem;
  margin-bottom: 53px;
  margin-bottom: 3.3125rem;
}

.kandidati_info .kandidati_img_basic_info .kandidati_img {
  float: left;
  width: 538px;
  width: 33.625rem;
  height: 100%;
  position: relative;
}

.kandidati_info .kandidati_img_basic_info .kandidati_img .kandidati_number {
  position: absolute;
  top: 0;
  left: 0;
  background: #e2001a;
  color: #fff;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none;
  width: 50px;
  width: 3.125rem;
  height: 50px;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kandidati_info .kandidati_img_basic_info .lajmi_img {
  float: left;
  width: 100%;
  height: 100%;
}

.kandidati_info .kandidati_img_basic_info .kandidati_basic_info {
  float: left;
  width: 300px;
  width: 18.75rem;
  height: 100%;
  padding: 35px 31px 74px 36px;
  padding: 2.1875rem 1.9375rem 4.625rem 2.25rem;
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
}

.kandidati_info .kandidati_img_basic_info .kandidati_basic_info .basic_info_heading {
  float: left;
  width: 100%;
  margin-bottom: 37px;
  margin-bottom: 2.3125rem;
  display: flex;
  align-items: center;
}

.kandidati_info .kandidati_img_basic_info .kandidati_basic_info .basic_info_heading span {
  content: " ";
  background-image: url("../images/dash.svg");
  height: 5px;
  height: 0.3125rem;
  width: 20px;
  width: 1.25rem;
  margin-right: 16px;
  margin-right: 1rem;
  display: block;
}

.kandidati_info .kandidati_img_basic_info .kandidati_basic_info .basic_info_heading h3 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  color: #231f20;
}

.kandidati_info .kandidati_img_basic_info .kandidati_basic_info .basic_info_heading .kandidati_info_social_icons {
  margin-left: auto;
}

.kandidati_info .kandidati_img_basic_info .kandidati_basic_info .basic_info_heading .kandidati_info_social_icons i {
  color: #666666;
  font-size: 15px;
  font-size: 0.9375rem;
  margin-left: 23px;
  margin-left: 1.4375rem;
}

.kandidati_info .kandidati_img_basic_info .kandidati_basic_info .basic_info_heading .kandidati_info_social_icons i:hover {
  color: #e2001a;
}

.kandidati_info .kandidati_img_basic_info .kandidati_basic_info .basic_info_content {
  float: left;
  width: 100%;
}

.kandidati_info .kandidati_img_basic_info .kandidati_basic_info .basic_info_content p {
  font-size: 14px;
  font-size: 0.875rem;
  color: #666666;
  text-transform: uppercase;
  margin-bottom: 4px;
  margin-bottom: 0.25rem;
}

.kandidati_info .kandidati_img_basic_info .kandidati_basic_info .basic_info_content h3 {
  font-size: 16px;
  font-size: 1rem;
  color: #231f20;
  font-weight: 700;
  margin-bottom: 28px;
  margin-bottom: 1.75rem;
}

.kandidati_info .kandidati_biography {
  margin-bottom: 79px;
  margin-bottom: 4.9375rem;
  font-size: 15px;
  font-size: 0.9375rem;
  color: #666;
}

.kandidati_info .kandidati_biography h4 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 37px;
  margin-bottom: 2.3125rem;
  line-height: 1.8em;
  color: #231f20;
}

.kandidati_info .kandidati_biography p {
  margin-bottom: 26px;
  margin-bottom: 1.625rem;
  line-height: 1.93em;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .kandidati_info {
    width: 100%;
    margin-right: 0;
  }
  .kandidati_info .kandidati_img_basic_info {
    height: 400px;
    margin-bottom: 30px;
    width: 100%;
  }
  .kandidati_info .kandidati_img_basic_info .kandidati_img {
    width: 50%;
  }
  .kandidati_info .kandidati_img_basic_info .kandidati_basic_info {
    width: 50%;
  }
  .kandidati_info .kandidati_img_basic_info .kandidati_basic_info .basic_info_heading {
    margin-bottom: 15px;
  }
  .kandidati_info .kandidati_biography {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .kandidati_info {
    width: 100%;
    margin-right: 0;
  }
  .kandidati_info .kandidati_img_basic_info {
    height: 400px;
    margin-bottom: 30px;
    width: 100%;
  }
  .kandidati_info .kandidati_img_basic_info .kandidati_img {
    width: 50%;
  }
  .kandidati_info .kandidati_img_basic_info .kandidati_basic_info {
    width: 50%;
  }
  .kandidati_info .kandidati_img_basic_info .kandidati_basic_info .basic_info_heading {
    margin-bottom: 15px;
  }
  .kandidati_info .kandidati_biography {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .kandidati_info {
    width: 100%;
    margin-right: 0;
  }
  .kandidati_info .kandidati_img_basic_info {
    height: auto;
    margin-bottom: 20px;
  }
  .kandidati_info .kandidati_img_basic_info .kandidati_img {
    width: 100%;
  }
  .kandidati_info .kandidati_img_basic_info .kandidati_basic_info {
    width: 100%;
    padding: 30px;
  }
  .kandidati_info .kandidati_biography {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .kandidati_info {
    width: 67%;
    margin-right: 5%;
  }
  .kandidati_info .kandidati_img_basic_info .kandidati_img {
    width: 60%;
  }
  .kandidati_info .kandidati_img_basic_info .kandidati_basic_info {
    width: 40%;
  }
}

.single_kandidati_shkrime {
  float: right;
  clear: right;
  width: 330px;
  width: 20.625rem;
  height: 414px;
  height: 25.875rem;
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
  padding: 40px;
  padding: 2.5rem;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
}

.single_kandidati_shkrime .shkrime_heading {
  float: left;
  width: 100%;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
  display: flex;
  align-items: center;
}

.single_kandidati_shkrime .shkrime_heading span {
  content: " ";
  background-image: url("../images/dash.svg");
  height: 5px;
  height: 0.3125rem;
  width: 20px;
  width: 1.25rem;
  margin-right: 16px;
  margin-right: 1rem;
  display: block;
}

.single_kandidati_shkrime .shkrime_heading h3 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
}

.single_kandidati_shkrime .shkrime_item {
  float: left;
  width: 100%;
}

.single_kandidati_shkrime .shkrime_item p {
  font-size: 12px;
  font-size: 0.75rem;
  color: #666;
  margin-bottom: 9px;
  margin-bottom: 0.5625rem;
  margin-top: 19px;
  margin-top: 1.1875rem;
}

.single_kandidati_shkrime .shkrime_item a {
  font-size: 14px;
  font-size: 0.875rem;
  color: #231f20;
  font-weight: 700;
  text-decoration: none;
  line-height: 18px;
  line-height: 1.125rem;
}

.single_kandidati_shkrime .shkrime_item a:hover {
  color: #e2001a;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .single_kandidati_shkrime {
    float: left;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .single_kandidati_shkrime {
    width: 57%;
    float: left;
  }
}

@media only screen and (max-width: 767px) {
  .single_kandidati_shkrime {
    width: 100%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .single_kandidati_shkrime {
    width: 28%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .right_sidebar_shkrime {
    height: auto;
    width: 100%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .right_sidebar_shkrime {
    height: auto;
    width: 100%;
  }
}

.single_kandidati_fb_plugin {
  float: right;
  clear: right;
  width: 330px;
  width: 20.625rem;
  height: 414px;
  height: 25.875rem;
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .single_kandidati_fb_plugin {
    width: 100%;
    margin-bottom: 50px;
  }
  .single_kandidati_fb_plugin iframe {
    width: 100%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .single_kandidati_fb_plugin {
    width: 100%;
    margin-bottom: 50px;
  }
  .single_kandidati_fb_plugin iframe {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .single_kandidati_fb_plugin {
    width: 100%;
    margin-bottom: 50px;
  }
  .single_kandidati_fb_plugin iframe {
    width: 100%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .single_kandidati_fb_plugin {
    width: 309px;
  }
}

.kontribo_buttons {
  float: right;
  clear: right;
  width: 330px;
  width: 20.625rem;
  height: 325px;
  height: 20.3125rem;
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
  padding: 40px;
  padding: 2.5rem;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
}

.kontribo_buttons .shkrime_heading {
  float: left;
  width: 100%;
  margin-bottom: 30px;
  margin-bottom: 1.875rem;
  display: flex;
  align-items: center;
}

.kontribo_buttons .shkrime_heading span {
  content: " ";
  background-image: url("../images/dash.svg");
  height: 5px;
  height: 0.3125rem;
  width: 20px;
  width: 1.25rem;
  margin-right: 16px;
  margin-right: 1rem;
  display: block;
}

.kontribo_buttons .shkrime_heading h3 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
}

.kontribo_buttons form {
  float: left;
  width: 100%;
}

.kontribo_buttons form > :nth-child(2n+2) {
  margin-right: 0;
  margin-right: 0;
}

.kontribo_buttons form .kontribo_form_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.kontribo_buttons form .input_holder {
  float: left;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.kontribo_buttons form .input_holder input:checked ~ .kontribo_button {
  border-color: transparent;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
}

.kontribo_buttons form .input_holder input:checked ~ .kontribo_button i,
.kontribo_buttons form .input_holder input:checked ~ .kontribo_button span {
  color: #e2001a;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.kontribo_buttons form .kontribo_button {
  float: left;
  width: 117px;
  width: 7.3125rem;
  height: 50px;
  height: 3.125rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  text-align: center;
  border: 1px solid #e6e6e6;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.kontribo_buttons form .kontribo_button input[type=text],
.kontribo_buttons form .kontribo_button input[type=number] {
  float: left;
  width: 60px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
  padding-right: 15px;
  line-height: 44px;
  background: transparent;
  border: 0;
}

.kontribo_buttons form .kontribo_button input[type=text] {
  text-align: center;
  padding-right: 0;
}

.kontribo_buttons form .kontribo_button i {
  float: left;
  width: 43px;
  font-size: 18px;
  text-align: center;
  line-height: 44px;
}

.kontribo_buttons form .kontribo_button span {
  line-height: 46px;
  font-weight: 700;
  font-size: 16px;
}

.kontribo_buttons form .kontribo_button:hover {
  border-color: transparent;
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
}

.kontribo_buttons form .kontribo_button:hover label {
  color: #e2001a;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.kontribo_buttons form button, .kontribo_buttons form input[type="submit"] {
  float: left;
  height: 47px;
  height: 2.9375rem;
  width: 100%;
  background: #e2001a;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, 0) !important;
  outline: none !important;
  cursor: pointer;
  transition: background .25s ease-in-out;
}

.kontribo_buttons form button:hover, .kontribo_buttons form input[type="submit"]:hover {
  background: #fc001d;
}

.kontribo_buttons form button:active, .kontribo_buttons form input[type="submit"]:active {
  transform: scale(0.95);
}

.kontribo_buttons .dhuro {
  float: left;
  height: 50px;
  height: 3.125rem;
  width: 100%;
  background: #e2001a;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .kontribo_buttons {
    float: right;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .kontribo_buttons {
    float: right;
  }
}

@media only screen and (max-width: 767px) {
  .kontribo_buttons {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 320px) {
  .kontribo_buttons form .kontribo_form_wrap .input_holder .kontribo_button {
    width: 107px !important;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .kontribo_buttons {
    width: 28%;
  }
  .kontribo_buttons form .kontribo_form_wrap .input_holder .kontribo_button {
    width: 107px;
  }
}

.te_tjere {
  background: #f2f2f2;
  padding-bottom: 104px;
  padding-bottom: 6.5rem;
  padding-top: 104px;
  padding-top: 6.5rem;
}

.te_tjere .te_tjere_heading {
  padding-top: 0;
  padding-top: 0;
  margin-bottom: 41px;
  margin-bottom: 2.5625rem;
  height: auto;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.te_tjere .te_tjere_heading span {
  content: " ";
  background-image: url(../images/dash.svg);
  height: 5px;
  height: 0.3125rem;
  width: 20px;
  width: 1.25rem;
}

.te_tjere .te_tjere_heading h2 {
  font-size: 27px;
  font-size: 1.6875rem;
  font-weight: 700;
  color: #231f20;
  text-transform: uppercase;
  margin-left: 29px;
  margin-left: 1.8125rem;
}

.te_tjere .media_grid {
  margin-bottom: 0;
  margin-bottom: 0;
}

.te_tjere .media_grid li {
  margin-bottom: 0;
  margin-bottom: 0;
}

.te_tjere .media_grid li .media_img {
  width: 290px;
  width: 18.125rem;
  height: 290px;
  height: 18.125rem;
  position: relative;
}

.te_tjere .media_grid li .media_img span {
  position: absolute;
  top: 0;
  left: 0;
  background: #e2001a;
  color: white;
  font-size: 24px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 50px;
  width: 3.125rem;
  height: 50px;
  height: 3.125rem;
}

.te_tjere .media_grid li .te_tjere_kandidati_name {
  float: left;
  width: 100%;
  margin-top: 30px;
  margin-top: 1.875rem;
  text-align: left;
}

.te_tjere .media_grid li .te_tjere_kandidati_name h2 {
  font-size: 18px;
  font-size: 1.125rem;
  text-align: left;
  color: #231f20;
  font-weight: 700;
}

.te_tjere .media_grid li:hover .te_tjere_kandidati_name h2 {
  color: #e2001a;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .te_tjere {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .te_tjere .media_block {
    width: calc(50% - 30px);
    margin-right: 0 !important;
    height: auto;
    margin-right: 5%;
    margin-bottom: 30px !important;
  }
  .te_tjere .media_block:nth-child(3n+3) {
    margin-right: 0;
  }
  .te_tjere .media_block .media_img {
    width: 100% !important;
    height: 220px !important;
  }
  .te_tjere .media_block .te_tjere_kandidati_name {
    margin-top: 20px !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .te_tjere {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .te_tjere .media_block {
    width: calc(33.3333333333% - 30px);
    height: auto;
    margin-right: 0 !important;
    margin-bottom: 30px !important;
  }
  .te_tjere .media_block:nth-child(3n+3) {
    margin-right: 0;
  }
  .te_tjere .media_block .media_img {
    width: 100% !important;
    height: 220px !important;
  }
  .te_tjere .media_block .te_tjere_kandidati_name {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .te_tjere {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .te_tjere .media_block {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 40px !important;
  }
  .te_tjere .media_block:nth-child(3n+3) {
    margin-right: 0;
  }
  .te_tjere .media_block .media_img {
    width: 100% !important;
    height: 250px !important;
  }
  .te_tjere .media_block .te_tjere_kandidati_name {
    margin-top: 20px !important;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .te_tjere .media_block {
    width: 260px;
    height: auto;
    margin-right: 2%;
    margin-bottom: 30px !important;
  }
  .te_tjere .media_block:nth-child(4n+4) {
    margin-right: 0;
  }
  .te_tjere .media_block .media_img {
    width: 100% !important;
    height: 220px !important;
  }
  .te_tjere .media_block .te_tjere_kandidati_name {
    margin-top: 20px !important;
  }
}

.statusi_banner {
  background-image: url("../images/levizja_statusi.png");
  height: 250px;
  height: 15.625rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.statusi_banner .height_container {
  height: 250px;
  height: 15.625rem;
}

.statusi_banner .banner_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.statusi_banner .banner_text .gr_parl_banner_title {
  font-size: 36px;
  font-size: 2.25rem;
  color: #fff;
  font-weight: 700;
}

.statusi_banner .banner_text .gr_parl_banner_subtitle {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 11px;
  margin-top: 0.6875rem;
  color: #fff;
}

.statusi_banner .banner_text .gr_parl_banner_subtitle p, .statusi_banner .banner_text .gr_parl_banner_subtitle span {
  float: left;
  opacity: 0.5;
}

.statusi_banner .banner_text .gr_parl_banner_subtitle span:before {
  content: "  ";
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .statusi_banner {
    height: 200px;
  }
  .statusi_banner .height_container {
    height: 200px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .statusi_banner {
    height: 200px;
  }
  .statusi_banner .height_container {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .statusi_banner {
    height: 120px;
  }
  .statusi_banner .height_container {
    height: 120px;
  }
  .statusi_banner .height_container .banner_text h1 {
    font-size: 23px;
  }
}

.levizja_statusi {
  float: left;
  width: 840px;
  width: 52.5rem;
  margin-right: 148px;
  margin-right: 9.25rem;
  margin-bottom: 122px;
  margin-bottom: 7.625rem;
}

.levizja_statusi .deputetet_heading {
  padding-top: 59px;
  padding-top: 3.6875rem;
  margin-bottom: 48px;
  margin-bottom: 3rem;
  height: 86px;
  height: 5.375rem;
  display: flex;
  align-items: center;
}

.levizja_statusi .deputetet_heading span {
  content: " ";
  background-image: url("../images/dash.svg");
  height: 5px;
  height: 0.3125rem;
  width: 20px;
  width: 1.25rem;
}

.levizja_statusi .deputetet_heading h2 {
  font-size: 27px;
  font-size: 1.6875rem;
  font-weight: 700;
  color: #231f20;
  text-transform: uppercase;
  margin-left: 29px;
  margin-left: 1.8125rem;
}

.levizja_statusi ul {
  margin-left: auto;
}

.levizja_statusi ul li {
  display: inline-block;
  margin-right: 15px;
  margin-right: 0.9375rem;
}

.levizja_statusi ul li a {
  text-decoration: none;
  font-size: 13px;
  font-size: 0.8125rem;
  color: #666666;
}

.levizja_statusi ul li a:hover {
  color: #e2001a;
  cursor: pointer;
}

.levizja_statusi ul li .pdf_icon:after {
  content: "\f1c1";
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-size: 1rem;
  padding-left: 10px;
  padding-left: 0.625rem;
}

.levizja_statusi ul li .pdf_icon:after:hover {
  color: #e2001a;
  cursor: pointer;
}

.levizja_statusi ul li .print_icon:after {
  content: "\f02f";
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-size: 1rem;
  padding-left: 10px;
  padding-left: 0.625rem;
}

.levizja_statusi ul li .print_icon:after:hover {
  color: #e2001a;
  cursor: pointer;
}

.levizja_statusi ul li .social_icons {
  font-size: 14px;
  font-size: 0.875rem;
}

.levizja_statusi ul li .social_icons:hover {
  color: #e2001a;
  cursor: pointer;
}

.levizja_statusi ul li:last-child {
  margin-right: 0;
  margin-right: 0;
}

.levizja_statusi ul li:nth-child(3) a {
  cursor: default;
}

.levizja_statusi ul li:nth-child(3) a:hover {
  color: #666666;
}

.levizja_statusi #see_all {
  color: #666;
  text-decoration: none;
  margin-left: auto;
}

.levizja_statusi #see_all span {
  content: " ";
  background-image: url("../images/lexomeshume.svg");
  height: 8px;
  height: 0.5rem;
  width: 14px;
  width: 0.875rem;
  margin-left: 22px;
  margin-left: 1.375rem;
  display: block;
  float: right;
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-top: 3px;
  margin-top: 0.1875rem;
}

.levizja_statusi #see_all:hover {
  color: #e2001a;
}

.levizja_statusi #see_all:hover span {
  content: " ";
  background-image: url("../images/lexomeshume-red.svg");
  height: 8px;
  height: 0.5rem;
  width: 14px;
  width: 0.875rem;
  margin-left: 22px;
  margin-left: 1.375rem;
  display: block;
  float: right;
  margin-left: 20px;
  margin-left: 1.25rem;
  margin-top: 3px;
  margin-top: 0.1875rem;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .levizja_statusi {
    width: 100%;
    margin-right: 0;
    margin-top: 40px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .levizja_statusi {
    width: 100%;
    margin-right: 0;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .levizja_statusi {
    width: 100%;
    margin-right: 0;
    margin-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .levizja_statusi {
    width: 70%;
    margin-right: 5%;
  }
  .levizja_statusi .deputetet_heading h2 {
    margin-left: 15px;
    font-size: 25px;
  }
  .levizja_statusi .deputetet_heading ul li {
    margin-right: 12px;
  }
}

.statuti_right {
  float: left;
  width: 290px;
  width: 18.125rem;
  margin-top: 59px;
  margin-top: 3.6875rem;
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
}

.statuti_right ul li {
  width: 100%;
  float: left;
}

.statuti_right ul li a {
  width: 100%;
  display: flex;
  align-items: center;
  height: 70px;
  height: 4.375rem;
  text-decoration: none;
  font-size: 14px;
  font-size: 0.875rem;
  padding-right: 30px;
  padding-right: 1.875rem;
  color: #231f20;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.statuti_right ul li a span {
  float: left;
  width: 57px;
  width: 3.5625rem;
  color: #e2001a;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.statuti_right ul li a p {
  float: left;
  width: 100%;
}

.statuti_right ul li a #statuti_heading {
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
}

.statuti_right ul li a.active, .statuti_right ul li a:hover {
  color: #e2001a;
  border-right: 5px solid #e2001a;
  box-shadow: -84px 0px 0px 0px white;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .statuti_right ul li a.active, .statuti_right ul li a:hover {
    box-shadow: -50px 0px 0px 0px white;
  }
}

.statuti_right ul li #statuti_dash {
  float: left;
  content: " ";
  background-image: url("../images/dash.svg");
  height: 5px;
  height: 0.3125rem;
  width: 10px;
  width: 0.625rem;
  margin: 0 16px 0 27px;
  margin: 0 1rem 0 1.6875rem;
}

.statuti_right ul li:hover:first-child a {
  color: #231f20;
  box-shadow: none;
  border-right: none;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .statuti_right {
    width: 100%;
  }
  .statuti_right ul li a.active {
    color: #e2001a;
    box-shadow: none;
    border-right: 5px solid #e2001a;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  }
  .statuti_right ul li.hide_prioritetet {
    display: none;
  }
  .statuti_right ul li.hide_statuti {
    display: none;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .statuti_right {
    width: 100%;
  }
  .statuti_right ul li a.active {
    color: #e2001a;
    box-shadow: none;
    border-right: 5px solid #e2001a;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  }
  .statuti_right ul li.hide_prioritetet {
    display: none;
  }
  .statuti_right ul li.hide_statuti {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .statuti_right {
    width: 100%;
  }
  .statuti_right ul li a.active {
    color: #e2001a;
    box-shadow: none;
    border-right: 5px solid #e2001a;
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  }
  .statuti_right ul li.hide_prioritetet {
    display: none;
  }
  .statuti_right ul li.hide_statuti {
    display: none;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .statuti_right {
    width: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  #levizja_statuti_container {
    display: flex;
    flex-direction: column;
  }
  #levizja_statuti_container #levizja_statusi {
    order: 2;
  }
  #levizja_statuti_container #statuti_right {
    order: 1;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  #levizja_statuti_container {
    display: flex;
    flex-direction: column;
  }
  #levizja_statuti_container #levizja_statusi {
    order: 2;
  }
  #levizja_statuti_container #statuti_right {
    order: 1;
  }
}

@media only screen and (max-width: 767px) {
  #levizja_statuti_container {
    display: flex;
    flex-direction: column;
  }
  #levizja_statuti_container #levizja_statusi {
    order: 2;
  }
  #levizja_statuti_container #statuti_right {
    order: 1;
  }
}

.neni {
  float: left;
  width: 100%;
  margin-bottom: 29px;
  margin-bottom: 1.8125rem;
}

.neni .neni_heading {
  font-size: 21px;
  font-size: 1.3125rem;
  display: flex;
  font-weight: 700;
  color: #231f20;
  text-decoration: none;
  margin-bottom: 29px;
  margin-bottom: 1.8125rem;
}

.neni .neni_heading:after {
  content: "";
  height: 1px;
  height: 0.0625rem;
  margin-left: 19px;
  margin-left: 1.1875rem;
  background-color: rgba(0, 0, 0, 0.1);
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.neni .neni_content {
  color: #666666;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 27px;
}

.neni:last-child {
  margin-bottom: 0;
  margin-bottom: 0;
}

.prioritetet {
  float: left;
  width: 100%;
  margin-bottom: 270px;
  margin-bottom: 16.875rem;
}

.prioritetet .prioriteti_heading {
  font-size: 21px;
  font-size: 1.3125rem;
  display: flex;
  font-weight: 700;
  color: #231f20;
  text-decoration: none;
  margin-bottom: 29px;
  margin-bottom: 1.8125rem;
}

.prioritetet .prioriteti_heading span {
  color: #e2001a;
}

.prioritetet .active_pr {
  color: #e2001a !important;
}

.prioritetet .prioriteti_content {
  color: #666666;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 27px;
  border-bottom: 1px solid RGBA(0, 0, 0, 0.1);
  padding-bottom: 44px;
  padding-bottom: 2.75rem;
  margin-bottom: 50px;
  margin-bottom: 3.125rem;
}

.prioritetet p:last-child {
  margin-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.fletushkat_banner {
  background-image: url("../images/publikimet.png");
  height: 250px;
  height: 15.625rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.fletushkat_banner .height_container {
  height: 250px;
  height: 15.625rem;
}

.fletushkat_banner .banner_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.fletushkat_banner .banner_text .gr_parl_banner_title {
  font-size: 36px;
  font-size: 2.25rem;
  color: #fff;
  font-weight: 700;
}

.fletushkat_banner .banner_text .gr_parl_banner_subtitle {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 11px;
  margin-top: 0.6875rem;
  color: #fff;
}

.fletushkat_banner .banner_text .gr_parl_banner_subtitle p, .fletushkat_banner .banner_text .gr_parl_banner_subtitle span {
  float: left;
  opacity: 0.5;
}

.fletushkat_banner .banner_text .gr_parl_banner_subtitle span:before {
  content: "  ";
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .fletushkat_banner {
    height: 200px;
  }
  .fletushkat_banner .height_container {
    height: 200px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .fletushkat_banner {
    height: 200px;
  }
  .fletushkat_banner .height_container {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .fletushkat_banner {
    height: 120px;
  }
  .fletushkat_banner .height_container {
    height: 120px;
  }
  .fletushkat_banner .height_container .banner_text h1 {
    font-size: 23px;
  }
}

.fletushkat_background {
  background: #fff;
}

.fletushkat_background .publikimet_heading {
  padding-top: 59px;
  padding-top: 3.6875rem;
  margin-bottom: 48px;
  margin-bottom: 3rem;
  height: 86px;
  height: 5.375rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.fletushkat_background .publikimet_heading span {
  content: " ";
  background-image: url("../images/dash.svg");
  height: 5px;
  height: 0.3125rem;
  width: 20px;
  width: 1.25rem;
}

.fletushkat_background .publikimet_heading h2 {
  font-size: 27px;
  font-size: 1.6875rem;
  font-weight: 700;
  color: #231f20;
  text-transform: uppercase;
  margin-left: 29px;
  margin-left: 1.8125rem;
}

.fletushkat_background .publikimet_heading #kerko_button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #231f20;
  border: 1px solid #e6e6e6;
  font-size: 15px;
  font-size: 0.9375rem;
  border-radius: 50px;
}

.fletushkat_background .publikimet_heading #kerko_button input {
  outline: none;
}

.fletushkat_background .publikimet_heading #kerko_button input[type=search] {
  -webkit-appearance: textfield;
  font-family: inherit;
  font-size: 100%;
}

.fletushkat_background .publikimet_heading #kerko_button input::-webkit-search-decoration,
.fletushkat_background .publikimet_heading #kerko_button input::-webkit-search-cancel-button {
  display: none;
}

.fletushkat_background .publikimet_heading #kerko_button input[type=search] {
  background: #fff url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 18px center;
  border: solid 0px;
  padding: 0px 10px 0px 32px;
  width: 135px;
  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
}

.fletushkat_background .publikimet_heading #kerko_button input[type=search]:focus {
  width: 100%;
  background-color: #fff;
  outline: none;
}

.fletushkat_background .publikimet_heading #kerko_button input:-moz-placeholder {
  color: #231f20;
  font-size: 15px;
  font-weight: 300;
}

.fletushkat_background .publikimet_heading #kerko_button input::-webkit-input-placeholder {
  color: #231f20;
  font-size: 15px;
  font-weight: 300;
}

.fletushkat_background .publikimet_heading #kerko_button input[type=search] {
  height: 50px;
  padding-left: 55px;
  cursor: pointer;
}

.fletushkat_background .publikimet_heading #kerko_button input[type=search]:hover {
  background-color: #fff;
}

.fletushkat_background .publikimet_heading #kerko_button input[type=search]:focus {
  width: 230px;
  padding-left: 55px;
  color: #000;
  outline: none;
  background-color: #fff;
  cursor: auto;
}

.fletushkat_background .publikimet_heading ul {
  position: absolute;
  right: 0;
}

.fletushkat_background .publikimet_heading ul li {
  display: inline-block;
  margin-right: 20px;
  margin-right: 1.25rem;
}

.fletushkat_background .publikimet_heading ul li a {
  text-decoration: none;
  font-size: 13px;
  font-size: 0.8125rem;
  color: #666666;
}

.fletushkat_background .publikimet_heading ul li a:hover {
  color: #e2001a;
  cursor: pointer;
}

.fletushkat_background .publikimet_heading ul li .pdf_icon:after {
  content: "\f1c1";
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-size: 1rem;
  padding-left: 10px;
  padding-left: 0.625rem;
}

.fletushkat_background .publikimet_heading ul li .pdf_icon:after:hover {
  color: #e2001a;
  cursor: pointer;
}

.fletushkat_background .publikimet_heading ul li .print_icon:after {
  content: "\f02f";
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-size: 1rem;
  padding-left: 10px;
  padding-left: 0.625rem;
}

.fletushkat_background .publikimet_heading ul li .print_icon:after:hover {
  color: #e2001a;
  cursor: pointer;
}

.fletushkat_background .publikimet_heading ul li .social_icons {
  font-size: 14px;
  font-size: 0.875rem;
}

.fletushkat_background .publikimet_heading ul li .social_icons:hover {
  color: #e2001a;
  cursor: pointer;
}

.fletushkat_background .publikimet_heading ul li:last-child {
  margin-right: 0;
  margin-right: 0;
}

.fletushkat_background .publikimet_heading ul li:nth-child(3) a {
  cursor: default;
}

.fletushkat_background .publikimet_heading ul li:nth-child(3) a:hover {
  color: #666666;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .fletushkat_background .publikimet_heading {
    margin-bottom: 100px;
  }
  .fletushkat_background .publikimet_heading #kerko_button {
    top: 120%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .fletushkat_background .publikimet_heading {
    margin-bottom: 100px;
  }
  .fletushkat_background .publikimet_heading #kerko_button {
    top: 120%;
  }
}

@media only screen and (max-width: 767px) {
  .fletushkat_background .publikimet_heading {
    margin-bottom: 50px;
    padding-top: 20px;
  }
  .fletushkat_background .publikimet_heading #kerko_button {
    top: 88%;
    position: static;
    transform: none;
    width: 100%;
    margin-top: 18px;
  }
  .fletushkat_background .publikimet_heading #kerko_button input[type=search] {
    float: left;
    width: 100%;
    clear: both;
    height: 40px;
    transform: none;
  }
  .fletushkat_background .publikimet_heading h2 {
    margin-left: 25px;
  }
}

.fletushkat_background .publikimet_default_template_text {
  float: left;
  margin-bottom: 50px;
  margin-bottom: 3.125rem;
}

.fletushkat_background .publikimet_default_template_text h4 {
  font-size: 21px;
  font-size: 1.3125rem;
  font-weight: 700;
  color: #231f20;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.fletushkat_background .publikimet_default_template_text p {
  font-size: 15px;
  font-size: 0.9375rem;
  color: #666666;
  line-height: 27px;
}

.fletushkat_background .media_grid {
  float: left;
  width: 100%;
  clear: both;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.fletushkat_background .media_grid ul > :nth-child(4n+4) {
  margin-right: 0;
  margin-right: 0;
}

.fletushkat_background .media_grid ul li {
  float: left;
  width: 290px;
  width: 18.125rem;
  height: 290px;
  height: 18.125rem;
  margin-right: 40px;
  margin-right: 2.5rem;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
  padding-top: 80px;
  padding-top: 5rem;
  border: 1px solid #e6e6e6;
  text-align: center;
}

.fletushkat_background .media_grid ul li a {
  text-decoration: none;
  color: #231f20;
  font-size: 18px;
  font-size: 1.125rem;
}

.fletushkat_background .media_grid ul li a img {
  width: 45px;
  width: 2.8125rem;
  height: 62px;
  height: 3.875rem;
  margin: 0 auto;
  margin-bottom: 55px;
  margin-bottom: 3.4375rem;
}

.fletushkat_background .media_grid ul li a h4 {
  font-weight: 700;
  margin-bottom: 7px;
  margin-bottom: 0.4375rem;
}

.fletushkat_background .media_grid ul li:hover {
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
}

.fletushkat_background .media_grid ul li:hover h4, .fletushkat_background .media_grid ul li:hover p {
  color: #e2001a;
  text-decoration: underline;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .fletushkat_background .media_grid ul .fletushka_block {
    width: 30%;
    height: 200px;
    padding-top: 37px;
    margin-right: 5%;
  }
  .fletushkat_background .media_grid ul .fletushka_block img {
    margin-bottom: 23px;
  }
  .fletushkat_background .media_grid ul .fletushka_block:nth-child(3n+3) {
    margin-right: 0 !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .fletushkat_background .media_grid ul .fletushka_block {
    width: 30%;
    height: 200px;
    padding-top: 37px;
    margin-right: 5%;
  }
  .fletushkat_background .media_grid ul .fletushka_block img {
    margin-bottom: 23px;
  }
  .fletushkat_background .media_grid ul .fletushka_block:nth-child(3n+3) {
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .fletushkat_background .media_grid ul .fletushka_block {
    width: 100%;
    margin-right: 0;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .fletushkat_background .media_grid ul .fletushka_block {
    width: 22%;
    height: 246px;
    padding-top: 37px;
    margin-right: 4%;
  }
  .fletushkat_background .media_grid ul .fletushka_block img {
    margin-bottom: 23px;
  }
  .fletushkat_background .media_grid ul .fletushka_block:nth-child(4n+4) {
    margin-right: 0 !important;
  }
}

.fletushkat_background .publikimet_grid_template {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.alternativa_banner {
  background-image: url("../images/alternativa_banner.png");
  height: 250px;
  height: 15.625rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.alternativa_banner .height_container {
  height: 250px;
  height: 15.625rem;
}

.alternativa_banner .banner_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.alternativa_banner .banner_text .gr_parl_banner_title {
  font-size: 36px;
  font-size: 2.25rem;
  color: #fff;
  font-weight: 700;
}

.alternativa_banner .banner_text .gr_parl_banner_subtitle {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 11px;
  margin-top: 0.6875rem;
  color: #fff;
}

.alternativa_banner .banner_text .gr_parl_banner_subtitle p, .alternativa_banner .banner_text .gr_parl_banner_subtitle span {
  float: left;
  opacity: 0.5;
}

.alternativa_banner .banner_text .gr_parl_banner_subtitle span:before {
  content: "  ";
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .alternativa_banner {
    height: 200px;
  }
  .alternativa_banner .height_container {
    height: 200px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .alternativa_banner {
    height: 200px;
  }
  .alternativa_banner .height_container {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .alternativa_banner {
    height: 120px;
  }
  .alternativa_banner .height_container {
    height: 120px;
  }
  .alternativa_banner .height_container .banner_text h1 {
    font-size: 23px;
  }
}

.alternativa_shteti_se_drejtes {
  width: 840px;
  width: 52.5rem;
  margin-right: 148px;
  margin-right: 9.25rem;
}

.alternativa_shteti_se_drejtes .shteti_se_drejtes_content h4 {
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  font-weight: 700;
  color: #666666;
  line-height: 27px;
  margin-bottom: 33px;
  margin-bottom: 2.0625rem;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.alternativa_shteti_se_drejtes .shteti_se_drejtes_content img {
  float: left;
  width: 840px;
  width: 52.5rem;
  height: 560px;
  height: 35rem;
}

.alternativa_shteti_se_drejtes .shteti_se_drejtes_content h3 {
  float: left;
  font-size: 21px;
  font-size: 1.3125rem;
  font-weight: 700;
  color: #231f20;
  margin-top: 55px;
  margin-top: 3.4375rem;
}

.alternativa_shteti_se_drejtes .shteti_se_drejtes_content p {
  float: left;
  width: 100%;
  font-size: 15px;
  font-size: 0.9375rem;
  color: #666666;
  line-height: 27px;
  margin-top: 28px;
  margin-top: 1.75rem;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.alternativa_shteti_se_drejtes .shteti_se_drejtes_content .sidebar_template_text_content {
  margin-bottom: 77px;
  margin-bottom: 4.8125rem;
}

@media only screen and (max-width: 767px) {
  .alternativa_shteti_se_drejtes .shteti_se_drejtes_content .sidebar_template_text_content {
    margin-bottom: 0;
  }
}

.alternativa_shteti_se_drejtes .shteti_se_drejtes_content .shkarko_alternativen {
  float: left;
  width: 100%;
  text-align: center;
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  background: #e2001a;
  border-radius: 2px;
  padding: 16px 0 20px 0;
  padding: 1rem 0 1.25rem 0;
  margin-top: 33px;
  margin-top: 2.0625rem;
}

.alternativa_shteti_se_drejtes .shteti_se_drejtes_content .shkarko_alternativen_plote {
  float: left;
  width: 100%;
  text-align: center;
  text-decoration: none;
  color: #e2001a;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  background: #f2f2f2;
  border-radius: 2px;
  padding: 16px 0 20px 0;
  padding: 1rem 0 1.25rem 0;
  margin-top: 33px;
  margin-top: 2.0625rem;
  margin-bottom: 202px;
  margin-bottom: 12.625rem;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .alternativa_shteti_se_drejtes {
    width: 100%;
    margin-right: 0;
  }
  .alternativa_shteti_se_drejtes .shteti_se_drejtes_content img {
    width: 100%;
  }
  .alternativa_shteti_se_drejtes .shteti_se_drejtes_content .shkarko_alternativen_plote {
    margin-bottom: 80px !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .alternativa_shteti_se_drejtes {
    width: 100%;
    margin-right: 0;
  }
  .alternativa_shteti_se_drejtes .shteti_se_drejtes_content img {
    width: 100%;
  }
  .alternativa_shteti_se_drejtes .shteti_se_drejtes_content .shkarko_alternativen_plote {
    margin-bottom: 80px !important;
  }
}

@media only screen and (max-width: 767px) {
  .alternativa_shteti_se_drejtes {
    width: 100%;
    margin-right: 0;
  }
  .alternativa_shteti_se_drejtes .deputetet_heading {
    padding-top: 14px;
    margin-bottom: 0;
  }
  .alternativa_shteti_se_drejtes .deputetet_heading h2 {
    font-size: 23px;
    margin-left: 25px;
  }
  .alternativa_shteti_se_drejtes .shteti_se_drejtes_content img {
    width: 100%;
    height: auto !important;
  }
  .alternativa_shteti_se_drejtes .shteti_se_drejtes_content h3 {
    margin-top: 45px;
  }
  .alternativa_shteti_se_drejtes .shteti_se_drejtes_content h4 {
    font-size: 13px;
    line-height: 20px;
  }
  .alternativa_shteti_se_drejtes .shteti_se_drejtes_content iframe {
    height: auto;
  }
  .alternativa_shteti_se_drejtes .shteti_se_drejtes_content p {
    font-size: 13px;
    line-height: 20px;
  }
  .alternativa_shteti_se_drejtes .shteti_se_drejtes_content .shkarko_alternativen {
    font-size: 13px;
    padding: 15px;
  }
  .alternativa_shteti_se_drejtes .shteti_se_drejtes_content .shkarko_alternativen_plote {
    font-size: 13px;
    padding: 15px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .alternativa_shteti_se_drejtes {
    width: 70%;
    margin-right: 5%;
  }
  .alternativa_shteti_se_drejtes .deputetet_heading h2 {
    margin-left: 15px;
    font-size: 25px;
  }
}

.alternativa_kontribo_section {
  float: right;
  clear: right;
  width: 290px;
  width: 18.125rem;
  margin-top: 59px;
  margin-top: 3.6875rem;
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
  margin-bottom: 38px;
  margin-bottom: 2.375rem;
  background: #fff;
}

.alternativa_kontribo_section .kontribo_section_heading {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 26px 32px 20px 31px;
  padding: 1.625rem 2rem 1.25rem 1.9375rem;
}

.alternativa_kontribo_section .kontribo_section_heading span {
  float: left;
  content: " ";
  background-image: url("../images/dash.svg");
  width: 10px;
  width: 0.625rem;
  height: 5px;
  height: 0.3125rem;
  margin-right: 17px;
  margin-right: 1.0625rem;
}

.alternativa_kontribo_section .kontribo_section_heading p {
  float: left;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  color: #231f20;
  text-transform: uppercase;
}

.alternativa_kontribo_section .kontribo_img {
  float: left;
  width: 100%;
  height: auto;
}

.alternativa_kontribo_section .kontribo_forma {
  float: left;
  width: 100%;
  padding: 30px;
  padding: 1.875rem;
}

.alternativa_kontribo_section .kontribo_forma form {
  float: left;
  width: 100%;
}

.alternativa_kontribo_section .kontribo_forma form > :nth-child(2n+2) {
  margin-right: 0;
  margin-right: 0;
}

.alternativa_kontribo_section .kontribo_forma form .kontribo_form_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.alternativa_kontribo_section .kontribo_forma form .input_holder {
  float: left;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.alternativa_kontribo_section .kontribo_forma form .input_holder input:checked ~ .kontribo_button {
  border-color: transparent;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
}

.alternativa_kontribo_section .kontribo_forma form .input_holder input:checked ~ .kontribo_button i,
.alternativa_kontribo_section .kontribo_forma form .input_holder input:checked ~ .kontribo_button span {
  color: #e2001a;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.alternativa_kontribo_section .kontribo_forma form .kontribo_button {
  float: left;
  width: 105px;
  width: 6.5625rem;
  height: 47px;
  height: 2.9375rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  text-align: center;
  border: 1px solid #e6e6e6;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.alternativa_kontribo_section .kontribo_forma form .kontribo_button input[type=text],
.alternativa_kontribo_section .kontribo_forma form .kontribo_button input[type=number] {
  float: left;
  width: 60px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
  padding-right: 15px;
  line-height: 44px;
  background: transparent;
  border: 0;
}

.alternativa_kontribo_section .kontribo_forma form .kontribo_button input[type=text] {
  text-align: center;
  padding-right: 0;
}

.alternativa_kontribo_section .kontribo_forma form .kontribo_button i {
  float: left;
  width: 43px;
  font-size: 18px;
  text-align: center;
  line-height: 44px;
}

.alternativa_kontribo_section .kontribo_forma form .kontribo_button span {
  line-height: 46px;
  font-weight: 700;
  font-size: 16px;
}

.alternativa_kontribo_section .kontribo_forma form .kontribo_button:hover {
  border: none;
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
}

.alternativa_kontribo_section .kontribo_forma form .kontribo_button:hover label {
  color: #e2001a;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.alternativa_kontribo_section .kontribo_forma form button {
  float: left;
  height: 47px;
  height: 2.9375rem;
  width: 100%;
  background: #e2001a;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, 0) !important;
  outline: none !important;
}

@media only screen and (max-width: 767px) {
  .alternativa_kontribo_section .kontribo_forma p {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .alternativa_kontribo_section {
    margin-top: 0;
    width: 45%;
    float: left;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .alternativa_kontribo_section {
    margin-top: 0;
    width: 45%;
    float: left;
  }
  .alternativa_kontribo_section .kontribo_forma form .kontribo_button {
    width: 150px !important;
    height: 55px !important;
  }
  .alternativa_kontribo_section .kontribo_forma form .kontribo_button i, .alternativa_kontribo_section .kontribo_forma form .kontribo_button span {
    line-height: 55px;
  }
  .alternativa_kontribo_section .kontribo_forma form button {
    height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .alternativa_kontribo_section {
    width: 100%;
  }
  .alternativa_kontribo_section .kontribo_forma form .kontribo_button {
    width: 120px !important;
    height: 50px !important;
  }
  .alternativa_kontribo_section .kontribo_forma form .kontribo_button i, .alternativa_kontribo_section .kontribo_forma form .kontribo_button span {
    line-height: 50px;
  }
  .alternativa_kontribo_section .kontribo_forma form button {
    height: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .alternativa_kontribo_section {
    width: 25%;
  }
  .alternativa_kontribo_section .kontribo_forma form .kontribo_button {
    width: 100px;
  }
}

@media (max-width: 320px) {
  .alternativa_kontribo_section .kontribo_forma form .kontribo_form_wrap .input_holder .kontribo_button {
    width: 107px !important;
  }
}

.alternativa_lajmet_e_fundit {
  float: right;
  clear: right;
  width: 290px;
  width: 18.125rem;
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
  background: #fff;
  padding: 27px 30px 27px 30px;
  padding: 1.6875rem 1.875rem 1.6875rem 1.875rem;
  margin-bottom: 38px;
}

.alternativa_lajmet_e_fundit .lajmet_section_heading {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
}

.alternativa_lajmet_e_fundit .lajmet_section_heading span {
  float: left;
  content: " ";
  background-image: url("../images/dash.svg");
  width: 10px;
  width: 0.625rem;
  height: 5px;
  height: 0.3125rem;
  margin-right: 17px;
  margin-right: 1.0625rem;
}

.alternativa_lajmet_e_fundit .lajmet_section_heading p {
  float: left;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  color: #231f20;
  text-transform: uppercase;
}

.alternativa_lajmet_e_fundit .lajmifundit_lajmi {
  float: left;
  width: 100%;
  margin-top: 27px;
  margin-top: 1.6875rem;
}

.alternativa_lajmet_e_fundit .lajmifundit_lajmi .lajmi_img {
  float: left;
  width: 100%;
  height: auto;
}

.alternativa_lajmet_e_fundit .lajmifundit_lajmi p {
  float: left;
  width: 100%;
  margin-top: 22px;
  margin-top: 1.375rem;
  margin-bottom: 13px;
  margin-bottom: 0.8125rem;
  font-size: 12px;
  font-size: 0.75rem;
  color: #666666;
}

.alternativa_lajmet_e_fundit .lajmifundit_lajmi p span {
  margin-left: 10px;
  margin-left: 0.625rem;
  margin-right: 10px;
  margin-right: 0.625rem;
}

.alternativa_lajmet_e_fundit .lajmifundit_lajmi h4 {
  float: left;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  color: #231f20;
}

.alternativa_lajmet_e_fundit .lajmifundit_lajmi:hover h4 {
  color: #e2001a;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .alternativa_lajmet_e_fundit {
    float: right;
    width: 45%;
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .alternativa_lajmet_e_fundit {
    float: right;
    width: 45%;
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .alternativa_lajmet_e_fundit {
    width: 100%;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .alternativa_lajmet_e_fundit {
    width: 25%;
  }
}

.gazetat_banner {
  background-image: url("../images/publikimet.png");
  height: 250px;
  height: 15.625rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: -1px;
}

.gazetat_banner .height_container {
  height: 250px;
  height: 15.625rem;
}

.gazetat_banner .banner_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.gazetat_banner .banner_text .gr_parl_banner_title {
  font-size: 36px;
  font-size: 2.25rem;
  color: #fff;
  font-weight: 700;
}

.gazetat_banner .banner_text .gr_parl_banner_subtitle {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 11px;
  margin-top: 0.6875rem;
  color: #fff;
}

.gazetat_banner .banner_text .gr_parl_banner_subtitle p, .gazetat_banner .banner_text .gr_parl_banner_subtitle span {
  float: left;
  opacity: 0.5;
}

.gazetat_banner .banner_text .gr_parl_banner_subtitle span:before {
  content: "  ";
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .gazetat_banner {
    height: 200px;
  }
  .gazetat_banner .height_container {
    height: 200px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .gazetat_banner {
    height: 200px;
  }
  .gazetat_banner .height_container {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .gazetat_banner {
    height: 120px;
  }
  .gazetat_banner .height_container {
    height: 120px;
  }
  .gazetat_banner .height_container .banner_text h1 {
    font-size: 23px;
  }
}

.gazetat_background {
  float: left;
  width: 100%;
  background: #fff;
}

.gazetat_background .publikimet_heading {
  padding-top: 59px;
  padding-top: 3.6875rem;
  margin-bottom: 48px;
  margin-bottom: 3rem;
  height: 86px;
  height: 5.375rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.gazetat_background .publikimet_heading span {
  content: " ";
  background-image: url("../images/dash.svg");
  height: 5px;
  height: 0.3125rem;
  width: 20px;
  width: 1.25rem;
}

.gazetat_background .publikimet_heading h2 {
  font-size: 27px;
  font-size: 1.6875rem;
  font-weight: 700;
  color: #231f20;
  text-transform: uppercase;
  margin-left: 29px;
  margin-left: 1.8125rem;
}

.gazetat_background .publikimet_heading #kerko_button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #231f20;
  border: 1px solid #e6e6e6;
  font-size: 15px;
  font-size: 0.9375rem;
  border-radius: 50px;
}

.gazetat_background .publikimet_heading #kerko_button input {
  outline: none;
}

.gazetat_background .publikimet_heading #kerko_button input[type=search] {
  -webkit-appearance: textfield;
  font-family: inherit;
  font-size: 100%;
}

.gazetat_background .publikimet_heading #kerko_button input::-webkit-search-decoration,
.gazetat_background .publikimet_heading #kerko_button input::-webkit-search-cancel-button {
  display: none;
}

.gazetat_background .publikimet_heading #kerko_button input[type=search] {
  background: #fff url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 18px center;
  border: solid 0px;
  padding: 0px 10px 0px 32px;
  width: 135px;
  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
}

.gazetat_background .publikimet_heading #kerko_button input[type=search]:focus {
  width: 100%;
  background-color: #fff;
  outline: none;
}

.gazetat_background .publikimet_heading #kerko_button input:-moz-placeholder {
  color: #231f20;
  font-size: 15px;
  font-weight: 300;
}

.gazetat_background .publikimet_heading #kerko_button input::-webkit-input-placeholder {
  color: #231f20;
  font-size: 15px;
  font-weight: 300;
}

.gazetat_background .publikimet_heading #kerko_button input[type=search] {
  height: 50px;
  padding-left: 55px;
  cursor: pointer;
}

.gazetat_background .publikimet_heading #kerko_button input[type=search]:hover {
  background-color: #fff;
}

.gazetat_background .publikimet_heading #kerko_button input[type=search]:focus {
  width: 230px;
  padding-left: 55px;
  color: #000;
  outline: none;
  background-color: #fff;
  cursor: auto;
}

.gazetat_background .publikimet_heading ul {
  position: absolute;
  right: 0;
}

.gazetat_background .publikimet_heading ul li {
  display: inline-block;
  margin-right: 20px;
  margin-right: 1.25rem;
}

.gazetat_background .publikimet_heading ul li a {
  text-decoration: none;
  font-size: 13px;
  font-size: 0.8125rem;
  color: #666666;
}

.gazetat_background .publikimet_heading ul li a:hover {
  color: #e2001a;
  cursor: pointer;
}

.gazetat_background .publikimet_heading ul li .pdf_icon:after {
  content: "\f1c1";
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-size: 1rem;
  padding-left: 10px;
  padding-left: 0.625rem;
}

.gazetat_background .publikimet_heading ul li .pdf_icon:after:hover {
  color: #e2001a;
  cursor: pointer;
}

.gazetat_background .publikimet_heading ul li .print_icon:after {
  content: "\f02f";
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-size: 1rem;
  padding-left: 10px;
  padding-left: 0.625rem;
}

.gazetat_background .publikimet_heading ul li .print_icon:after:hover {
  color: #e2001a;
  cursor: pointer;
}

.gazetat_background .publikimet_heading ul li .social_icons {
  font-size: 14px;
  font-size: 0.875rem;
}

.gazetat_background .publikimet_heading ul li .social_icons:hover {
  color: #e2001a;
  cursor: pointer;
}

.gazetat_background .publikimet_heading ul li:last-child {
  margin-right: 0;
  margin-right: 0;
}

.gazetat_background .publikimet_heading ul li:nth-child(3) a {
  cursor: default;
}

.gazetat_background .publikimet_heading ul li:nth-child(3) a:hover {
  color: #666666;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .gazetat_background .publikimet_heading {
    margin-bottom: 100px;
  }
  .gazetat_background .publikimet_heading #kerko_button {
    top: 120%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .gazetat_background .publikimet_heading {
    margin-bottom: 100px;
  }
  .gazetat_background .publikimet_heading #kerko_button {
    top: 120%;
  }
}

@media only screen and (max-width: 767px) {
  .gazetat_background .publikimet_heading {
    margin-bottom: 50px;
    padding-top: 20px;
  }
  .gazetat_background .publikimet_heading #kerko_button {
    top: 88%;
    position: static;
    transform: none;
    width: 100%;
    margin-top: 17px;
  }
  .gazetat_background .publikimet_heading #kerko_button input[type=search] {
    float: left;
    width: 100%;
    clear: both;
    height: 40px;
    transform: none;
  }
  .gazetat_background .publikimet_heading h2 {
    margin-left: 25px;
  }
}

.gazetat_background .gazetat_grid {
  float: left;
  width: 100%;
  clear: both;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.gazetat_background .gazetat_grid ul > :nth-child(4n+4) {
  margin-right: 0;
  margin-right: 0;
}

.gazetat_background .gazetat_grid ul li {
  float: left;
  width: 290px;
  width: 18.125rem;
  height: 450px;
  height: 28.125rem;
  margin-right: 40px;
  margin-right: 2.5rem;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
  padding-top: 20px;
  padding-top: 1.25rem;
  border: 1px solid #e6e6e6;
  text-align: center;
}

.gazetat_background .gazetat_grid ul li a {
  text-decoration: none;
  color: #231f20;
  font-size: 18px;
  font-size: 1.125rem;
}

.gazetat_background .gazetat_grid ul li a img {
  width: 245px;
  width: 15.3125rem;
  height: 323px;
  height: 20.1875rem;
  margin: 0 auto;
  margin-bottom: 28px;
  margin-bottom: 1.75rem;
}

.gazetat_background .gazetat_grid ul li a h4 {
  font-weight: 700;
  margin-bottom: 7px;
  margin-bottom: 0.4375rem;
}

.gazetat_background .gazetat_grid ul li:hover {
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
}

.gazetat_background .gazetat_grid ul li:hover h4, .gazetat_background .gazetat_grid ul li:hover p {
  color: #e2001a;
  text-decoration: underline;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .gazetat_background .gazetat_grid ul .fletushka_block {
    width: 48%;
    margin-right: 2%;
  }
  .gazetat_background .gazetat_grid ul .fletushka_block:nth-child(2n+2) {
    margin-right: 0;
    margin-left: 2%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .gazetat_background .gazetat_grid ul .fletushka_block {
    width: 30%;
    margin-right: 5%;
  }
  .gazetat_background .gazetat_grid ul .fletushka_block:nth-child(3n+3) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .gazetat_background .gazetat_grid ul .fletushka_block {
    width: 100%;
    margin-right: 0;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .gazetat_background .gazetat_grid ul .fletushka_block {
    width: 23%;
    margin-right: 2.5%;
  }
  .gazetat_background .gazetat_grid ul .fletushka_block:nth-child(4n+4) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .gazetat_background .pagination_wrapper {
    margin-top: 34px;
  }
}

.sidebar_template_banner {
  background-image: url("../images/publikimet.png");
  height: 250px;
  height: 15.625rem;
  margin-top: -1px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.sidebar_template_banner .height_container {
  height: 250px;
  height: 15.625rem;
}

.sidebar_template_banner .banner_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.sidebar_template_banner .banner_text .gr_parl_banner_title {
  font-size: 36px;
  font-size: 2.25rem;
  color: #fff;
  font-weight: 700;
}

.sidebar_template_banner .banner_text .gr_parl_banner_subtitle {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 11px;
  margin-top: 0.6875rem;
  color: #fff;
}

.sidebar_template_banner .banner_text .gr_parl_banner_subtitle p, .sidebar_template_banner .banner_text .gr_parl_banner_subtitle span {
  float: left;
  opacity: 0.5;
}

.sidebar_template_banner .banner_text .gr_parl_banner_subtitle span:before {
  content: "  ";
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .sidebar_template_banner {
    height: 200px;
  }
  .sidebar_template_banner .height_container {
    height: 200px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .sidebar_template_banner {
    height: 200px;
  }
  .sidebar_template_banner .height_container {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar_template_banner {
    height: 120px;
  }
  .sidebar_template_banner .height_container {
    height: 120px;
  }
  .sidebar_template_banner .height_container .banner_text h1 {
    font-size: 23px;
  }
}

.organogram_banner {
  background-image: url("../images/organogram-banner.png");
  height: 250px;
  height: 15.625rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.organogram_banner .height_container {
  height: 250px;
  height: 15.625rem;
}

.organogram_banner .banner_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.organogram_banner .banner_text .gr_parl_banner_title {
  font-size: 36px;
  font-size: 2.25rem;
  color: #fff;
  font-weight: 700;
}

.organogram_banner .banner_text .gr_parl_banner_subtitle {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 11px;
  margin-top: 0.6875rem;
  color: #fff;
}

.organogram_banner .banner_text .gr_parl_banner_subtitle p, .organogram_banner .banner_text .gr_parl_banner_subtitle span {
  float: left;
  opacity: 0.5;
}

.organogram_banner .banner_text .gr_parl_banner_subtitle span:before {
  content: "  ";
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .organogram_banner {
    height: 200px;
  }
  .organogram_banner .height_container {
    height: 200px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .organogram_banner {
    height: 200px;
  }
  .organogram_banner .height_container {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .organogram_banner {
    height: 120px;
  }
  .organogram_banner .height_container {
    height: 120px;
  }
  .organogram_banner .height_container .banner_text h1 {
    font-size: 23px;
  }
}

.organogrami_large_image {
  float: left;
  width: 100%;
  height: 704px;
  height: 44rem;
  margin-bottom: 45px;
  margin-bottom: 2.8125rem;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .organogrami_large_image {
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .organogrami_large_image {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .organogrami_large_image {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 320px) {
  .organogrami_large_image {
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .organogrami_large_image {
    width: 100%;
    height: auto;
  }
}

.organogrami_bottom_half {
  float: left;
  width: 100%;
  z-index: 5;
  box-shadow: 0 -9px 55px -19px #d9d6d9;
}

.kryesia_lista {
  float: left;
  width: 100%;
}

.kryesia_lista ul {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
}

.kryesia_lista ul > :nth-child(6n+6) {
  margin-right: 0;
  margin-right: 0;
}

.kryesia_lista ul li {
  float: left;
  width: 180px;
  width: 11.25rem;
  margin-right: 40px;
  margin-right: 2.5rem;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
  border: 1px solid #e6e6e6;
}

.kryesia_lista ul li a {
  text-decoration: none;
}

.kryesia_lista ul li a .kryesia_kandidati_img {
  float: left;
  width: 100%;
  height: 180px;
  height: 11.25rem;
  position: relative;
}

.kryesia_lista ul li a .kryesia_kandidati_img:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: #e2001a;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.kryesia_lista ul li a .kryesia_kandidati_name {
  float: left;
  width: 100%;
  padding-top: 26px;
  padding-top: 1.625rem;
  padding-bottom: 26px;
  padding-bottom: 1.625rem;
  padding-left: 24px;
  padding-left: 1.5rem;
  padding-right: 24px;
  padding-right: 1.5rem;
  position: relative;
}

.kryesia_lista ul li a .kryesia_kandidati_name h3 {
  color: #231f20;
  text-align: left;
  font-size: 15px;
  font-size: 0.9375rem;
}

.kryesia_lista ul li a .kryesia_kandidati_name span {
  font-weight: 700;
}

.kryesia_lista ul li:hover {
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.kryesia_lista ul li:hover .kryesia_kandidati_img:after {
  opacity: 1;
}

.kryesia_lista ul li:hover .kryesia_kandidati_name {
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
}

.kryesia_lista ul li:hover .kryesia_kandidati_name h3, .kryesia_lista ul li:hover .kryesia_kandidati_name span {
  color: #e2001a;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .kryesia_lista ul li {
    width: 30%;
    margin-right: 5%;
    margin-bottom: 30px !important;
  }
  .kryesia_lista ul li:nth-child(3n+3) {
    margin-right: 0;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .kryesia_lista ul li {
    width: 30%;
    margin-right: 5%;
    margin-bottom: 30px !important;
  }
  .kryesia_lista ul li:nth-child(3n+3) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .kryesia_lista ul li {
    width: 100%;
    margin-right: 0;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .kryesia_lista ul li {
    width: 185px;
    margin-right: 4% !important;
    margin-bottom: 30px !important;
  }
  .kryesia_lista ul li:nth-child(5n+5) {
    margin-right: 0 !important;
  }
}

.antaresohu_banner {
  background-image: url("../images/antaresohu-miresevjen-banner.png");
  height: 250px;
  height: 15.625rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.antaresohu_banner .height_container {
  height: 250px;
  height: 15.625rem;
}

.antaresohu_banner .banner_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.antaresohu_banner .banner_text .gr_parl_banner_title {
  font-size: 36px;
  font-size: 2.25rem;
  color: #fff;
  font-weight: 700;
}

.antaresohu_banner .banner_text .gr_parl_banner_subtitle {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 11px;
  margin-top: 0.6875rem;
  color: #fff;
}

.antaresohu_banner .banner_text .gr_parl_banner_subtitle p, .antaresohu_banner .banner_text .gr_parl_banner_subtitle span {
  float: left;
  opacity: 0.5;
}

.antaresohu_banner .banner_text .gr_parl_banner_subtitle span:before {
  content: "  ";
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .antaresohu_banner {
    height: 200px;
  }
  .antaresohu_banner .height_container {
    height: 200px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .antaresohu_banner {
    height: 200px;
  }
  .antaresohu_banner .height_container {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .antaresohu_banner {
    height: 120px;
  }
  .antaresohu_banner .height_container {
    height: 120px;
  }
  .antaresohu_banner .height_container .banner_text h1 {
    font-size: 23px;
  }
}

.error {
  position: absolute;
  color: #e2001a;
  bottom: -20px;
  left: 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.error_input {
  border: 1px solid #e2001a !important;
}

#language_checkboxes span.error {
  position: absolute;
  color: #e2001a;
  bottom: -20px;
  left: 0;
}

.antaresohu_form1 {
  float: left;
  width: 100%;
}

.antaresohu_form1 form {
  float: left;
  width: 100%;
}

.antaresohu_form1 form .antaresohu_1, .antaresohu_form1 form .antaresohu_2 {
  float: left;
  width: 100%;
}

.antaresohu_form1 form .antaresohu_1 .form_columns, .antaresohu_form1 form .antaresohu_2 .form_columns {
  float: left;
  margin-right: 40px;
  margin-right: 2.5rem;
  margin-bottom: 37px;
  margin-bottom: 2.3125rem;
}

.antaresohu_form1 form .antaresohu_1 .form_columns label, .antaresohu_form1 form .antaresohu_2 .form_columns label {
  color: #666666;
  font-size: 15px;
  font-size: 0.9375rem;
}

.antaresohu_form1 form .antaresohu_1 .form_columns input, .antaresohu_form1 form .antaresohu_1 .form_columns select, .antaresohu_form1 form .antaresohu_2 .form_columns input, .antaresohu_form1 form .antaresohu_2 .form_columns select {
  width: 290px;
  width: 18.125rem;
  height: 50px;
  height: 3.125rem;
  margin-top: 17px;
  margin-top: 1.0625rem;
  display: flex;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  color: #231f20;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  padding-left: 40px;
  padding-left: 2.5rem;
  outline: none;
}

.antaresohu_form1 form .antaresohu_1 .form_columns input[type=number]::-webkit-inner-spin-button,
.antaresohu_form1 form .antaresohu_1 .form_columns input[type=number]::-webkit-outer-spin-button, .antaresohu_form1 form .antaresohu_2 .form_columns input[type=number]::-webkit-inner-spin-button,
.antaresohu_form1 form .antaresohu_2 .form_columns input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .antaresohu_form1 form .antaresohu_1 .form_columns input, .antaresohu_form1 form .antaresohu_2 .form_columns input {
    width: 246px;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns select, .antaresohu_form1 form .antaresohu_2 .form_columns select {
    width: 246px;
  }
}

.antaresohu_form1 form .antaresohu_1 .inputWithIcon, .antaresohu_form1 form .antaresohu_2 .inputWithIcon {
  position: relative;
}

.antaresohu_form1 form .antaresohu_1 .inputWithIcon i, .antaresohu_form1 form .antaresohu_2 .inputWithIcon i {
  position: absolute;
  left: 0;
  top: 40px;
  padding: 9px 16px;
  padding: 0.5625rem 1rem;
  color: #aaa;
  transition: 0.3s;
  font-size: 13px;
  font-size: 0.8125rem;
  color: #e2001a;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 700;
}

.antaresohu_form1 form .antaresohu_1 .form_columns2, .antaresohu_form1 form .antaresohu_2 .form_columns2 {
  float: left;
  width: 620px;
  margin-right: 40px;
  margin-right: 2.5rem;
}

.antaresohu_form1 form .antaresohu_1 .form_columns2 label, .antaresohu_form1 form .antaresohu_2 .form_columns2 label {
  color: #666666;
  font-size: 15px;
  font-size: 0.9375rem;
}

.antaresohu_form1 form .antaresohu_1 .form_columns2 input, .antaresohu_form1 form .antaresohu_2 .form_columns2 input {
  width: 620px;
  width: 38.75rem;
  height: 50px;
  height: 3.125rem;
  margin-top: 17px;
  margin-top: 1.0625rem;
  padding-left: 40px;
  padding-left: 2.5rem;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  outline: none;
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .antaresohu_form1 form .antaresohu_1 .form_columns2 input, .antaresohu_form1 form .antaresohu_2 .form_columns2 input {
    width: 534px;
  }
}

.antaresohu_form1 form .antaresohu_1 .form_columns3, .antaresohu_form1 form .antaresohu_2 .form_columns3 {
  float: left;
  width: 950px;
  width: 59.375rem;
}

.antaresohu_form1 form .antaresohu_1 .form_columns3 label, .antaresohu_form1 form .antaresohu_2 .form_columns3 label {
  color: #666666;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 300;
}

.antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes {
  float: left;
  width: 100%;
  margin-top: 17px;
  margin-top: 1.0625rem;
  height: 50px;
  height: 3.125rem;
  display: flex;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
}

@media only screen and (max-width: 767px) {
  .antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes {
    flex-wrap: wrap;
    height: auto;
    padding: 16px;
    padding-bottom: 0;
    justify-content: flex-start;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes .cbox_holder, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes .cbox_holder {
    margin-right: 0;
    margin-bottom: 16px;
    justify-content: flex-start;
    width: 50%;
  }
}

.antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes .cbox_holder, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes .cbox_holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  margin-right: 1rem;
}

.antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes .cbox_holder:last-child, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes .cbox_holder:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes .cbox_holder, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes .cbox_holder {
    margin-right: 22px;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes .cbox_holder label, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes .cbox_holder label {
    margin-left: 10px;
    margin-right: 0;
    font-size: 14px;
  }
}

.antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes label, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes label {
  color: #231f20;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  float: left;
  margin-left: 16px;
}

.antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes input[type=checkbox], .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes input[type=checkbox] {
  width: 20px;
  width: 1.25rem;
  height: 20px;
  height: 1.25rem;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  border-radius: 10%;
  float: left;
  position: relative;
  cursor: pointer;
  margin: 0;
}

.antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes input[type=checkbox]:focus, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes input[type=checkbox]:focus {
  outline: none;
}

.antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes input[type=checkbox]:checked:after, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes input[type=checkbox]:checked:after {
  content: '\f00c';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 700;
  position: absolute;
  top: .1em;
  left: .25em;
  font-size: 13px;
  font-size: 0.8125rem;
  color: #e2001a;
  transition: all .2s;
}

.antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes input[type=checkbox]:checked + label, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes input[type=checkbox]:checked + label {
  color: #e2001a;
}

.antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes input[type="text"], .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes input[type="text"] {
  position: absolute;
  right: 0;
  width: 280px;
  width: 17.5rem;
  height: 100%;
  border-left: 1px solid #e6e6e6 !important;
  border: none;
  outline: 0;
  margin: 0;
  margin: 0;
  color: #231f20;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  padding-left: 16px;
  padding-left: 1rem;
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .antaresohu_form1 form .antaresohu_1 .form_columns3, .antaresohu_form1 form .antaresohu_2 .form_columns3 {
    width: 820px;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes input[type="text"], .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes input[type="text"] {
    width: 163px;
  }
}

.antaresohu_form1 form .antaresohu_1 #antaresohu_form_email, .antaresohu_form1 form .antaresohu_2 #antaresohu_form_email {
  margin-right: 0;
  margin-right: 0;
}

.antaresohu_form1 form .antaresohu_1 > :nth-child(4n+4), .antaresohu_form1 form .antaresohu_2 > :nth-child(4n+4) {
  margin-right: 0;
  margin-right: 0;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .antaresohu_form1 form .antaresohu_1 .form_columns, .antaresohu_form1 form .antaresohu_2 .form_columns {
    width: 48%;
    margin-right: 0;
    padding-right: 10px;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns:nth-child(2n+2), .antaresohu_form1 form .antaresohu_2 .form_columns:nth-child(2n+2) {
    margin-right: 0;
    padding-right: 0;
    float: right;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns input, .antaresohu_form1 form .antaresohu_2 .form_columns input {
    width: 100%;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns select, .antaresohu_form1 form .antaresohu_2 .form_columns select {
    width: 100%;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns2, .antaresohu_form1 form .antaresohu_2 .form_columns2 {
    width: 100%;
    margin-right: 0;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns2 input, .antaresohu_form1 form .antaresohu_2 .form_columns2 input {
    width: 100%;
  }
  .antaresohu_form1 form .antaresohu_1 .antaresohu_address_responsive, .antaresohu_form1 form .antaresohu_2 .antaresohu_address_responsive {
    margin-bottom: 27px;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns3, .antaresohu_form1 form .antaresohu_2 .form_columns3 {
    width: 100%;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes label, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes label {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .antaresohu_form1 form .antaresohu_1 .form_columns, .antaresohu_form1 form .antaresohu_2 .form_columns {
    width: 48%;
    margin-right: 0;
    padding-right: 10px;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns:nth-child(2n+2), .antaresohu_form1 form .antaresohu_2 .form_columns:nth-child(2n+2) {
    margin-right: 0;
    padding-right: 0;
    float: right;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns input, .antaresohu_form1 form .antaresohu_2 .form_columns input {
    width: 100%;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns select, .antaresohu_form1 form .antaresohu_2 .form_columns select {
    width: 100%;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns2, .antaresohu_form1 form .antaresohu_2 .form_columns2 {
    width: 100%;
    margin-right: 0;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns2 input, .antaresohu_form1 form .antaresohu_2 .form_columns2 input {
    width: 100%;
  }
  .antaresohu_form1 form .antaresohu_1 .antaresohu_address_responsive, .antaresohu_form1 form .antaresohu_2 .antaresohu_address_responsive {
    margin-bottom: 27px;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns3, .antaresohu_form1 form .antaresohu_2 .form_columns3 {
    width: 100%;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes label, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes label {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .antaresohu_form1 form .antaresohu_1 .form_columns, .antaresohu_form1 form .antaresohu_2 .form_columns {
    width: 100%;
    margin-right: 0;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns input, .antaresohu_form1 form .antaresohu_2 .form_columns input {
    width: 100%;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns select, .antaresohu_form1 form .antaresohu_2 .form_columns select {
    width: 100%;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns2, .antaresohu_form1 form .antaresohu_2 .form_columns2 {
    width: 100%;
    margin-right: 0;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns2 input, .antaresohu_form1 form .antaresohu_2 .form_columns2 input {
    width: 100%;
  }
  .antaresohu_form1 form .antaresohu_1 .antaresohu_address_responsive, .antaresohu_form1 form .antaresohu_2 .antaresohu_address_responsive {
    margin-bottom: 27px;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns3, .antaresohu_form1 form .antaresohu_2 .form_columns3 {
    width: 100%;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes_mobile, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes_mobile {
    display: block !important;
    float: left;
    width: 100%;
    margin-top: 17px;
    margin-top: 1.0625rem;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    position: relative;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes_mobile .language, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes_mobile .language {
    float: left;
    width: 50%;
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes_mobile .language label, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes_mobile .language label {
    color: #231f20;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 700;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes_mobile .language input[type=checkbox], .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes_mobile .language input[type=checkbox] {
    width: 20px;
    width: 1.25rem;
    height: 20px;
    height: 1.25rem;
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #b3b3b3;
    border-radius: 10%;
    margin: 0 16px;
    margin: 0 1rem;
    position: relative;
    cursor: pointer;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes_mobile .language input[type=checkbox]:focus, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes_mobile .language input[type=checkbox]:focus {
    outline: none;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes_mobile .language input[type=checkbox]:checked:after, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes_mobile .language input[type=checkbox]:checked:after {
    content: '\f00c';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 700;
    position: absolute;
    top: .1em;
    left: .25em;
    font-size: 13px;
    font-size: 0.8125rem;
    color: #e2001a;
    transition: all .2s;
  }
  .antaresohu_form1 form .antaresohu_1 .form_columns3 .language_checkboxes_mobile .language input[type=checkbox]:checked + label, .antaresohu_form1 form .antaresohu_2 .form_columns3 .language_checkboxes_mobile .language input[type=checkbox]:checked + label {
    color: #e2001a;
  }
}

.antaresohu_vazhdo_button {
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 45px;
  margin-bottom: 2.8125rem;
  display: flex;
  align-items: center;
  margin-top: 22px;
  margin-top: 1.375rem;
  position: relative;
}

.antaresohu_vazhdo_button .vazhdo_buttoni {
  margin: 0 auto;
  width: 180px;
  width: 11.25rem;
  height: 50px;
  height: 3.125rem;
  text-decoration: none;
  text-align: center;
  color: #fff;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  background: #e2001a;
  text-transform: uppercase;
  border-radius: 2px;
  padding: 0 22px;
  padding: 0 1.375rem;
  line-height: 50px;
  line-height: 3.125rem;
  position: relative;
  z-index: 2;
  transition: background .25s ease-in-out;
}

.antaresohu_vazhdo_button .vazhdo_buttoni:hover {
  background: #fc001d;
}

.antaresohu_vazhdo_button .vazhdo_buttoni:active {
  transform: scale(0.98);
}

.antaresohu_vazhdo_button::before {
  content: '';
  height: 1px;
  height: 0.0625rem;
  background: #CCC;
  width: 100%;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.antaresohu_vazhdo_button::before {
  left: 0;
}

.antaresohu_hr {
  float: left;
  width: 100%;
  margin-bottom: 29px;
  margin-bottom: 1.8125rem;
  margin-top: 29px;
  margin-top: 1.8125rem;
}

.antaresohu_hr hr {
  width: 100%;
  display: block;
  height: 1px;
  height: 0.0625rem;
  border: 0;
  background: #e6e6e6;
}

.konfirmo_antaresimin {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 106px;
  margin-bottom: 6.625rem;
}

.konfirmo_antaresimin input[type=checkbox] {
  width: 12px;
  width: 0.75rem;
  height: 12px;
  height: 0.75rem;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  margin-right: 22px;
  margin-right: 1.375rem;
  position: relative;
  cursor: pointer;
}

.konfirmo_antaresimin input[type=checkbox]:focus {
  outline: none;
}

.konfirmo_antaresimin input[type=checkbox]:checked:after {
  content: '\f00c';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 700;
  position: absolute;
  top: -1px;
  background: #e2001a;
  font-size: 12px;
  font-size: 0.75rem;
  color: #fff;
  transition: all .2s;
}

.konfirmo_antaresimin label a {
  color: #666666;
  font-size: 15px;
  font-size: 0.9375rem;
  text-decoration: none;
}

.konfirmo_antaresimin button[type=submit] {
  align-self: flex-end;
  margin-left: auto;
  width: 290px;
  width: 18.125rem;
  height: 50px;
  height: 3.125rem;
  background: #e2001a;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, 0) !important;
  outline: none !important;
}

@media only screen and (max-width: 767px) {
  .konfirmo_antaresimin button[type=submit] {
    width: 100%;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .konfirmo_antaresimin_mobile {
    display: block !important;
    float: left;
    width: 100%;
    margin-bottom: 30px;
  }
  .konfirmo_antaresimin_mobile .confirm_check {
    display: flex;
    align-items: center;
    margin: 30px 0;
  }
  .konfirmo_antaresimin_mobile .confirm_check input[type=checkbox] {
    width: 12px;
    width: 0.75rem;
    height: 12px;
    height: 0.75rem;
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #b3b3b3;
    margin-right: 22px;
    margin-right: 1.375rem;
    position: relative;
    cursor: pointer;
  }
  .konfirmo_antaresimin_mobile .confirm_check input[type=checkbox]:focus {
    outline: none;
  }
  .konfirmo_antaresimin_mobile .confirm_check input[type=checkbox]:checked:after {
    content: '\f00c';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 700;
    position: absolute;
    top: -1px;
    background: #e2001a;
    font-size: 12px;
    font-size: 0.75rem;
    color: #fff;
    transition: all .2s;
  }
  .konfirmo_antaresimin_mobile .confirm_check label {
    width: 90%;
    color: #666666;
    font-size: 13px;
    font-size: 0.8125rem;
  }
  .konfirmo_antaresimin_mobile .confirm_check label span {
    color: #e2001a;
    font-weight: 700;
  }
  .konfirmo_antaresimin_mobile button[type=submit] {
    align-self: flex-end;
    margin-left: auto;
    width: 100%;
    height: 50px;
    height: 3.125rem;
    background: #e2001a;
    color: #fff;
    font-weight: 700;
    border-radius: 2px;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: none !important;
    border-color: rgba(0, 0, 0, 0) !important;
    outline: none !important;
  }
}

.antaresohu_2,
.antaresohu_1 {
  position: relative;
  float: left;
  width: 100%;
}

.is--hidden {
  user-select: none;
}

.is--hidden:before {
  content: " ";
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.kontribo_banner {
  background-image: url("../images/kontribo-banner.png");
  height: 250px;
  height: 15.625rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.kontribo_banner .height_container {
  height: 250px;
  height: 15.625rem;
}

.kontribo_banner .banner_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.kontribo_banner .banner_text .gr_parl_banner_title {
  font-size: 36px;
  font-size: 2.25rem;
  color: #fff;
  font-weight: 700;
}

.kontribo_banner .banner_text .gr_parl_banner_subtitle {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 11px;
  margin-top: 0.6875rem;
  color: #fff;
}

.kontribo_banner .banner_text .gr_parl_banner_subtitle p, .kontribo_banner .banner_text .gr_parl_banner_subtitle span {
  float: left;
  opacity: 0.5;
}

.kontribo_banner .banner_text .gr_parl_banner_subtitle span:before {
  content: "  ";
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .kontribo_banner {
    height: 200px;
  }
  .kontribo_banner .height_container {
    height: 200px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .kontribo_banner {
    height: 200px;
  }
  .kontribo_banner .height_container {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .kontribo_banner {
    height: 200px;
  }
  .kontribo_banner .height_container {
    height: 200px;
  }
  .kontribo_banner .height_container .banner_text h1 {
    font-size: 23px;
  }
}

.background_donacione {
  float: left;
  width: 100%;
  background: #fff;
}

.donacione_tabs {
  z-index: 99;
  box-shadow: inset 0 84px 97px -81px rgba(0, 0, 0, 0.1);
}

.donacione_tabs ul {
  float: left;
  width: 100%;
  position: relative;
}

.donacione_tabs ul li {
  float: left;
  width: 320px;
  width: 20rem;
  height: auto;
  background: transparent;
  border-right: 1px solid #fff;
}

.donacione_tabs ul li a {
  text-decoration: none;
  color: #231f20;
}

.donacione_tabs ul li a p {
  font-size: 21px;
  font-size: 1.3125rem;
  padding-left: 47px;
  padding-left: 2.9375rem;
  padding-right: 33px;
  padding-right: 2.0625rem;
  padding-top: 40px;
  padding-top: 2.5rem;
  margin-bottom: 5px;
  margin-bottom: 0.3125rem;
  display: flex;
  align-items: center;
}

.donacione_tabs ul li a p::before {
  content: " ";
  background-image: url("../images/dash.svg");
  height: 5px;
  height: 0.3125rem;
  width: 20px;
  width: 1.25rem;
  display: inline-block;
  margin-right: 30px;
  margin-right: 1.875rem;
}

.donacione_tabs ul li a h4 {
  font-size: 21px;
  font-size: 1.3125rem;
  font-weight: 700;
  padding-left: 47px;
  padding-left: 2.9375rem;
  padding-right: 20px;
  padding-right: 1.25rem;
  padding-bottom: 86px;
  padding-bottom: 5.375rem;
}

.donacione_tabs ul li a #kartela::before {
  content: "\f09d";
  font-family: 'Font Awesome 5 Pro';
  height: 15px;
  height: 0.9375rem;
  width: 20px;
  width: 1.25rem;
  display: inline-block;
  margin-right: 30px;
  margin-right: 1.875rem;
}

.donacione_tabs ul li a #kartela_2::before {
  content: "\f09d ";
  font-family: 'Font Awesome 5 Pro';
  height: 15px;
  height: 0.9375rem;
  width: 20px;
  width: 1.25rem;
  display: inline-block;
  margin-right: 30px;
  margin-right: 1.875rem;
}

.donacione_tabs ul li a #mallra_sherbime::before {
  content: "\f49d";
  font-family: 'Font Awesome 5 Pro';
  height: 15px;
  height: 0.9375rem;
  width: 20px;
  width: 1.25rem;
  display: inline-block;
  margin-right: 30px;
  margin-right: 1.875rem;
}

.donacione_tabs ul li a #informata_shtese::before {
  content: "\f095";
  font-family: 'Font Awesome 5 Pro';
  height: 15px;
  height: 0.9375rem;
  width: 20px;
  width: 1.25rem;
  display: block;
  display: inline-block;
  margin-right: 30px;
  margin-right: 1.875rem;
}

.donacione_tabs ul li a #prioritetet_tab_icon::before {
  content: "\f024 ";
  font-family: 'Font Awesome 5 Pro';
  height: 15px;
  height: 0.9375rem;
  width: 20px;
  width: 1.25rem;
  display: inline-block;
  margin-right: 30px;
  margin-right: 1.875rem;
}

.donacione_tabs ul li a #zotimet_tab_icon::before {
  content: "\f19c ";
  font-family: 'Font Awesome 5 Pro';
  height: 15px;
  height: 0.9375rem;
  width: 20px;
  width: 1.25rem;
  display: inline-block;
  margin-right: 30px;
  margin-right: 1.875rem;
}

.donacione_tabs ul li a #gr_parl_tab_icon::before {
  content: "\f508";
  font-family: 'Font Awesome 5 Pro';
  height: 15px;
  height: 0.9375rem;
  width: 20px;
  width: 1.25rem;
  display: inline-block;
  margin-right: 30px;
  margin-right: 1.875rem;
}

.donacione_tabs ul li a #kandid_komuna_tab_icon::before {
  content: "\f500";
  font-family: 'Font Awesome 5 Pro';
  height: 15px;
  height: 0.9375rem;
  width: 20px;
  width: 1.25rem;
  display: inline-block;
  margin-right: 30px;
  margin-right: 1.875rem;
}

.donacione_tabs ul li:last-child {
  border-right: none;
}

.donacione_tabs ul li:hover, .donacione_tabs ul li.active {
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  background: #fff;
  transform: translateY(-10px);
  box-shadow: 0px -18px 35px -16px rgba(0, 0, 0, 0.1);
}

.donacione_tabs ul li:hover p, .donacione_tabs ul li:hover h4, .donacione_tabs ul li.active p, .donacione_tabs ul li.active h4 {
  color: #e2001a;
}

@media only screen and (max-width: 767px) {
  .donacione_tabs ul li:hover, .donacione_tabs ul li.active {
    background: transparent;
    transform: none;
    box-shadow: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .donacione_tabs ul li.active {
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
    background: #e6e6e6;
    transform: none;
    box-shadow: none;
  }
  .donacione_tabs ul li.active p, .donacione_tabs ul li.active h4 {
    color: #e2001a;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .donacione_tabs ul li.active {
    transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
    background: #e6e6e6;
    transform: none;
    box-shadow: none;
  }
  .donacione_tabs ul li.active p, .donacione_tabs ul li.active h4 {
    color: #e2001a;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .donacione_tabs ul li {
    width: 50%;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    background: #e6e6e6;
  }
  .donacione_tabs ul li a h4 {
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .donacione_tabs ul li {
    width: 50%;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    background: #e6e6e6;
  }
  .donacione_tabs ul li a h4 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .donacione_tabs ul li {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
  }
  .donacione_tabs ul li a p {
    padding-left: 15px;
    padding-right: 15px;
    font: size 20px;
  }
  .donacione_tabs ul li a p i {
    display: block;
    font-size: 15px;
    margin-right: 20px;
  }
  .donacione_tabs ul li a p b {
    display: block;
  }
  .donacione_tabs ul li a p:before {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .donacione_tabs ul {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .donacione_tabs ul {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
  }
}

@media only screen and (max-width: 767px) {
  .donacione_tabs ul {
    margin-bottom: 40px;
  }
  .donacione_tabs ul li {
    border-bottom: 1px solid #e6e6e6;
  }
  .donacione_tabs ul li a p {
    padding-top: 40px;
    padding-left: 48px;
  }
  .donacione_tabs ul li a h4 {
    padding-bottom: 20px;
  }
  .donacione_tabs ul #mobile_hide_border {
    border-bottom: none;
  }
  .donacione_tabs ul #mobile_hide_border a p {
    padding-left: 34px !important;
    color: #e2001a;
  }
  .donacione_tabs ul #mobile_hide_border a p b {
    display: block !important;
  }
  .donacione_tabs ul #mobile_hide_border a p .fa-flag {
    display: block !important;
    color: #e2001a;
  }
}

@media only screen and (max-width: 767px) and (max-width: 320px) {
  .donacione_tabs ul #mobile_hide_border a p {
    font-size: 17px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .donacione_tabs ul li {
    width: 25%;
  }
  .donacione_tabs ul li a p {
    font-size: 19px;
  }
  .donacione_tabs ul li a h4 {
    font-size: 17px;
  }
}

.donacione_tabs .homepage_tabs_tablets {
  display: block;
}

.donacione_tabs .kontribo_tabs_wrapper {
  float: left;
  width: 100%;
  clear: both;
}

.donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks {
  float: left;
  width: 100%;
  clear: both;
  height: auto;
  padding-bottom: 32px;
  padding-bottom: 2rem;
  margin-bottom: 57px;
  margin-bottom: 3.5625rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .transferet_brenda_ks_left {
  float: left;
  width: 50%;
}

.donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .transferet_brenda_ks_left h2 {
  float: left;
  width: 100%;
  font-size: 21px;
  font-size: 1.3125rem;
  margin-bottom: 25px;
  margin-bottom: 1.5625rem;
}

.donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .transferet_brenda_ks_left h4 {
  float: left;
  width: 100%;
  font-size: 15px;
  font-size: 0.9375rem;
  color: #e2001a;
  margin-bottom: 18px;
  margin-bottom: 1.125rem;
}

.donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .transferet_brenda_ks_left p {
  float: left;
  width: 100%;
  font-size: 15px;
  font-size: 0.9375rem;
  color: #666666;
  margin-bottom: 8px;
  margin-bottom: 0.5rem;
}

.donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .transferet_brenda_ks_left h3 {
  float: left;
  width: 100%;
  color: #231f20;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 22px;
  margin-bottom: 1.375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .xhirollogaria_graybox {
  float: left;
  width: 50%;
}

.donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .xhirollogaria_graybox .gray_box {
  width: 510px;
  width: 31.875rem;
  height: 193px;
  height: 12.0625rem;
  padding: 39px;
  padding: 2.4375rem;
  margin-left: 18px;
  margin-left: 1.125rem;
  margin-top: 55px;
  margin-top: 3.4375rem;
  background: #f2f2f2;
}

.donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .xhirollogaria_graybox .gray_box p {
  color: #808080;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 16px;
  margin-bottom: 17px;
  margin-bottom: 1.0625rem;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .transferet_brenda_ks_left {
    width: 50%;
    padding-right: 20px;
  }
  .donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .xhirollogaria_graybox {
    width: 50%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .xhirollogaria_graybox .gray_box {
    width: 100%;
    margin-left: 0;
    height: auto;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .transferet_brenda_ks_left {
    width: 50%;
    padding-right: 20px;
  }
  .donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .xhirollogaria_graybox {
    width: 50%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .xhirollogaria_graybox .gray_box {
    width: 100%;
    margin-left: 0;
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .transferet_brenda_ks_left {
    width: 100%;
    padding-right: 20px;
  }
  .donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .xhirollogaria_graybox {
    float: left;
    width: 100%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .transferet_brenda_ks .xhirollogaria_graybox .gray_box {
    float: left;
    width: 100%;
    margin-left: 0;
    height: auto;
  }
}

.donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo {
  margin-bottom: 59px;
  margin-bottom: 3.6875rem;
}

.donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo .pcb_contact_info,
.donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo .vv_contact_info {
  float: left;
  width: 50%;
}

.donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo .pcb_contact_info .pcb_logo {
  float: left;
  width: 100%;
  width: 167px;
  width: 10.4375rem;
  height: 30px;
  height: 1.875rem;
}

.donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo .pcb_contact_info .contact_pcb {
  float: left;
  width: 100%;
  text-align: left;
  margin-top: 20px;
  margin-top: 1.25rem;
}

.donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo .pcb_contact_info .contact_pcb p {
  color: #666;
  font-size: 15px;
  font-size: 0.9375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo .pcb_contact_info .contact_pcb a {
  text-decoration: none;
  color: #e2001a;
  font-size: 15px;
  font-size: 0.9375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo .pcb_contact_info .contact_pcb .tel_bottom_dist {
  margin-bottom: 4px;
  margin-bottom: 0.25rem;
}

.donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo .vv_contact_info .vv_logo {
  float: right;
  width: 176px;
  width: 11rem;
  height: 23px;
  height: 1.4375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo .vv_contact_info .contact_vv {
  float: right;
  width: 100%;
  text-align: right;
  margin-top: 20px;
  margin-top: 1.25rem;
}

.donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo .vv_contact_info .contact_vv p {
  color: #666;
  font-size: 15px;
  font-size: 0.9375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo .vv_contact_info .contact_vv a {
  text-decoration: none;
  color: #e2001a;
  font-size: 15px;
  font-size: 0.9375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo .contact_email {
  margin-top: 20px;
  margin-top: 1.25rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

@media only screen and (max-width: 767px) {
  .donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo .pcb_contact_info {
    width: 100%;
    margin-bottom: 60px;
  }
  .donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo .vv_contact_info {
    width: 100%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo .vv_contact_info .vv_logo {
    float: left;
  }
  .donacione_tabs .kontribo_tabs_wrapper .pcb_vv_contact_contactinfo .vv_contact_info .contact_vv {
    text-align: left;
  }
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_mallra_sherbime_text {
  float: left;
  width: 100%;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_mallra_sherbime_text .dhuro_mallra_left_text,
.donacione_tabs .kontribo_tabs_wrapper .dhuro_mallra_sherbime_text .dhuro_mallra_right_text {
  float: left;
  width: 50%;
  padding-right: 25px;
  padding-right: 1.5625rem;
  padding-left: 15px;
  padding-left: 0.9375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_mallra_sherbime_text .dhuro_mallra_left_text p,
.donacione_tabs .kontribo_tabs_wrapper .dhuro_mallra_sherbime_text .dhuro_mallra_right_text p {
  color: #666;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 24px;
  text-align: justify;
  hyphens: auto;
}

@media only screen and (max-width: 767px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_mallra_sherbime_text .dhuro_mallra_left_text {
    width: 100%;
    float: left;
    margin-bottom: 20px;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_mallra_sherbime_text .dhuro_mallra_right_text {
    float: left;
    width: 100%;
  }
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper {
  float: left;
  width: 100%;
  margin-top: 54px;
  margin-top: 3.375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form {
  float: left;
  width: 100%;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dhuro_form_columns {
  height: 50px;
  height: 3.125rem;
  border: 1px solid #e6e6e6;
  margin-bottom: 29px;
  margin-bottom: 1.8125rem;
  display: flex;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  position: relative;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dhuro_form_columns label {
  color: #231f20;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
}

@media (max-width: 320px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dhuro_form_columns label {
    font-size: 12px;
  }
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dhuro_form_columns input[type=checkbox] {
  width: 20px;
  width: 1.25rem;
  height: 20px;
  height: 1.25rem;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  border-radius: 10%;
  margin: 0 16px;
  margin: 0 1rem;
  position: relative;
  cursor: pointer;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dhuro_form_columns input[type=checkbox]:focus {
  outline: none;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dhuro_form_columns input[type=checkbox]:checked:after {
  content: '\f00c';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 700;
  position: absolute;
  top: .1em;
  left: .25em;
  font-size: 13px;
  font-size: 0.8125rem;
  color: #e2001a;
  transition: all .2s;
  border: none;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dhuro_form_columns input[type=checkbox]:checked + label {
  color: #e2001a;
}

@media (max-width: 320px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dhuro_form_columns input[type=checkbox] {
    width: 15px;
    height: 15px;
    margin: 0 8px;
  }
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dhuro_form_columns i {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 700;
  margin: 0 23px;
  margin: 0 1.4375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dhuro_form_columns input[type="text"] {
  position: absolute;
  right: 0;
  width: 91%;
  height: 100%;
  border: none;
  outline: 0;
  margin: 0;
  margin: 0;
  color: #231f20;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dhuro_form_columns input[type="text"]:focus::-webkit-input-placeholder {
  opacity: 0;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dhuro_form_columns input[type="text"] {
    width: 85%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dhuro_form_columns input[type="text"] {
    width: 88%;
  }
}

@media only screen and (max-width: 767px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dhuro_form_columns input[type="text"] {
    width: 65%;
  }
}

@media (max-width: 320px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dhuro_form_columns input[type="text"] {
    width: 63%;
  }
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dhuro_form_columns:hover {
  border: none;
  -webkit-box-shadow: 0px 0px 154px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 154px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 154px 0px rgba(0, 0, 0, 0.2);
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns {
  float: left;
  margin-bottom: 27px;
  margin-bottom: 1.6875rem;
  margin-top: 15px;
  margin-top: 0.9375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns label {
  color: #666666;
  font-size: 15px;
  font-size: 0.9375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns input, .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns select {
  width: 224px;
  width: 14rem;
  height: 50px;
  height: 3.125rem;
  margin-top: 17px;
  margin-top: 1.0625rem;
  display: flex;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  color: #231f20;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  padding-left: 40px;
  padding-left: 2.5rem;
  outline: none;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns input[type=number]::-webkit-inner-spin-button,
.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .inputWithIcon {
  position: relative;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .inputWithIcon i {
  position: absolute;
  left: 14;
  top: 40px;
  padding: 9px 16px;
  padding: 0.5625rem 1rem;
  color: #aaa;
  transition: 0.3s;
  font-size: 13px;
  font-size: 0.8125rem;
  color: #e2001a;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 700;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .antaresohu_hr {
  float: left;
  width: 100%;
  margin-bottom: 29px;
  margin-bottom: 1.8125rem;
  margin-top: 29px;
  margin-top: 1.8125rem;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .antaresohu_hr hr {
  width: 100%;
  display: block;
  height: 1px;
  height: 0.0625rem;
  border: 0;
  background: #e6e6e6;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .konfirmo_dergimin {
  display: flex;
  align-items: center;
  margin-bottom: 106px;
  margin-bottom: 6.625rem;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .konfirmo_dergimin input[type=checkbox] {
  width: 12px;
  width: 0.75rem;
  height: 12px;
  height: 0.75rem;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  margin-right: 22px;
  margin-right: 1.375rem;
  position: relative;
  cursor: pointer;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .konfirmo_dergimin input[type=checkbox]:focus {
  outline: none;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .konfirmo_dergimin input[type=checkbox]:checked:after {
  content: '\f00c';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 700;
  position: absolute;
  top: -1px;
  background: #e2001a;
  font-size: 12px;
  font-size: 0.75rem;
  color: #fff;
  transition: all .2s;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .konfirmo_dergimin label {
  color: #666666;
  font-size: 15px;
  font-size: 0.9375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .konfirmo_dergimin label span {
  color: #e2001a;
  font-weight: 700;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .konfirmo_dergimin button[type=submit] {
  align-self: flex-end;
  margin-left: auto;
  width: 290px;
  width: 18.125rem;
  height: 50px;
  height: 3.125rem;
  background: #e2001a;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, 0) !important;
  outline: none !important;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns {
    width: 50%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns input {
    width: 100%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns select {
    width: 100%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dergo_mallra {
    width: 100% !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns {
    width: 50%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns input {
    width: 100%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns select {
    width: 100%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .dergo_mallra {
    width: 100% !important;
  }
}

@media only screen and (max-width: 767px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns {
    width: 100%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns input {
    width: 100%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns select {
    width: 100%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .column-3 {
    width: 50%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns {
    width: auto;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns input {
    width: 189px;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_form_wrapper form .form_columns select {
    width: 189px;
  }
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele {
  float: left;
  width: 100%;
  clear: both;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .individ_biznes {
  float: left;
  width: 100%;
  clear: both;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .individ_biznes .container {
  float: left;
  width: auto;
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  font-size: 21px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .individ_biznes .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .individ_biznes .container input:checked ~ .checkmark {
  background-color: #e2001a;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .individ_biznes .container input:checked ~ .checkmark:after {
  display: block;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .individ_biznes .container input[type=radiobutton]:checked + label {
  color: #e2001a;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .individ_biznes .container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #e6e6e6;
  border-radius: 50%;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .individ_biznes .container .checkmark:after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .individ_biznes .container:first-child {
  margin-right: 16px;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma {
  float: left;
  width: 100%;
  clear: both;
  margin-top: 62px;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_form_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_form_wrap .column-2 {
    width: 33.333%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_form_wrap .column-2 .input_holder {
    width: 100%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_form_wrap .column-2 .input_holder label {
    width: 100%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_form_wrap .column-2 {
    width: 33.333%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_form_wrap .column-2 .input_holder {
    width: 100%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_form_wrap .column-2 .input_holder label {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_form_wrap .column-2 {
    width: 50%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_form_wrap .column-2 .input_holder {
    width: 100%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_form_wrap .column-2 .input_holder label {
    width: 100%;
  }
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .input_holder {
  float: left;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .input_holder input:checked ~ .kontribo_button {
  border-color: transparent;
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .input_holder input:checked ~ .kontribo_button i,
.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .input_holder input:checked ~ .kontribo_button span {
  color: #e2001a;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_button {
  float: left;
  width: 180px;
  width: 11.25rem;
  height: 50px;
  height: 3.125rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  text-align: center;
  border: 1px solid #e6e6e6;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_button input[type=text],
.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_button input[type=number] {
  float: left;
  width: 60px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
  padding-right: 15px;
  line-height: 44px;
  background: transparent;
  border: 0;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_button input[type=text] {
  text-align: center;
  padding-right: 0;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_button i {
  float: left;
  width: 43px;
  font-size: 18px;
  text-align: center;
  line-height: 44px;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_button span {
  float: right;
  margin-right: 15px;
  line-height: 46px;
  font-weight: 700;
  font-size: 16px;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_button:hover {
  border: none;
  box-shadow: 0 0 66px -6px rgba(0, 0, 0, 0.27);
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_button:hover label {
  color: #e2001a;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .dhuro_shuma .kontribo_button {
    width: 150px;
  }
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .shuma_limit {
  float: left;
  width: 100%;
  clear: both;
  margin-top: 47px;
  margin-top: 2.9375rem;
  margin-bottom: 21px;
  margin-bottom: 1.3125rem;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .shuma_limit p {
  float: left;
  color: #231f20;
  font-size: 15px;
  font-size: 0.9375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .shuma_limit span {
  float: right;
  color: #231f20;
  font-size: 15px;
  font-size: 0.9375rem;
}

@media (max-width: 320px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .shuma_limit p, .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .shuma_limit span {
    font-size: 13px;
  }
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .slidecontainer {
  float: left;
  width: 100%;
  clear: both;
  height: 90px;
  position: relative;
  margin-bottom: 36px;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .slidecontainer .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #e6e6e6;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin-bottom: 27px;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .slidecontainer .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 5px solid white;
  background: #e2001a;
  cursor: pointer;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .slidecontainer .slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .slidecontainer .range_start {
  float: left;
  font-size: 18px;
  font-weight: 300;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .slidecontainer .range_start span {
  font-size: 16px;
  font-weight: 700;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .slidecontainer .range_value {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 180px;
  height: 50px;
  display: inline-block;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  padding: 16px;
  font-size: 18px;
  font-weight: 300;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .slidecontainer .range_value span {
  float: right;
  font-size: 16px;
  font-weight: 700;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .slidecontainer .range_end {
  float: right;
  margin-left: auto;
  font-size: 18px;
  font-weight: 300;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .slidecontainer .range_end span {
  font-size: 16px;
  font-weight: 700;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatoret_posedojne {
  float: left;
  width: 100%;
  clear: both;
  text-align: center;
  padding: 23px 5px 25px 5px;
  background: #f2f2f2;
  border-radius: 2px;
  margin-bottom: 40px;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatoret_posedojne p {
  color: #666666;
  font-size: 15px;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatori_form_data {
  float: left;
  width: 100%;
  clear: both;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatori_form_data .form_columns {
  margin-bottom: 27px;
  margin-bottom: 1.6875rem;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatori_form_data .form_columns label {
  color: #666666;
  font-size: 15px;
  font-size: 0.9375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatori_form_data .form_columns input, .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatori_form_data .form_columns select {
  width: 100%;
  height: 50px;
  height: 3.125rem;
  margin-top: 17px;
  margin-top: 1.0625rem;
  display: flex;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  color: #231f20;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  padding-left: 40px;
  padding-left: 2.5rem;
  outline: none;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatori_form_data .form_columns input[type=number]::-webkit-inner-spin-button,
.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatori_form_data .form_columns input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatori_form_data .inputWithIcon {
  position: relative;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatori_form_data .inputWithIcon i {
  position: absolute;
  left: 0;
  top: 40px;
  padding: 9px 16px;
  padding: 0.5625rem 1rem;
  color: #aaa;
  transition: 0.3s;
  font-size: 13px;
  font-size: 0.8125rem;
  color: #e2001a;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatori_form_data .column-3 {
    width: 50%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatori_form_data .column-6 {
    width: 100%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatori_form_data .column-3 {
    width: 50%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatori_form_data .column-6 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatori_form_data .column-3 {
    width: 100%;
  }
  .donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .donatori_form_data .column-6 {
    width: 100%;
  }
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .antaresohu_hr {
  float: left;
  width: 100%;
  margin-bottom: 29px;
  margin-bottom: 1.8125rem;
  margin-top: 29px;
  margin-top: 1.8125rem;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .antaresohu_hr hr {
  width: 100%;
  display: block;
  height: 1px;
  height: 0.0625rem;
  border: 0;
  background: #e6e6e6;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .vazhdo_dhuro {
  display: flex;
  align-items: center;
  margin-bottom: 106px;
  margin-bottom: 6.625rem;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .vazhdo_dhuro input[type=checkbox] {
  width: 12px;
  width: 0.75rem;
  height: 12px;
  height: 0.75rem;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  margin-right: 22px;
  margin-right: 1.375rem;
  position: relative;
  cursor: pointer;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .vazhdo_dhuro input[type=checkbox]:focus {
  outline: none;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .vazhdo_dhuro input[type=checkbox]:checked:after {
  content: '\f00c';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 700;
  position: absolute;
  top: -1px;
  background: #e2001a;
  font-size: 12px;
  font-size: 0.75rem;
  color: #fff;
  transition: all .2s;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .vazhdo_dhuro label {
  color: #666666;
  font-size: 15px;
  font-size: 0.9375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .vazhdo_dhuro label a {
  color: #e2001a;
  font-weight: 700;
  text-decoration: none;
}

.donacione_tabs .kontribo_tabs_wrapper .dhuro_me_kartele .vazhdo_dhuro button[type=submit] {
  align-self: flex-end;
  margin-left: auto;
  width: 290px;
  width: 18.125rem;
  height: 50px;
  height: 3.125rem;
  background: #e2001a;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, 0) !important;
  outline: none !important;
}

.donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .form_columns {
  margin-bottom: 27px;
  margin-bottom: 1.6875rem;
}

.donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .form_columns label {
  color: #666666;
  font-size: 15px;
  font-size: 0.9375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .form_columns input, .donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .form_columns select {
  width: 100%;
  height: 50px;
  height: 3.125rem;
  margin-top: 17px;
  margin-top: 1.0625rem;
  display: flex;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  color: #231f20;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  padding-left: 40px;
  padding-left: 2.5rem;
  outline: none;
}

.donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .form_columns input[type=number]::-webkit-inner-spin-button,
.donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .form_columns input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .form_columns textarea {
  float: left;
  width: 100%;
  height: 200px;
  height: 12.5rem;
  margin-top: 17px;
  margin-top: 1.0625rem;
  display: flex;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  color: #231f20;
  font-weight: 700;
  font-weight: 700;
  font-size: 15px;
  font-size: 0.9375rem;
  padding: 20px;
  padding: 1.25rem;
  outline: none;
  resize: none;
}

.donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .form_columns textarea::placeholder {
  color: #d0c8c8;
  font-weight: 300;
}

.donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .form_columns button[type=submit] {
  float: right;
  margin: 30px auto;
  width: 290px;
  width: 18.125rem;
  height: 50px;
  height: 3.125rem;
  background: #e2001a;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, 0) !important;
  outline: none !important;
}

.donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .form_columns .vv_contact_info {
  float: right;
  width: 210px;
}

.donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .form_columns .vv_contact_info .vv_logo {
  width: 176px;
  width: 11rem;
  height: 23px;
  height: 1.4375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .form_columns .vv_contact_info .contact_vv {
  width: 210px;
  text-align: left;
  margin-top: 20px;
  margin-top: 1.25rem;
}

.donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .form_columns .vv_contact_info .contact_vv p {
  color: #666;
  font-size: 15px;
  font-size: 0.9375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .form_columns .vv_contact_info .contact_vv a {
  text-decoration: none;
  color: #e2001a;
  font-size: 15px;
  font-size: 0.9375rem;
}

.donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .inputWithIcon {
  position: relative;
}

.donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .inputWithIcon i {
  position: absolute;
  left: 0;
  top: 40px;
  padding: 9px 16px;
  padding: 0.5625rem 1rem;
  color: #aaa;
  transition: 0.3s;
  font-size: 13px;
  font-size: 0.8125rem;
  color: #e2001a;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .column-3 {
    width: 50%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .column-3 {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .donacione_tabs .kontribo_tabs_wrapper .kontakto_info_shtese .form_columns button[type=submit] {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .donacione_tabs .kontribo_tabs_wrapper {
    padding-top: 30px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .donacione_tabs .kontribo_tabs_wrapper {
    padding-top: 30px;
  }
}

.block_section {
  float: left;
  width: 100%;
}

.tab_container .tabs_content {
  width: 100%;
  display: none;
}

.tab_container .tabs_content.active {
  width: 100%;
  display: block;
}

.kontribo_tabs_wrapper .tabs_content_status {
  display: none;
}

.kontribo_tabs_wrapper .tabs_content_status.active {
  display: block;
}

.media_gallery_popup {
  position: fixed;
  background: rgba(255, 255, 255, 0.92);
  width: 100%;
  z-index: 6;
  height: 100%;
  top: 0;
  left: 0;
}

.konfirmo_antaresimin {
  padding-top: 40px;
  padding-top: 2.5rem;
  margin-top: 28px;
  margin-top: 1.75rem;
  border-top: 1px solid #e6e6e6;
}

.confirm_cbox {
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .confirm_cbox {
    width: 50%;
  }
}

.confirm_cbox input {
  display: none;
}

.confirm_cbox label {
  color: #666;
  font-size: 15px;
}

.confirm_cbox input:checked + label:before {
  font-family: 'Font Awesome 5 Pro Solid';
  font-weight: normal;
  color: #e2001a;
  content: "\f14a";
}

.confirm_cbox .label_text {
  float: left;
  padding-left: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .confirm_cbox .label_text {
    width: calc(100% - 40px);
  }
}

@media only screen and (max-width: 767px) {
  .confirm_cbox .label_text {
    width: calc(100% - 40px);
  }
}

.confirm_cbox label:before {
  content: "\f0c8";
  float: left;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  font-size: 14px;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.single_right {
  float: right;
  margin-top: -50px;
  width: 330px;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .single_right {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .single_right {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .single_right {
    width: 100%;
  }
}

.single_kandidati_sm_section {
  flex-wrap: wrap;
}

.single_kandidati_sm_section iframe, .single_kandidati_sm_section .fb-page {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .single_kandidati_sm_section {
    width: 100% !important;
  }
  .single_kandidati_sm_section iframe, .single_kandidati_sm_section .fb-page {
    width: 100% !important;
  }
}

.small_title {
  margin-bottom: 30px;
  position: relative;
  clear: both;
  padding-left: 40px;
}

.small_title span {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.publikimet_heading {
  position: relative;
}

.publikimet_heading > span {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  display: none !important;
}

.publikimet_heading h2 {
  margin-left: 0 !important;
  padding-left: 52px;
  position: relative;
}

.publikimet_heading h2:before {
  content: " ";
  height: 5px;
  width: 20px;
  position: absolute;
  background: #e2001a;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
}

.publikimet_heading select {
  width: 290px;
  width: 18.125rem;
  height: 50px;
  height: 3.125rem;
  padding: 0 20px;
  padding: 0 1.25rem;
  display: flex;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  color: #231f20;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  outline: none;
  background: url(../images/select_down_arrow.svg) no-repeat;
  background-position: calc(100% - 20px) center;
  appearance: none;
  background-size: 9px 6px;
}

@media only screen and (max-width: 767px) {
  .publikimet_heading {
    height: auto !important;
  }
  .publikimet_heading select {
    margin-right: 0;
    width: 100%;
    margin-bottom: 20px;
  }
}

.kryetaret_header {
  position: relative;
}

.kryetaret_header > span {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  display: none !important;
}

.kryetaret_header h2 {
  margin-left: 0 !important;
  padding-left: 52px;
  position: relative;
}

.kryetaret_header h2:before {
  content: " ";
  height: 5px;
  width: 20px;
  position: absolute;
  background: #e2001a;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
}

.kryetaret_header form {
  border: 0 !important;
  border-radius: 0 !important;
}

@media only screen and (max-width: 767px) {
  .kryetaret_header {
    height: auto !important;
  }
  .kryetaret_header select {
    margin-right: 0;
    width: 100%;
    margin-bottom: 20px;
  }
}

.form_columns select {
  background: url(../images/select_down_arrow.svg) no-repeat;
  background-position: calc(100% - 20px) center;
  appearance: none;
  background-size: 9px 6px;
  padding-right: 46px;
}

.single_post section {
  text-align: left;
}

.single_post .single_kandidati_heading {
  max-width: 840px;
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .single_post .single_kandidati_heading {
    max-width: 67%;
  }
}

.single_post__excerpt {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 37px;
  margin-bottom: 2.3125rem;
  color: #231f20;
}

.single_post__excerpt p {
  line-height: 1.8em !important;
  margin-bottom: 0 !important;
}

.single_post__content {
  font-size: 15px;
  color: #666;
}

.single_post__content ul {
  list-style-type: disc;
  padding-left: 22px;
  margin-bottom: 26px;
  margin-bottom: 1.625rem;
  line-height: 1.93em;
}

.single_post__content ol {
  list-style-type: decimal;
  padding-left: 22px;
  margin-bottom: 26px;
  margin-bottom: 1.625rem;
  line-height: 1.93em;
}

.single_post__content h2, .single_post__content h3, .single_post__content h4, .single_post__content h5 {
  margin-top: 15px;
  margin-top: 0.9375rem;
  margin-bottom: 26px;
  margin-bottom: 1.625rem;
  line-height: 1.93em;
  color: #231f20;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .single_post__content h2, .single_post__content h3, .single_post__content h4, .single_post__content h5 {
    line-height: 1.2em;
  }
}

.single_post__content h2 {
  font-size: 35px;
}

.single_post__content h3 {
  font-size: 30px;
}

.single_post__content h4 {
  font-size: 28px;
}

.single_post__content h5 {
  font-size: 18px;
}

.single_post__content a {
  text-decoration: none;
  color: #666;
  position: relative;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
}

.single_post__content a:after {
  content: " ";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #e2001a;
}

.single_post__content a:hover {
  color: #e2001a;
}

.single_post__content b, .single_post__content strong {
  font-weight: 700;
}

.single_post__content i, .single_post__content em {
  font-style: italic;
}

.single_post__content blockquote, .single_post__content q {
  padding-left: 30px;
  padding-left: 1.875rem;
  font-style: italic;
  font-size: 18px;
  quotes: "“" "”";
  position: relative;
}

.single_post__content blockquote:before, .single_post__content q:before {
  content: open-quote;
  float: left;
  color: #e2001a;
  font-weight: bold;
  font-size: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.single_post__content blockquote:after, .single_post__content q:after {
  content: close-quote;
  float: right;
  color: #e2001a;
  font-weight: bold;
  font-size: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.single_post__content iframe, .single_post__content .fb_iframe_widget, .single_post__content .instagram-media, .single_post__content .twitter-tweet {
  margin-bottom: 26px;
  margin-bottom: 1.625rem;
  max-width: 100%;
  line-height: 1.93em;
}

.single_post__content iframe span, .single_post__content .fb_iframe_widget span, .single_post__content .instagram-media span, .single_post__content .twitter-tweet span {
  max-width: 100% !important;
}

.single_post__content figure {
  margin-bottom: 26px;
  margin-bottom: 1.625rem;
  line-height: 1.93em;
}

.single_post__content figure figcaption {
  line-height: 1.1em;
  font-size: 13px;
  font-weight: bold;
}

.single_post__content figure a {
  text-decoration: none;
}

.single_post__content figure a:after {
  display: none;
}

.single_post__content table {
  line-height: 1.93em;
  margin-bottom: 26px;
  margin-bottom: 1.625rem;
  border: 1px solid #b9b9b9;
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
}

@media only screen and (max-width: 767px) {
  .single_post__content table {
    table-layout: fixed;
    word-break: break-all;
  }
}

.single_post__content table thead {
  background-color: #e0e0e0;
  color: #000;
  text-align: left;
  vertical-align: bottom;
}

.single_post__content table tr {
  border-top: 1px solid #b9b9b9;
}

.single_post__content table th, .single_post__content table td {
  padding: 0.5em 1em;
}

.single_post__content table td, .single_post__content table th {
  line-height: 1.2em;
}

.single_post__content table th {
  background-color: #e0e0e0;
  color: #000;
  text-align: left;
  vertical-align: bottom;
  font-weight: bold;
}

.single_post__content table td, .single_post__content table th {
  border-width: 0 0 0 1px;
  font-size: inherit;
  margin: 0;
  overflow: visible;
  padding: .5em 1em;
  border-right: 1px solid #b9b9b9;
}

.single_post__content table tr:nth-child(2n-1) td {
  background-color: #efefef;
}

.footer_banner {
  float: left;
  width: 100%;
  background: #e2001a;
  color: white;
  display: inline-block;
  height: 200px;
  height: 12.5rem;
  padding-top: 37px;
  padding-top: 2.3125rem;
  padding-bottom: 37px;
  padding-bottom: 2.3125rem;
  font-weight: 300;
}

.footer_banner .rasti_10_shkurtit {
  float: left;
  display: inline-block;
}

.footer_banner .rasti_10_shkurtit .dhjete_shkurti_img {
  float: left;
  margin-right: 32px;
  margin-right: 2rem;
}

.footer_banner .rasti_10_shkurtit .dhjete_shkurti_text {
  float: left;
  margin-top: 16px;
  margin-top: 1rem;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .footer_banner .rasti_10_shkurtit {
    width: 50%;
  }
  .footer_banner .rasti_10_shkurtit .dhjete_shkurti_img {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
  .footer_banner .rasti_10_shkurtit .dhjete_shkurti_text {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-weight: 300;
  }
  .footer_banner .rasti_10_shkurtit .dhjete_shkurti_text h4 {
    padding: 0 15%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .footer_banner .rasti_10_shkurtit {
    width: 50%;
  }
  .footer_banner .rasti_10_shkurtit .dhjete_shkurti_img {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
  .footer_banner .rasti_10_shkurtit .dhjete_shkurti_text {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-weight: 300;
  }
  .footer_banner .rasti_10_shkurtit .dhjete_shkurti_text h4 {
    padding: 0 15%;
  }
}

@media only screen and (max-width: 767px) {
  .footer_banner .rasti_10_shkurtit {
    width: 100%;
    margin-bottom: 40px;
  }
  .footer_banner .rasti_10_shkurtit .dhjete_shkurti_img {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
  .footer_banner .rasti_10_shkurtit .dhjete_shkurti_text {
    width: 100%;
    text-align: center;
    line-height: 23px;
    margin-top: 30px;
    font-weight: 300;
  }
  .footer_banner .rasti_10_shkurtit .dhjete_shkurti_text h4 {
    padding: 0;
  }
}

.footer_banner .astrit_dehari {
  float: right;
  display: inline-block;
  text-align: right;
}

.footer_banner .astrit_dehari .ah_img {
  float: right;
}

.footer_banner .astrit_dehari .ah_text {
  float: left;
  margin-right: 32px;
  margin-right: 2rem;
  margin-top: 16px;
  margin-top: 1rem;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .footer_banner .astrit_dehari {
    float: left;
    width: 50%;
  }
  .footer_banner .astrit_dehari .ah_img {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
  .footer_banner .astrit_dehari .ah_text {
    float: left;
    display: block !important;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    font-weight: 300;
  }
  .footer_banner .astrit_dehari .ah_text h4 {
    padding: 0 15%;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .footer_banner .astrit_dehari {
    float: left;
    width: 50%;
  }
  .footer_banner .astrit_dehari .ah_img {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
  .footer_banner .astrit_dehari .ah_text {
    float: left;
    display: block !important;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    font-weight: 300;
  }
  .footer_banner .astrit_dehari .ah_text h4 {
    padding: 0 15%;
  }
}

@media only screen and (max-width: 767px) {
  .footer_banner .astrit_dehari {
    float: left;
    width: 100%;
  }
  .footer_banner .astrit_dehari .ah_img {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
  .footer_banner .astrit_dehari .ah_text {
    float: left;
    display: block !important;
    width: 100%;
    text-align: center;
    line-height: 23px;
    margin-top: 30px;
    font-weight: 300;
  }
  .footer_banner .astrit_dehari .ah_text h4 {
    padding: 0;
  }
}

.footer_banner h3 {
  font-size: 27px;
  font-size: 1.6875rem;
  font-weight: 700;
  margin-bottom: 15px;
  margin-bottom: 0.9375rem;
}

@media only screen and (max-width: 767px) {
  .footer_banner h3 {
    margin-bottom: 10px;
  }
}

.footer_banner h4 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 300;
  margin-bottom: 15px;
  margin-bottom: 0.9375rem;
}

@media only screen and (max-width: 767px) {
  .footer_banner h4 {
    margin-bottom: 10px;
  }
}

.footer_banner .read_more {
  text-decoration: none;
  font-size: 14px;
  font-size: 0.875rem;
  color: white;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .footer_banner {
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .footer_banner {
    height: auto;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .footer_banner {
    height: auto;
  }
}

.footer_main {
  float: left;
  background: #f2f2f2;
  width: 100%;
  padding-top: 135px;
  padding-top: 8.4375rem;
}

.footer_main .footer_lists_content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 97px;
  margin-bottom: 6.0625rem;
  justify-content: space-between;
}

.footer_main .footer_lists_content ul {
  display: inline-block;
}

.footer_main .footer_lists_content ul .footer_list_heading {
  color: #151515;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
}

.footer_main .footer_lists_content ul .footer_list_item a {
  color: #666666;
  font-size: 15px;
  font-size: 0.9375rem;
  text-decoration: none;
}

.footer_main .footer_lists_content ul .footer_list_heading {
  margin-bottom: 32px;
  margin-bottom: 2rem;
}

.footer_main .footer_lists_content ul .footer_list_item {
  margin-bottom: 33px;
  margin-bottom: 2.0625rem;
}

.footer_main .footer_lists_content ul .footer_list_item:last-child {
  margin-bottom: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .footer_main .footer_lists_content ul {
    padding: 0 30px 0 0;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .footer_main .footer_lists_content ul {
    padding: 0 30px 0 0;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_main .footer_lists_content ul {
    width: 50%;
    margin-bottom: 30px;
  }
  .footer_main .footer_lists_content ul:nth-child(2n+2) {
    padding-left: 30px;
    padding-right: 0;
  }
  .footer_main .footer_lists_content ul .footer_list_heading {
    margin-bottom: 15px;
    margin-bottom: 0.9375rem;
  }
  .footer_main .footer_lists_content ul .footer_list_item {
    margin-bottom: 15px;
    margin-bottom: 0.9375rem;
  }
  .footer_main .footer_lists_content ul .footer_list_item:last-child {
    margin-bottom: 0;
    margin-bottom: 0;
  }
  .footer_main .footer_lists_content ul .footer_list_item a {
    line-height: 20px;
    font-size: 13px;
  }
}

.footer_main .footer_lists_content #invisible_element {
  color: transparent;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .footer_main .footer_lists_content {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .footer_main .footer_lists_content {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_main .footer_lists_content {
    margin-bottom: 10px;
    padding: 0 20px;
  }
}

.footer_main .footer_social_media li {
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
  margin-bottom: 10px;
  cursor: pointer;
}

.footer_main .footer_social_media li:last-child {
  margin-bottom: 0;
}

.footer_main .footer_social_media li a {
  font-size: 18px;
  font-size: 1.125rem;
  width: 50px;
  width: 3.125rem;
  height: 50px;
  height: 3.125rem;
  line-height: 50px;
  line-height: 3.125rem;
  float: left;
  text-align: center;
  background: white;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 0 129px -5px #9e909e;
  color: #cccccc;
}

.footer_main .footer_social_media li a:hover {
  background: #27ba9e;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
  color: white;
}

.footer_main .footer_social_media li:last-child {
  margin-bottom: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .footer_main .footer_social_media {
    text-align: center;
    padding-right: 0 !important;
    margin-bottom: 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .footer_main .footer_social_media {
    width: 100% !important;
    text-align: center;
    padding-right: 0 !important;
    margin-bottom: 0 !important;
  }
  .footer_main .footer_social_media ul {
    padding-right: 0 !important;
  }
  .footer_main .footer_social_media li {
    float: none;
    width: auto;
    clear: none;
    display: inline-block;
    margin-bottom: 0 !important;
    margin-right: 10px;
  }
  .footer_main .footer_social_media li:last-child {
    margin-right: 0;
  }
}

.footer_main .footer_copyright_logo {
  margin-bottom: 185px;
  margin-bottom: 11.5625rem;
}

.footer_main .footer_copyright_logo .footer_logo_img {
  float: none;
  width: 176px;
  width: 11rem;
  height: 23px;
  height: 1.4375rem;
  margin: 0 auto 1.3125rem auto;
}

.footer_main .footer_copyright_logo .copy_right {
  text-align: center;
  color: #666666;
  font-size: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .footer_main .footer_copyright_logo {
    float: left;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .footer_main .footer_copyright_logo {
    float: left;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_main .footer_copyright_logo {
    margin-bottom: 50px;
  }
  .footer_main .footer_copyright_logo .copy_right {
    font-size: 13px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .footer_main {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .footer_main {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_main {
    padding-top: 50px;
  }
}

.cta_col a {
  text-decoration: none;
  color: white;
}

@media screen and (min-width: 1025px) and (max-width: 1309px) {
  .container {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .lajmi_item, .lajmi_item_big {
    height: auto !important;
  }
  .lajmi_item a, .lajmi_item_big a {
    float: left;
    width: 100%;
    clear: both;
  }
  .lajmi_item:before, .lajmi_item_big:before {
    display: none !important;
  }
  .lajmi_item_big {
    margin-bottom: 40px;
  }
  .column_item_text {
    float: left;
    width: 100%;
    clear: both;
    position: static !important;
    margin-top: 25px;
    height: auto !important;
  }
  .column_item_text p {
    color: #666 !important;
    font-size: 13px !important;
    font-weight: 300;
  }
  .column_item_text > a {
    font-size: 16px !important;
    max-height: 100% !important;
    font-weight: 700 !important;
    color: #231f20 !important;
    line-height: 1.32em !important;
  }
  .lajmet_block {
    display: flex;
    flex-wrap: wrap;
  }
  .lajmet_block .mini_lajmet {
    order: 2;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .facebook_column {
    width: 100% !important;
    margin-right: 0;
    text-align: center;
  }
  .facebook_column iframe {
    display: inline-block;
  }
  .astrit_dehari {
    text-align: center !important;
  }
  .astrit_dehari a {
    display: flex !important;
    flex-wrap: wrap;
  }
  .astrit_dehari .ah_text {
    width: 100%;
    margin-right: 0 !important;
    margin-top: 20px !important;
    order: 2;
  }
  .cta_col h4 {
    line-height: 1.4em;
  }
  .footer_social_media {
    width: 100% !important;
    text-align: center;
    padding-right: 0 !important;
    margin-bottom: 20px !important;
  }
  .footer_social_media ul {
    padding-right: 0 !important;
  }
  .footer_social_media li {
    float: none !important;
    width: auto !important;
    clear: none !important;
    display: inline-block;
    margin-bottom: 0 !important;
    margin-right: 10px;
  }
  .footer_social_media li:last-child {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .facebook_column {
    text-align: center;
  }
  .facebook_column iframe {
    display: inline-block;
  }
  .astrit_dehari {
    text-align: center !important;
  }
  .astrit_dehari a {
    display: flex !important;
    flex-wrap: wrap;
  }
  .astrit_dehari .ah_text {
    width: 100%;
    margin-right: 0 !important;
    margin-top: 20px !important;
    order: 2;
  }
  .cta_col h4 {
    line-height: 1.4em;
  }
  .kontribo_anetaresohu {
    overflow: hidden;
    border-bottom: 10px solid #e5e5e5;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .facebook_column {
    text-align: center;
  }
  .facebook_column iframe {
    display: inline-block;
  }
  .astrit_dehari {
    text-align: center !important;
  }
  .astrit_dehari a {
    display: flex !important;
    flex-wrap: wrap;
  }
  .astrit_dehari .ah_text {
    width: 100%;
    margin-right: 0 !important;
    margin-top: 20px !important;
    order: 2;
  }
  .kontribo_anetaresohu {
    overflow: hidden;
    border-bottom: 10px solid #e5e5e5;
  }
}

@media only screen and (max-width: 767px) {
  .donacione_tabs {
    position: relative;
    overflow: hidden;
  }
  .kontribo_anetaresohu {
    overflow: hidden;
  }
}

@media (min-width: 1100px) and (max-width: 1330px) {
  .media_grid_view .lajmet_block {
    width: 905px;
  }
  .media_grid_view .lajmet_block .mini_lajmet {
    width: 275px;
  }
  .media_grid_view .lajmi_item_big {
    width: 590px;
  }
  .media_grid_view .lajmet_e_fundit_block {
    width: 238px;
  }
  .media_grid_bottom_block {
    display: flex;
    justify-content: space-between;
  }
  .bottom_block_item {
    width: calc(25% - 20px) !important;
    margin-right: 0 !important;
  }
  .bottom_block_item .lajme_icon {
    left: 20px !important;
  }
  .bottom_block_item .lajme_text {
    left: 79px !important;
  }
}

.main_header {
  position: relative;

  .headerWrap {
    @include rem('height', 80px);
    position: relative;
    box-shadow: -3px -2px 102px 0px rgba(0, 0, 0, 0.18);
    z-index: 99;

    @include mobile {
      height: 60px;
    }
  }
}

.logo {
  float: left;
  @include rem('width', 245px);
  height: auto;
  @include rem('margin-left', 24px);
  @include rem('margin-top', 23px);

  img {
    @extend %imgCropped;
  }

  @include mobile {
    float: left;
    width: 180px;
    height: 100%;
    margin-left: 15px;
    margin-top: 0;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: 19px;
    }
  }

  @media (max-width: 320px) {
    width: 150px;
  }

  @media screen and (min-width: 1025px) and (max-width:1440px) {
    width: 205px;
  }
}

.header_nav_bar {
  float: left;
  height: 100%;
  @include rem('margin-left', 45px);

  li {
    height: 100%;
    display: inline-flex;
    align-items: center;

    a {
      text-decoration: none;
      color: #231f20;
      @include rem('margin-right', 29px);
      @include rem('font-size', 20px);

    }

    &:last-child {
      @include rem('margin-right', 0);
    }

    &:hover {
      cursor: pointer;

      a {
        color: #e2001a;
      }

      .nav_extension {

        visibility: visible;
        opacity: 1;
        display: block;
      }
    }
  }

  @include tablet {
    display: none;
  }

  @include tablet-big {
    display: none;
  }

  @include mobile {
    display: none;
  }

  @media screen and (min-width: 1025px) and (max-width:1440px) {
    margin-left: 40px;

    li {
      a {
        font-size: 16px;
        margin-right: 25px;
      }
    }
  }
}

.active_link {
  color: #e2001a !important;
}

.hide_desktop {
  display: none !important;
}

.hide_tablet {
  @include tablet {
    display: none !important;
  }

  @include tablet-big {
    display: none !important;
  }
}

.hide_mobile {
  @include mobile {
    display: none !important;
  }
}


.search_icon {
  float: left;
  text-decoration: none;
  font-family: 'Font Awesome 5 Pro Light';
  font-weight: 400;
  @include rem('font-size', 16px);
  @include rem('margin-left', 25px);
  @include rem('margin-top', 33px);

  .fa-search {
    color: #231f20;
  }

  &:hover {
    .fa-search {
      color: #e2001a;
    }
  }

  @include tablet {
    margin-left: 40%;
  }

  @include tablet-big {
    margin-left: 55%;
  }

  @include mobile {
    margin-top: 25px;
    margin-left: 25px;
    @include rem('font-size', 12px);
  }

  @media screen and (min-width: 1025px) and (max-width:1440px) {
    margin-left: 15px;
  }
}

.languages {
  float: left;
  @include rem('margin-top', 30px);

  li {
    @include rem('margin-left', 25px);

    a {
      text-decoration: none;
      color: #b3b3b3;
      @include rem('font-size', 20px);

      @include mobile {
        @include rem('font-size', 16px);
      }
    }

    &:hover {
      a {
        color: #e2001a;
      }
    }
  }

  @include mobile {
    margin-top: 23px;
  }

  @media screen and (min-width: 1025px) and (max-width:1440px) {
    margin-top: 32px;

    li {
      a {
        font-size: 16px;
      }
    }
  }
}

.right_header {
  float: right;
  height: 100%;
  display: flex;
  align-items: center;
}

.header_social_media {
  float: left;

  li {
    display: inline-block;

    a {
      font-family: 'Font Awesome 5 Pro Light';
      @include rem('font-size', 18px);
      color: #b3b3b3;
      @include rem('margin-right', 26px);
      text-decoration: none;

      &:hover {
        transition: $transition1;
        color: #27ba9e;
      }
    }
  }

  @include tablet {
    display: none;
  }

  @include tablet-big {
    display: none;
  }

  @include mobile {
    display: none;
  }

  @media screen and (min-width: 1025px) and (max-width:1440px) {
    li {
      a {
        margin-right: 20px;
        font-size: 16px;
      }
    }
  }
}

.donation_activists {
  float: left;
  border: 2px solid #e6e6e6;
  border-radius: 2px;
  text-decoration: none;

  @include rem('margin-right', 18px);
  @include rem('padding', 18px 21px 15px 18px);

  i {

    @include rem('font-size', 14px);
    color: #27ba9e;
    font-weight: bold;
    @include rem('padding-right', 17px);
    padding-right: 17px;
    font-family: 'Font Awesome 5 Pro Light';

  }

  span {
    @include rem('font-size', 15px);
    font-weight: 700;
    color: #231f20;

  }

  &:hover {
    border: 2px solid #27ba9e;
    transition: $transition1;
  }

  @include tablet {
    display: none;
  }

  @include tablet-big {
    display: none;
  }

  @include mobile {
    display: none;
  }

  @media screen and (min-width: 1025px) and (max-width:1440px) {
    padding: 15px;
    margin-right: 15px;

    span {
      font-size: 13px;
    }

  }
}

#nav_btn {
  display: block;
  float: right;
  @include rem('height', 80px);
  @include rem('width', 80px);
  background: #27ba9e;
  cursor: pointer;
  @include flexCenter(true, true);

  span, span::before, span::after {
    @include rem('width', 21px);
    @include rem('height', 2px);
    float: left;
    display: block;
    background: #fff;
    position: relative;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;

    @include mobile {
      width: 17px;
    }
  }

  span {
    margin: 8px 0;
  }

  span::before, span::after {
    content: '';
    position: absolute;
  }

  span::before {
    top: -8px;
  }

  span::after {
    bottom: -8px;
  }

  &.active span {
    background-color: transparent;
  }

  &.active span::before, .active span::after {
    top: 0;
  }

  &.active span:before {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  &.active span::after {
    transform: translateY(-10px) rotate(-45deg);
    -webkit-transform: translateY(-10px) rotate(-45deg);
    top: 10px;
  }

  @include mobile {
    @include rem('height', 60px);
    @include rem('width', 60px);
  }
}

.nav_extension {
  float: left;
  width: 100%;
  @include rem('height', 250px);
  position: absolute;
  top: 80px;
  left: 0;
  background: rgba(102, 102, 102, 0.9);
  z-index: 1;
  display: none;
  opacity: 0;
  visibility: hidden;
  animation: fadeIn 0.5s;
  @include rem('padding-left', 0);
  @include rem('padding-right', 0);
  @include rem('padding-bottom', 75px);

  .levizja_suboptions {
    float: left;
    width: 100%;
    @include rem('font-size', 15px);
    @include rem('margin-right', 53px);
    @include rem('padding-top',75px); 

    &:last-child {
      @include rem('margin-right', 0);
    }

    li {
      display: block;
      @include rem('margin-bottom', 13px);
      float: left;
      width: 25%; 

      a {
        @include rem('padding-bottom', 10px);
        @include rem('padding-right', 10px);
        text-decoration: none;
        color: white;
				position: relative;
				float: left; 
				&:after {
					content: " ";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 3px;
					background:transparent;
					transition: $transition1;
				}
        &:hover {
          cursor: pointer;
          &:after {
						background:white;
          }
        }
      }
    }
  }

  &:hover {
    visibility: visible;
    opacity: 1;
    display: block;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @media screen and (min-width: 1025px) and (max-width:1440px) {
    height: 200px;
    padding-left: 268px;

    .levizja_suboptions {
      margin-top: 40px !important;
    }
  }
}

.hamburger_menu_toggle {
  @include rem('width', 290px);
  height: auto;
  background: #fff;
  z-index: 999;
  position: absolute;
  right: 0;
  top: 80px;
  display: none;
  -webkit-box-shadow: 0px 0px 129px -23px rgba(158, 144, 158, 1);
  -moz-box-shadow: 0px 0px 129px -23px rgba(158, 144, 158, 1);
  box-shadow: 0px 0px 129px -23px rgba(158, 144, 158, 1);
  @include rem('padding-top', 50px);
  @include rem('padding-left', 50px);

  ul {
    display: block;

    a {
      transition: $transition1;
      text-decoration: none;
      color: #231f20;
    }

    .list_heading {
      @include rem('font-size', 18px);
      font-weight: 700;
      @include rem('margin-bottom', 36px);
    }

    .menu_list_item {
      @include rem('font-size', 16px);
      font-weight: 400;
      @include rem('margin-bottom', 23px);

      &:last-child {
        margin-bottom: 44px;
      }

      &:hover {
        a {
          color: #e2001a;
        }
      }
    }

    @include tablet {
      display: none;
    }

    @include tablet-big {
      display: none;
    }

    @include mobile {
      display: none;
    }
  }

  @include mobile {
    width: 100%;
    padding: 0;

    .hamburger_menu_content {
      display: block !important;
      @include fullBlock;
      height: 100%;

      .hamburger_menu_heading {
        @include fullBlock;
        border-bottom: 1px solid #e6e6e6;
        padding: 30px;

        .hamburger_donation_activists {
          float: left;
          text-decoration: none;
          @include rem('margin-right', 9px);

          i {

            @include rem('font-size', 14px);
            color: #27ba9e;
            font-weight: bold;
            @include rem('padding-right', 17px);
            font-family: 'Font Awesome 5 Pro Light';
          }

          span {
            @include rem('font-size', 15px);
            font-weight: 700;
            color: #231f20;
          }

          @include mobile {
            i {
              @include rem('font-size', 13px);
              padding-right: 10px;
            }

            span {
              @include rem('font-size', 14px);
            }

          }
        }

        .hamburger_social {
          float: right;
          display: inline-block !important;

          li {
            display: inline-block;

            a {
              font-family: 'Font Awesome 5 Pro Light';
              @include rem('font-size', 15px);
              color: #b3b3b3;
              @include rem('margin-left', 15px);
              text-decoration: none;
              transition: $transition1;

              &:last-child {
                margin-right: 0;
              }

              &:focus {
                color: #27ba9e;
              }

              @include mobile {
                margin-left: 8px !important;
              }

            }

          }
         @media (max-width: 320px) { 
              width: 100%;
              float: left; 
              margin-top: 5px;
              li a { 
                margin-left: 0 !important;
                margin-right: 10px !important;
              }
            }
        }

        @include mobile {
          padding: 25px 15px;
        }

        @media (max-width: 320px) {
          .hamburger_donation_activists {
            margin-right: 10px;

            span {
              font-size: 12px
            }
          }

          .hamburger_social {
            i {
              font-size: 12px;
            }
          }
        }
      }

      .hamburger_menu_accordion {
        @include fullBlock;
        padding: 50px 40px;
        display: inline-block !important;

        .accordion_hamburger {
          display: inline-block !important;
          @include fullBlock;

          li {
            float: left;
            width: 100%;
            margin-bottom: 30px;

            &:last-child {
              margin-bottom: 0;
            }

            &.is--active {
              .toggle_hamburger_menu {
                color: #e2001a !important;
              }
            }

            a.toggle_hamburger_menu {
              width: 100% !important;
              font-size: 16px;
              color: #231f20;
              font-weight: 700;
              display: flex;
              justify-content: space-between;

              i {
                color: #231f20;
              }
            }

            .inner_list {
              @include fullBlock;
              padding: 30px 0 0 0;

              li {
                @include fullBlock;
                margin-bottom: 25px;

                &:last-child {
                  margin-bottom: 0;
                }

                a {
                  color: #b3b3b3;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  @include tablet {
    width: 60%;
    padding: 0;

    .hamburger_menu_content {
      display: block !important;
      @include fullBlock;
      height: 100vh;

      .hamburger_menu_heading {
        @include fullBlock;
        border-bottom: 1px solid #e6e6e6;
        padding: 30px;

        .hamburger_donation_activists {
          float: left;
          text-decoration: none;
          @include rem('margin-right', 18px);

          i {

            @include rem('font-size', 14px);
            color: #27ba9e;
            font-weight: bold;
            @include rem('padding-right', 15px); 
            font-family: 'Font Awesome 5 Pro Light';
          }

          span {
            @include rem('font-size', 15px);
            font-weight: 700;
            color: #231f20;
          }
        }

        .hamburger_social {
          float: right;
          display: inline-block !important;

          li {
            display: inline-block;

            a {
              font-family: 'Font Awesome 5 Pro Light';
              @include rem('font-size', 15px);
              color: #b3b3b3;
              @include rem('margin-left', 15px);
              text-decoration: none;
              transition: $transition1;

              &:last-child {
                margin-right: 0;
              }

              &:focus {
                color: #27ba9e;
              }
            }

          }
        }
      }

      .hamburger_menu_accordion {
        @include fullBlock;
        padding: 50px 40px;
        display: inline-block !important;

        .accordion_hamburger {
          display: inline-block !important;
          @include fullBlock;

          li {
            float: left;
            width: 100%;
            margin-bottom: 30px;

            &:last-child {
              margin-bottom: 0;
            }

            &.is--active {
              .toggle_hamburger_menu {
                color: #e2001a !important;
              }
            }

            a.toggle_hamburger_menu {
              width: 100% !important;
              font-size: 16px;
              color: #231f20;
              font-weight: 700;
              display: flex;
              justify-content: space-between;

              i {
                color: #231f20;
              }
            }

            .inner_list {
              @include fullBlock;
              padding: 30px 0 0 0;

              li {
                @include fullBlock;
                margin-bottom: 25px;

                &:last-child {
                  margin-bottom: 0;
                }

                a {
                  color: #b3b3b3;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  @include tablet-big {
    width: 60%;
    padding: 0;

    .hamburger_menu_content {
      display: block !important;
      @include fullBlock;
      height: 100%;

      .hamburger_menu_heading {
        @include fullBlock;
        border-bottom: 1px solid #e6e6e6;
        padding: 30px;

        .hamburger_donation_activists {
          float: left;
          text-decoration: none;
          @include rem('margin-right', 18px);

          i {

            @include rem('font-size', 14px);
            color: #27ba9e;
            font-weight: bold;
            @include rem('padding-right', 17px);
            font-family: 'Font Awesome 5 Pro Light';
          }

          span {
            @include rem('font-size', 15px);
            font-weight: 700;
            color: #231f20;
          }
        }

        .hamburger_social {
          float: right;
          display: inline-block !important;

          li {
            display: inline-block;

            a {
              font-family: 'Font Awesome 5 Pro Light';
              @include rem('font-size', 15px);
              color: #b3b3b3;
              @include rem('margin-left', 15px);
              text-decoration: none;
              transition: $transition1;

              &:last-child {
                margin-right: 0;
              }

              &:focus {
                color: #27ba9e;
              }
            }
          }
        }
      }

      .hamburger_menu_accordion {
        @include fullBlock;
        padding: 50px 40px;
        display: inline-block !important;

        .accordion_hamburger {
          display: inline-block !important;
          @include fullBlock;

          li {
            float: left;
            width: 100%;
            margin-bottom: 30px;

            &:last-child {
              margin-bottom: 0;
            }

            &.is--active {
              .toggle_hamburger_menu {
                color: #e2001a !important;
              }
            }

            a.toggle_hamburger_menu {
              width: 100% !important;
              font-size: 16px;
              color: #231f20;
              font-weight: 700;
              display: flex;
              justify-content: space-between;

              i {
                color: #231f20;
              }
            }

            .inner_list {
              @include fullBlock;
              padding: 30px 0 0 0;

              li {
                @include fullBlock;
                margin-bottom: 25px;

                &:last-child {
                  margin-bottom: 0;
                }

                a {
                  color: #b3b3b3;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  @include mobile {
    top: 60px;
  }
}

#search_form {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  @include rem('height', 80px);
  background: white;
  display: none;
  animation: popupIn 0.4s;

  input[type=search] {
    background: #fff url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
    width: 100%;
    height: 100%;
    text-align: center;
    border: solid 0px;
    padding: 9px 10px 9px 32px;
  }

  @keyframes popupIn {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @include mobile {
    height: 60px;
  }
}
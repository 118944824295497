html.lb-disable-scrolling {
  overflow: hidden;
  /* Position fixed required for iOS. Just putting overflow: hidden; on the body is not enough. */
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(255,255,255,.92);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: fixed ;
  left: 0;
  top: 0 !important;
  width: 100%;
  height: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

.lightbox .lb-image {
  display: block;
  width:70%;
  height: auto;
  // max-width: 70%;
  // max-height: auto;
  border-radius: 3px;
  margin: auto;

  /* Image border */
  border: 4px solid white;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 100% !important;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
  width: 34%;
}

.lb-nav a.lb-prev {
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
  margin-left: 50px;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  transition: all .3s cubic-bezier(.3,0,.2,1);
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  margin-right: 50px;
}

.lb-nav a.lb-next:hover {
  -webkit-filter: none; /* Safari 6.0 - 9.0 */
  filter: none;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
  color: black;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  margin-top: 10px;
  padding-bottom: 1em;
  font-size: 12px;
  color: black;
}

.lb-data .lb-close {
  position: absolute;
  top:0;
  right:0;
  margin-top: 20px;
  margin-right: 20px;
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}


// Error Message
.error-404-wrap {
  float:left;
  display: block;
  width: 100%;
  min-height: auto;
  margin: 0 auto;
  background: url(../images/background_404.svg) no-repeat 0 0;
  background-size: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 0;
}
.error-404 {
  float: none;
  margin: 0 auto;
  width: 405px;
  text-align: center;
}

.content_right {
  font-size: 17px;
  font-size: 1.0625rem;
}
.single_wrap {
  padding-top: 20px;
  padding-top: 1.25rem;
  margin-bottom: 51px;
  margin-bottom: 3.1875rem;
}


section{
  text-align: center;
}
.homePageLink{
  color:#e2001a;
}
.page-content{
  text-align:center;
}
.page-content p{
   font-family:"Museo Sans 300";
   font-size: 16px;
}
.page-content .img_404{ 
  width: 200px;
  margin: 0 auto;

}
.page-content .img_404 img{
  width:100%;
  height:auto;
  object-fit: cover;
 
}


@media (max-width: 767px){
  .error-404 {
      float: none;
      margin: 0 auto;
      width: 95%;
      text-align: center;
  }
  .page-content .img_404 {
      width: 130px;
      margin: 0 auto;
  }
}

@media (max-width: 320px){
   .error-404 {
      float: none;
      margin: 0 auto;
      width: 95%;
      text-align: center;
  }
  .page-content .img_404 {
      width: 130px;
      margin: 0 auto;
  }
}


// Confirm message


.confirmation-wrap{
  float:left;
  width:100%;
  margin-top: 30px;
  margin-bottom: 30px;
  height:auto;
}
.confirmation_heading{
  font-family: "Museo Sans 300";
  font-size: 21px;
  color: #666;
  font-weight: 700;
  width: 40%;
  margin: 0 auto;
  margin-bottom: 15px;
  text-align: center;
}
.confirmation_text{
   font-family:"Museo Sans 300";
  font-size:16px;
  color: #666;
  width: 40%;
  text-align: center;
  margin: 0 auto;
}


@media (min-width: 991px) and (max-width: 1199px)  {
  .confirmation_heading {
      font-family: "Museo Sans 300";
      font-size: 18px;
      color: #666;
      font-weight: 700;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 15px;
      text-align: center;
      font-size: 18px;
  }
  .confirmation_text {
      font-family: "Museo Sans 300";
      font-size: 14px;
      color: #666;
      width: 77%;
      text-align: center;
      margin: 0 auto;
  }
}
@media (min-width: 768px) and (max-width: 990px){
  .confirmation-wrap {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
  }
  .confirmation_heading {
      font-family: "Museo Sans 300";
      font-size: 18px;
      color: #666;
      font-weight: 700;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 15px;
      text-align: center;
      font-size: 18px;
  }
  .confirmation_text {
      font-family: "Museo Sans 300";
      font-size: 14px;
      color: #666;
      width: 77%;
      text-align: center;
      margin: 0 auto;
  }
}
@media (max-width: 767px){
  .confirmation-wrap {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
  }
  .confirmation_heading {
      font-family: "Museo Sans 300";
      font-size: 18px;
      color: #666;
      font-weight: 700;
      width: 100% ; 
      margin-bottom: 15px;
      text-align: center;
  }
  .confirmation_text {
      font-family: "Museo Sans 300";
      font-size: 12px;
      color: #666;
      width: 85%;
      text-align: center;
      margin: 0 auto;
  }
  
}

@media (max-width: 320px){
  .confirmation-wrap {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
  }
  .confirmation_heading {
      font-family: "Museo Sans 300";
      font-size: 18px;
      color: #666;
      font-weight: 700;
      width: 100% ; 
      margin-bottom: 15px;
      text-align: center;
  }
  .confirmation_text {
      font-family: "Museo Sans 300";
      font-size: 12px;
      color: #666;
      width: 85%;
      text-align: center;
      margin: 0 auto;
  }

}

// te harta ne homepage 

#citiesMap path.active {
  fill: #e2001a;
}